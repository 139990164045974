<div class="main-container" *ngIf="user && authConfig; else loader">

  <mat-card *ngIf="!mustRestart else fatalError">
    <mat-card-header class="card-header">
      <mat-card-title color="primary">
        <span class="title">
          <mat-icon>admin_panel_settings</mat-icon>

          {{ 'totp.title' | trans}}
        </span>
        <div class="state border " [ngClass]="user.totpEnrolled ? 'active accent' : 'inactive warn' "
          matTooltip="{{ user.totpEnrolled ? 'Actif depuis le '+(user.totpSince | date:'short') : ''}}">
          <mat-icon>{{user.totpEnrolled ? 'verified_user' : 'privacy_tip'}}</mat-icon>
          <mat-label>{{(user.totpEnrolled ? 'totp.status_active':'totp.statut_inactive' ) | trans}}</mat-label>
        </div>
      </mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <!-- <div style="color: red;">For debug / step : {{step}}</div> -->
      <!-- <div style="color: red;">For debug / activationError : {{activationError}}</div> -->

      <!-- Description when user not yet enroled -->
      <div *ngIf="!step && !user.totpEnrolled" class="description-container">
        <div class="help-msg" [innerHTML]="authConfig.doubleAuth.msgDescriptionBeforeActivation |safe"></div>
        <button mat-raised-button color="accent" (click)="getKey()">
          <mat-icon>verified_user</mat-icon>
          {{'totp.activate_button' | trans}}
        </button>
      </div>

      <!-- Description when user is enroled -->
      <div *ngIf="!step && user.totpEnrolled" class="description-container">
        <div class="help-msg" [innerHTML]="authConfig.doubleAuth.msgDescriptionAfterActivation |safe"></div>

        <button *ngIf="user.totpEnrolled" mat-raised-button color="primary" (click)="getKey()">
          <mat-icon>edit</mat-icon>
          {{'totp.reactivate_button' | trans}}
        </button>

        <!-- <button *ngIf="user.totpEnrolled" mat-raised-button color="warn" (click)="step = 'waitingForPassword'">
          <mat-icon>remove_moderator</mat-icon>
          Je souhaite désactiver cette sécurité
        </button> -->
      </div>


      <!-- Check password before activation -->
      <div *ngIf=" step === 'waitingForPassword' || step === 'waitingForKey' " class="message-and-form-container">

        <ng-container *ngIf="!user.fromOidc else loader;">

          <h3>Vérification de votre mot de passe</h3>

          <div class="help-msg">
            Pour votre sécurité, veuillez d'abord renseigner votre mot de passe
          </div>

          <form [formGroup]="passwordForm" (ngSubmit)="getKey()" class="password-form">
            <mat-form-field class="password">
              <mat-label>Mot de passe</mat-label>
              <input #passwordInput [type]="showPass ? 'text' : 'password'" matInput formControlName="password"
                [appFocusOnShow]="true">
              <mat-icon matSuffix (click)="showPass = !showPass" style="cursor:pointer;">
                {{ showPass ? 'visibility_off' : 'visibility' }}
              </mat-icon>
              <mat-error>
                {{passwordError}}
              </mat-error>

            </mat-form-field>

            <button type="submit" mat-raised-button color="primary" (click)="getKey()"
              [disabled]=" step === 'waitingForKey' || !passwordForm.valid">
              Valider
              <app-loader *ngIf="step === 'waitingForKey'" size="0.75"></app-loader>
            </button>

            <a (click)="cancel()">Annuler</a>
          </form>
        </ng-container>

      </div>


      <!-- Activation -->
      <div *ngIf="step === 'waitingForTotpCode' || step === 'totpActivation'" class="activation-container">

        <div class="qrcode-container">
          <qrcode [qrdata]="activationData.totpURL" [allowEmptyString]="true"
            [ariaLabel]="'QR Code : '+activationData.totpURL" [elementType]="'canvas'" [errorCorrectionLevel]="'M'"
            [title]="'QR Code pour l\'activation de la double authentification'" [width]="250">
          </qrcode>

          <div class="key-displayer">
            <span matTooltip="Clé copiée" [matTooltipDisabled]="true" matTooltipPosition="above"
              #keyDisplayTooltip="matTooltip">
              {{activationData.totpKey }}
            </span>


            <button mat-mini-fab color="primary-lighter" matTooltip="Copier la clé dans le presse papier"
              matTooltipPosition="above" [cdkCopyToClipboard]="activationData.totpKey"
              (cdkCopyToClipboardCopied)="showCopiedTooltip($event)">
              <mat-icon>
                content_copy
              </mat-icon>
            </button>

          </div>

        </div>

        <div class="message-and-form-container">
          <div class="help-msg" [innerHTML]="authConfig.doubleAuth.msgHelpActivation | dynamic:activationData | safe  ">
          </div>


          <form [formGroup]="activationForm" (ngSubmit)="activate()" class="activation-form">

            <mat-form-field class="totp-code-input">
              <mat-label>Code </mat-label>
              <input #codeIntput type="text" matInput formControlName="activationCode" required minlength="6"
                maxlength="6" size="6" aria-label="Saisie du Code TOTP" cdkFocusInitial [appFocusOnShow]="true">

              <!-- <totp-code-input [(ngModel)]="validationCode" name="test">
              </totp-code-input> -->
              <mat-error>
                {{activationError}}
              </mat-error>

            </mat-form-field>

            <button type="submit" mat-raised-button color="accent" (click)="activate()"
              [disabled]=" step === 'totpActivation' || !activationForm.valid">
              <mat-icon>verified_user</mat-icon>
              Activer
              <app-loader *ngIf="step === 'totpActivation'" size="0.75"></app-loader>
            </button>

            <a (click)="cancel()">Annuler</a>

          </form>

        </div>

      </div>

    </mat-card-content>

  </mat-card>

</div>



<ng-template #loader>
  <app-loader [message]="waitingMessage" [ngClass]="(user && authConfig)? '' : 'margin' "></app-loader>
</ng-template>


<ng-template #fatalError>
  <div class="fatal-error">
    <mat-card>


      <mat-card-title class="mat-error">
        <mat-icon color="warn" style="float: left; margin-right: 10px;">error</mat-icon>
        <span>Erreur</span>
        <button mat-icon-button aria-label="dismiss" (click)="cancel()" style="float:right;">
          <mat-icon>clear</mat-icon>
        </button>

      </mat-card-title>
      <mat-card-content>
        <pre [innerHTML]="fatalErrorMsg | safe" class="mat-error"></pre>
      </mat-card-content>

    </mat-card>
  </div>
</ng-template>
