<mat-toolbar mat-dialog-title color="primary">
  <button mat-icon-button [mat-dialog-close] class="close-dialog">
    <mat-icon>close</mat-icon>
  </button>
  <span class="title">Filtre Etablissement/Accueil</span>
</mat-toolbar>
<mat-dialog-content class="thin-scrollbar" fxLayout="column"
  style="padding: 10px 24px 24px 24px; width: 570px; height: 700px">
  <div fxLayout="row" fxLayoutAlign="space-between start" style="margin-top: 1em; position: relative;">
    <div class="recherche-filed">
      <mat-form-field class="no-hint">
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Rechercher">
      </mat-form-field>
    </div>


    <mat-tree [dataSource]="treeSource" [treeControl]="treeControl">
      <mat-tree-node *matTreeNodeDef="let accueil; when: isAccueil " matTreeNodeToggle matTreeNodePadding>
        <span class="node-title">
          <button mat-icon-button disabled></button>
          <mat-checkbox class="checklist-leaf-node" [checked]="selection.isSelected(accueil)"
            (change)="selection.toggle(accueil); onSelectionUpdate()">
            <i class="icon icodomino-accueil"></i>{{ accueil.name }}</mat-checkbox>
        </span>
      </mat-tree-node>

      <mat-nested-tree-node *matTreeNodeDef="let node; when: !isAccueil" matTreeNodePadding>
        <span class="node-title">

          <button type="button" mat-icon-button matTreeNodeToggle color="primary"
            [attr.aria-label]="'toggle ' + node.name "
            [disabled]="node.level == 'etablissement' && node.accueils.length == 0">
            <mat-icon>
              {{ treeControl.isExpanded(node) ? "keyboard_arrow_down" : "keyboard_arrow_right" }}</mat-icon>
          </button>

          <mat-checkbox [checked]="descendantsAllSelected(node)" [indeterminate]="descendantsPartiallySelected(node)"
            (change)="itemSelectionToggle(node); onSelectionUpdate()">
            {{ node.name }}</mat-checkbox>

        </span>
        <ul [class.tree-node-invisible]="!treeControl.isExpanded(node)">
          <ng-container matTreeNodeOutlet></ng-container>
        </ul>
      </mat-nested-tree-node>
    </mat-tree>
  </div>
</mat-dialog-content>

<div mat-dialog-actions>
  <button mat-raised-button class="btnAction" (click)="onSave()" color="primary" matTooltip="Enregistrer ">
    <mat-icon>save</mat-icon>
  </button>
</div>
