import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { ApiCrudService } from './api-crud.service';

import { EtablissementItemNode } from '@app/models/etablissement';


@Injectable({ providedIn: 'root' })
export class EtablissementService extends ApiCrudService<EtablissementItemNode> {


  urlEtb = 'conf/etablissements';


  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(
    protected http: HttpClient,) {
    super();
  }

  getAllEtablissement(): Observable<EtablissementItemNode[]> {
    return this.http.get<EtablissementItemNode[]>(`${this.urlEtb}`, this.httpOptions);
  }

  getTreeNodeChildren(node: EtablissementItemNode): EtablissementItemNode[] {
    return node.accueils || node.etablissements || [];
  }


}