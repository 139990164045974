<app-form-viewer *ngIf="!isLoading; else loader" [formFields]="form" [readOnly]="readOnly" [model]="data"
  (save)="onSave($event)" [formTitle]="formTitle" [typeForm]="typeForm">
</app-form-viewer>

<ng-template #loader>
  <div style="width: 100%; height: 50vh; display:flex;">
    <app-loader [message]="loadingMessage" isLoading="true" style="text-align: center;">
    </app-loader>
  </div>
</ng-template>
