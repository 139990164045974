<div *ngIf="regieConfig && (regieConfig.payMode === 'prepay' || payableFactures); else loader" class="main-container">

  <mat-card *ngIf="regieConfig.pausePaiement && regieConfig.pausePaiementMessage" class="primary-lighter-bg"
    style="margin-bottom: 10px;">
    <div fxLayout="row" fxLayoutAlign="start center">
      <mat-icon color="accent" style="margin-right: 24px;">info</mat-icon>
      <div [innerHTML]="regieConfig.pausePaiementMessage | safe"></div>
    </div>
  </mat-card>

  <mat-card *ngIf="regieConfig.payMode === 'prepay'" class="prepay">
    <mat-card-title>{{ 'facture.paiement.prepay.title'|trans }}</mat-card-title>

    <mat-card-content>
      <p>{{ 'facture.paiement.prepay.explain'|trans }}</p>

      <mat-form-field class="prepay-amount">
        <mat-label>{{ 'facture.paiement.prepay.amount'|trans }}</mat-label>
        <input matInput [(ngModel)]="selectedTotalAmount">
        <mat-icon matSuffix>euro_symbol</mat-icon>
      </mat-form-field>
    </mat-card-content>

    <mat-card-actions align="right">
      <button mat-raised-button color="primary" (click)="goPay()"
        [disabled]="regieConfig.pausePaiement || !loadingRegie">
        {{ 'facture.paiement.prepay.submit'|trans }}
      </button>
    </mat-card-actions>
  </mat-card>

  <ng-container *ngIf="regieConfig.payMode !== 'prepay'" [ngTemplateOutlet]="facturesTable"></ng-container>
</div>

<ng-template #facturesTable>
  <div class="header">
    <h3 *ngIf="payableFactures.length > 1">
      {{ 'facture.paiement.factures_non_soldees'|trans:{nb: payableFactures.length} }}</h3>
    <h3 *ngIf="payableFactures.length === 1">
      {{ 'facture.paiement.facture_non_soldee'|trans:{nb: payableFactures.length} }}</h3>

    <button *ngIf="multiplePayMode" mat-raised-button color="primary" (click)="goPay()"
      [disabled]="selectedTotalAmount === 0 || paying || regieConfig.pausePaiement">
      <span *ngIf="selectedTotalAmount === 0">
        {{ 'facture.paiement.pay_multi_empty'|trans }}
      </span>
      <span *ngIf="selectedTotalAmount > 0" class="row items-center">
        <mat-icon style="margin-right: 5px;">payment</mat-icon>
        {{ 'facture.paiement.pay_multi'|trans:{montant: selectedTotalAmount|number:'1.2'} }}
      </span>
      <app-loader *ngIf="paying" size="1"></app-loader>
    </button>
  </div>

  <table mat-table style="width: 100%;" [dataSource]="dataSource" class="mat-elevation-z2">
    <!-- Checkbox Column -->
    <ng-container matColumnDef="select" *ngIf="multiplePayMode">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="toggleMaster()" [checked]="isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let facture">
        <mat-checkbox (change)="toggleFacture(facture)" [checked]="selection.isSelected(facture)">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="numero">
      <th mat-header-cell *matHeaderCellDef>{{ 'facture.data.num'|trans }}</th>
      <td mat-cell *matCellDef="let facture">{{ facture.num }}</td>
    </ng-container>

    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef>{{ 'facture.data.date'|trans }}</th>
      <td mat-cell *matCellDef="let facture">{{ facture.date|date:'shortDate' }}</td>
    </ng-container>

    <ng-container matColumnDef="montant">
      <th mat-header-cell *matHeaderCellDef>{{ 'facture.data.montant'|trans }}</th>
      <td mat-cell *matCellDef="let facture">{{ facture.montant|currency:'EUR' }}</td>
    </ng-container>

    <ng-container matColumnDef="resteAPayer">
      <th mat-header-cell *matHeaderCellDef>{{ 'facture.data.reste'|trans }}</th>
      <td mat-cell *matCellDef="let facture"><b>{{ facture.resteAPayer|currency:'EUR' }}</b></td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>{{ 'facture.table.actions'|trans }}</th>
      <td mat-cell *matCellDef="let facture">

        <button *ngIf="!multiplePayMode" mat-raised-button [matTooltip]="'facture.paiement.pay_one_tooltip'|trans"
          color="primary" (click)="goPay(facture)" [disabled]="paying || regieConfig.pausePaiement || !loadingRegie">
          <mat-icon>payment</mat-icon>
          {{ 'facture.paiement.pay_one'|trans }}
          <app-loader *ngIf="paying" size="1"></app-loader>
        </button>

        <button mat-icon-button [matTooltip]="'facture.action.details_tooltip'|trans" (click)="openDetail(facture)">
          <mat-icon color="primary">more_vert</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</ng-template>

<ng-template #loader>
  <app-loader class="margin"></app-loader>
</ng-template>
