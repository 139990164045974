import { FormControl, ValidationErrors } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';

// List of accepted separators : "-", " ", "." (could be updated)
//const pattern = /^(0|(\\+33)|(0033))[1-9][-\.|\s]?([\d][-\.|\s]?){8}$/;

// Pour l'instant, je desactive le masque car bloquant pour les n° étrangers (demande d'Hegenheim)
// Demande urgente donc pas le temps de chercher un truc "internationnal" ... on verra ça plus tard
const pattern = /.*/;

export function PhoneNumberValidator(control: FormControl): ValidationErrors {
  return !control.value || pattern.test(control.value) ? null : { phoneNumber: true };
}

export function PhoneNumberValidatorMessage(err, field: FormlyFieldConfig) {
  return 'Numéro de téléphone invalide';
}
