import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, of, NEVER } from 'rxjs';
import { timer } from 'rxjs';
import { retryWhen, concatMap, switchMap, delayWhen, tap } from 'rxjs/operators';
import { SnackbarService } from '@app/services';
import { WaitingRoomService, WaitingRoomData, WaitingRoomStatus } from '@app/services/waiting-room.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@env/environment';

@Injectable()
export class WaitingRoomInterceptor implements HttpInterceptor {
  constructor(
    private waitingRoomService: WaitingRoomService,
    private translateService: TranslateService,
    private snackbar: SnackbarService,
  ) { }


  retryWaitMilliSeconds: number = 1000 * 60 * 1; // default : 1 minute
  timeDiffWithSrv: number // in millisecond

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const realErrorsRetryCount = environment.production ? 10 : 2;
    let realErrorsCount = 0;

    return next.handle(request).pipe(
      // lorqu'on a passé la salle d'attente et qu'on en est à la phase de réservation, 
      // on profite de chaque requete pour mettre à jour le statut
      tap(event => {
        if (event instanceof HttpResponse) {
          try { // prevent possible error in this treatement to be catched by the retry when 
            if (this.getWaitingRoomData(event.headers)) {

              if (!this.waitingRoomService.inRestrictedRoom$.value) { // yes !! we was in waitingroom and we can go now !!
                this.waitingRoomService.enterRestrictedRoom()
              }
              // this.storeRestrictedUrls(request.url)
            }
          } catch (error) {
            console.error('Erreur lors du traitement des entêtes "waiting-room" :', error)
          }
        }
      }),

      // Lorsqu'on est en attente, le serveur renvoit le statut sous forme d'une erreur 503
      // Ici on utilise `retryWhen` qui "attrape" les erreurs dans le but de réessayer plus tard ...
      retryWhen(error =>
        error.pipe(
          concatMap((err, count) => {

            const waitingRoomData: WaitingRoomData = this.getWaitingRoomData(err.headers);
            if (err.status === 503 && waitingRoomData) { //

              console.warn('WaitingRoomInterceptor / RetryWhen / waitingRoomData:', waitingRoomData)

              switch (waitingRoomData.status) {
                case WaitingRoomStatus.canGo: break; // should not be possible as if we can go, we should not receive an error

                case WaitingRoomStatus.mustWait: // we are in waiting room
                  console.warn("OK ! I'm in the waiting room")

                  this.waitingRoomService.openWaitingRoom()
                  break;

                case WaitingRoomStatus.error: // can't enter in waiting room (too much people already in)
                  console.warn("Oh no ! I can't enter in the waiting room", err)
                  this.waitingRoomService.displayError('errorEnterRoom', err.error.errorMessage)
                  break;
              }

              return of(err);
              // return NEVER;
            } else if (this.waitingRoomService.inWaitingRoom$.value) {

              // Il y a eu une autre erreur pendant que nous étions en salle d'attente .... on ressaye quand même au moins 5 fois ... 
              console.warn("Error when in waiting room ... will retry " + (realErrorsRetryCount - realErrorsCount) + " time left")
              if (realErrorsCount < realErrorsRetryCount) {
                realErrorsCount++
                return of(err);
              } else {
                this.waitingRoomService.displayError('other', 'Oops, une erreur inattendue est survenue. Merci de réessayer ultérieurement.')
                this.waitingRoomService.inWaitingRoom$.next(false)
              }
            }
            return throwError(err);
          }),
          // delay(retryWaitMilliSeconds)
          delayWhen(this.delayRetryObs)
        )
      )
    );
  }

  delayRetryObs = () => { // used for delay the 'retryWhen ' 
    return timer(this.retryWaitMilliSeconds).pipe(
      switchMap(_ => {
        // Delay finished, but are we still in waiting room ?
        // If no, this mean that user has give up : we must cancel the request            
        if (!this.waitingRoomService.inWaitingRoom$.value) return NEVER;
        return of(true);
      })
    )

  };


  getWaitingRoomData(headers: HttpHeaders) {
    const waitingRoomStatus: string = headers.get('Waiting-room-status');
    if (!waitingRoomStatus) return null;

    if (waitingRoomStatus === 'noWaitingRoom') {
      if (this.waitingRoomService.inWaitingRoom$.value) {
        // console.log('was in waiting room')
        this.waitingRoomService.inWaitingRoom$.next(false);
        this.waitingRoomService.closeWaitingRoom()
      }
      return null
    }

    const data: WaitingRoomData = this.waitingRoomService.data$.value || ({} as WaitingRoomData)

    const headerServerTime = headers.get('My-time');
    const headerRank = headers.get('Waiting-room-rank');
    const headerLastRefresh = headers.get('Waiting-room-last-refresh');
    const headerRefreshInterval = headers.get('Waiting-room-refresh-interval-needed')
    const headerAccess = headers.get('Waiting-room-access');
    const headerLimit = headers.get('Waiting-room-limit');
    const headerMaxExtend = headers.get('Waiting-room-max-extend')
    const headerRetryAfter = headers.get('Retry-After')
    const headerWaitingTimeMin = headers.get('Waiting-time-estimation-min')
    const headerWaitingTimeMax = headers.get('Waiting-time-estimation-max')


    const serverTime: Date = headerServerTime ? new Date(headerServerTime) : null;
    const localTime = new Date()
    const timeDiffWithSrv = serverTime ? localTime.getTime() - serverTime.getTime() : null;
    const adjustServerTime = (time: Date): Date => {
      time.setTime(time.getTime() + timeDiffWithSrv)
      return time;
    }

    // Always updated
    data.status = WaitingRoomStatus[waitingRoomStatus]
    data.waitingTimeMin = headerWaitingTimeMin ? Math.round(parseInt(headerWaitingTimeMin)) : null;
    data.waitingTimeMax = headerWaitingTimeMax ? Math.round(parseInt(headerWaitingTimeMax)) : null;


    // Update only if header is present
    headerRank ? data.rank = parseInt(headerRank) : null;
    headerRefreshInterval ? data.refreshInterval = parseInt(headerRefreshInterval) : null;

    headerLastRefresh ? data.lastRefresh = adjustServerTime(new Date(headerLastRefresh)) : null;
    headerAccess ? data.accessTime = adjustServerTime(new Date(headerAccess)) : null;
    headerLimit ? data.limitTime = adjustServerTime(new Date(headerLimit)) : null;

    const retryAfter: Date = headerRetryAfter ? new Date(headerRetryAfter) : null;
    if (retryAfter && serverTime) {
      this.retryWaitMilliSeconds = retryAfter.getTime() - serverTime.getTime()
      console.warn('Waiting room active : Retry-After', retryAfter, 'ServerTime', serverTime, 'retryWaitMilliSeconds', this.retryWaitMilliSeconds)
    }

    if (headerMaxExtend) {
      data.maxExtend = parseInt(headerMaxExtend)
      data.extensionsCount = data.extensionsCount || 0
      data.extensionsLeft = data.extensionsLeft || data.maxExtend - data.extensionsCount
    }

    this.waitingRoomService.setData(data);
    return data;
  }

}
