<mat-toolbar color="primary" mat-dialog-title>
  <button class="close-dialog" mat-icon-button [mat-dialog-close]>
    <mat-icon>close</mat-icon>
  </button>
  <span class="title">
    <p>Téléchargement des documents</p>
  </span>
  <span></span>
</mat-toolbar>

<div class="container" appDnd (fileDropped)="onFileDropped($event)">
  <input type="file" class="accent" #fileDropRef id="fileDropRef" multiple (change)="fileBrowseHandler($event)" />
  <h3>Faites glisser et déposez les fichiers ici</h3>
  <label for="fileDropRef" class="accent-lighter-bg">Rechercher un fichier</label>
</div>

<div class="files-list thin-scrollbar">
  <div class="single-file" *ngFor="let file of files; let i = index">
    <app-icon size="30" *ngIf="getIcon(file.type) as icon; else default" type="far" [icon]="'file-'+icon"
      [class.invalid]="!valid"></app-icon>
    <ng-template #default><i class="far fa-file "></i></ng-template>
    <div class="info">
      <h4 class="name">
        {{ file?.name }}
      </h4>
      <p class="size">
        {{ formatBytes(file?.size) }}
      </p>
      <mat-progress-bar *ngIf="file.progress != null" mode="determinate" [value]="file?.progress" color="accent">
      </mat-progress-bar>
    </div>
    <mat-icon color="warn" *ngIf="file.progress == null" (click)="deleteFile(i)" class="icon-action">delete</mat-icon>
  </div>
</div>
<div mat-dialog-actions>

  <button mat-raised-button color="primary" (click)="add()" matTooltip="Envoyer">
    <app-icon type="fa" icon="cloud-upload-alt">upload</app-icon>
  </button>
</div>
