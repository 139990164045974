<ng-container *ngFor="let presence of presences">
  <div class="presence-row" [style.background]="presence.color" [style.color]="presence.contrastColor"
    (click)="clickPresence.emit(presence)" [ngClass]="{'other-account': presence.reservation.otherAccount}">
    <mat-icon class="status-icon" [matTooltip]="('reservation.state_text.' + presence.status)|trans">
      {{ ('reservation.state_icon.' + presence.status)|trans }}
    </mat-icon>
    <div class="text-wrapper">
      <div *ngIf="presence.reservation.type !== 'mikado'" class="presence-title">
        <b>{{ presence.title }}</b>
        <i *ngIf="presence.activities && presence.activities.length" class="icon icodomino-activite activities"
          matTooltip="+ {{ presence.activities.length }} activités"></i>

        <span *ngIf="presence.showTimes" class="presence-hours">
          {{ presence.startTime }} - {{ presence.endTime }}
          <span *ngIf="presence.startTime2">
            & {{ presence.startTime2 }} - {{ presence.endTime2 }}
          </span>
        </span>
      </div>

      <div *ngIf="presence.reservation.type === 'mikado'">
        {{ presence.startTime }} - {{ presence.endTime }}
      </div>

      <div class="presence-status">
        {{ ('reservation.state_text.' + presence.status)|trans }}
      </div>

      <span *ngIf="presence.consumer as consumer" class="presence-child">
        {{ consumer.prenom }} {{ consumer.nom }}
      </span>

      <div *ngIf="presence.subtitle" class="presence-periode">
        {{ presence.subtitle }}
      </div>
    </div>
    <mat-icon class="link-icon">arrow_forward</mat-icon>
  </div>
</ng-container>
