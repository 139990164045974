<div class="conteneur" *ngIf="userData; else loader">
  <div fxLayout="row wrap">

    <mat-card class="card login" fxFlex="48.5" fxFlex.sm="48" fxFlex.xs="100">
      <mat-card-header class="card-header" fxLayout="row" fxLayoutAlign="space-between center">
        <mat-card-title fxLayout="row" fxLayoutAlign="start center">
          <mat-icon>account_circle</mat-icon>
          Informations de connexion
        </mat-card-title>
      </mat-card-header>

      <mat-card-content>
        <div class="row items-center" style="margin-top: 5px;" *ngIf="user.fromOidc">
          <img [src]="authConfig.externalAuth.providerLogo" class="oidc-logo" [matTooltip]="userNameTooltip"
            [tooltipClass]="login-tooltip" />
          <span> Vous êtes identifié grâce à {{user.oidcProvider}} </span>
        </div>
        <div class="btn-group" *ngIf="user.fromOidc">
          <button mat-raised-button color="primary" routerLink="/logout">
            <mat-icon>exit_to_app </mat-icon>
            Se déconnecter
          </button>
        </div>


        <div class="row items-center" style="margin-top: 5px;">
          <mat-icon class="email-icon" matTooltip="Adresse email">alternate_email</mat-icon>
          <span>{{ userData.email }}</span>
        </div>

        <div class="btn-group">
          <button mat-raised-button color="primary" (click)="onClickChangeEmail()" [disabled]="!user">
            <mat-icon>email</mat-icon>
            Modifier mon adresse email
          </button>

          <button mat-raised-button color="primary" routerLink="/account/update-password" *ngIf="!user.fromOidc">
            <mat-icon>password</mat-icon>
            Modifier mon mot de passe
          </button>

          <button mat-raised-button color="primary" routerLink="/account/totp-manage"
            *ngIf="authConfig.doubleAuth.isActive">
            <mat-icon>admin_panel_settings</mat-icon>
            {{(user.totpEnrolled ? 'totp.access_button_when_active' : 'totp.access_button_when_inactive') |trans }}
          </button>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card class="card" fxFlex="48.5" fxFlex.sm="48" fxFlex.xs="100" *ngFor="let step of adulteFormSteps">
      <mat-card-header class="card-header" fxLayout="row" fxLayoutAlign="space-between center">
        <mat-card-title fxLayout="row" fxLayoutAlign="start center">
          <mat-icon *ngIf="step.stepName === 'coordinates'">contacts</mat-icon>
          <mat-icon *ngIf="step.stepName === 'personal'">assignment_ind</mat-icon>
          <mat-icon *ngIf="step.stepName === 'professional'">business_center</mat-icon>
          <mat-icon *ngIf="step.stepName === 'complement'">assignment</mat-icon>
          <span>{{ step.label }}</span>
        </mat-card-title>
        <button style="margin-bottom: 20px;" mat-mini-fab color="primary"
          [matTooltip]="editable ? 'Voir/Modifier' : 'Voir'" routerLink="/account/user/{{step.stepName}}">
          <mat-icon *ngIf="editable">edit</mat-icon>
          <mat-icon *ngIf="!editable">visibility</mat-icon>
        </button>
      </mat-card-header>
      <mat-card-content style="flex: 1">
        <ng-container *ngIf="step.stepName === 'coordinates'" [ngTemplateOutlet]="coordinates"></ng-container>
        <ng-container *ngIf="step.stepName === 'personal'" [ngTemplateOutlet]="personal"></ng-container>
        <ng-container *ngIf="step.stepName === 'professional'" [ngTemplateOutlet]="professional"></ng-container>
        <ng-container *ngIf="step.stepName === 'complement'" [ngTemplateOutlet]="complement"></ng-container>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<ng-template #coordinates>
  <h4 style="margin-bottom: 5px;">{{ userData.civilite }} {{ userData.nom | uppercase }} {{ userData.prenom }}</h4>
  <p style="margin-top: 2px;">
    <span *ngIf="userData.rue2">{{ userData.rue2 }}<br></span>
    <span *ngIf="userData.rue3">{{ userData.rue3 }}<br></span>
    <span>{{ userData.numRue }} {{ userData.rue }}<br></span>
    <span>{{ userData.codePostal }} {{ userData.ville }}</span>
  </p>
  <div fxLayout="row" fxLayoutAlign="start center">
    <mat-icon *ngIf="userData.telephone || userData.telMobile" style="margin-right: 5px;">local_phone
    </mat-icon>
    <div>
      <span *ngIf="userData.telephone"> - {{ userData.telephone }} <br></span>
      <span *ngIf="userData.telMobile"> - {{ userData.telMobile }}</span>
    </div>
  </div>
</ng-template>

<ng-template #personal>
  <p *ngIf="userData.situationFamiliale && listKeysFieldForm.includes('situationFamiliale')">
    <b>Situation familiale : </b> {{ userData.situationFamilialeName }}
  </p>
  <ng-container *ngIf="userData.sexe === 'F'">
    <p *ngIf="userData.dateNaissance && listKeysFieldForm.includes('dateNaissance')"><b>Née le :
      </b>{{ userData.dateNaissance | date:'shortDate' }}</p>
    <p *ngIf="userData.sexe && listKeysFieldForm.includes('sexe')"><b>Sexe : </b> Féminin</p>
  </ng-container>
  <ng-container *ngIf="userData.sexe === 'M'">
    <p *ngIf="userData.dateNaissance && listKeysFieldForm.includes('dateNaissance')"><b>Né le
        :</b>{{ userData.dateNaissance | date:'shortDate' }}</p>
    <p *ngIf="userData.sexe && listKeysFieldForm.includes('sexe')"><b>Sexe : </b> Masculin</p>
  </ng-container>
  <mat-icon class="more-horiz" routerLink="/account/user/personal">more_horiz</mat-icon>
</ng-template>

<ng-template #professional>
  <p *ngIf="userData.profession && listKeysFieldForm.includes('profession')"><b>Profession : </b>{{userData.profession}}
  </p>
  <p *ngIf="userData.lieuTravail && listKeysFieldForm.includes('lieuTravail')"><b>Lieu de travail :
    </b>{{ userData.lieuTravail}}<br>
    <span *ngIf="userData.rueTravail && listKeysFieldForm.includes('rueTravail')">{{ userData.rueTravail }} </span><br>
    <span
      *ngIf="userData.codePostalTravail && listKeysFieldForm.includes('codePostalTravail')">{{ userData.codePostalTravail }}
    </span>
    <span *ngIf="userData.villeTravail && listKeysFieldForm.includes('villeTravail')">{{ userData.villeTravail }}</span>
  </p>
  <mat-icon class="more-horiz" routerLink="/account/user/professional">more_horiz</mat-icon>
</ng-template>

<ng-template #complement>
  <mat-icon class="more-horiz" routerLink="/account/user/complement">more_horiz</mat-icon>
</ng-template>

<ng-template #updateEmailDialog>
  <app-user-update-email [user]="user" [inDialog]="true" (done)="closeUpdateEmailDialog()"
    (cancel)="closeUpdateEmailDialog()"></app-user-update-email>
</ng-template>

<ng-template #loader>
  <app-loader [message]="'Chargement des données...'" class="margin"></app-loader>
</ng-template>
