import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { FileService, SnackbarService } from "@app/services";


@Component({
  selector: "app-file-upload",
  templateUrl: "./file-upload.component.html",
  styleUrls: ["./file-upload.component.scss"],
})
export class FileUploadComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<FileUploadComponent>,
    private fileService: FileService,
    private snackbarService: SnackbarService
  ) { }
  @Input() type: string;
  onSend = new EventEmitter<boolean>();

  displayedColumns = ["title", "type"];

  @Input() readOnly: string | boolean = false; // pass 'add' to allow upload, but disable delete
  @Input() selectMode: string | boolean; // false / true or 'multi' (single by default)
  @ViewChild("fileDropRef", { static: false }) fileDropEl: ElementRef;
  @ViewChild("uploadBar") uploadBar: any;
  files: any[] = [];
  uploadProgress: number;
  uploadingFile: File;
  loading = false;
  uploadingFileCount = 0;

  ngOnInit() { }

  onFileDropped($event) {
    this.prepareFilesList($event);
  }

  fileBrowseHandler(event) {
    this.prepareFilesList(event.target.files);
  }

  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      item.progress = null;
      this.files.push(item);
    }
    this.fileDropEl.nativeElement.value = "";
  }

  deleteFile(index: number) {
    this.files.splice(index, 1);
  }
  getIcon(type: string): string {
    return this.fileService.getFileIcon(type)
  }

  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
  add() {
    this.uploadingFileCount = this.files.length;
    this.files.forEach((file) => {
      file.progress = 0
      const formData = new FormData();
      formData.set("file", file);
      formData.set("type", file.type);
      this.fileService.upload(formData).subscribe((resp: any) => {
        if (typeof resp === "object" && "type" in resp) {
          file.progress = 100;
          this.uploadingFileCount--;
          if (this.uploadingFileCount == 0) {
            this.dialogRef.close();
          }
        } else if (resp.status === "progress") {
          file.progress = resp.message;
        }
        this.onSend.emit(true)
      });
      this.snackbarService.info("Envoi terminé!");
    })
  }
}