import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpResponse, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthenticationService, SnackbarService } from '@app/services';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenticationService,
    private translateService: TranslateService,
    private snackbar: SnackbarService,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // Cases where auth is not required (so skip to next request step)
    if (/^(http|https):/i.test(request.url)
      || /^(\/)?(assets)\//i.test(request.url) // start with '/assets/' or 'assets/'
      || /^(\/)?(theme)\//i.test(request.url) // start with'/theme/' or 'theme/'
      || (/^(\/)?(conf)\//i.test(request.url) // start with'/conf/' or 'conf/' but Only for 'GET' method
        && request.method.toLocaleUpperCase() === 'GET')
    ) {
      return next.handle(request);
    }

    // add authorization header with auth token if available
    const currentUser = this.authenticationService.currentUserValue;

    if (currentUser && currentUser.token) {
      request = request.clone({
        setHeaders: {
          Authorization: currentUser.token
        }
      });
    }

    // test & refresh user role from response http headers if available
    return next.handle(request).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          if (this.authenticationService.isAuthenticated) {

            const isAuthenticated = event.headers.get('is-authenticated')

            if (isAuthenticated === 'yes') {

              if (event.headers.has('user-role')) {
                this.authenticationService.updateRole(event.headers.get('user-role'));
              }

              if (event.headers.has('session-expiration')) {
                const date = this.parseExpireDate(event.headers.get('session-expiration'));
                this.authenticationService.updateExpiration(date);
              }
            } else if (isAuthenticated === 'no') {
              if (this.authenticationService.isAuthenticated && !this.isLogoutUrl(request.url)) {
                // this.authenticationService.updateUser(null);
                this.authenticationService.afterLogout();
                this.translateService.get('login.autologout_message').subscribe(msg => this.snackbar.error(msg));
              }
            }
          }
        }
      })
    );
  }

  parseExpireDate(date: string) {
    if (date.indexOf("Z") > 0) { // already an iso format
      return new Date(date);
    }

    let input = [date.substr(0, 4), date.substr(4, 2), date.substr(6, 2)].join('-');

    if (date.length > 8) {
      input += 'T' + [date.substr(8, 2), date.substr(10, 2), date.substr(12, 2)].join(':') + 'Z';
    }

    return new Date(input);
  }

  isLogoutUrl(url: string): boolean {
    const logoutUrls = ['/api/oidc/logout-callback', '/api/logout']
    return logoutUrls.findIndex(logoutUrl => url.startsWith(logoutUrl)) >= 0

  }
}
