<div class="loader-container" [hidden]="suspend">
  <mat-progress-spinner mode="indeterminate" [strokeWidth]="2" [diameter]="sizeInPx" [color]="color" class="loader">
  </mat-progress-spinner>
  <span *ngIf="message" class="message">{{ message }}</span>

  <!-- @TODO: should replace message span (may remove @Input) -->
  <span class="message">
    <ng-content></ng-content>
  </span>
</div>
