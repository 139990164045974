import { Component, OnInit, Input } from '@angular/core';
import { ActivityWithDay, PlanningService } from '@app/services/planning.service';
import { commentForMonaco, commentForTinyMCE } from '@app/components/_elements/text-editor/text-editor.component';
@Component({
  selector: 'app-activity-preview',
  templateUrl: './activity-preview.component.html',
  styleUrls: ['./activity-preview.component.scss']
})
export class ActivityPreviewComponent implements OnInit {

  @Input() modeAffichageActivite: string;
  @Input() activity: ActivityWithDay;
  preview: { image: string, text: string };

  constructor(
    private planningService: PlanningService
  ) { }

  ngOnInit() {
    // Not sure it is still useful , but more secure 
    this.activity.textPreview = this.activity.textPreview.replace(commentForMonaco, '').replace(commentForTinyMCE, '').trim();
    this.preview = this.planningService.getActivityPreview(this.activity);
  }
}
