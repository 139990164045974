import {
  transition,
  trigger,
  style,
  animate,
  sequence,
  state
} from '@angular/animations';

export const tableAnimation = [
  trigger('highlightRowsAnimation', [
    transition('void => highlight', [
      style({ background: 'var(--accent-lighter)', color: 'var(--accent-lighter-contrast)' }),
      sequence([
        animate('.25s ease', style({ background: 'var(--accent)', color: 'var(--accent-contrast)' })),
        animate('.85s ease', style({ background: '', color: '' })),
      ])
    ])
  ]),
  trigger('rowEnterLeave', [
    state('void', style({
      background: 'var(--accent-lighter)', color: 'var(--accent-lighter-contrast)',
      opacity: 0, transform: 'translateX(-700px)', 'box-shadow': 'none'
    })),
    transition('void => new', sequence([animate('.5s ease')])),
    transition('deleted => void', [animate('.5s ease')])
  ])
];
