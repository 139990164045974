<!-- TinyMCE -->
<editor *ngIf="editorType=== 'TinyMCE' else monacoEditorTemplate" [init]="init" [ngModel]="internalValue"
  (ngModelChange)="onChange($event)" style="width: 100%;"></editor>

<ng-template #monacoEditorTemplate>
  <div #monacoBox class="monaco-container">

    <div class="monaco-header">
      <div class="switch-editor-toggle">
        <span>Mode WYSIWYG</span>
        <mat-slide-toggle (change)="toggleEditorType()" [(ngModel)]="monacoEditorActive">
        </mat-slide-toggle>
        <span class="active">Mode HTML</span>
      </div>

      <div class="other-controls">
        <button type="button" mat-button (click)="openMonacoCommands()" color="accent">
          <i class="fas fa-terminal"></i>
          <span class="label special">Commandes</span>
          <span class="helpTouch">[F1]</span>
        </button>

        <button type="button" mat-button (click)="openPreview()" color="accent">
          <mat-icon>visibility</mat-icon>
          <span class="label">Aperçu</span>
        </button>
      </div>

    </div>

    <!-- The code editor component -->
    <app-code-editor [ngModel]="internalValue" (ngModelChange)="onChange($event)" [options]="codeEditorOptions">
    </app-code-editor>

    <div class="monaco-footer">
      <span class="spacer"></span>
      <div class="resize-handle" title="Redimensionner" aria-label="Redimensionner l'éditeur."
        (mousedown)="monacoResizeStart($event)">
        <svg focusable=" false">
          <path d="M8.1 1.1A.5.5 0 1 1 9 2l-7 7A.5.5 0 1 1 1 8l7-7ZM8.1 5.1A.5.5 0 1 1 9 6l-3 3A.5.5 0 1 1 5 8l3-3Z">
          </path>
        </svg>
      </div>
    </div>

  </div>
</ng-template>


<ng-template #previewTemplate>
  <mat-toolbar color="primary" mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle
    [cdkDragDisabled]="platformService.isMobile" style="cursor: move;">
    <h1 class="title">
      Aperçu
    </h1>
    <span></span>
    <button type="button" class="close-dialog" mat-icon-button [mat-dialog-close]>
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
  <mat-dialog-content style="margin-top: 20px; min-width: 300px; min-height: 200px;">
    <div [innerHtml]="internalValue | safe"></div>
  </mat-dialog-content>
</ng-template>
