import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BaseConfigService } from './config/base.service';

@Injectable({
  providedIn: 'root'
})
export class PortailTransLoader implements TranslateLoader {

  remoteUrl = 'conf/translation';

  storedTranslation = {};

  constructor(
    private http: HttpClient,
    private baseConfigService: BaseConfigService
  ) { }

  getTranslation(lang: string): Observable<any> {
    let conf = this.baseConfigService.getFirstConf("translation").content;
    return of(conf);
  }

  save(modifiedTranslation) {
    return this.http.put(this.remoteUrl, modifiedTranslation);
  }
}
