import { Component, OnInit, Input, ViewContainerRef, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { PlatformService } from '@app/services';
import { HeaderService } from '@app/services/header.service';

@Component({
  selector: 'app-topbar-actions',
  templateUrl: './topbar-actions.component.html',
  styleUrls: ['./topbar-actions.component.scss']
})
export class TopbarActionsComponent implements OnInit, AfterViewInit {

  @ViewChild('placeholder', { read: ViewContainerRef }) container: ViewContainerRef;

  constructor(
    private headerService: HeaderService,
    private router: Router,

    public platformService: PlatformService
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.headerService.topbarActionsTemplate$.subscribe(tpl => {
      this.container.clear();

      if (tpl) {
        this.container.createEmbeddedView(tpl);
      }
    });
  }
}
