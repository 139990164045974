import { Component, OnDestroy, OnInit } from '@angular/core';
import { PlatformService } from '@app/services';
import { HeaderService } from '@app/services/header.service';
import { Subject } from 'rxjs';
import { distinctUntilChanged, map, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-subheader',
  templateUrl: './subheader.component.html',
  styleUrls: ['./subheader.component.scss']
})
export class SubheaderComponent implements OnInit, OnDestroy {

  showBreadcrumb: boolean;
  showActions: boolean;

  onDestroy$ = new Subject();

  constructor(
    private headerService: HeaderService,
    private platformService: PlatformService
  ) { }

  ngOnInit(): void {
    this.platformService.mediaAlias$.pipe(
      map(() => this.platformService.isMobile),
      distinctUntilChanged(),
      takeUntil(this.onDestroy$),
    ).subscribe(x => this.showBreadcrumb = !x);

    this.headerService.topbarActionsTemplate$.pipe(
      takeUntil(this.onDestroy$),
    ).subscribe(x => this.showActions = !!x);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
