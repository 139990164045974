import { Component } from '@angular/core';
import { DataTableTypeComponent } from '../datatable-type';
import { parseTime,formatTime } from '@app/utils/date-functions';

@Component({
  selector: 'app-week-programmation',
  templateUrl: '../datatable-type/datatable.type.component.html',
  styleUrls: ['../datatable-type/datatable.type.component.scss']
})
export class WeekProgrammationTypeComponent extends DataTableTypeComponent {

  ngOnInit() {
    super.ngOnInit();
    setTimeout(() => this.computeTitle());
  }

  applyModelToFormValue(fromSuggestion = false) {
    super.applyModelToFormValue(fromSuggestion);

    setTimeout(() => this.computeTitle());
  }

  computeTitle() {
    const rows = this.field.fieldGroup;
    const options = this.field.specificOptions;
    
    rows.forEach(row => {

      if (!options.showDays.value && !options.showTime.value) {
        row.title = 'Semaine ' + (parseInt(row.key as string, 10) + 1);
        return;
      }

      const arrayField = row.fieldGroup.find(x => x.key === 'jour_programmation');

      const titleParts = [];

      arrayField.fieldGroup.forEach(day => {
        const dayValue = day.formControl.value;
        let dayTitle = '';
        
        if (dayValue.jour_co) {
          if (options.showDays.value) {
            const dayDef =  day.parent.defaultValue.find(d => d.key === day.model.key)
            const dayLabel = dayDef && dayDef.templateOptions && dayDef.templateOptions.label ? dayDef.templateOptions.label : dayDef.configTitle;
            dayTitle += dayLabel;
          }

          if (options.showTime.value) {
            dayTitle = dayTitle ? ('<td style="font-weight: 500; padding: 2px 5px; min-width:90px;">' + dayTitle + '</td>') : '';
            dayTitle += '<td style="padding: 2px 5px; min-width:90px;">' ;
            
            const firstPlage = day.fieldGroup.find(x => x.key === 'plage1Deb');
            if (firstPlage && !firstPlage.hide) {
               // we don't handle seconds for now, so only keep "xx:xx"
              const plage1Deb = parseTime(dayValue.plage1Deb.substring(0, 5));
              const plage1Fin = parseTime(dayValue.plage1Fin.substring(0, 5));
              dayTitle += formatTime(plage1Deb) + '&nbsp;-&nbsp;' + formatTime(plage1Fin) ;

              const secondPlage = day.fieldGroup.find(x => x.key === 'plage2Deb');
              if (secondPlage && !secondPlage.hide && dayValue.plage2Deb && dayValue.plage2Fin) {
                const plage2Deb = parseTime(dayValue.plage2Deb.substring(0, 5));
                const plage2Fin = parseTime(dayValue.plage2Fin.substring(0, 5));
                dayTitle += '  /  ' + formatTime(plage2Deb) + '&nbsp;-&nbsp;' + formatTime(plage2Fin) ;
              }
            }
            dayTitle += '</td>'; 
          }

          titleParts.push(dayTitle);
        }

        if (options.showTime.value) {
          row.title = '<table>' + titleParts.map(part => '<tr>' + part + '</tr>').join('') + '</table>';
        } else {
          row.title = titleParts.join(', ');
        }
      });
    });
  }
}
