import { Injectable } from '@angular/core';
import { BaseAdapterService } from './base-adapter.service';
import { News } from '@app/models/news';

@Injectable({
  providedIn: 'root'
})
export class NewsAdapterService extends BaseAdapterService<News> {
  fields = {
    id: 'idActualite',
    title: 'titre',
    enabled: 'estVisible',
    content: 'contenu',
    createdAt: 'dateCreation',
    publishFrom: 'dateDebut',
    publishUntil: 'dateFin',
    subTitle: 'resume',
    img: 'imageUrl',
    order: 'ordre',
    outsidePeriode: 'horsPeriodeVisible',
    categories: '_categories',
    etablissementAccueil: 'etablissementAccueilJSON',
    isPrivate: 'estPrive'
  };
}
