import { Directive, Input, Output, HostListener, inject, ElementRef, EventEmitter, AfterViewInit, OnInit, OnDestroy, Renderer2, ViewContainerRef, ComponentRef } from '@angular/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { interval, Observable, Subject } from 'rxjs';
import { takeUntil, takeWhile } from 'rxjs/operators';
import { MatTooltip } from '@angular/material/tooltip';
import { ThemePalette } from '@angular/material/core';

export interface autoSubmitConf {
  delay?: number, // number of seconds, default to 10
  tooltip?: string, // tooltip text, can integrate '{{delay}}', otherwise it will be added after text as 'Validation automatique dans {{delay}} secondes'
  cancel$?: Observable<any>, // observable to cancel the autosubmit behaviour (and hide this timer)
  color?: ThemePalette, // optionnal , if not provided, we will determine it depends on host button color
  noFlash?: boolean // to disable the flash around timer 
}

@Directive({
  selector: '[autoSubmitCountdown]',
  providers: [MatTooltip],
})
export class AutoSubmitCountdownDirective implements OnInit, AfterViewInit, OnDestroy {

  @Input('autoSubmitCountdown') conf: autoSubmitConf; // receive the conf directly in the directive


  @Output() click: EventEmitter<any> = new EventEmitter();

  onDestroy$ = new Subject()

  private readonly renderer: Renderer2 = inject(Renderer2);
  private viewContainerRef: ViewContainerRef = inject(ViewContainerRef);

  initialDelay: number;
  color: ThemePalette;

  host: HTMLElement;
  countdownSpan: HTMLElement;
  containerDiv: HTMLElement;
  flashingDiv: HTMLElement;
  spinnner: ComponentRef<MatProgressSpinner>;
  tooltip: MatTooltip;

  noFlash: boolean;
  mouseOver: boolean;


  constructor(
    el: ElementRef,
    tooltip: MatTooltip
  ) {
    this.host = el.nativeElement;
    this.tooltip = tooltip;
  }


  ngOnInit(): void {

    if (!this.conf) {
      return
    }

    // Default value for 'delay'
    if (!this.conf.delay || typeof this.conf.delay !== 'number') {
      this.conf.delay = 10;
    }

    // Color
    if (!!this.conf.color) {
      this.color = this.conf.color;
    } else {
      this.color = this.determineColor()
    }

    // Default value for 'tooltip'
    if (!this.conf.tooltip || typeof this.conf.tooltip !== 'string') {
      this.conf.tooltip = ` Validation automatique dans {{delay}} secondes `
    } else if (this.conf.tooltip.indexOf('{{delay}}') < 0) {
      this.conf.tooltip += `
      -----------------------------------------------------------------
      Validation automatique dans {{delay}} secondes`
    }

    // If no 'cancel$' observable provided, we'll use 'onDestroy$'
    // NB : mean that button will not be cancellable
    if (!this.conf.cancel$ || !(this.conf.cancel$ instanceof Observable)) {
      this.conf.cancel$ = this.onDestroy$
    }


    this.host.classList.add('button-with-autosubmit-timer')
    this.tooltip.tooltipClass = 'autosubmit-timer-tooltip';
    this.tooltip.message = this.tooltipMessage;
    this.initialDelay = this.conf.delay

    this.buildCountDown()

  }

  ngAfterViewInit() {
    if (!this.conf || this.conf.delay === 0) {
      return
    }

    interval(1000).pipe(
      takeUntil(this.onDestroy$),
      takeUntil(this.conf.cancel$),
      takeWhile(_ => this.conf.delay >= 0),
    ).subscribe(() => {

      this.conf.delay--

      if (this.conf.delay >= 0) {
        this.countdownSpan.innerText = this.conf.delay + '\'\'';
        this.tooltip.message = this.tooltipMessage;
        if (this.mouseOver) {
          setTimeout(() => { this.tooltip.show() })
        }
      }


      if (this.conf.delay < (this.initialDelay)) {
        const val = Math.round((this.initialDelay - this.conf.delay) / this.initialDelay * 100)
        this.spinnner.instance.mode = 'determinate'
        this.spinnner.setInput('value', val)
      }

      // When under 5 second, flash faster
      if (this.conf.delay < 5 && !!this.flashingDiv) {
        this.flashingDiv.classList.add('more-speed')
      }

      // Juste before the end 
      if (this.conf.delay < 1) {
        this.spinnner.instance.mode = 'indeterminate'
      }

      // Finaly click on the button (let one more second)
      if (this.conf.delay === 0) {
        setTimeout(() => {
          const fakeEvent = { screenX: 0, screenY: 0 } // because "close buttons" on MatDialog need this
          this.click.emit(fakeEvent)
          this.removeTimer()
          this.onDestroy$.next()
        }, 1000)
      }
    })

    this.conf.cancel$.subscribe(() => this.removeTimer())
  }

  get tooltipMessage(): string {
    return this.conf.tooltip.replace('{{delay}}', this.conf.delay.toString());
  }

  @HostListener('mouseover') mouseover() {
    this.tooltip.show();
    this.mouseOver = true;
  }

  @HostListener('mouseleave') mouseleave() {
    this.tooltip.hide();
    this.mouseOver = false;
  }

  buildCountDown() {

    this.spinnner = this.viewContainerRef.createComponent(MatProgressSpinner);
    this.spinnner.instance.diameter = 32
    this.spinnner.instance.color = this.color
    this.spinnner.instance.mode = 'indeterminate'

    this.containerDiv = this.renderer.createElement('div');
    this.containerDiv.classList.add('auto-submit-timer')

    this.countdownSpan = this.renderer.createElement('span');
    this.countdownSpan.classList.add('counter')
    this.countdownSpan.innerText = this.conf.delay + '\'\''

    this.containerDiv.appendChild(this.spinnner.location.nativeElement)
    this.containerDiv.appendChild(this.countdownSpan)

    if (!this.conf.noFlash) {
      this.flashingDiv = this.renderer.createElement('div');
      this.flashingDiv.classList.add(this.color)
      this.flashingDiv.classList.add('auto-submit-flashing')
      this.containerDiv.appendChild(this.flashingDiv)
    }

    this.host.appendChild(this.containerDiv)

  }

  determineColor(): ThemePalette {
    let buttonColor = '';
    if (this.host.hasAttribute('color')) {
      buttonColor = this.host.getAttribute('color')
    }

    switch (buttonColor) {
      case 'primary': return 'accent';
      case 'accent': return 'warn';
      case 'warn': return 'accent';
      default: return 'primary';
    }

  }

  removeTimer() {
    const timer = this.host.getElementsByClassName('auto-submit-timer').item(0)
    if (!!timer) {
      this.host.removeChild(timer)
    }
  }


  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
