<mat-toolbar mat-dialog-title color="primary" *ngIf="!noTitle; else simpleCloseButton">
  <span class="title">{{ title }}</span>
  <button mat-icon-button [mat-dialog-close] class="close-dialog">
    <mat-icon>close</mat-icon>
  </button>
</mat-toolbar>


<div mat-dialog-content *ngIf="data.template" class="thin-scrollbar">
  <ng-container [ngTemplateOutlet]="data.template"></ng-container>
</div>

<div mat-dialog-content *ngIf="data.message" [innerHTML]="data.message|safe:'html'" class="thin-scrollbar"></div>

<div mat-dialog-actions align="center">
  <button mat-raised-button color="primary" cdkFocusInitial [mat-dialog-close]>{{ confirmText }}</button>
</div>


<ng-template #simpleCloseButton>
  <button mat-icon-button [mat-dialog-close] class="simple-close-dialog-button">
    <mat-icon [mat-dialog-close]>close</mat-icon>
  </button>
</ng-template>
