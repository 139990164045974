<div *ngFor="let month of months" class="month">
  <div class="month-header">{{ month.title|titlecase }}</div>

  <div class="month-body">
    <table>
      <tr>
        <th></th>
        <th *ngFor="let wd of ['L', 'M', 'M', 'J', 'V', 'S', 'D']">{{ wd }}</th>
      </tr>

      <tr>
        <td class="week-number">{{ month.firstLineWeek.number }}</td>
        <td *ngFor="let nothing of month.fakeDays"></td>
        <td *ngFor="let d of month.firstLineWeek.days" [attr.data-date]="d.date"
          [class.selected]="highlightIndexed && highlightIndexed[d.date]">
          {{ d.day }}
        </td>
      </tr>

      <tr *ngFor="let week of month.weeks">
        <td class="week-number">{{ week.number }}</td>
        <td *ngFor="let dd of week.days" [attr.data-date]="dd.date"
          [class.selected]="highlightIndexed && highlightIndexed[dd.date]">
          {{ dd.day }}
        </td>
      </tr>
    </table>
  </div>
</div>
