import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MandatSepa } from '@app/models/mandat-sepa';
import { PlatformService } from '@app/services';
import { MandatSepaService } from '@app/services/mandat-sepa.service';

@Component({
  selector: 'app-mandat-sepa-pdf-dialog',
  templateUrl: './mandat-sepa-pdf-dialog.component.html',
  styleUrls: ['./mandat-sepa-pdf-dialog.component.scss']
})
export class MandatSepaPdfDialogComponent implements OnInit {

  mandat: MandatSepa;
  idFamille: number;
  pdf: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private mandatSepaService: MandatSepaService,
    public platformService: PlatformService,
  ) {
    this.mandat = this.data.mandat;
    this.idFamille = this.data.idFamille;
  }

  ngOnInit(): void {
    this.mandatSepaService.getPdfMandatSepa(this.idFamille, this.mandat.idSEPAMandat).subscribe((result: any) => {
      this.pdf = `data:application/pdf;base64,${result.pdf}`;
    });
  }

  downloadPdf(base64String, fileName) {
    const source = base64String;
    const link = document.createElement("a");
    link.href = source;
    link.download = `${fileName}.pdf`
    link.click();
  }

  onClickDownloadPdf(pdfBase64: string, numRUM: string) {
    this.downloadPdf(pdfBase64, `Mandat-SEPA-${numRUM}`);
  }


}
