import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigDiabolo, DiaboloInsc, ParamLibre } from '@app/models/diabolo';
import { Family } from '@app/models/family';
import { tap } from 'rxjs/operators';
import moment from 'moment';
import { Periode, PeriodeTreeItem } from '@app/models/periode';
import { of } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class DiaboloService {

  configUrl = 'conf/diaboloInscription';
  configAdminUrl = 'conf/admin/diaboloInscription';

  constructor(
    private http: HttpClient
  ) { }

  getConfigForAdmin() {
    return this.http.get<ConfigDiabolo>(this.configAdminUrl);
  }

  getConfigForUser() {
    return this.http.get(this.configUrl);
  }

  saveConfig(config: any,) {
    return this.http.put(this.configUrl, { config }, httpOptions);
  }

  getPeriodes() {
    return this.http.get<PeriodeTreeItem[] | Periode[]>(`${this.configAdminUrl}/periodes`);
  }

  sortParamsLibres(paramsLibres: ParamLibre[]) {
    if (paramsLibres.length) {
      paramsLibres.sort((p1, p2) => p1.ordre === p2.ordre ? 0 : !p1.ordre || p1.ordre > p2.ordre ? 1 : -1);
    }
    return paramsLibres;
  }

  getFamilyInscriptions(f: Family | number) {
    const idFamily = typeof f === 'object' ? f.id : f;
    return this.http.get(`familles/${idFamily}/diabolo-inscriptions`);
  }

  getInscription(f: Family | number, id: number) {
    const idFamily = typeof f === 'object' ? f.id : f;

    return this.http.get<DiaboloInsc>(`familles/${idFamily}/diabolo-inscription/${id}`);
  }

  getPeriodesByUsagers(idFamille: number, idEnfant: number, idAdulte: number, dateDebut: string | Date, dateFin: string | Date) {
    return this.http.get<PeriodeTreeItem[]>(`familles/${idFamille}/diabolo/periodesByUsager?idEnfant=${idEnfant}&idAdulte=${idAdulte}&dateDebut=${dateDebut}&dateFin=${dateFin}`);
  }

  checkPermInscription(config: ConfigDiabolo) {

    const start = moment(config.startDate).isValid() ? moment(config.startDate) : null;
    const end = moment(config.endDate).isValid() ? moment(config.endDate).add(1, 'day') : null;
    const currentDateTime = moment(new Date());

    if ((start && end && currentDateTime.isBetween(start, end))
      || (!end && currentDateTime.isAfter(start))
      || (!start && currentDateTime.isBefore(end))
      || !start && !end) {
      return true;
    };

    return false;
  }

  saveInscription(inscription: DiaboloInsc, idFamily: number) {
    return this.http.post<{ errors?: [] }>(`familles/${idFamily}/diabolo-inscription`, { inscription });
  }

  eraseInscription(f: Family | number, idInscription: number) {
    const idFamily = typeof f === 'object' ? f.id : f;
    return this.http.delete(`familles/${idFamily}/diabolo-inscription/${idInscription}`)
  }

  executeProgsPerso() {
    console.log("executeProgPerso");

  }

}
