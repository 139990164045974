import { Component, OnInit, ViewChild } from '@angular/core';
import { Child } from '@app/models/child';
import { FormConfigService, ChildService, PermissionService, FamilyService, FormHelperService, SnackbarService, UserService } from '@app/services';
import { ExtendedFormlyFieldConfig, StepType } from '@app/forms/config/form-model';
import { FormViewerComponent, SubmitFormEvent } from '@app/components/_public/form-viewer/form-viewer.component';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Observable, forkJoin } from 'rxjs';
import { distinctUntilChanged, filter, startWith, switchMap, take } from 'rxjs/operators';
import { FormArray, FormGroup } from '@angular/forms';
import { FormType } from '@app/models/global-config';
import { Adulte } from '@app/models/adulte';

@Component({
  selector: 'app-user-child-edit',
  templateUrl: './child-edit.component.html',
  styleUrls: ['./child-edit.component.scss']
})
export class ChildEditComponent implements OnInit {

  id: number;
  step: string;
  readOnly: boolean;

  form: StepType[];
  formData: any;
  formTitle: string;

  isLoading = true;
  loadingMessage = 'Chargement';
  typeForm: FormType = 'form-enfant';

  @ViewChild(FormViewerComponent) formViewer: FormViewerComponent;

  constructor(
    private formConfigService: FormConfigService,
    private helperService: FormHelperService,
    private familyService: FamilyService,
    private childService: ChildService,
    private route: ActivatedRoute,
    private location: Location,
    private permService: PermissionService,
    private snackbarService: SnackbarService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.id = +this.route.snapshot.paramMap.get('id') !== 0 ? +this.route.snapshot.paramMap.get('id') : null;
    this.step = this.route.snapshot.paramMap.get('step');
    this.readOnly = !this.permService.hasPermission('child_edit') || !this.familyService.currentFamily.active;

    const sources: Observable<any>[] = [
      this.childService.getFormData(this.id, this.step),
      this.userService.getCurrentFamilyAdults()
    ]

    if (!this.id) {
      this.formTitle = 'Nouvel enfant';
    } else {
      sources.push(this.loadChild(this.id))
    }

    forkJoin(sources).subscribe(([result, adultes, child]: [any, any, any]) => {
      child ? this.formTitle = child.prenom + ' ' + child.nom : null

      this.formData = !result.data ? { modeCreation: true } : result.data;

      if (result.config) {
        this.form = this.formConfigService.getFormView(result.config).filter(ff => ff.enabled);
        this.adjustPersonneAutoriseeBlocks();

        if (this.form[0].stepName === 'complement') {
          this.checkAutorisationButtonValue();
        }

        this.updateAutoriteParentaleLabels(this.form, adultes);
      }

      setTimeout(() => { // doit être encadré dans un setTimeout car sinon le 'formViewer' n'est pas accessible (car il était masqué par 'isLoading')...
        this.formViewer.handleReturnProgramOnDisplay(result)
      })

      this.isLoading = false;

    }, err => this.snackbarService.error('Une erreur est survenue lors du chargement'));

  }

  updateAutoriteParentaleLabels(form: StepType[], adultes: any) {
    form.forEach(step => {
      if (step.stepName === "complement") {
        const fieldAutoriteParentaleResp1 = this.formConfigService.findFieldByName(step.fields, "autoriteParentaleResp1");
        const fieldAutoriteParentaleResp2 = this.formConfigService.findFieldByName(step.fields, "autoriteParentaleResp2");
        const resp1 = adultes.find(ad => ad.order === 1);
        const resp2 = adultes.find(ad => ad.order === 2);
        const resp1Label = resp1 ? resp1.nom + ' ' + resp1.prenom : 'Responsable 1';
        const resp2Label = resp2 ? resp2.nom + ' ' + resp2.prenom : 'Responsable 2';

        if (fieldAutoriteParentaleResp2) {
          if (!resp2) {
            fieldAutoriteParentaleResp2.hide = true;
            fieldAutoriteParentaleResp2.templateOptions.required = false;
          } else {
            fieldAutoriteParentaleResp2.templateOptions.label = resp2Label;
          }

        }

        if (fieldAutoriteParentaleResp1) {
          if (!resp1) {
            fieldAutoriteParentaleResp1.hide = true;
          } else {
            fieldAutoriteParentaleResp1.templateOptions.label = resp1Label;
          }
        }

      }
    });
  }

  loadChild(id: number): Observable<Child> {
    return this.familyService.familySwitch$.pipe(
      filter(f => !!f),
      take(1),
      switchMap(f => this.childService.getFamilyChild(f.id, id, true))
    );
  }

  adjustPersonneAutoriseeBlocks() {
    const step = this.formConfigService.findStep(this.form, 'complement');

    if (!step) {
      return;
    }

    ['Bloc_chercher_enfant', 'Bloc_contact_urgence'].forEach(name => {
      const block = this.formConfigService.findFieldByName(step.fields, name);

      if (block) {
        this.adjustPersonneAutoriseeBlock(block);
      }
    });
  }

  adjustPersonneAutoriseeBlock(blockField: ExtendedFormlyFieldConfig) {
    if (!blockField.hooks) {
      blockField.hooks = {};
    }

    blockField.hooks.onInit = field => {
      const formArray = field.formControl as FormArray;

      if (formArray) {
        formArray.valueChanges.pipe(
          distinctUntilChanged((x: any[], y: any[]) => x.length === y.length),
          startWith(true)
        ).subscribe(x => {
          // Manually lock fields for adulte contacts, because Formly is bad :/
          formArray.controls.forEach((item: FormGroup, i) => {
            const modelValue = field.model[i];

            if (modelValue?.isAdulte) {
              Object.keys(item.controls).forEach(key => {
                if (!['lienFamille', 'commentaire'].includes(key)) {
                  item.controls[key].disable({ emitEvent: false });
                }
              });
            }
          });
        });
      }
    };
  }

  // Si la valeur sélection par l'utilisateur n'existe plus dans le formulaire alors elle est reinitialisée.
  // Cela se produit quand l'utilisateur sélectionne une option et qu'elle est ensuite supprimée côté admin.
  checkAutorisationButtonValue() {
    let listAutorisationForm = this.getAutorisationsFormList();
    if (listAutorisationForm && this.formData && this.formData.Bloc_autorisations) {
      listAutorisationForm.forEach(auth => {
        this.formData.Bloc_autorisations.forEach(authData => {
          if (auth.key === authData.key) {
            let tabOption = [];
            if (auth.templateOptions && auth.templateOptions.options) {
              for (const option of auth.templateOptions.options) {
                tabOption.push(option.value)
              }
            }
            if (!tabOption.includes(authData.valeur) && authData.valeur !== "") {
              authData.valeur = "";
            }
          }
        })
      })
    }
  }

  getAutorisationsFormList() {
    let listAutorisation;

    if (this.form[0].stepName === 'complement') {
      this.form[0].fields.forEach(field => {
        if (field.key === 'Bloc_autorisations') {
          listAutorisation = field.defaultValue;
        }
      })
      return listAutorisation;
    }
  }

  onSave(event: SubmitFormEvent) {
    const data = event.model;
    this.formViewer.setErrorMessage('');
    this.loadingMessage = 'Enregistrement';

    if (this.readOnly) {
      return;
    }

    this.isLoading = true;

    const saveMethod = (this.id) ? this.childService.update(data, this.id, this.step) : this.childService.create(data);

    saveMethod.subscribe((result: any) => {
      this.helperService.displayDebugTraces(result.traces);
      this.helperService.notifySuccess('Modification effectuée', result.messages);

      this.location.back();
    }, err => {
      this.isLoading = false;

      setTimeout(() => { // doit être encadré dans un setTimeout car sinon le 'formViewer' n'est pas accessible (car il était masqué par 'isLoading')...
        this.helperService.manageServerError(err, this.formViewer);
      })

    });

  }

}
