import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigMandatSepa, MandatSepa, PiecesAFournir } from '@app/models/mandat-sepa';
import { Family } from '@app/models/family';
import { FamilyService } from './family.service';
import { GedService } from './ged.service';
import { map } from 'rxjs/operators';
import { GedDocument } from '@app/models/ged';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class MandatSepaService {

  url: string = 'mandat-sepa';
  configUrl: string = 'conf/mandat-sepa';
  configAdminUrl: string = 'conf/admin/mandat-sepa';

  constructor(
    private http: HttpClient,
    private familyService: FamilyService,
    private gedService: GedService
  ) { }

  getListPays() {
    return this.http.get<any[]>(`${this.url}/pays`);
  }

  getListCreanciers() {
    return this.http.get<any[]>(`${this.url}/creanciers`);
  }

  getConfigForAdmin() {
    return this.http.get<ConfigMandatSepa>(this.configAdminUrl);
  }

  getConfigForUser() {
    return this.http.get<ConfigMandatSepa>(this.configUrl);
  }

  getFamilyMandatsSepa(f: Family | number) {
    const idFamily = typeof f === 'object' ? f.id : f;
    return this.http.get(`familles/${idFamily}/mandats-sepa`);
  }

  getBicByBanqueGuichet(codeBanque: string, codeGuichet: string) {
    return this.http.get(`${this.url}/bic/${codeBanque}/${codeGuichet}`);
  }

  saveMandatSepa(mandatSepa: MandatSepa, idFamily: number) {
    return this.http.post(`familles/${idFamily}/mandat-sepa`, mandatSepa);
  }

  getMandatSepa(f: Family | number, idMandatSepa: number) {
    const idFamily = typeof f === 'object' ? f.id : f;
    return this.http.get(`familles/${idFamily}/mandat-sepa/${idMandatSepa}`);
  }

  getPdfMandatSepa(f: Family | number, idSEPAMandat: number) {
    const idFamily = typeof f === 'object' ? f.id : f;
    return this.http.get(`familles/${idFamily}/mandat-sepa/${idSEPAMandat}/pdf`);
  }

  saveConfig(config: any,) {
    return this.http.put(this.configUrl, { config }, httpOptions);
  }

  sendDocument(file: File, piece: PiecesAFournir, idMandat: number, idEntite: number) {

    const formData = new FormData();

    formData.set('file', file);
    formData.set('type', file.type);

    formData.set('entite', piece.obj);
    formData.set('idPieceAFournir', piece.id + '');

    formData.set('idMandat', idMandat + '')
    formData.set('idEntite', idEntite + '');


    return this.http.post(`familles/${this.familyService.currentFamily.id}/documents`, formData, {
      reportProgress: true,
      observe: 'events',
    }).pipe(map(event => this.gedService.mapProgressResponse(event)));

  }

  getCurrentFamilyDocuments() {
    return this.http.get<GedDocument[]>(`familles/${this.familyService.currentFamily.id}/mandat-sepa/documents`);
  }
}
