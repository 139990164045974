import { Component, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { PlatformService, WaitingRoomService } from '@app/services';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { WaitingRoomConfig } from '@app/models/reservation';


@Component({
  selector: 'app-waiting-room-notifier',
  templateUrl: './waiting-room-notifier.component.html',
  styleUrls: ['./waiting-room-notifier.component.scss']
})
export class WaitingRoomNotifierComponent implements OnInit, OnDestroy {

  conf: WaitingRoomConfig;

  @ViewChild('statusTpl') statusDialogTpl: TemplateRef<any>;
  statusDialogRef: MatDialogRef<any>;

  accessHour: Date;
  limitHour: Date;
  countDown: Date;

  spinnerMode: ProgressSpinnerMode = 'indeterminate'
  spinnerVal: number;
  animationClass: Array<string> = []

  newData$ = new Subject();
  onDestroy$ = new Subject();

  constructor(
    private platformService: PlatformService,
    private dialog: MatDialog,
    private waitingRoomService: WaitingRoomService
  ) { }

  ngOnInit(): void {

    combineLatest([this.waitingRoomService.config$, this.waitingRoomService.data$]).pipe(
    ).subscribe(([conf, data]) => {
      this.conf = conf;
      this.accessHour = data?.accessTime;
      this.limitHour = data?.limitTime;
    })

    // display a countdown to the user when he is in the "restricted room"
    this.waitingRoomService.countDown$.pipe(
      takeUntil(this.onDestroy$),
      takeUntil(this.waitingRoomService.timeIsUp$),
    ).subscribe(countDown => {
      // Because when we receive this, it's an utc date value but we work with local date
      countDown.setTime(countDown.getTime() - (countDown.getTimezoneOffset() * 60 * 1000))
      this.countDown = countDown

      const accessTime = this.waitingRoomService.data$.value.accessTime
      const limitTime = this.waitingRoomService.data$.value.limitTime
      const initialDelay = limitTime.getTime() - accessTime.getTime()
      const timeLeftMS = countDown.getTime()
      const countDownInPercent = (timeLeftMS / initialDelay * 100)

      this.spinnerVal = countDownInPercent
      this.spinnerMode = (timeLeftMS <= 5000) ? 'indeterminate' : 'determinate'

      let timeLeftSecond = Math.round(timeLeftMS / 1000)
      // console.log('timeLeftSecond', timeLeftSecond)
      if ([1, 10, 30, 60, 120].indexOf(timeLeftSecond) >= 0) {
        this.animate()
      }

      if (timeLeftSecond < 10) {
        this.animationClass.push('finish-soon')
      }

    })

    setTimeout(() => {
      this.animate();
      this.openStatus();
    })
  }

  animate() {
    this.animationClass.push('anim-hey')
    setTimeout(() => { this.animationClass = this.animationClass.filter(c => c !== 'anim-hey') }, 2000)
  }

  openStatus() {
    this.statusDialogRef = this.dialog.open(this.statusDialogTpl, {
      panelClass: this.platformService.isSmall ? 'full-screen-dialog' : '',
      ariaLabel: 'Statut de la salle d\'attente',
      disableClose: true,
    })
    this.statusDialogRef.backdropClick().subscribe(() => { this.closeStatus() })
  }

  closeStatus() {
    if (this.statusDialogRef) {
      this.statusDialogRef.addPanelClass('status-hide-before-close')
      setTimeout(() => {
        this.statusDialogRef.close()
      }, 350)
    }
  }

  ngOnDestroy() {
    if (this.statusDialogRef) {
      this.statusDialogRef.close()
    }

    this.onDestroy$.next();
    this.onDestroy$.complete();
  }


}
