<div *ngIf="!!countDown" class="notif-container " [ngClass]="animationClass"
  matTooltip="Temps restant pour réserver. Cliquer ici pour plus de détail" (click)="openStatus()">
  <mat-progress-spinner color="warn" [mode]="spinnerMode" [value]="spinnerVal" [diameter]="56" strokeWidth="7">
  </mat-progress-spinner>
  <span> {{countDown | date:'m'}}' {{countDown | date:'ss'}}'' </span>
</div>

<ng-template #statusTpl>
  <mat-toolbar mat-dialog-title color="accent">
    <mat-icon>info</mat-icon>
    <h1 class="title">Accès aux planning de réservation</h1>
    <button mat-icon-button class="close-dialog" (click)="closeStatus()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
  <mat-dialog-content *ngIf="conf else loader;">
    <div class="message-container">
      <div class="main-icon">
        <mat-icon color="accent">
          feedback
        </mat-icon>
      </div>
      <div class="message-content">
        <div [innerHTML]="conf.msgInfoGlobal | safe"></div>
        <div [innerHTML]="conf.msgAccesReservations | safe"></div>
      </div>
    </div>

    <div class="pannel-data border">
      <p class="data">
        <mat-icon>schedule</mat-icon>
        Accès autorisé à : <strong> {{accessHour | date:'mediumTime'}} </strong>
      </p>

      <p class="data">
        <mat-icon>access_time_filled</mat-icon>
        Accès autorisé jusqu'à : <strong> {{limitHour | date:'mediumTime'}} </strong>
      </p>

      <p class="data important accent">
        <mat-icon color="accent">timelapse</mat-icon>
        Temps restant : <strong> {{countDown | date:'m \'min\' ss \'s\''}} </strong>

      </p>
    </div>

  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="closeStatus()">
      <span>Compris</span>
      <mat-icon>check</mat-icon>
    </button>
  </mat-dialog-actions>


</ng-template>


<ng-template #loader>
  <app-loader class="margin" message="Chargement..."></app-loader>
</ng-template>
