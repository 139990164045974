import { Injectable } from '@angular/core';
import { ApiCrudService } from './api-crud.service';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, of, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

export interface ProgrammePerso {
  id: number;
  title: string;
  description: string;
  code: string;
  category: string;
  usedBy: string[];
}

export interface ProgrammePersoCategory {
  key: string;
  title: string;
}

@Injectable({
  providedIn: 'root'
})
export class ProgrammePersoService extends ApiCrudService<ProgrammePerso> {

  url = 'programme-perso';
  categoriesUrl = 'programme-perso-categories';

  cachedCategories$ = new BehaviorSubject<ProgrammePersoCategory[]>([]);
  cachedCategoriesValue: ProgrammePersoCategory[] = [];

  constructor(
    protected http: HttpClient
  ) {
    super();
  }

  getAllByCategories(categories: string[]): Observable<ProgrammePerso[]> {
    const caterogiesList = categories.toString();
    return this.http.get<ProgrammePerso[]>(this.url + '?categories=' + caterogiesList);
  }

  getCategories(): Observable<ProgrammePersoCategory[]> {
    if (this.cachedCategoriesValue.length === 0) {
      return this.http.get<ProgrammePersoCategory[]>(this.categoriesUrl, this.httpOptions).pipe(
        tap(categories => {
          this.cachedCategoriesValue = categories.sort((c1, c2) => (c1.title < c2.title ? -1 : 1));
          this.cachedCategories$.next(categories);
        })
      );
    } else {
      return of(this.cachedCategoriesValue);
    }
  }

  executeProgrammePersoForm(data: any, stepName: string, typeForm: string, idFamille: number, idAssmat: number) {
    return this.http.post("programme-perso/form", { data, stepName, typeForm, idFamille, idAssmat });
  }

  executeProgrammePersoInscDia(data: any, idFamille: number) {
    return this.http.post("programme-perso/inscription/diabolo", { data, idFamille });
  }

}
