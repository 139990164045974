<div *ngIf="showLoader else main;" class="loader-overlay">
  <app-loader [size]="4">
    <p *ngIf="showLoader">Chargement en cours ...</p>
  </app-loader>
</div>

<ng-template #main>
  <div [ngSwitch]="periode?.modeAffichageActivite">
    <!-- Affichage par période -->
    <ng-container *ngSwitchCase="'periode'">
      <ng-container *ngIf="activitiesByPeriode?.length;else noActivity">
        <ng-container *ngTemplateOutlet="periodeLayout"></ng-container>
      </ng-container>
    </ng-container>

    <!-- Affichage par semaine -->
    <!-- *ngSwitchCase="'semaine'" -->
    <ng-container *ngSwitchDefault>
      <mat-accordion *ngIf="activitiesByWeek?.length; else noActivity">
        <ng-container *ngTemplateOutlet="weekLayout"></ng-container>
      </mat-accordion>
    </ng-container>

  </div>
</ng-template>

<ng-template #noActivity>
  <mat-card class="warn-lighter-bg">
    <div class="section-title">
      <mat-icon>warning</mat-icon>
      Aucune activité disponible.
    </div>
  </mat-card>
</ng-template>

<ng-template #periodeLayout>
  <div class="periode-panel" fxLayout="row wrap" fxLayoutAlign="space-evenly stretch" fxLayoutGap="5px">

    <mat-card fxFlex="0 1 auto" *ngFor="let act of activitiesByPeriode; let i = index" class="activite-card"
      fxLayout="column">
      <mat-card-header fxLayout="row" fxLayoutAlign="center center">
        <mat-card-title>{{ act.name }}</mat-card-title>
      </mat-card-header>
      <div class="content-preview">
        <app-activity-preview [activity]="act.sessions[0]" [modeAffichageActivite]="'periode'"></app-activity-preview>
        <button mat-stroked-button *ngIf="act.sessions[0].description"
          (click)="openDescriptionActivity(act.sessions[0])" style="margin-inline: 16px; margin-top: 5px;">
          {{'reservation.activite.action.see_more' | trans}}</button>
      </div>
      <mat-card-content fxFlex class="thin-scrollbar" style="padding-inline: 16px; margin-bottom: 7px;">
        <ng-container *ngFor="let session of act.sessions;">
          <div class="activity-session" style="padding-bottom: 0;" (click)="openActivityPeriodeDialog(act)"
            *ngIf="session.reserved || session.selected || session.canceled" [ngClass]="{
              'reserved': session.reserved,
              'selected': session.selected ,
              'canceled': session.canceled,
              'disabled': (session.selected || session.reserved ? session.disabledCancel : session.disabled)
            }">
            <div fxLayout="row" fxLayoutAlign="space-between start">
              <div fxFlex *ngIf="session.datetimeDetails" [innerHTML]="session.datetimeDetails|safe"
                style="margin: 5px;">
              </div>
              <div *ngIf="!session.datetimeDetails && !session.group" style="margin: 5px;">
                {{session.date | date:"fullDate" | titlecase}} <span *ngIf="session.startTime && session.endTime">de
                  {{session.startTime}} à {{session.endTime}}</span>
              </div>
              <ng-container fxFlex *ngTemplateOutlet="iconSelection; context: {data: session}">
              </ng-container>
            </div>
          </div>
        </ng-container>
      </mat-card-content>
      <mat-divider style="position: relative;"></mat-divider>
      <mat-card-actions fxFlexAlign="center" align="center">
        <button mat-raised-button color="primary" [disabled]="act.disabled" (click)="openActivityPeriodeDialog(act)">
          {{'reservation.activite.action.select' | trans}}
        </button>
      </mat-card-actions>
    </mat-card>
  </div>
</ng-template>


<ng-template #weekLayout>
  <mat-expansion-panel *ngFor="let week of activitiesByWeek" class="week-panel primary-lighter-bg">
    <mat-expansion-panel-header>
      <mat-panel-title>Semaine {{ week.start|date:'ww' }}</mat-panel-title>
      <mat-panel-description>
        <!-- @TODO: probably better make the format depend on available space (screen width: short for small, else full) -->
        Du {{ week.start|date:'fullDate' }} au {{ week.end|date:'fullDate' }}
      </mat-panel-description>
    </mat-expansion-panel-header>

    <ng-container *ngFor="let weekDay of week.days">
      <mat-card *ngFor="let act of weekDay.activities" class="activity-select mat-elevation-z4" [ngClass]="{
        'reserved': act.reserved,
        'selected': act.selected,
        'canceled': act.canceled,
        'disabled': (act.selected || act.reserved ? act.disabledCancel : act.disabled)
      }">
        <!-- (click)="act.disabled ? null : toggleActivity(act)" -->
        <mat-card-header fxLayoutAlign="space-between start">
          <mat-card-title>{{ act.label || act.name }}</mat-card-title>
          <mat-card-subtitle>
            <span *ngIf="act.datetimeDetails" [innerHTML]="act.datetimeDetails|safe"></span>

            <div *ngIf="!act.datetimeDetails && !act.group">
              {{ act.date|date:'fullDate'|titlecase}}
              <span *ngIf="act.startTime && act.endTime">de {{ act.startTime }} à {{ act.endTime }}</span>
            </div>
          </mat-card-subtitle>
          <ng-container *ngTemplateOutlet="iconSelection; context: {data: act, modeSelect: true}"></ng-container>
        </mat-card-header>

        <mat-card-content class="thin-scrollbar">
          <app-activity-preview [activity]="act"></app-activity-preview>
          <div fxLayout="row" fxLayoutAlign="center center">
            <button mat-stroked-button *ngIf="act.description" (click)="openDescriptionActivity(act)"
              style="margin-block: 5px;">{{'reservation.activite.action.see_more' | trans}}</button>
          </div>
        </mat-card-content>
        <mat-divider></mat-divider>
        <mat-card-actions align="center">
          <!-- Sélection -->
          <ng-container *ngTemplateOutlet="selection; context: {data: act}"></ng-container>
        </mat-card-actions>
      </mat-card>
    </ng-container>

  </mat-expansion-panel>
</ng-template>

<mat-accordion>
</mat-accordion>

<ng-template #activityPeriodeDialog let-data>
  <mat-toolbar mat-dialog-title color="primary">
    <span class="title">{{ data.activityPeriode.name }}</span>
    <button mat-icon-button [mat-dialog-close] class="close-dialog">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>

  <div mat-dialog-content class="thin-scrollbar">

    <p><b>{{'reservation.activite.text_details_dialog_periodeMode_reservation' | trans}}</b></p>

    <div *ngFor="let session of data.activityPeriode.sessions" class="activity-session mat-elevation-z2"
      style="cursor: auto;" [ngClass]="{
      'reserved': session.reserved,
      'selected': session.selected ,
      'canceled': session.canceled,
      'disabled': (session.selected || session.reserved ? session.disabledCancel : session.disabled),
      'mat-elevation-z4' : session.selected
    }">

      <div fxLayout="row" fxLayoutAlign="space-between start">
        <div *ngIf="session.datetimeDetails" [innerHTML]="session.datetimeDetails|safe" style="padding-inline: 15px;">
        </div>
        <div *ngIf="!session.datetimeDetails && !session.group" style="padding: 15px;">
          {{session.date | date:"fullDate" | titlecase}} <span *ngIf="session.startTime && session.endTime">de
            {{session.startTime}} à {{session.endTime}}</span>
        </div>
        <ng-container *ngTemplateOutlet="iconSelection; context: {data: session , modeSelect: true}">
        </ng-container>
      </div>

      <!-- Sélection -->
      <ng-container *ngTemplateOutlet="selection; context: {data: session}"></ng-container>

    </div>
  </div>
  <mat-dialog-actions align="end">
    <button mat-raised-button color="accent-lighter" cdkFocusInitial [mat-dialog-close]>Fermer</button>
  </mat-dialog-actions>
</ng-template>


<ng-template #selection let-data="data">
  <div style="text-align: center;">
    <div *ngIf="!data.selected && !data.reserved" class="action-button">
      <button mat-raised-button color="primary" [disabled]="data.disabled" (click)="toggleActivity(data)">
        <div fxLayoutAlign="center center">
          <span>{{'reservation.activite.action.reserve' | trans}}</span>
          <app-icon *ngIf="data.groupInfo?.length" type="fa" icon="campground" [matTooltip]="data.groupInfo"
            matTooltipClass="tooltip-pre-line" matTooltipPosition="right" style="margin-left: 5px;"></app-icon>

        </div>
      </button>

      <span *ngIf="data.disabled" class="action-hint">
        {{ ('reservation.error_type.' + data.disabled)|trans }}
      </span>

      <ng-container *ngIf="!data.disabled && liveCapacity">
        <span *ngIf="periode.gestionListeAttente && data.dispos < 1" class="action-hint">
          <mat-icon color="accent" style="font-size: 20px;">format_list_numbered</mat-icon>
          {{ 'reservation.planning.capacity_attente'|trans }}
        </span>

        <span *ngIf="periode.warnLowCapacity && (data.dispos > 0 && data.dispos <= periode.warnLowCapacity)"
          class="action-hint">
          <mat-icon color="accent">offline_bolt</mat-icon>
          {{ 'reservation.planning.capacity_warn'|trans:{places: data.dispos} }}
        </span>
      </ng-container>
    </div>

    <ng-container *ngIf="data.selected || data.reserved">
      <!-- Annulation -->
      <button *ngIf="!(data.reserved && !periode.allowCancel)" mat-raised-button color="warn"
        [disabled]="data.disabledCancel" (click)="toggleActivity(data)">
        Annuler
      </button>

      <!-- Periode doesn't allow cancel -->
      <button *ngIf="data.reserved && !periode.allowCancel" mat-raised-button [disabled]="true">
        <div fxLayoutAlign="center center">
          <span>Déjà réservé</span>
          <app-icon *ngIf="data.groupInfo?.length" type="fa" icon="campground" [matTooltip]="data.groupInfo"
            matTooltipClass="tooltip-pre-line" matTooltipPosition="right" style="margin-left: 5px;"></app-icon>
        </div>
      </button>
    </ng-container>
  </div>
</ng-template>

<ng-template #iconSelection let-data="data" let-modeSelect="modeSelect">
  <div class="state-icons-container" [matTooltip]="data.reserved ? 'Réservé : '+(('reservation.state_text.' + data.reservedStatus)|trans)  
    : data.selected ? 'Sélectionné : '+('reservation.state_text.new'|trans) 
    : data.canceled ? ('reservation.state_text.canceling'|trans) 
    : ' ? '">

    <mat-icon *ngIf="modeSelect && (data.selected || data.reserved)" class="checked-icon"
      [ngClass]="{'disabled': data.reserved && data.disabledCancel}">
      done_outline
    </mat-icon>

    <mat-icon *ngIf="data.selected" class="selected-icon">
      {{ 'reservation.state_icon.waiting'|trans }}
    </mat-icon>

    <mat-icon *ngIf="data.reserved" class="reserved-icon"
      [ngClass]="{'disabled': data.reserved && data.disabledCancel}">
      {{ ('reservation.state_icon.' + data.reservedStatus)|trans }}
    </mat-icon>

    <mat-icon *ngIf="data.canceled" class="canceled-icon" color="warn">
      {{ 'reservation.state_icon.canceling'|trans }}
    </mat-icon>
  </div>
</ng-template>
