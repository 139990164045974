import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiCrudService } from './api-crud.service';
import { FamilyService } from './family.service';
import { PreInscription } from '@app/models/pre-inscription';
import { Observable, of } from 'rxjs';

export interface AgrementMinMax {
  numJour: number,
  jour: string,
  maxHeure: string,
  minHeure: string
}

@Injectable({ providedIn: 'root' })
export class PreinscriptionService extends ApiCrudService<any> {

  url = 'pre-inscriptions';
  formUrl = 'pre-inscriptions/#idPreInscription#/form/#formPart#';

  constructor(
    protected http: HttpClient,
    private familyService: FamilyService
  ) {
    super();
  }

  getCurrentFamilyItems() {
    if (this.familyService.currentFamily) {
      return this.http.get<PreInscription[]>(this.familyService.getCurrentFamilyDependantUrl(this.url))
    }
    return of(null);
  }

  create(data: PreInscription | any) {
    return this.http.post(this.familyService.getCurrentFamilyDependantUrl(this.url), data)
  }

  update(data: PreInscription | any, id?: number, part?: string) {
    part = part ? part : '';
    const url = this.url + (id ? '/' + id : '') + '/' + part;

    return this.http.put(url, data, this.httpOptions);
  }

  getFormData(id, part): Observable<any> {
    part = part ? part : ''
    const url = this.formUrl.replace('#idPreInscription#', id).replace('#formPart#', part);
    return this.http.get(url);
  }

  getAgrementsByIdEtabs(listIdEtabs: number[], dateDebut: string, dateFin: string): Observable<AgrementMinMax[]> {
    let idsEtab = listIdEtabs.toString();
    return (this.http.get(`agrements/${idsEtab}?dateDebut=${dateDebut}&dateFin=${dateFin}`) as Observable<AgrementMinMax[]>);
  }

}
