<app-loader *ngIf="isLoading">
</app-loader>
<p *ngIf="!isLoading && !isSearching && !total" class="empty-message">{{'messagerie.no_message'|trans }}</p>
<ng-template appTopbarActions>
  <button [hidden]="!total" mat-mini-fab color="accent-lighter" [matTooltip]="'messagerie.refresh_messages'|trans"
    (click)="getMessages()">
    <i class="fas fa-redo-alt"></i>
  </button>
</ng-template>
<div class="container" [hidden]="!total && !isSearching">
  <div class="chat-list mat-elevation-z5" [ngStyle]="{ 'width': (isSmallScreen ? 100 : 40) + '%' }"
    [hidden]="isSmallScreen && message">
    <div class="inbox">
      <i class="far fa-envelope-open envlop-style"></i>
      <span style="font-size: 20px; font-weight: bold;">{{ 'messagerie.message_inbox'|trans }}</span>
      <mat-icon style="position: absolute; right: 0;margin-right: 15px;"
        [matTooltip]="'messagerie.filter_per_etablissement'|trans" (click)="openFilterDetails()">filter_list</mat-icon>
    </div>
    <div class="search-input">
      <mat-form-field>
        <input matInput placeholder="Rechercher" (keyup)="applyFilter($event.target.value)">
      </mat-form-field>
    </div>

    <div class="message-card thin-scrollbar">
      <app-loader *ngIf="!isMessagesLoaded"></app-loader>
      <table mat-table [dataSource]="dataSource" matSort style="width:100%; " [hidden]="!isMessagesLoaded">
        <ng-container matColumnDef="contenu">
          <td class="hover-card" mat-cell *matCellDef="let row"
            [ngClass]="{'focused':  message && row.idPortailMessage == message.idPortailMessage}">
            <div [matBadge]="row._countUnreadMessageAdmin" matBadgeOverlap="false" style="top: 35px;"
              class="demo-section red-badge" *ngIf="row._countUnreadMessageAdmin"></div>
            <h3 class="message-object" [class.bold-row]="row._countUnreadMessageAdmin"
              [matTooltip]="(userId === -1 && row._countUnreadMessageAdmin) ? 'Message non lu : Il y a des établissements qui n\'ont pas encore lu le message.':null"
              matTooltipPosition="right" [innerHtml]="row.objetMessage | highlight: search"></h3>
            <div style="margin-bottom: 10px;">
              <span class="etab-send" [class.bold-row]="row._countUnreadMessageAdmin">
                {{ row._userInfo.nomUser}} {{ row._userInfo.prenomUser}} ({{ row._userInfo.emailUser}})
              </span>
            </div>
            <div class="message-contenu" [innerHtml]="(row.contenu | highlight: search) | safe">
            </div>
            <span class="date-message">{{row.dateHeureEnvoi | date:'short' }}</span>
          </td>
        </ng-container>
        <tr mat-row *matRowDef="let row; columns: ['contenu']" (click)="showDetails(row)" class="preview-detail-row">
        </tr>
      </table>
    </div>
    <mat-paginator (page)="handlePageEvent($event)" [length]="total" [pageSizeOptions]="[10, 20, 50, 100]">
    </mat-paginator>
  </div>
  <div class="chat-details mat-elevation-z5" [ngStyle]="{ 'width': (isSmallScreen ? 100 : 60) + '%' }"
    [hidden]="isSmallScreen && !message">
    <div *ngIf="message" [@fadeInLeft]="isAnimationTriggered ? '*' : 'void'" class="header">
      <div class="messages-header">
        <span (click)="close()" style="cursor: pointer;margin-right: 90%" matTooltip="Retour">
          <i class="fa fa-arrow-left" aria-hidden="true"></i>
        </span>
        <div class="infos-msg">
          <div class="object-title">
            <h2 style="display: inline;">{{ 'messagerie.message_object'|trans }} :
            </h2>
            <span style="font-size: 16px;display: inline;">{{message.objetMessage}}</span>
          </div>
          <div style="display: flex; align-items: center;" *ngIf="userId > -1">
            <form *ngIf="!message.isClosed">
              <button class="respond-btn" type="button" mat-raised-button color="accent-lighter"
                (click)="addMessage(message)" [matTooltip]="'messagerie.respond_to_first_message'|trans">
                Répondre
              </button>
            </form>
            <i class="fa fa-ellipsis-v" aria-hidden="true" style="margin-top: 20px; cursor: pointer;"
              [matMenuTriggerFor]="messageMenu" [matMenuTriggerData]="{message:message}"></i>
          </div>
        </div>
        <div class="etab-container">
          <div class="etab-to">
            <span>À :</span>
          </div>
          <div class="etablissements">
            <div class="etablissement"
              *ngFor="let etab of (message.showAllEtabs ? message.etablissements : message.etablissements.slice(0, 4))">
              <span>{{etab.name}}</span>
            </div>

            <div class="etablissement" *ngIf="message._etablissements.length > 4"
              (click)="message.showAllEtabs = !message.showAllEtabs">
              <span *ngIf="!message.showAllEtabs">afficher {{message._etablissements.length - 4}} de plus</span>
              <span *ngIf="message.showAllEtabs">afficher moins</span>
            </div>
          </div>
        </div>
        <mat-divider></mat-divider>

      </div>
      <div class="chat-area thin-scrollbar" #chatArea>
        <app-chat-bubble class="chat" [message]="message" [isClosed]="message.isClosed" [userId]="userId"
          [search]="search" (addMessage)="addMessage($event)">
        </app-chat-bubble>
      </div>
      <div class="close-demande" *ngIf="message && message.isClosed">
        <p style="text-align: center;font-size: medium;">{{ 'messagerie.demande_is_closed_admin'|trans }}</p>
      </div>
    </div>
    <ng-template #filterDetails let-data>
      <mat-toolbar mat-dialog-title color="primary">
        <button mat-icon-button [mat-dialog-close] class="close-dialog">
          <mat-icon>close</mat-icon>
        </button>
        <span class="title">{{ 'messagerie.filter_per_etablissement'|trans }}</span>
      </mat-toolbar>
      <h4 class="section-title subtitle">
        <mat-icon color="primary">arrow_right</mat-icon>
        Par établissement
      </h4>
      <div class="inline-fields">
        <mat-form-field>
          <mat-label>Choix de l'établissement</mat-label>
          <mat-select [(ngModel)]="selectedEtablissementId">
            <mat-option *ngFor="let etablissement of etablissements" [value]="etablissement.idEtablissement">
              {{etablissement.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div mat-dialog-actions>
        <button mat-raised-button color="primary" matTooltip="Rechercher" (click)="applyFilterMessages()"
          [disabled]="!selectedEtablissementId">
          <i class="fas fa-search"></i>
        </button>
      </div>
    </ng-template>
    <div *ngIf="!message" class="empty-message">
      <p>{{ 'messagerie.select_to_read'|trans }}</p>
    </div>
  </div>
</div>
<mat-menu #messageMenu>
  <ng-template matMenuContent let-message="message">
    <button mat-menu-item (click)="closeUserDemande()" [disabled]="message.isClosed">
      <i class="fa fa-window-close cloture-btn" aria-hidden="true"></i>
      <span style="margin-left: 10px;">{{ 'messagerie.close_demande'|trans }}</span>
    </button>
    <button mat-menu-item (click)="markAsUnread(message)">
      <i class="fas fa-check"></i>
      <span style="margin-left: 10px;">{{ 'messagerie.mark_as_unread'|trans }}</span>
    </button>
  </ng-template>
</mat-menu>
