<mat-form-field style="width: 100%;">
  <mat-label>{{to.label}}</mat-label>

  <input type="text" [placeholder]="to.placeholder" matInput [formControl]="formControl" [matAutocomplete]="auto"
    [required]="to.required" (keydown.enter)="onEnter($event)">

  <mat-autocomplete #auto="matAutocomplete">

    <mat-option *ngFor="let address of results$ | async" (onSelectionChange)="optionSelected(address)">
      {{ address.label || address.properties.label }}
    </mat-option>
  </mat-autocomplete>

</mat-form-field>
<div *ngIf="apiMode == 1 && !fieldSearchDisabled" style="padding-bottom: 20px;" fxLayout="row"
  fxLayoutAlign="start center">
  <mat-slide-toggle [(ngModel)]="toggleEnabledField" color="primary" style="margin-left: 5px;"
    (ngModelChange)="editFields()" class="slideToggle">
    {{'admin.forms.edit_address-fields' | trans}}
  </mat-slide-toggle>
</div>
