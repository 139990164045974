import { Injectable, Inject, Component, Output, EventEmitter } from '@angular/core';
import { MatSnackBar, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

export enum TypeSnackbar {
  info = 'info',
  alert = 'alert',
  error = 'error'
}

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  private snackBarRef: any;

  constructor(
    private snackBar: MatSnackBar,
    private trans: TranslateService
  ) { }

  info(message, duration?) {
    duration = duration ? duration : 3000;
    this.open({ message, duration });
  }

  error(message) {
    this.open({ message, type: TypeSnackbar.error, textButton: 'Fermer' });
  }

  open({ message, type = TypeSnackbar.info, duration = 3000, textButton = '' }: any) {
    // {message: string, type?: TypeSnackbar, duration?: number, textButton?: string } ) {

    message = message ? this.trans.instant(message) : message;

    let icon: string;
    let color: string;

    switch (type) {
      case TypeSnackbar.info: icon = 'info'; color = 'primary'; break;
      case TypeSnackbar.alert: icon = 'warning'; color = 'accent'; break;
      case TypeSnackbar.error: icon = 'error'; color = 'warn'; break;
      default: icon = '';
    }

    duration = (textButton && textButton !== '') ? null : duration;


    // tslint:disable-next-line: no-use-before-declare
    this.snackBarRef = this.snackBar.openFromComponent(AppSnackbarComponent, {
      data: {
        message,
        icon,
        color,
        textButton
      },
      duration,
    });

    this.snackBarRef.instance.snackBarRefComponent = this.snackBarRef;

    return this.snackBarRef;
  }
}

@Component({
  selector: 'app-snackbar',
  template: `
              <div *ngIf="data.devMode" style="height:unset;"> displayed in dev mode only  ({{data.devMode}})</div>
              <div>
                <mat-icon [color]="data?.color" >{{ data?.icon }}</mat-icon>
                <span style="flex:1; max-height:50vh; overflow:auto;" [innerHTML]="data?.message|safe"></span>
                <button *ngIf="data?.textButton!=''"  mat-button (click)="dismiss()" color="accent" >{{data.textButton}}</button>
              </div>
            `,
  styles: [`:host{max-width:unset;}
            div{    display: flex;
              justify-content: space-between;
              align-items: center;
              height: 100%;
              line-height: 20px;
              opacity: 1;
              font-size: 12px;
            }
            mat-icon{margin:5px}`]
})
export class AppSnackbarComponent {

  @Output() close = new EventEmitter();

  public snackBarRefComponent: any;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    public snackbarService: SnackbarService
  ) {
    if (!this.data || typeof this.data.devMode === 'undefined') {
      this.data.devMode = false;
    }
  }

  dismiss() {
    this.snackBarRefComponent.dismiss();
    this.close.emit();
  }

}
