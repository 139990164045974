<mat-card *ngIf="!! oidcMessage" class="oidc-message-container">
  <mat-card-title [class]="oidcMessage.type === 'error' ? 'mat-error' : ''">

    <span *ngIf="oidcMessage.type === 'error'">
      <mat-icon color="warn" style="float: left; margin-right: 10px;">error</mat-icon>
      Erreur
    </span>
    <button mat-icon-button aria-label="dismiss" (click)="clearOidcMessage()" style="float:right;">
      <mat-icon>clear</mat-icon>
    </button>
  </mat-card-title>
  <mat-card-content>
    <pre *ngIf="oidcMessage.type === 'error' else simpleMessage" [innerHTML]="oidcMessage.message" class="mat-error"
      style="text-align: left;"></pre>
    <ng-template #simpleMessage>
      <div [innerHTML]="oidcMessage.message|dynamic:oidcMessage.variables|safe" style="text-align: left;"></div>
    </ng-template>

    <div *ngIf="oidcMessage.type === 'error'">
      <h3>Que faire ?</h3>
      <ul>
        <li>Nous vous invitons à fermer tous les onglets de votre navigateur et à réessayer en suivant les étapes de
          connexion.</li>
        <li>N'utilisez ni la « flèche retour en arrière » de votre navigateur ni raccourcis.</li>
        <li>Si vous avez besoin de recommencer tout le processus de connexion, veillez à bien fermer votre navigateur au
          préalable.</li>
      </ul>
    </div>

    <div *ngIf="oidcMessage.type === 'infoUserNotFound'" class="button-container">
      <button mat-stroked-button color="primary" (click)="clearOidcMessage()">
        <mat-icon>person </mat-icon>
        {{'login.oidc_user_not_found.return_connect'|trans}}
      </button>

      <button mat-stroked-button color="primary" *ngIf="permService.hasPermission('account_create')"
        routerLink="/register">
        <mat-icon>assignment</mat-icon>
        {{'login.oidc_user_not_found.go_register'|trans}}
      </button>

    </div>

  </mat-card-content>
</mat-card>

<ng-container *ngIf="!oidcMessage">
  <ng-container *ngIf="!redirecting; else redirection">

    <mat-card *ngIf="!homeCard" class="conteneur">
      <mat-card-header>
        <mat-card-title>Connexion</mat-card-title>
        <mat-icon mat-card-avatar class="icon">person</mat-icon>
      </mat-card-header>
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </mat-card>

    <div *ngIf="homeCard">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </div>
  </ng-container>
</ng-container>

<ng-template #content>
  <ng-container *ngIf="authService.currentUser$|async as user; else loginBlock">

    <div class="already-logged-msg">

      <div class="oidc-logged-msg" style="margin-top: 5px;" *ngIf="user.fromOidc">
        <img [src]="externalAuthConf?.providerLogo" class="oidc-logo" [matTooltip]="userNameTooltip"
          [tooltipClass]="login-tooltip" />
        <span> Vous êtes identifié grâce à {{user.oidcProvider}} </span>
      </div>

      <p>{{ 'login.logged_in_as'|trans }} {{ user.username || user.email }}</p>

      <button mat-raised-button color="primary" routerLink="/account">Mon compte</button>
    </div>
  </ng-container>
</ng-template>

<ng-template #loginBlock>

  <div *ngIf="externalAuthConf?.isActive" class="external-auth">

    <app-open-id-form (redirecting)="redirecting=$event" (redirectingMessage)="redirectingMessage=$event"
      [from]="'login'">
    </app-open-id-form>

    <div class="choice-separator">
      Ou
    </div>
  </div>

  <form (ngSubmit)="login()" [formGroup]="loginForm">
    <div fxLayout="row" fxLayoutAlign="center center" class="login-error warn-bg" *ngIf="loginError" translate>
      <mat-icon style="margin-right: 5px;">error</mat-icon> <span>Nom d'utilisateur ou mot de passe incorrect.</span>
    </div>

    <div fxLayout="column" class="content">
      <mat-form-field>

        <mat-label>Adresse e-mail</mat-label>

        <input type="email" matInput formControlName="username" autocomplete="username" [appFocusOnShow]="!homeCard"
          name="portailfamilles_login" />

        <mat-error
          *ngIf="loginForm.controls.username.invalid && (loginForm.controls.username.dirty || loginForm.controls.username.touched) ">
          <span translate>Nom d'utilisateur requis</span>
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <input type="password" matInput formControlName="password" [type]="showPass ? 'text' : 'password'"
          autocomplete="current-password" placeholder="Mot de passe" required name="portailfamilles_password" />
        <mat-icon matSuffix (click)="showPass = !showPass" style="cursor:pointer;">
          {{ showPass ? 'visibility_off' : 'visibility' }}
        </mat-icon>
        <mat-error
          *ngIf="loginForm.controls.password.invalid && (loginForm.controls.password.dirty || loginForm.controls.password.touched)">
          <span translate>Mot de passe requis</span>
        </mat-error>
      </mat-form-field>

      <div fxLayout="row wrap" fxLayoutAlign="space-between center">
        <mat-slide-toggle formControlName="remember" color="primary">{{'login.remember_me'|trans}}</mat-slide-toggle>
        <a href="#" routerLink="/forgot-password" style="margin: 10px; cursor: pointer;" translate>
          login.forgot_your_password
        </a>
      </div>

      <button id="btnLogin" mat-raised-button color="primary" type="submit" [disabled]="normalLoginLoading">
        <span translate>login.login_button_label</span>
        <app-loader size="1" class="" *ngIf="normalLoginLoading"></app-loader>
      </button>

      <div class="new" *ngIf="permService.hasPermission('account_create')">
        <span class="mat-h3"> {{'login.register_label'|trans}} </span>
        <button type="button" id="btn-register" mat-button color="accent" href="#" routerLink="/register">
          {{'login.register_button_label'|trans}}
        </button>
      </div>

    </div>

  </form>
</ng-template>

<ng-template #redirection>
  <app-loader class="redirecting-loader">
    <h3>{{ 'login.'+redirectingMessage|trans }}</h3>
  </app-loader>
</ng-template>
