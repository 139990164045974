import { Component } from '@angular/core';
import { FieldArrayType, FormlyFormBuilder } from '@ngx-formly/core';
import { ExtendedFormlyFieldConfig, ExtendedFormlyFormOptions, ExtendedFormlyTemplateOptions } from '@app/forms/config/form-model';

@Component({
  selector: 'app-formly-repeat-section',
  template: `

  <div *ngIf="showError" class="mat-error">
    <formly-validation-message [field]="field">
    </formly-validation-message>
  </div>

  <div *ngFor="let field of field.fieldGroup; let i = index;" class="row">
  <formly-group style="width:100%;"
  [model]="model[i]"
  [field]="field"
  [options]="options"
  [form]="formControl">
    <div style="margin-left: 10px">
      <button mat-raised-button type="button" color="warn" (click)="remove(i)">Supprimer</button>
    </div>{{field.fieldGroup[key]}}
  </formly-group>
  <mat-divider style='position:relative; margin-bottom: 20px; margin-top: 20px;'></mat-divider>
  </div>
  <div style="margin:10px 0;">
    <button type="button" style="display:block; margin:auto;" [disabled]="to.max && model.length >= to.max"
    mat-raised-button color="primary" (click)="add()">
      {{ to.addText }}
    </button>
  </div>
`,
})
export class RepeatTypeComponent extends FieldArrayType {
  field: ExtendedFormlyFieldConfig;
  options: ExtendedFormlyFormOptions;
  to: ExtendedFormlyTemplateOptions;

  constructor(builder: FormlyFormBuilder) {
    super(builder);
  }
}
