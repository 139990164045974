<mat-card class="conteneur">
  <mat-card-header>
    <mat-card-title>{{ title }}</mat-card-title>
    <mat-icon mat-card-avatar class="icon">vpn_key</mat-icon>
  </mat-card-header>
  <mat-card-content *ngIf="!fatalErrorMsg else fatalError;">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="content" fxLayout="column" *ngIf="response==''">
        <mat-form-field *ngIf="!token && form.get('oldPassword')">
          <mat-label>Ancien mot de passe</mat-label>
          <input [type]="showPass ? 'text' : 'password'" matInput formControlName="oldPassword">
          <button mat-icon-button matSuffix (click)="showPass = !showPass" type="button" tabindex="-1">
            <mat-icon>{{ showPass ? 'visibility_off' : 'visibility' }}</mat-icon>
          </button>
        </mat-form-field>

        <div>
          <app-new-password [label]="'Nouveau mot de passe'" [requiredForce]="2"></app-new-password>
        </div>

      </div>
      <mat-card-actions fxLayout="column" fxLayoutAlign="space-between center" fxLayoutGap="10px">
        <button mat-raised-button type="submit" class="submit-bt" color="primary" *ngIf="response==''"
          [disabled]="isLoading">
          <span>Valider</span>
          <app-loader size="1" class="" *ngIf="isLoading"></app-loader>
        </button>
      </mat-card-actions>
    </form>
  </mat-card-content>
</mat-card>

<ng-template #fatalError>
  <div class="fatal-error">
    <mat-card-title class="mat-error">
      <mat-icon color="warn" style="float: left; margin-right: 10px;">error</mat-icon>
      <span>Erreur</span>
    </mat-card-title>
    <mat-card-content>

      <pre [innerHTML]="fatalErrorMsg | safe" class="mat-error"></pre>

    </mat-card-content>
    <mat-card-actions fxLayout="column" fxLayoutAlign="space-between center" fxLayoutGap="10px">
      <button mat-raised-button type="button" color="warn" (click)="goBack()">
        <span>Retour</span>
      </button>
    </mat-card-actions>

  </div>
</ng-template>
