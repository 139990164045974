import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { autoSubmitConf } from '@app/directives/auto-submit-countdown.directive';
import { ConfirmType, WaitingRoomService } from '@app/services';
import { filter } from 'rxjs/operators';


@Component({
  selector: 'app-waiting-room-confirm',
  templateUrl: './waiting-room-confirm.component.html',
  styleUrls: ['./waiting-room-confirm.component.scss']
})
export class WaitingRoomConfirmComponent implements OnInit {

  title: string;
  message: string;

  confirmText: string;
  cancelText: string;
  thirdChoiceText: string;

  autoValidation: 'confirm' | 'cancel' | null = null
  autoValidationConf: autoSubmitConf = { delay: 20, tooltip: '' };

  type: ConfirmType;

  templateData: any = {};

  constructor(
    private waitingRoomService: WaitingRoomService,
    @Inject(MAT_DIALOG_DATA) private data,
    public dialogRef: MatDialogRef<WaitingRoomConfirmComponent>,
  ) {
    if (data && data.type) {
      this.type = data.type
    }
  }

  ngOnInit(): void {
    this.waitingRoomService.data$.subscribe(data => {
      this.templateData = { ...this.templateData, ...data }
    })

    this.waitingRoomService.config$.subscribe(conf => {

      switch (this.type) {
        case 'quitWaitingRoom':
          this.title = 'Quitter la salle d\'attente ? ';
          this.message = conf.msgConfirmQuitSalleAttente;
          this.confirmText = 'Quitter la salle d\'attente';
          this.cancelText = 'Rester';
          break;

        case 'quitRestrictedRoom':
          this.title = 'Quitter la zone de réservation ? ';
          this.message = conf.msgConfirmQuitReservation;
          this.cancelText = 'Non, je termine d\'abord mes réservations';
          this.confirmText = 'Non, mais je reviens vite';
          this.thirdChoiceText = 'Oui, je libère la place';

          this.launchMinuteur()

          break;

        case 'stillHere':
          this.title = 'Etes vous toujours là ? ';
          this.message = conf.msgConfirmToujourEnCours;
          this.confirmText = 'Oui, je termine mes réservations';
          this.cancelText = 'Non, je libère la place';
          this.autoValidation = 'cancel'

          this.launchMinuteur()

          this.waitingRoomService.countDown$.subscribe(countdown => {
            const countDownInSecond = Math.round(countdown.getTime() / 1000)
            if (countDownInSecond < this.autoValidationConf.delay) {
              this.autoValidationConf = {
                ...this.autoValidationConf,
                delay: countDownInSecond,
              }
            }
          })

          break;

        default:
          this.title = this.data.title || 'Confirmation';
          this.message = this.data.message || 'Le type de message n\'est pas configuré ! ';
          this.confirmText = this.data.confirmText || 'Confirmer';
          this.cancelText = this.data.cancelText || 'Annuler';
          break;


      }
    })
  }

  launchMinuteur() {
    this.waitingRoomService.countDown$.pipe(
      filter(cd => !!cd)
    ).subscribe(countdown => {
      const minuteur = countdown.getUTCMinutes() + ' min ' + countdown.getUTCSeconds() + ' s'
      this.templateData = { ...this.templateData, minuteur }
    })

  }

}
