import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CogitoInscPreIsnc } from '@app/models/cogito';
import { FamilyService, PermissionService } from '@app/services';
import { CogitoService } from '@app/services/cogito.service';
import moment from 'moment';
import { interval, Subject, Subscription } from 'rxjs';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-cogito-preinscription',
  templateUrl: './cogito-preinscription.component.html',
  styleUrls: ['./cogito-preinscription.component.scss']
})
export class CogitoPreinscriptionComponent implements OnInit {

  preinscriptions: CogitoInscPreIsnc[];
  preinscriptionsFiltered: CogitoInscPreIsnc[];
  permToCreate: boolean;
  permToEdit: boolean;
  checkPermInscription: Subscription;
  inscriptionsActive: boolean;
  isLoading: boolean;
  config: any;
  drawerOpened = false;
  showAccepted: boolean = true;
  showRefused: boolean = true;
  showWaiting: boolean = true;

  onDestroy$ = new Subject();

  constructor(
    private familyService: FamilyService,
    private permService: PermissionService,
    private cogitoService: CogitoService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.permToEdit = this.permService.hasPermission('cogito_edit') && this.familyService.currentFamily.active;
    this.permToCreate = this.permService.hasPermission('cogito_create') && this.familyService.currentFamily.active;

    this.familyService.currentFamily$.pipe(
      filter(f => !!f),
      takeUntil(this.onDestroy$),
      tap(_ => { this.preinscriptions = null; }),
      switchMap(f => this.cogitoService.getFamilyPreinscriptions(f))
    ).subscribe((data: any) => {
      this.config = data.config;
      this.inscriptionsActive = this.cogitoService.checkPermInscription(data.config);
      this.preinscriptions = data.preinscriptionsTab;
      this.sortBydateDemande(this.preinscriptions);
      this.preinscriptionsFiltered = [...this.preinscriptions];
      this.isLoading = true;
    });

    this.checkPermInscription = interval(1000).subscribe(_ => {
      if (this.config) {
        this.inscriptionsActive = this.cogitoService.checkPermInscription(this.config);
      }
    });
  }

  onClickEdit(item: CogitoInscPreIsnc) {
    this.router.navigate([`account/cogito-preinscriptions/${item.idPreInscriptionScolaire}`]);
  }

  isEditable(preinscription: CogitoInscPreIsnc): boolean {
    if (preinscription.decision === "Acceptée" || preinscription.decision === "Refusée") {
      return false;
    } else return true;
  }

  closeDrawer() {
    this.drawerOpened = false;
  }

  toggleOptions() {
    this.drawerOpened = !this.drawerOpened;
  }

  toggleShowAccepted(event) {
    this.filterByDecision("Acceptée", event)
  }

  toggleShowRefused(event) {
    this.filterByDecision("Refusée", event)
  }

  toggleShowWaiting(event) {
    this.filterByDecision("En attente", event)
  }

  filterByDecision(decision: string, event: boolean) {
    if (event === false) {
      this.preinscriptionsFiltered = this.preinscriptionsFiltered.filter(preinsc => preinsc.decision !== decision);
    } else {
      let preinsc = this.preinscriptions.filter(preinsc => preinsc.decision === decision);
      this.preinscriptionsFiltered.push(...preinsc);
    }
    this.sortBydateDemande(this.preinscriptionsFiltered);
  }

  sortBydateDemande(ArrayPreinsc: CogitoInscPreIsnc[]) {
    ArrayPreinsc.sort((a, b) => {
      if (moment(a.dateDemande) > moment(b.dateDemande)) return -1;
      if (moment(a.dateDemande) < moment(b.dateDemande)) return 1;
      return 0;
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.checkPermInscription.unsubscribe();
  }

}
