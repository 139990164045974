<div class="main-container">

  <app-payment-status></app-payment-status>

  <mat-card *ngIf="validateSuccess" class="top-status-message primary-lighter-bg">
    <mat-icon>check</mat-icon>
    <span>
      {{ 'panier.validate_message_without_payment'|trans }}
    </span>
  </mat-card>

  <mat-card class="header-info">
    <mat-card-header>
      <mat-card-title>{{ 'panier.entete.title'|trans }}</mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <p class="row items-center">
        <mat-icon>help</mat-icon>
        {{ 'panier.entete.help'|trans }}
      </p>

      <p class="row items-center">
        <mat-icon>warning</mat-icon>
        {{ 'panier.entete.alert'|trans }}
      </p>
    </mat-card-content>
  </mat-card>

  <app-loader *ngIf="!dataByRegie; else readyContent" class="margin">
    Chargement des données ...
  </app-loader>
</div>

<ng-template #readyContent>
  <h3 *ngIf="!dataByRegie || !dataByRegie.length; else panierList" style="text-align: center;">
    {{ 'panier.empty'|trans }}
  </h3>
</ng-template>

<ng-template #panierList>
  <mat-card *ngFor="let regieData of dataByRegie">
    <mat-card-header>
      <mat-card-title>
        <app-icon type="do" icon="regie" matTooltip="Régie"></app-icon>
        {{ regieData.nomRegie }}
      </mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <mat-card *ngIf="regieData.pausePaiementMessage && regieData.pausePaiement" class="primary-lighter-bg"
        style="margin-bottom: 10px;">
        <div fxLayout="row" fxLayoutAlign="start center">
          <mat-icon color="accent" style="margin-right: 24px;">info</mat-icon>
          <div [innerHTML]="regieData.pausePaiementMessage | safe"></div>
        </div>
      </mat-card>
      <div *ngFor="let reservation of regieData.reservations" class="reservation-item panier-section">
        <div class="top-part row items-center" (click)="selectReservation(reservation, regieData)">
          <span class="row items-center">
            <mat-icon *ngIf="reservation.alreadyPaying" color="warn" style="margin-right: 10px;">warning</mat-icon>

            <mat-checkbox [checked]="reservation.selected" [disabled]="factureLoading || reservation.alreadyPaying"
              (click)="selectReservation(reservation, regieData, $event)">
              Réservation n° <b>{{ reservation.number }}</b>
            </mat-checkbox>
          </span>

          <span>pour <b>{{ reservation.referent }}</b></span>

          <div class="column dates">
            <span style="font-style: italic;">créée le {{ reservation.creationDate|date:'dd/MM/y à HH:mm' }}</span>
            <span>à régler avant : <b>{{ getExpireText(reservation.expirationDate) }}</b></span>
          </div>
        </div>

        <hr>

        <div class="bottom-part row items-center">
          <div class="periode-info column">
            <span>
              <app-icon type="do" icon="etablissement" matTooltip="Établissement"></app-icon>
              {{ reservation.etablissement }}
            </span>
            <span>
              <app-icon type="do" icon="accueil" matTooltip="Accueil"></app-icon>
              {{ reservation.accueil }}
            </span>
            <span>
              <app-icon type="do" icon="periode" matTooltip="Période"></app-icon>
              {{ reservation.periode }}
            </span>
          </div>

          <div class="column">
            <!-- @TODO: add dates range ? -->
            <button mat-raised-button color="primary" (click)="openReservationDetails(reservation)">
              Détail
              <mat-icon>loupe</mat-icon>
            </button>
          </div>
        </div>
      </div>

      <div class="panier-section">

        <div *ngIf="regieData.checkedReservations < 1" class="row items-center" style="padding: 5px;">
          <mat-icon color="primary" style="margin-right: 10px;">info</mat-icon>
          {{ 'panier.none_selected'|trans }}
        </div>

        <mat-card *ngIf="regieData.errors && regieData.errors.length" class="warn-lighter-bg errors-list">
          <mat-icon>highlight_off</mat-icon>
          <div>
            <div *ngFor="let error of regieData.errors">{{ error|trans }}</div>
          </div>
        </mat-card>

        <app-loader *ngIf="!regieData.facture && !regieData.errors && regieData.checkedReservations > 0"
          style="margin: 1em">
          Chargement de la facture ...
        </app-loader>

        <app-facture-line-table *ngIf="regieData.facture" [facture]="regieData.facture">
        </app-facture-line-table>

        <div *ngIf="regieData.montantAvoir" class="panier-section avoir">
          <div>
            <mat-icon color="primary">info</mat-icon>
            {{ 'panier.credit_message'|trans:{amount: regieData.montantAvoir|currency:'EUR'} }}
          </div>

          <div>
            <mat-checkbox [(ngModel)]="regieData.useAvoir" (change)="updateTotal(regieData)">
              {{ 'panier.credit_use_before'|trans }}
            </mat-checkbox>
            <span>
              <input [disabled]="!regieData.useAvoir" [(ngModel)]="regieData.useAvoirMontant"
                (ngModelChange)="updateTotal(regieData)"> €
              {{ 'panier.credit_use_after'|trans }} ({{ regieData.checkedReservations }} réservations)
            </span>
          </div>
        </div>

      </div>
    </mat-card-content>

    <mat-card-actions>

      <h4 *ngIf="regieData.facture">
        <label>Total : </label>
        <span class="value">
          {{ regieData.toPay|currency:'EUR' }}
        </span>
      </h4>

      <div class="buttons">
        <button mat-raised-button routerLink="/account">
          <mat-icon>reply</mat-icon>
          {{ 'panier.action.return'|trans }}
        </button>

        <button mat-raised-button color="primary" routerLink="/account/reservations/new">
          <mat-icon>add</mat-icon>
          {{ 'panier.action.new_reservation'|trans }}
        </button>

        <button mat-raised-button color="accent" [disabled]="regieData.pausePaiement || !regieData.facture"
          (click)="goPay(regieData)">
          <mat-icon>arrow_forward</mat-icon>
          {{ (regieData.toPay > 0 ? 'panier.action.validate_with_payment' : 'panier.action.validate_without_payment' )|trans }}
        </button>
      </div>
    </mat-card-actions>
  </mat-card>
</ng-template>

<ng-template #detailDialog let-data>
  <mat-toolbar mat-dialog-title color="primary" class="title-toolbar">
    <button class="close-dialog" mat-icon-button mat-dialog-close matTooltip="Fermer">
      <mat-icon>close</mat-icon>
    </button>
    <span class="title">Détail de la réservation</span>
  </mat-toolbar>

  <mat-dialog-content class="thin-scrollbar">
    <app-user-reservation-details [reservation]="data.reservation"></app-user-reservation-details>
  </mat-dialog-content>

  <mat-dialog-actions align="center">
    <!-- <button mat-raised-button color="accent-lighter" mat-dialog-close
      routerLink="/account/reservations/{{ data.reservation.idPeriode }}-{{ data.reservation.idInscription }}">
      Voir / Modifier la réservation sur le planning
    </button> -->
  </mat-dialog-actions>
</ng-template>
