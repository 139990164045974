import { Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { Messagerie } from '@app/models/message';
import { FileService, SnackbarService } from '@app/services';
import { MessagerieComponent } from '../messagerie.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MessagerieService } from '@app/services/messagerie.service';


@Component({
  selector: 'app-new-message',
  templateUrl: './new-message.component.html',
  styleUrls: ['./new-message.component.scss']
})
export class NewMessageComponent implements OnInit {

  object = null
  content = null
  etablissements = [];
  selectedEtablissements = [];
  files: any[] = [];
  parentId: number;
  idMessageParent0: number;
  modeEdit: boolean;
  saving = false;
  sending = false;
  etablissementsUser = []
  etablissementsMapping: { [id: number]: string } = {};


  @Input() message: Messagerie;
  @ViewChild("fileDropRef", { static: false }) fileDropEl: ElementRef;


  constructor(
    private fileService: FileService,
    public dialogRef: MatDialogRef<MessagerieComponent>,
    private messagerieService: MessagerieService,
    private snackbarService: SnackbarService,

    @Inject(MAT_DIALOG_DATA) private data
  ) {
    this.etablissementsUser = data.etablissementsUser
    this.etablissements = data.etablissements;
    this.idMessageParent0 = data.idMessageParent0
    if (data.message) {
      this.parentId = data.message.idPortailMessage;
      this.object = data.message.objetMessage;
      if (data.message.idEtablissementExpediteur)
        this.selectedEtablissements = [data.message.idEtablissementExpediteur];
      else
        this.selectedEtablissements = data.message._etablissements;
    }
  }

  ngOnInit(): void {
    this.initData()
    this.etablissements.forEach((etab) => {
      this.etablissementsMapping[etab.idEtablissement] = etab.name;
    });
  }

  initData() {
    this.modeEdit = !!this.data.message
  }

  submit() {
    this.sending = true;
    const formData = new FormData();
    if (this.object) { formData.set('objetMessage', this.object) }
    if (this.content) { formData.set('contenu', this.content) }
    formData.set('idMessageParent', `${this.parentId || null}`)
    formData.set('_etablissements', this.selectedEtablissements.join(','))
    formData.set('idMessageParent0', `${this.idMessageParent0 || null}`)
    this.files.forEach((file, i) => formData.append(`_messagePJ${i + 1}`, file))
    formData.append('fileCount', this.files.length + "")
    this.messagerieService.createMessage(formData).subscribe(
      (res) => {
        this.sending = false;
        this.snackbarService.info('Message envoyé');
        this.dialogRef.close(formData);
      },
      (error) => {
        this.sending = false;
        console.error('Error sending message:', error);
      }
    );
  }

  onFileChange(event) {
    for (var i = 0; i < event.target.files.length; i++)
      this.files.push(event.target.files[i]);
  }

  getIcon(type: string): string {
    return this.fileService.getFileIcon(type)
  }

  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  deleteFile(index: number) {
    this.files.splice(index, 1);
  }
}
