import { Component, OnInit, ElementRef, ViewChild, TemplateRef, OnDestroy } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { Facture, RegieConfig } from '@app/models/facturation';
import { FacturationService, PlatformService, FamilyService, SnackbarService } from '@app/services';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { FactureDetailComponent } from '../detail/detail.component';
import { TranslateService } from '@ngx-translate/core';
import { interval, Subscription } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { PaymentRedirectDialogComponent } from '@app/components/_common/payment-redirect-dialog/payment-redirect-dialog.component';

export interface PayRequestParams {
  idRegie: number;
  idFamily: number;
  amount?: number;
  factures?: number[];
  backUrl?: string;
}

@Component({
  selector: 'app-paiement',
  templateUrl: './paiement.component.html',
  styleUrls: ['./paiement.component.scss']
})
export class PaiementComponent implements OnInit, OnDestroy {

  // Inputs
  idRegie = +this.currentRoute.snapshot.paramMap.get('regie');

  // Data
  payableFactures: Facture[];
  regieConfig: RegieConfig;
  checkPausePaiement: Subscription;

  // Table
  displayedColumns: string[] = ['numero', 'date', 'montant', 'resteAPayer', 'actions'];
  selection: SelectionModel<Facture>; // Used only if multiplePayMode
  dataSource: MatTableDataSource<Facture>;

  // Info
  selectedTotalAmount: number;

  // Helpers
  multiplePayMode = false;

  // Status
  paying = false;
  loadingRegie: boolean = false;

  @ViewChild('paymentDialog') paymentDialog: TemplateRef<any>;

  constructor(
    private dialog: MatDialog,
    private elementRef: ElementRef,
    private snackbar: SnackbarService,
    private currentRoute: ActivatedRoute,
    private familyService: FamilyService,
    private platformService: PlatformService,
    private translateService: TranslateService,
    private facturationService: FacturationService
  ) { }

  ngOnInit() {
    this.currentRoute.data.subscribe(data => console.log('ROUTE DATA : ', data));

    this.facturationService.getRegieConfig(this.idRegie).subscribe(config => {
      this.checkPausePaiement = interval(500).subscribe(_ => {
        config.pausePaiement = this.facturationService.checkPausePaiement(config);
        this.loadingRegie = true;
      });
      this.regieConfig = config;
      this.multiplePayMode = this.regieConfig.payMode === 'multiple';

      // Should load only if regie is not "prepay"
      if (this.regieConfig.payMode !== 'prepay') {
        // Get only factures for one regie + status = "waiting" => (maybe could use a generic "filter" URL ? => if exist someday)
        this.facturationService.getPayableFactures(this.familyService.currentFamily.id, this.idRegie).subscribe(data => {
          this.payableFactures = data;
          this.dataSource = new MatTableDataSource(this.payableFactures);

          if (this.multiplePayMode) {
            this.displayedColumns.unshift('select');
            this.selection = new SelectionModel<Facture>(true, this.payableFactures);
            this.refreshTotal();
          }
        });
      }
    });
  }

  openDetail(facture: Facture) {
    const detailsDialog = this.dialog.open(FactureDetailComponent, {
      data: { facture, regie: this.regieConfig }
    });

    this.platformService.adaptDialogToScreen(detailsDialog);
  }

  isAllSelected() {
    const selectedCount = this.selection.selected.length;
    const totalCount = this.dataSource.data.length;

    return selectedCount === totalCount;
  }

  toggleMaster() {
    this.isAllSelected() ? this.selection.clear() : this.selection.select(...this.payableFactures);
    this.refreshTotal();
  }

  toggleFacture(facture: Facture) {
    this.selection.toggle(facture);
    this.refreshTotal();
  }

  refreshTotal() {
    const selected = this.selection.selected.map(f => f.resteAPayer);
    this.selectedTotalAmount = selected.length ? selected.reduce((acc, val) => acc + val) : 0;
  }

  goPay(clickedFacture?: Facture) {
    this.paying = true;

    const redirectingDialog = this.dialog.open(PaymentRedirectDialogComponent, {
      data: { regie: this.regieConfig },
      disableClose: true
    });

    const params = {
      idRegie: this.regieConfig.id,
      idFamily: this.familyService.currentFamily.id
    } as PayRequestParams;

    if (this.regieConfig.payMode === 'prepay') {
      params.amount = this.selectedTotalAmount;
    } else {
      params.factures = this.multiplePayMode ? this.selection.selected.map(f => f.id) : [clickedFacture.id];
    }

    let tabRef: Window;

    // Prepare the new tab for PayFip -- must be done in the main thread, a security enforcement in some browsers (Safari)
    if (this.regieConfig.typePaiement === 'payfip') {
      tabRef = this.openPayfipTab();
      this.platformService.watchForTabClose(tabRef).subscribe(() => redirectingDialog.close());
    }

    this.facturationService.sendPayRequest(params, this.regieConfig.typePaiement, 'facture').subscribe(result => {

      if (!result || result.error || result.errors || result.Error) {
        // tell the user something wrong happened, abort redirection
        this.snackbar.error('Erreur : impossible de rediriger sur la plateforme de paiement');

        if (tabRef) {
          tabRef.close();
        }

        return;
      }

      if (result.params) {
        const form = this.facturationService.buildPaymentForm(result.url, result.params);
        (this.elementRef.nativeElement as HTMLElement).appendChild(form);
        form.submit();
        setTimeout(() => form.remove());
      } else if (this.regieConfig.typePaiement === 'payfip') {
        tabRef.location = result.url;
      } else if (result.url) {
        window.location = result.url;
      }

      setTimeout(() => redirectingDialog.close());
    });
  }

  openPayfipTab() {
    const tab = window.open('', '_blank');
    tab.document.body.innerHTML = '<h1>' + this.translateService.instant('facture.paiement.redirect_message_tab') + '</h1>';
    tab.document.title = 'Redirection vers la plateforme de paiement';

    return tab;
  }

  ngOnDestroy() {
    this.checkPausePaiement.unsubscribe()
  }
}
