export class HeaderConfig {

  title = {
    enabled: true,
    text: 'Portail Famille',
    style: {}
  };

  logo = {
    enabled: false,
    image: '/assets/images/portail-logo.png',
    height: 64,
    width: 64
  };

  banner = {
    enabled: false,
    image: '/assets/images/banniere-famille.png',
    height: '25%'
  };
}
