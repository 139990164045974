import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PaymentRedirectDialogComponent } from '@app/components/_common/payment-redirect-dialog/payment-redirect-dialog.component';
import { ConfigTicket } from '@app/models/reservation-ticket';
import { FacturationService, FamilyService, PlatformService, SnackbarService } from '@app/services';
import { PanierRegieData, PanierRubriqueData, TicketService } from '@app/services/ticket.service';
import { TranslateService } from '@ngx-translate/core';
import { interval, Subscription } from 'rxjs';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-achat-ticket',
  templateUrl: './achat-ticket.component.html',
  styleUrls: ['./achat-ticket.component.scss']
})
export class AchatTicketComponent implements OnInit, OnDestroy {

  data: PanierRegieData[];
  configTicket: ConfigTicket;
  checkPausePaiement: Subscription[] = [];
  loadingRegie = false;

  constructor(
    private familyService: FamilyService,
    private ticketService: TicketService,
    private snackbarService: SnackbarService,
    private platformService: PlatformService,
    private translateService: TranslateService,
    private facturationService: FacturationService,
    private elementRef: ElementRef,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {

    this.ticketService.getConfigForUser().subscribe(conf => {
      this.configTicket = conf;
      this.familyService.currentFamily$.pipe(
        filter(f => !!f),
        switchMap(fam => this.ticketService.getPanierData(fam.id)),
        tap(data => {
          this.data = data;
          this.data.forEach(regie => {
            this.checkPausePaiement.push(interval(1000).subscribe(_ => {
              regie.pausePaiement = this.facturationService.checkPausePaiement(regie);
              this.loadingRegie = true;
            }));
          })
          if (conf.groupInscriptions) {
            this.groupInscription();
          }
        })
      ).subscribe();
    })
  }

  groupInscription() {
    this.data.forEach(panierRegie => {

      let allRubFactByRegie = [];

      panierRegie.inscriptions.forEach(inscription => {
        inscription.rubriquesFacturation.forEach(rub => {
          allRubFactByRegie.push({ rub, idInsc: inscription.idInscription, nomUsager: [] })
        })
      });

      panierRegie.inscriptions.forEach(inscription => {
        allRubFactByRegie.forEach(r => {
          inscription.rubriquesFacturation.forEach(rub => {
            if (r.rub.id === rub.id) {
              r.nomUsager.push(inscription.nomUsager);
            }
          });
        });
      })

      let rubFactByRegieWithoutDuplicate = allRubFactByRegie
        .filter((v, i, a) => a.findIndex(t => (t.rub.id === v.rub.id && t.rub.designation === v.rub.designation && t.rub.nomUsager === v.rub.nomUsager)) === i);

      let groupByNomUsager = [this.groupBy(rubFactByRegieWithoutDuplicate, 'nomUsager')]

      panierRegie.inscriptions = [];

      groupByNomUsager.forEach(data => {
        for (const nomUsagers in data) {
          if (Object.prototype.hasOwnProperty.call(data, nomUsagers)) {
            const inscriptions = data[nomUsagers];
            const nomUser = nomUsagers.split(',');
            const id = inscriptions.map(x => x.idInsc).shift()
            const rubriques = inscriptions.map(x => x.rub);
            panierRegie.inscriptions.push({ idInscription: id, rubriquesFacturation: rubriques, nomUsager: nomUser })
          }
        }
      })
    });
  }

  groupBy(objectArray, property) {
    return objectArray.reduce(function (acc, obj) {
      let key = obj[property]
      if (!acc[key]) {
        acc[key] = []
      }
      acc[key].push(obj)
      return acc
    }, {})
  }

  increase(rub: PanierRubriqueData) {
    rub.quantite = (rub.quantite || 0) + 1;
    this.calculateTotal();
  }

  decrease(rub: PanierRubriqueData) {
    rub.quantite = rub.quantite ? rub.quantite - 1 : 0;
    this.calculateTotal();
  }

  calculateTotal() {
    this.data.forEach(regie => {
      let total = 0;

      regie.inscriptions.forEach(insc => insc.rubriquesFacturation.filter(rub => rub.quantite).forEach(rub => {
        total += rub.quantite * rub.tarif;
      }));

      regie.total = total;
    });
  }

  buyTickets(regie: PanierRegieData) {
    if ((regie.total || 0) <= 0) {
      this.snackbarService.error('Montant du paiement invalide');
      return;
    }

    const waitingDialog = this.dialog.open(PaymentRedirectDialogComponent, {
      disableClose: true,
      width: 'min(400px, 80%)',
      data: { typePaiement: regie.typePaiement, state: 'redirecting' }
    });

    let tabRef;

    if (regie.typePaiement === 'payfip') {
      // we must do this in main thread, due to some browser security reason ...
      tabRef = this.openPayfipTab();
      this.platformService.watchForTabClose(tabRef).subscribe(() => waitingDialog.close());
    }

    this.ticketService.sendBuyRequest(this.familyService.currentFamily.id, regie).subscribe((result: any) => {

      if (result.errors) {
        const errors = result.errors as string[];
        this.snackbarService.error('Des erreurs se sont produites : ' + errors.join(','));
      } else if (result.url) {
        if (result.params) {
          const form = this.facturationService.buildPaymentForm(result.url, result.params);

          (this.elementRef.nativeElement as HTMLElement).appendChild(form);
          form.submit();
          setTimeout(() => form.remove()); // not so useful as we directly move from current page ..
        } else if (regie.typePaiement === 'payfip') {
          tabRef.location = result.url;
        } else {
          window.location.href = result.url;
        }

        if (tabRef) {
          waitingDialog.componentInstance.setState('paying');
        }
      }

      console.log('===> redirect to payment URL', result);
    });
  }

  openPayfipTab() {
    const tab = window.open('', '_blank');
    tab.document.body.innerHTML = '<h1>' + this.translateService.instant('facture.paiement.redirect_message_tab') + '</h1>';
    tab.document.title = 'Redirection vers la plateforme de paiement';

    return tab;
  }

  ngOnDestroy() {
    this.checkPausePaiement.forEach((subscription) => subscription.unsubscribe())
  }
}
