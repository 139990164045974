import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-champ-heure',
  template: `
  <app-time-input [formControl]="formControl" [picker]="true" [label]="to.label" [required]="to.required" [min]="min" [max]="max"></app-time-input>
  <span *ngIf="(formControl.dirty || formControl.touched) && formControl.errors?.required" class="mat-error">Obligatoire</span>
   <mat-error *ngIf="error && !formControl.disabled" class="mat-error">
     {{error}}
   </mat-error>
  `,
  styles: [
    `:host {
      display: block;
      margin-right: 10px;
    }
    .mat-error {
      display: block;
      height: 0;
      margin: 0.2em 0 0.3em;
      font-size: 90%;
    }
    `
  ]
})
export class ChampHeureComponent extends FieldType implements OnInit {
  error?: string;

  constructor() {
    super();
  }

  ngOnInit(): void {


    this.formControl.valueChanges.subscribe(value => {
      if (this.validateTime(value)) {
        this.error = this.validateTime(value)
        this.formControl.setErrors({ 'horaires': true });
      } else {
        this.error = null;
      }

    })

    // this.field.validators = {
    //   horaires: {
    //     expression: (control: AbstractControl) => !control.value || (control.value < this.to.minHour || control.value > this.to.maxHour),
    //     message: (error: any, field: FormlyFieldConfig) => `"${field.formControl.value}" ne respecte pas les horaires de l'établissement.`
    //   }
    // }

    if (this.formControl.disabled) {
      this.formControl.reset();
    }
  }

  public get min() {
    return this.to.minHour ? this.to.minHour : "00:00";
  }

  public get max() {
    return this.to.maxHour ? this.to.maxHour : "23:59";
  }

  validateTime(value: string) {
    if (value < this.min) {
      return `Heure minimum ${this.min}`
    }

    if (value > this.max) {
      return `Heure maximum ${this.max}`
    }
    return null;
  }

}
