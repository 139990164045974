import { ValidationErrors } from "@angular/forms";
import { FormlyFieldConfig } from "@ngx-formly/core";

export function SuggestionValidator(field: FormlyFieldConfig): ValidationErrors {

  const isEmpty = Object.values(field['value']).every(x => (x === null));
  return !isEmpty ? null : { suggestion: 'noItem' };
}

export function SuggestionValidatorMessage(err, field: FormlyFieldConfig) {
  if (field.validation && (err + '_template' in field.validation.messages)) {
    return field.validation.messages[err + '_template'].toString();
  }
}
