import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild, Route, CanLoad, ActivatedRoute, RouterState, UrlSegment } from '@angular/router';
import { SnackbarService } from '@app/services';

import { AuthenticationService } from '@app/services/authentication.service';

@Injectable()
export class AuthenticationGuard implements CanActivate, CanActivateChild, CanLoad {

  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private snackbarServive: SnackbarService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkAccess(route, state.url);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkAccess(route, state.url);
  }

  canLoad(route: Route, segments: UrlSegment[]): boolean {
    return this.checkAccess(route, segments.join('/'));
  }

  checkAccess(route: Route | ActivatedRouteSnapshot, redirectUrl?: string): boolean {
    if (!this.checkLogin()) {

      // Not logged, redirect to login page with redirect url (referer) if given, to forward where intended after login
      this.router.navigate(['/login'], redirectUrl ? { queryParams: { redirect: redirectUrl }, replaceUrl: true } : {});
      this.snackbarServive.open({ message: 'Veuillez vous authentifier', type: 'alert', duration: 5000 })

      return false;
    }

    if (route.data && route.data.roles && !this.checkRoles(route.data.roles)) {
      // @TODO: find something to do here, 403 status or redirect to home ?
      this.router.navigate(['/']);
      this.snackbarServive.open({ message: 'Interdit', type: 'error', duration: 5000 })
      return false;
    }

    return true;
  }

  checkLogin(): boolean {
    return this.authService.isAuthenticated;
  }

  checkRoles(roles: any[]) {
    return roles.filter(role => this.authService.hasRole(role)).length;
  }
}
