import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { ExtendedFormlyTemplateOptions } from '@app/forms/config/form-model';

@Component({
  selector: 'app-formly-message',
  styles: [`
  .bloc-form-content{    padding: 8px 16px !important;}
  mat-icon{ margin-right:10px;}
  `],
  template: /*html*/`
    <div [ngClass]="{'bloc-form border': to.borderVisible==true}">
      <div class="bloc-form-title-bar" *ngIf="(to.displayTitle && to.label && to.label !== '')">

       <mat-icon>{{ (to.icon) ? to.icon : 'chevron_right'}}</mat-icon>
       <span>{{ to.label }}</span>
      </div>
      <div class="bloc-form-content" [ngClass]="{'no-padding': to.nopadding==true}">
        <div *ngIf="to.message" [innerHTML]="to.message | safe"></div>
      </div>
    </div>
  `,
})


export class FormMessageComponent extends FieldType implements OnInit {
  to: ExtendedFormlyTemplateOptions;
  ngOnInit() {}
}
