import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentType } from '@app/models/facturation';
import { FacturationService } from '@app/services/facturation.service';
import { FlashService } from '@app/services/flash.service';

@Component({
  template: `
  <app-loader class="margin">Veuillez patienter ...</app-loader>
  `
})
export class PaymentBackComponent implements OnInit {

  moduleRedirectUrls = {
    'reservation-panier': '/account/reservations/panier',
    facture: '/account/factures',
    // admin: '/admin/facturation' // mmmh actually admin opens a new tab for each "test payment", so this is probably useless
  };

  constructor(
    private router: Router,
    private currentRoute: ActivatedRoute,
    private factService: FacturationService,
    private flashService: FlashService
  ) { }

  ngOnInit() {
    const type = this.currentRoute.snapshot.paramMap.get('type') as PaymentType;

    // Module = "reservations-panier" for example, to redirect there after payment
    // We can't pass a full URL because in some case we can't give GET arg (like url?redirect=...) in backUrl (tested with Payline)
    // This is still not perfect, we can't give an exact URL so we can't redirect back user to something like "/facture/{id}" ...

    // @TODO: check if would be better to store the "current_module" as flash, and read it here when the user returns ...
    const module = this.currentRoute.snapshot.paramMap.get('module');

    // @NB: in most case we already get a status (OK / KO) from the Payment API (button user clicked), maybe do something with it ?
    // (like cancel the payment in server and get the status updated here ...)

    // we should probably know here how to get the ref / token from params, and send to server to get the real status

    // if we redirect to home (in admin case for example), we get no info (maybe set a snackbar here instead of flash ?)

    const queryParams = this.currentRoute.snapshot.queryParams;

    this.factService.sendPaymentBack(type, queryParams).subscribe(res => {
      this.flashService.addFlash('payment_return_data', res);

      const redirectUrl = this.getModuleUrl(module);
      this.router.navigate([redirectUrl]);
    });
  }

  getModuleUrl(module: string) {
    if (module in this.moduleRedirectUrls) {
      return this.moduleRedirectUrls[module];
    }

    return '/'; // go back home (may rather redirect to /account if user logged ?)
  }
}
