import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-waiting-dialog',
  styles: [/*css*/`
  .mat-dialog-content {
    min-width: min(500px, 80vw);
    display: flex;
  
  }
  .mat-dialog-content .app-loader {
    min-height: 180px;
    font-size:1.1em;
  }
  `],
  template: /*html*/`

  <mat-toolbar mat-dialog-title color="primary">
    <h1 class="title">{{title}}</h1>
  </mat-toolbar>

  <mat-dialog-content>
    <app-loader>{{ waitingMessage|trans }}</app-loader>
  </mat-dialog-content>

  `,
})

export class WaitingDialogComponent implements OnInit {
  title: string;
  waitingMessage: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data
  ) {
    this.title = this.data?.title || 'Traitement en cours';
    this.waitingMessage = this.data?.waitingMessage || 'Veuillez patienter ...';
  }

  ngOnInit() { }

}
