<mat-toolbar color="primary" class="mat-elevation-z4" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <mat-toolbar-row>
        <button class="close-dialog" mat-icon-button (click)="onCancel()">
            <mat-icon>close</mat-icon>
        </button>

        <h1 mat-dialog-title *ngIf="data.title">{{data.title}} </h1>
    </mat-toolbar-row>
</mat-toolbar>

<form [formGroup]="form" (keydown.enter)="onEnter($event)" (ngSubmit)="onSubmit()">
    <div mat-dialog-content>

        <formly-form style="width:100%;" [form]="form" [fields]="fields" [model]="model" [options]="data.options">
        </formly-form>

    </div>
    <div mat-dialog-actions class="mat-elevation-z6">
        <button type="button" class="close-dialog" mat-raised-button color="primary" (click)="onCancel()">
            <mat-icon>close</mat-icon>
            {{ data.options.formState.disabled || data.readOnly ? 'Fermer':'Annuler' }}
        </button>
        <button type="submit" mat-raised-button color="primary" *ngIf="!(data.options.formState.disabled || data.readOnly)" cdkFocusInitial
            (click)="validate()">
            <mat-icon>check</mat-icon>
            Valider
        </button>
    </div>
</form>
