import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType, HttpEvent } from '@angular/common/http';
import { GedConfig, GedPiece, GedDocument } from '@app/models/ged';
import { ApiCrudService } from './api-crud.service';
import { FamilyService } from './family.service';
import { map } from 'rxjs/operators';
import { AccountType } from '@app/models/user';
import { SnackbarService } from './snackbar.service';

const iconClasses = {
  image: ['jpg', 'jpeg', 'png'],
  pdf: ['pdf'],
  word: ['doc', 'docx']
};

@Injectable({
  providedIn: 'root'
})
export class GedService extends ApiCrudService<any> {

  configUrl = 'conf/ged';
  url = 'ged';

  constructor(
    protected http: HttpClient,
    private familyService: FamilyService,
    private snackbarService: SnackbarService
  ) {
    super();
  }

  getConfig() {
    return this.http.get<GedConfig>(this.configUrl, this.httpOptions);
  }

  updateConfig(config: GedConfig | any) {
    return this.http.put(this.configUrl, { config }, this.httpOptions);
  }

  sendDocument(file: File, piece: GedPiece, idEntite: number, typeAccount: AccountType) {

    const formData = new FormData();

    formData.set('file', file);
    formData.set('type', file.type);

    formData.set('entite', piece.obj);
    formData.set('idPieceAFournir', piece.id + '');

    formData.set('idEntite', idEntite + '');

    if (typeAccount === 'family') {
      return this.http.post(`familles/${this.familyService.currentFamily.id}/documents`, formData, {
        reportProgress: true,
        observe: 'events',
      }).pipe(map(event => this.mapProgressResponse(event)));
    }

    if (typeAccount === 'assmat') {
      return this.http.post(`assmats/${idEntite}/documents`, formData, {
        reportProgress: true,
        observe: 'events',
      }).pipe(map(event => this.mapProgressResponse(event)));
    }
  }

  getCurrentFamilyDocuments() {
    return this.http.get<GedDocument[]>(`familles/${this.familyService.currentFamily.id}/documents`);
  }

  getAssmatDocuments(idAssmat: number) {
    return this.http.get<GedDocument[]>(`assmats/${idAssmat}/documents`);
  }

  getDocument(idDocument) {
    return this.http.get(`documents/${idDocument}`, {
      responseType: 'blob',
      reportProgress: true,
      observe: 'events'
    }).pipe(map(response => this.mapProgressResponse(response)));
  }

  mapProgressResponse(event: HttpEvent<any>) {
    switch (event.type) {
      case HttpEventType.DownloadProgress:
        return { type: 'dl_progress', value: Math.round(100 * event.loaded / event.total) };
      case HttpEventType.UploadProgress:
        return { type: 'up_progress', value: Math.round(100 * event.loaded / event.total) };
      case HttpEventType.Response:
        return { type: 'response', value: event.body };
      default:
        return { type: 'otherType', value: event.type };
    }
  }

  checkDocuments(fileList: FileList, configGed: GedConfig, uploadingFile: File) {
    const uploadingFileExtension = this.getExtension(fileList[0].name);
    const fileConfigSize = +configGed.size * 1048576;
    const fileConfigExtension = configGed.extensions.toLocaleLowerCase().split(' ');
    let errorMessage: string;

    if (!fileConfigExtension.includes(uploadingFileExtension) && !configGed.extensions.includes('*')) {
      errorMessage = `Ce type de fichier n\'est pas autorisé. Fichiers autorisés : ${configGed.extensions}.`;
    }

    if (uploadingFile.size > fileConfigSize) {
      errorMessage = `Fichier trop volumineux. Le fichier ne doit pas dépasser ${configGed.size}Mo.`;
    }

    return errorMessage
  }

  getExtension(fileName: string) {
    return fileName.split('.').pop().toLowerCase();
  }

  getFileIcon(doc: GedDocument) {
    const extension = doc.nomFichier.split('.').pop().toLowerCase();
    return Object.keys(iconClasses).find(key => iconClasses[key].includes(extension));
  }

  downloadDocument(doc: GedDocument, documentDownload: any) {
    this.getDocument(doc.idDocumentElectronique).subscribe(resp => {
      if (resp.type === 'dl_progress') {
        documentDownload[doc.idDocumentElectronique] = resp.value as number;
      } else if (resp.type === 'response') {
        this.openBlob(doc, resp.value, documentDownload);
      }
    }, err => this.snackbarService.error('Erreur lors de l\'ouverture du fichier : ' + err));
  }

  // @see https://stackoverflow.com/questions/52154874/angular-6-downloading-file-from-rest-api
  // => keep this reference, if needs IE support ...
  openBlob(doc: GedDocument, blob: Blob, documentDownload: any) {
    const blobURL = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = blobURL;
    link.download = doc.nomFichier;

    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

    setTimeout(() => {
      documentDownload[doc.idDocumentElectronique] = null;
      window.URL.revokeObjectURL(blobURL);
    }, 100);
  }

  sortByOrder(array: GedPiece[]) {
    array.sort((a: GedPiece, b: GedPiece) => {
      if (a.order < b.order) return -1;
      if (a.order > b.order) return 1;
      return 0;
    });
  }
}
