<!-- Uncomment this for draggable dialog :) (could use a margin-top -24 + padding-top: 16) -->
<!-- <h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  Sélection d'un document
</h1> -->
<!-- <mat-toolbar mat-dialog-title color="primary" ></mat-toolbar> -->
<mat-toolbar mat-dialog-title color="primary" class="title-toolbar" fxLayout="row" fxLayoutAlign="space-between center">
  <button mat-icon-button [mat-dialog-close] class="close-dialog">
    <mat-icon>close</mat-icon>
  </button>
  <span>
    Sélection d'un document
  </span>
</mat-toolbar>
<div mat-dialog-content class="thin-scrollbar">
  <admin-file-manager #fileManager [documentPicker]="true" [readOnly]="'add'" [selectMode]="true" [type]="type">
  </admin-file-manager>
</div>

<div mat-dialog-actions fxLayout='row' fxLayoutAlign='end center'>
  <button mat-raised-button color="primary" [mat-dialog-close]="fileManager.selection?.selected">Valider</button>
</div>
