<ng-container [ngSwitch]="type">
  <i *ngSwitchCase="'do'" class="icon icodomino-{{ icon }}" [ngStyle]="{'font-size.px': size, 'color': color}"></i>
  <mat-icon *ngSwitchCase="'mat'" [color]="color" [ngStyle]="{'font-size.px': size, 'color': color}">{{ icon }}</mat-icon>
  <i *ngSwitchCase="'fa'" class="icon fas fa-{{ icon }}" [ngStyle]="{'font-size.px': size, 'color': color}"></i>
  <i *ngSwitchCase="'fas'" class="icon fas fa-{{ icon }}" [ngStyle]="{'font-size.px': size, 'color': color}"></i>
  <i *ngSwitchCase="'far'" class="icon far fa-{{ icon }}" [ngStyle]="{'font-size.px': size, 'color': color}"></i>
  <i *ngSwitchCase="'fab'" class="icon fab fa-{{ icon }}" [ngStyle]="{'font-size.px': size, 'color': color}"></i>
  <i *ngSwitchCase="'faIconWithType'" class="icon {{ icon }}" [ngStyle]="{'font-size.px': size, 'color': color}"></i>
</ng-container>

