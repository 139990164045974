<mat-card-header>
  <mat-card-title class="accent" style="margin-top: 12px;">
    <mat-icon color="accent" style="float:left; margin-right: 15px;">info</mat-icon>
    {{'info.confirmEmail.textTitle'|trans}}
  </mat-card-title>
  <button mat-icon-button color="warn" (click)="cancel()" matTooltip="Fermer">
    <mat-icon>close</mat-icon>
  </button>
</mat-card-header>
<mat-divider></mat-divider>
<mat-card-content>
  <div style="margin-top:20px;">
    {{'info.confirmEmail.textContent'|trans}}
  </div>
  <button mat-stroked-button color="primary" style="text-align: center; margin-top: 24px;"
    (click)="$event.preventDefault();sendMailConfirm();cancel()">
    {{'info.confirmEmail.textButton'|trans}}
  </button>
</mat-card-content>
