<mat-dialog-content>

  <app-loader>
    <span *ngIf="state === 'loading'">Récupération des informations ...</span>
    <span *ngIf="state === 'redirecting'">Redirection vers la plateforme de paiement en cours ...</span>
    <span *ngIf="state === 'paying'">Paiement en cours ...</span>
  </app-loader>

  <img *ngIf="typePaiement" src="assets/images/payment/{{ typePaiement }}.png" class="logo">
</mat-dialog-content>
