<app-form-viewer *ngIf="!isLoading; else loader" [readOnly]="readOnly" [formFields]="form" [typeForm]="typeForm"
  [model]="data" (save)="onSave($event)" [formTitle]="formTitle" (stepChange)=" onStepChange($event)">
</app-form-viewer>

<ng-template #loader>
  <div style="width: 100%; height: 50vh; display:flex;">
    <app-loader [message]="loadingMessage" isLoading="true" style="text-align: center;">
    </app-loader>
  </div>
</ng-template>
