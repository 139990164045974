import { Component, OnInit, OnDestroy, Input, SimpleChanges } from '@angular/core';
import { News } from '@app/models/news';
import { NewsService } from '@app/services/news.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import SwiperCore, { Pagination, Navigation, SwiperOptions, Autoplay } from "swiper";

// install Swiper modules
SwiperCore.use([Pagination, Navigation, Autoplay]);

@Component({
  selector: 'app-home-news',
  templateUrl: './home-news.component.html',
  styleUrls: ['./home-news.component.scss']
})
export class HomeNewsComponent implements OnInit, OnDestroy {

  news: News[];

  @Input() categorie: any[];
  @Input() nbNewsEnabled: number;
  @Input() newsStyleType: string;

  swiperConfig: SwiperOptions = {
    autoplay: { pauseOnMouseEnter: true, disableOnInteraction: true },
    pagination: true,
    navigation: true,
    loop: true
  };

  seeMoreLink: string;

  private onDestroy$ = new Subject();

  constructor(
    private newsService: NewsService
  ) { }

  ngOnInit() {
  }

  loadNews() {
    this.news = null;

    this.newsService.getNewsByCatOrAll(this.categorie).pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(news => {
      this.news = news;

      // handle max limit
      if (this.nbNewsEnabled && (this.news?.length > this.nbNewsEnabled)) {
        this.news = this.news.slice(0, this.nbNewsEnabled);
        this.seeMoreLink = this.categorie?.length === 1 ? ('/news/categories/' + this.categorie) : '/news';
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['categorie']) {
      if (changes['categorie'].currentValue === this.categorie) {
        this.loadNews();
      } else {
        this.categorie = changes['categorie'].currentValue;
        this.loadNews();
      }
    }

    if (changes.nbNewsEnabled) {
      this.loadNews();
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
