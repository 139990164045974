import { Component, OnInit, ViewChild, Input, Output, EventEmitter, IterableDiffers } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Reglement } from '@app/models/facturation';
import { PermissionService } from '@app/services';
import { trigger, state, transition, style, animate } from '@angular/animations';
import { UntypedFormBuilder } from '@angular/forms';

@Component({
  selector: 'app-reglements-list',
  templateUrl: './list-reglement.component.html',
  styleUrls: ['./list-reglement.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('void, collapsed', style({ height: '0', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('void => expanded, expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ListReglementComponent implements OnInit {

  @Input() reglements: Reglement[];

  // @TODO: improve this (test display != admin list ...)
  @Input() afficheTotaux = false;
  @Input() regieColumn = false;
  @Input() paginatorSize = 20;
  @Input() adminMode = false;
  @Input() reglementUpdated: Reglement;
  @Input() sortActive: string;

  @Output() validate = new EventEmitter();
  @Output() cancel = new EventEmitter();

  selectedReglement: Reglement;
  iterableDiffer;

  constructor(
    private permService: PermissionService,
    private iterableDiffers: IterableDiffers,
    private formBuilder: UntypedFormBuilder,
  ) {
    this.iterableDiffer = iterableDiffers.find([]).create(null);
  }

  displayedColumns: string[] = ['status', 'date', 'details', 'montant'];

  dataSource: MatTableDataSource<Reglement>;

  formFilterTable = this.formBuilder.group({
    numPiece: [''],
    debiteur: [''],
    mode: [''],
    regie: [''],
    montant: ['']
  });

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  ngOnInit() {
    this.adminMode = this.adminMode && this.permService.isManager();

    if (this.adminMode) {
      this.displayedColumns = ['status', 'date', 'numPiece', 'owner', 'montant', 'mode'];
    }

    if (this.regieColumn) {
      this.displayedColumns.push('regie');
    }

    if (!this.adminMode) {
      this.sortActive = "date";
    }

    // this.dataSource = new MatTableDataSource(this.reglements);
    // this.initTableParams();

    this.formFilterTable.valueChanges.subscribe((dataChanged) => {
      this.dataSource.filter = JSON.stringify(dataChanged);
    });
  }

  ngDoCheck() {
    let changes = this.iterableDiffer.diff(this.reglements);
    if (changes) {
      this.dataSource = new MatTableDataSource(this.reglements);
      this.dataSource.filterPredicate = this.tableFilter;
      if (this.adminMode) {
        this.dataSource.filter = JSON.stringify(this.formFilterTable.value);
      }
      this.initTableParams();
      this.reglementUpdated ? this.selectedReglement = this.reglementUpdated : this.selectedReglement = null;
    }
  }

  initTableParams() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  tableFilter(data: Reglement, filter: string): boolean {
    const searchTerms: any = JSON.parse(filter);
    return data.numPiece.trim().toLowerCase().indexOf(searchTerms.numPiece.trim().toLowerCase()) !== -1
      && data.mode.trim().toLowerCase().indexOf(searchTerms.mode.trim().toLowerCase()) !== -1
      && (data.debiteur.trim().toLowerCase().indexOf(searchTerms.debiteur.trim().toLowerCase()) !== -1 || data.login.trim().toLowerCase().indexOf(searchTerms.debiteur.trim().toLowerCase()) !== -1)
      && data.regie.trim().toLowerCase().indexOf(searchTerms.regie.trim().toLowerCase()) !== -1
      && data.montant.toString().trim().toLowerCase().indexOf(searchTerms.montant.trim().toLowerCase()) !== -1;
  }

  getTotalMontant() {
    return !this.reglements ?
      0 :
      this.reglements.filter(r => r.status !== 'EnAttente').map(t => t.montant).reduce((acc, value) => acc + value, 0);
  }

  getTotalCount() {
    return this.reglements ? this.reglements.length : 0;
  }

  toggleSelect(reglement: Reglement) {
    // Toggle system
    this.selectedReglement = this.isExpandedReglement(reglement) ? null : reglement;
  }

  isExpandedReglement(reglement: Reglement) {
    return this.selectedReglement && this.selectedReglement.id === reglement.id;
  }
}
