<mat-card *ngIf="paymentStatus" class="flash-message" [ngClass]="{'success': isOK, 'error': !isOK}">
  <mat-card-title>
    <mat-icon>{{ isOK ? 'check' : 'close' }}</mat-icon>
    <span *ngIf="isOK">Paiement effectué</span>
    <span *ngIf="paymentStatus.status === 'annule'">Paiement annulé</span>
    <span *ngIf="paymentStatus.status === 'refuse'">Paiement refusé</span>
    <span class="spacer"></span>
    <i class="close-icon" (click)="paymentStatus = null">&times;</i>
  </mat-card-title>

  <p *ngIf="isOK">Votre paiement du {{ paymentStatus.datetime|date:'dd/MM/yyyy à hh:mm' }}, portant la référence "{{ paymentStatus.ref }}",
    a bien été pris en compte.
  </p>

  <p *ngIf="paymentStatus.status === 'annule'">Le paiement a été annulé et ne sera pas pris en compte.</p>
  <p *ngIf="paymentStatus.status === 'refuse'">Le paiement a été refusé et ne sera pas pris en compte.</p>

  <p *ngIf="isTest" class="hint">
    NB : Ce paiement a été effectué en mode test. S'il a été lancé depuis une page d'administration, vous pouvez fermer
    cet onglet.
  </p>
</mat-card>
