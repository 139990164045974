<div class="conteneur" *ngIf="config && inscriptionsActive; else loader">

  <mat-card class="info-card" *ngIf="config.infoMessage" [portailCustomStyle]="config.styleCardInfo">
    <div [innerHtml]="config.infoMessage | safe"></div>
  </mat-card>

  <mat-card *ngIf="options; else loader"
    [ngClass]="{'form-container': !platformService.isMobile, 'form-container-mobile': platformService.isMobile}">
    <mat-card-header class="card-header" fxLayout="row" fxLayoutAlign="center center">
      <mat-card-title fxLayout="row" fxLayoutAlign="center center">
        <span *ngIf="!isEdit">{{ 'cogito.new_inscription'|trans }}</span>
        <span *ngIf="isEdit">{{ inscription.prenom }} {{ inscription.nom }}</span>
      </mat-card-title>
      <mat-card-subtitle *ngIf="isEdit">
        Année scolaire : {{inscription.anneeScolaire.designation}}
      </mat-card-subtitle>
    </mat-card-header>

    <mat-slide-toggle [(ngModel)]="inscription.isRedoublement" style="margin-bottom: 20px;"
      [disabled]="inscription.disabledEdit">Redoublement
    </mat-slide-toggle>

    <ng-container *ngIf="!isEdit">
      <mat-form-field>
        <mat-label>Enfant</mat-label>
        <mat-select [(ngModel)]="inscription.idEnfant" (ngModelChange)="childChanged($event)" required>
          <mat-option *ngFor="let child of options.childList" [value]="child.id">
            {{ child.prenom }} {{ child.nom }}
            <span
              *ngIf="child.age && inscription.anneeScolaire">({{ child.age.years + ' ans au ' + setDisplayDateToCalculate(dateToCalculate) }})</span>
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Année scolaire</mat-label>
        <mat-select [(ngModel)]="inscription.anneeScolaire"
          [disabled]="!config.enabledSelectAnneeScolaire || inscription.disabledEdit || !inscription.idEnfant"
          (ngModelChange)=" anneeScolaireChanged($event)" [compareWith]="compareAnneeFn" required>
          <mat-option *ngFor="let anneeScolaire of config.listAnneesScolaires" [value]="anneeScolaire">
            {{ anneeScolaire.designation }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>

    <mat-form-field>
      <mat-label>Niveau scolaire</mat-label>
      <mat-select [(ngModel)]="inscription.niveauScolaire" (ngModelChange)=" niveauScolaireChanged($event)"
        [disabled]="!config.enabledSelectNiveauScolaire || !inscription.idEnfant || !inscription.anneeScolaire || inscription.disabledEdit"
        required>
        <mat-option *ngFor="let niveauScolaire of listNiveauxScolairesAvailable"
          [value]="niveauScolaire.idNiveauScolaire">
          {{ niveauScolaire.designation }}
        </mat-option>
      </mat-select>
      <mat-hint style="margin-block: 5px;"
        *ngIf="config.enabledSelectNiveauScolaire && inscription.idEnfant && inscription.anneeScolaire && config.limitSelectNiveauScolaire !== 'NoLimit'">
        <mat-icon color="primary" style="margin-right: 5px;">info</mat-icon>
        <span>{{ 'cogito.edit.message_niveau_scolaire'|trans }}</span>
      </mat-hint>
    </mat-form-field>

    <mat-form-field *ngIf="demandeDerogation && config.enabledDerogationObligatoire && libelleSecteurScolaireOrigine">
      <mat-label>Secteur scolaire d'origine</mat-label>
      <input matInput [value]="libelleSecteurScolaireOrigine" readonly disabled>
    </mat-form-field>

    <mat-form-field *ngIf="demandeDerogation">
      <mat-label>Motif de dérogation</mat-label>
      <mat-select [(ngModel)]="inscription.idMotifDerogation" required>
        <mat-option *ngFor="let motif of config.listMotifsDerogation" [value]="motif.idMotifDerogation">
          {{ motif.motif }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{demandeDerogation ? 'Secteur scolaire de destination' : 'Secteur scolaire'}}</mat-label>
      <mat-select [(ngModel)]="inscription.secteurScolaire" (ngModelChange)="secteurScolaireChanged($event)"
        [disabled]="!inscription.idEnfant || inscription.disabledEdit || !inscription.niveauScolaire || (config.enabledDerogationObligatoire && !inscription.idMotifDerogation)"
        required>
        <mat-option *ngFor="let secteurScolaire of listSecteursScolaireAvailable"
          [value]="secteurScolaire.idSecteurScolaire">
          {{ secteurScolaire.libelle }}
        </mat-option>
      </mat-select>
      <mat-hint
        *ngIf="config.enabledDerogationObligatoire && inscription.niveauScolaire && !isEdit && !demandeDerogation"
        style="margin-block: 5px;">
        <mat-icon color="primary" style="margin-right: 5px;">info</mat-icon>
        <span>{{ 'cogito.edit.message_derogation_secteur_scolaire'|trans }}</span>
      </mat-hint>
    </mat-form-field>

    <div>
      <button *ngIf="config.enabledDerogationObligatoire && !isEdit" mat-raised-button
        [color]="demandeDerogation ? 'warn': 'accent'" [disabled]="!inscription.idEnfant || !inscription.niveauScolaire"
        (click)="onClickDemandeDerogation()" style="margin-bottom: 20px;">
        {{demandeDerogation ? 'Supprimer la demande de dérogation' : 'Demande de dérogation'}}</button>
    </div>

    <mat-form-field>
      <mat-label>École</mat-label>
      <mat-select [(ngModel)]="inscription.ecole" (ngModelChange)="ecoleChanged($event)"
        [disabled]="!inscription.idEnfant || !inscription.secteurScolaire || !inscription.niveauScolaire || inscription.disabledEdit"
        required>
        <mat-option *ngFor="let ecole of listEcolesAvailable" [value]="ecole.idEcole">
          {{ ecole.libelle }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Classe</mat-label>
      <mat-select [(ngModel)]="inscription.classe"
        [disabled]="!inscription.idEnfant || !inscription.secteurScolaire || !inscription.niveauScolaire || !inscription.ecole || inscription.disabledEdit"
        required>
        <mat-option *ngFor="let classe of listClassesAvailable" [value]="classe.idClasseScolaire">
          {{ classe.libelleClasse }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <app-params-libre *ngFor="let paramLibre of paramsLibres" class="params-libres-component" [paramLibre]="paramLibre"
      [ngStyle]="{'padding': paramLibre.nature ==='Booléen' ? '0 0 20px 0' : '0', 'width': !platformService.isMobile ? '70%' : '100%'}">
    </app-params-libre>

    <mat-card-actions align="center">
      <button mat-raised-button color="primary" (click)="onClickValidateInscription()"
        [disabled]="saving || !inscription.idEnfant || !inscription.classe || !checkParamsLibres() || inscription.disabledEdit">
        Valider
        <mat-progress-spinner *ngIf="saving" mode="indeterminate" strokeWidth="2" diameter="24" color="accent-lighter"
          class="icon-loader"></mat-progress-spinner>
      </button>
    </mat-card-actions>

  </mat-card>
</div>

<ng-template #loader>
  <app-loader class="margin"></app-loader>
</ng-template>
