<div *ngIf="showError" class="mat-error">
  <formly-validation-message [field]="field">
  </formly-validation-message>
</div>

<div cdkDropList (cdkDropListDropped)="drop($event)">
  <div *ngFor="let field of field.fieldGroup; let i = index;" cdkDrag>

    <div style="width: 100%;">
      <span>
        Choix : {{ i + 1 }}
      </span>
      <formly-group style="width:80%; display:inline-block;" [model]="model[i]" [field]="field" [options]="options"
        [form]="formControl">
      </formly-group>
      <button mat-button color="warn" (click)="remove(i)">
        <mat-icon>delete</mat-icon>
      </button>
      <mat-icon class="movable" cdkDragHandle style="vertical-align: middle;">open_with</mat-icon>
    </div>
    <hr>
  </div>
</div>

<div style="margin:10px 0;">
  <button type="button" style="display:block; margin:auto;" mat-raised-button color="primary" (click)="add()"
    [disabled]="to.max && model.length >= to.max">{{ to.addText }}</button>
</div>
