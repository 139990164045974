import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { AppRoute } from '@app/models/nav-item';
import { HeaderService } from '@app/services/header.service';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit, OnDestroy {

  @Input() standalone = false;

  items: AppRoute[];

  titleRefresher: Subscription;
  destroy$ = new Subject();

  constructor(
    private headerService: HeaderService,
    private elementRef: ElementRef
  ) { }

  ngOnInit(): void {
    this.headerService.breadcrumbs$.pipe(
      takeUntil(this.destroy$),
      tap(items => this.items = items)
    ).subscribe(_ => setTimeout(() => this.scrollToEnd()));

    this.headerService.pageTitle$.pipe(
      debounceTime(100),
      distinctUntilChanged(),
      takeUntil(this.destroy$)
    ).subscribe(title => {
      if (title && this.items?.length > 1) {
        this.items[this.items.length - 1].title = title;
      }

      setTimeout(() => this.scrollToEnd());
    });
  }

  scrollToEnd() {
    const el = this.elementRef.nativeElement as HTMLElement;

    if (el) {
      el.scrollLeft += 9999;
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
