<app-form-viewer *ngIf="!isLoading; else loader" [readOnly]="readOnly" [formFields]="form" [model]="data"
  (save)="onSave($event)" [formTitle]="formTitle" [typeForm]="typeForm">
</app-form-viewer>

<ng-template #loader>
  <div style="width: 100%; height: 50vh; display:flex;">
    <app-loader [message]="loadingMessage" isLoading="true" style="text-align: center;">
    </app-loader>
  </div>
</ng-template>

<ng-template #updateAddressBottomSheet>
  <mat-card-header class="card-header">
    <mat-card-title class="accent">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayout="row" fxLayoutAlign="start center">
          <mat-icon color="accent" style="margin-right: 12px;">info</mat-icon>
          <span>{{'update_address.title_card' | trans}}</span>
        </div>
        <button mat-icon-button color="warn" (click)="$event.preventDefault();bottomSheet.dismiss();"
          matTooltip="Fermer">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>

    <mat-divider></mat-divider>

    <div fxLayout="row" fxLayoutAlign="center center"
      style="border: 1px solid black; border-radius: 10px; margin-top: 2px; padding: 2px;">
      <span>
        <span *ngIf="data.rue2">{{ data.rue2 }}<br></span>
        <span *ngIf="data.rue3">{{ data.rue3 }}<br></span>
        <span>{{ data.numRue }} {{ data.rue }}<br></span>
        <span>{{ data.codePostal }} {{ data.ville }}</span>
      </span>
    </div>

    <p>
      <b *ngIf="manyAddressesDisplay">{{'update_address.content_card_addresses' | trans}}</b>
      <b *ngIf="!manyAddressesDisplay">{{'update_address.content_card_only_address' | trans}}</b>
    </p>

    <div fxLayout="column" style="margin-bottom: 10px;">
      <div *ngIf="dataUpdateAddress.familyAddress && !dataUpdateAddress.familyAddressIsSameConjointAddress"
        fxLayout="row wrap" fxLayoutAlign="space-between center" class="address-list">
        <div class="label-checkbox">
          <b>{{ foyerData.civilite  }}
            {{ foyerData.nomFamille | uppercase }}
          </b>
          <div>
            <span *ngIf="dataUpdateAddress.familyAddress.rue2">{{ dataUpdateAddress.familyAddress.rue2 }}<br></span>
            <span *ngIf="dataUpdateAddress.familyAddress.rue3">{{ dataUpdateAddress.familyAddress.rue3 }}<br></span>
            <span>{{ dataUpdateAddress.familyAddress.numRue }} {{ dataUpdateAddress.familyAddress.rue }}<br></span>
            {{ dataUpdateAddress.familyAddress.codePostal }} {{ dataUpdateAddress.familyAddress.ville }}
          </div>
        </div>
        <mat-radio-group class="radio-group" [(ngModel)]="updateFamilyChecked">
          <mat-radio-button value='oui'>Oui</mat-radio-button>
          <mat-radio-button value='non'>Non</mat-radio-button>
        </mat-radio-group>
      </div>

      <div *ngIf="dataUpdateAddress.userAddress && !dataUpdateAddress.userAddressIsSameConjointAddress"
        fxLayout="row wrap" fxLayoutAlign="space-between center" class="address-list">
        <div class="label-checkbox">
          <b>{{ userData.civilite }} {{ userData.nom | uppercase }} {{ userData.prenom }}</b>
          <div>
            <span *ngIf="dataUpdateAddress.userAddress.rue2">{{ dataUpdateAddress.userAddress.rue2 }}<br></span>
            <span *ngIf="dataUpdateAddress.userAddress.rue3">{{ dataUpdateAddress.userAddress.rue3 }}<br></span>
            <span>{{ dataUpdateAddress.userAddress.numRue }} {{ dataUpdateAddress.userAddress.rue }}<br></span>
            {{ dataUpdateAddress.userAddress.codePostal }} {{ dataUpdateAddress.userAddress.ville }}
          </div>
        </div>
        <mat-radio-group class="radio-group" [(ngModel)]="updateUserChecked">
          <mat-radio-button value='oui'>Oui</mat-radio-button>
          <mat-radio-button value='non'>Non</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
  </mat-card-content>
  <mat-card-actions align="center">
    <button mat-raised-button color="warn" (click)="$event.preventDefault();bottomSheet.dismiss();">Fermer</button>
    <button mat-raised-button color="accent" (click)="$event.preventDefault(); validUpdate(); bottomSheet.dismiss();"
      [disabled]="!updateFamilyChecked && !updateUserChecked">Valider</button>
  </mat-card-actions>
</ng-template>
