<div class="main-container" [ngClass]="{'with-etx-auth':externalAuthIsVisible, 'force-vertical':containerWidth<700}">

  <!-- <ng-container *ngIf="!!externalAuth && !isLoading  &&  !id && actualStep && actualStep.stepName === 'connexion'"> -->
  <ng-container *ngIf="externalAuthIsVisible">

    <div class="external-auth" [ngClass]="'stepper-is-'+stepperOrientation">
      <mat-card>
        <mat-card-content>
          <app-open-id-form (redirecting)="isLoading=$event" (redirectingMessage)="loadingMessage='login.'+$event"
            [from]="'register'">
          </app-open-id-form>
        </mat-card-content>
      </mat-card>

      <div class="choice-separator">
        Ou
      </div>
    </div>
  </ng-container>

  <app-form-viewer *ngIf="!isLoading; else loader" [readOnly]="readOnly" [formFields]="form" [model]="data"
    (save)="onSave($event)" [formTitle]="formTitle" (stepChange)="onStepChange($event)" [typeForm]="typeForm"
    [verticalStepperBeakpoint]="externalAuthIsVisible ? 1000: 800" (containerWidthChange)="containerWidth=$event"
    (stepperOrientationChange)="stepperOrientation=$event">
  </app-form-viewer>

</div>


<ng-template #loader>
  <div style="width: 100%; height: 50vh; display:flex;">
    <app-loader isLoading="true" style="text-align: center;">
      <h3>{{ loadingMessage|trans }}</h3>
    </app-loader>
  </div>
</ng-template>

<div #loginCheckindicator id="loginCheckindicator">
  <app-loader size="1" class="" *ngIf="loginCheckState==='loading'"></app-loader>
  <mat-icon color="primary" *ngIf="loginCheckState==='ok'">check_circle</mat-icon>
  <mat-icon color="warn" *ngIf="loginCheckState==='ko'">report_problem</mat-icon>
</div>

<ng-template #loginAlreadyExistQuestion>
  <mat-card-header>
    <mat-card-title class="accent">
      <mat-icon color="accent" style="float:left;">warning</mat-icon>
      Cette adresse e-mail est déjà utilisée
    </mat-card-title>
  </mat-card-header>
  <mat-divider></mat-divider>
  <mat-card-content>
    <div class="mat-body-2 text-center" style="padding:0.5em;">
      Vous avez déjà un compte sur notre site?
    </div>
    <mat-divider></mat-divider>
    <mat-nav-list>
      <mat-list-item (click)="$event.preventDefault();goLogin();bottomSheet.dismiss();" color="primary">
        <span mat-line>Connexion</span>
      </mat-list-item>

      <mat-list-item (click)="$event.preventDefault();goForgotPassword();bottomSheet.dismiss();">
        <span mat-line>Vous avez oublié votre mot de passe ?</span>
      </mat-list-item>

      <mat-list-item (click)="$event.preventDefault();bottomSheet.dismiss();">
        <span mat-line>Continuer avec une autre adresse e-mail </span>
      </mat-list-item>
    </mat-nav-list>
  </mat-card-content>
</ng-template>

<ng-template #updateAddressBottomSheet>
  <mat-card-header class="card-header">
    <mat-card-title class="accent">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayout="row" fxLayoutAlign="start center">
          <mat-icon color="accent" style="margin-right: 12px;">info</mat-icon>
          <span>{{'update_address.title_card' | trans}}</span>
        </div>
        <button mat-icon-button color="warn" (click)="$event.preventDefault();bottomSheet.dismiss();"
          matTooltip="Fermer">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>

    <mat-divider></mat-divider>

    <div fxLayout="row" fxLayoutAlign="center center"
      style="border: 1px solid black; border-radius: 10px; margin-top: 2px; padding: 2px;">
      <span>
        <span *ngIf="data.rue2">{{ data.rue2 }}<br></span>
        <span *ngIf="data.rue3">{{ data.rue3 }}<br></span>
        <span>{{ data.numRue }} {{ data.rue }}<br></span>
        <span>{{ data.codePostal }} {{ data.ville }}</span>
      </span>
    </div>

    <p>
      <b *ngIf="manyAddressesDisplay">{{'update_address.content_card_addresses' | trans}}</b>
      <b *ngIf="!manyAddressesDisplay">{{'update_address.content_card_only_address' | trans}}</b>
    </p>

    <div fxLayout="column" style="margin-bottom: 10px;">
      <div *ngIf="dataUpdateAddress.familyAddress && !dataUpdateAddress.familyAddressIsSameUserAddress"
        fxLayout="row wrap" fxLayoutAlign="space-between center" class="address-list">
        <div class="label-checkbox">
          <b>{{ foyerData.civilite  }}
            {{ foyerData.nomFamille | uppercase }}
          </b>
          <div>
            <span *ngIf="dataUpdateAddress.familyAddress.rue2">{{ dataUpdateAddress.familyAddress.rue2 }}<br></span>
            <span *ngIf="dataUpdateAddress.familyAddress.rue3">{{ dataUpdateAddress.familyAddress.rue3 }}<br></span>
            <span>{{ dataUpdateAddress.familyAddress.numRue }} {{ dataUpdateAddress.familyAddress.rue }}<br></span>
            {{ dataUpdateAddress.familyAddress.codePostal }} {{ dataUpdateAddress.familyAddress.ville }}
          </div>
        </div>
        <mat-radio-group class="radio-group" [(ngModel)]="updateFamilyChecked">
          <mat-radio-button value='oui'>Oui</mat-radio-button>
          <mat-radio-button value='non'>Non</mat-radio-button>
        </mat-radio-group>
      </div>

      <div *ngIf="dataUpdateAddress.conjointAddress && !dataUpdateAddress.conjointAddressIsSameUserAddress"
        fxLayout="row wrap" fxLayoutAlign="space-between center" class="address-list">
        <div class="label-checkbox">
          <b>
            {{ conjointData.civilite }}
            {{ conjointData.nom | uppercase }}
            {{ conjointData.prenom }}
          </b>
          <div>
            <span *ngIf="dataUpdateAddress.conjointAddress.rue2">{{ dataUpdateAddress.conjointAddress.rue2 }}<br></span>
            <span *ngIf="dataUpdateAddress.conjointAddress.rue3">{{ dataUpdateAddress.conjointAddress.rue3 }}<br></span>
            <span>{{ dataUpdateAddress.conjointAddress.numRue }} {{ dataUpdateAddress.conjointAddress.rue }}<br></span>
            {{ dataUpdateAddress.conjointAddress.codePostal }} {{ dataUpdateAddress.conjointAddress.ville }}
          </div>
        </div>
        <mat-radio-group class="radio-group" [(ngModel)]="updateConjointChecked">
          <mat-radio-button value='oui'>Oui</mat-radio-button>
          <mat-radio-button value='non'>Non</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
  </mat-card-content>
  <mat-card-actions align="center">
    <button mat-raised-button color="warn" (click)="$event.preventDefault();bottomSheet.dismiss();">Fermer</button>
    <button mat-raised-button color="accent" (click)="$event.preventDefault(); validUpdate(); bottomSheet.dismiss();"
      [disabled]="!updateFamilyChecked && !updateConjointChecked">Valider</button>
  </mat-card-actions>
</ng-template>
