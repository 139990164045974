<mat-sidenav-container>
  <mat-sidenav class="thin-scrollbar" [mode]="platformService.isMobile ? 'over' : 'side'"
    [opened]="!platformService.isMobile" ngClass.gt-sm="has-border">

    <!-- Don't load the menu content if we're redirecting user ... -->
    <app-menu *ngIf="menuService.menuVisible$ | async"></app-menu>

  </mat-sidenav>

  <mat-sidenav-content cdkScrollable class="main-container thin-scrollbar">

    <!-- Maybe move to subheader ? -->
    <app-banner *ngIf="banner" [config]="banner"></app-banner>

    <app-subheader *ngIf="!inAdmin"></app-subheader>

    <div [class]="menuService.sidenav.opened ? 'withMenu' : 'withoutMenu'"
      [@routeAnimations]="routerOutlet?.activatedRouteData" class="router-wrapper">
      <router-outlet #routerOutlet="outlet"></router-outlet>
    </div>

    <!-- <app-footer *ngIf="!inAdmin"></app-footer> -->
  </mat-sidenav-content>

</mat-sidenav-container>
