import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export class Session {
  id: string;
}

const storageKey = 'currentSession';

@Injectable()
export class SessionService {

  private currentSessionSubject: BehaviorSubject<Session>;
  private myStorage: Storage;
  private _dominoConnect: boolean;
  private set dominoConnect(value) { this._dominoConnect = value; }
  public get dominoConnect() { return this._dominoConnect; }

  currentSession$: Observable<Session>;

  constructor() {
    this.currentSessionSubject = new BehaviorSubject<Session>(null);
    this.currentSession$ = this.currentSessionSubject.asObservable();
  }

  public get currentSessionValue(): Session {
    return this.currentSessionSubject.value;
  }

  public init(dominoConnect: boolean) {
    this.dominoConnect = dominoConnect
    if (this.dominoConnect) {
      sessionStorage.setItem('sessionFromDomino', 'true')
    } else { // for reload purpose
      this.dominoConnect = JSON.parse(sessionStorage.getItem('sessionFromDomino'))
    }

    this.myStorage = this.dominoConnect ? sessionStorage : localStorage;
    this.updateSession(this.getSessionFromStorage());
  }

  private getSessionFromStorage(): Session {
    const sessionJSON = this.myStorage.getItem(storageKey);
    const session: Session = (JSON.parse(sessionJSON) as Session);
    return session;
  }

  private storeSession(session?: Session) {
    this.clearStoredSession();

    if (session) {
      this.myStorage.setItem(storageKey, JSON.stringify(session));
    }
  }

  private clearStoredSession() {
    sessionStorage.removeItem(storageKey);
    localStorage.removeItem(storageKey);
  }

  updateSession(session: Session, storeSession = false) {

    this.currentSessionSubject.next(session);

    if (storeSession) {
      this.storeSession(session);
    }

  }

}
