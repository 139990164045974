import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { WaitingRoomConfig } from '@app/models/reservation';
import { AuthenticationService, SnackbarService, WaitingRoomData, WaitingRoomService } from '@app/services';

@Component({
  selector: 'app-waiting-room',
  templateUrl: './waiting-room.component.html',
  styleUrls: ['./waiting-room.component.scss']
})
export class WaitingRoomComponent implements OnInit {

  conf: WaitingRoomConfig;
  data: WaitingRoomData;

  waitingTime: string;


  @ViewChild('cancelDialogTpl') cancelDialogTpl: TemplateRef<any>;
  cancelDialogRef: MatDialogRef<any>;
  canceling = false;
  // canceling: boolean = false;

  constructor(
    private waitingRoomService: WaitingRoomService,
  ) { }

  ngOnInit(): void {

    this.waitingRoomService.config$.subscribe(conf => this.conf = conf)

    this.waitingRoomService.data$.subscribe(data => {
      this.data = data;

      const wtMin = Math.round(this.data.waitingTimeMin / (1000 * 60))
      const wtMax = Math.round(this.data.waitingTimeMax / (1000 * 60))

      this.waitingTime = null

      if (this.data.waitingTimeMin) {
        this.waitingTime = `entre ${wtMin} et ${wtMax} minutes`
      } else {
        this.waitingTime = `à moins de ${wtMax} minutes`
      }
    })

  }

  onCancel() {
    this.waitingRoomService.confirm('quitWaitingRoom')
      .subscribe(confirm => {
        if (confirm) {
          this.waitingRoomService.quitWaitingRoom('Abandon de l\'utilisateur depuis la salle d\'attente', true, true)
        }
      })
  }

}
