import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Footer } from '@app/models/footer';
import { first, map, catchError, tap } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({ providedIn: 'root' })
export class FooterService {

  private footersUrl = 'http://portail.local/api/?p=footers';
  private currentFooterSubject = new BehaviorSubject<Footer>(null);

  footers: Footer[];

  constructor(private http: HttpClient) { }

  findAll(): Observable<Footer[]> {
    return this.http.get<Footer[]>(this.footersUrl);
  }

  find(id: number): Observable<Footer> {
    return this.http.get<Footer>(`${this.footersUrl}/${id}`);
  }

  get currentFooter$(): Observable<Footer> {
    if (!this.currentFooterValue) {
      this.initCurrentFooter();
    }

    return this.currentFooterSubject.asObservable();
  }

  set currentFooter(footer: Footer) {
    this.currentFooterSubject.next(footer);
  }

  get currentFooterValue() {
    return this.currentFooterSubject.value;
  }

  initCurrentFooter() {
    this.findAll().pipe(
      first(),
      // Currently displayed footer is still partly random picked
      map((footers: Footer[]) => {
        const enabledFooters = footers.filter(f => f.enabled);
        return enabledFooters[Math.floor(Math.random() * enabledFooters.length)];
      }),
      tap((f: Footer) => this.currentFooter = f),
      catchError(this.handleError<Footer>('init footer'))
    ).subscribe();
  }

  postUpdateFooter(refresh: boolean, footer: Footer) {
    if (refresh) {
      if (footer && footer.enabled) {
        this.currentFooter = footer;
      } else {
        this.initCurrentFooter();
      }
    }
  }

  addFooter(footer: Footer, refreshActive = true): Observable<any> {
    return this.http.post(this.footersUrl, footer, httpOptions).pipe(
      tap(_ => this.postUpdateFooter(refreshActive, footer))
    );
  }

  updateFooter(footer: Footer, refreshActive = true): Observable<any> {
    return this.http.put(this.footersUrl, footer, httpOptions).pipe(
      tap(_ => this.postUpdateFooter(refreshActive, footer)),
      catchError(this.handleError<Footer>('update footer'))
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
