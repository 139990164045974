import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PointageInscription, PointagePresence, PointageRubrique } from '@app/models/pointage';

@Injectable({
  providedIn: 'root'
})
export class AssmatPointageService {

  constructor(
    protected http: HttpClient,
  ) { }

  getRubriques(){
    return this.http.get<PointageRubrique[]>(`pointage/rubriquesMikado`);
  }

  getListInscriptionsByAssmat(id: number){
    return this.http.get(`pointage/listInscriptions/${id}`);
  }

  getPresAbsByIdAssmat(id:number){
    return this.http.get(`pointage/presAbs/${id}`);
  }

  onSave(presencesDeleted: PointagePresence[], presencesUpdatedOrCreated: PointagePresence[]){
    const data = {presencesDeleted, presencesUpdatedOrCreated}
    return this.http.post(`pointage/save`, data);
  }

  findInscriptionById(id:number, listChildInscription: PointageInscription[]){
    return listChildInscription.find(insc => insc.idInscription === id);
  }
}
