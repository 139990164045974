<div *ngIf="loaded" #themeWrapper
  class="theme-wrapper {{themeService.customOrDefaultClass$|async}}  {{themeService.themeBrightnessClass$|async}}">

  <app-header #header></app-header>

  <app-main #main></app-main>

  <!-- <app-footer></app-footer> -->

</div>
