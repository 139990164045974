import { Component, OnInit, OnDestroy, ViewChild, ViewContainerRef, TemplateRef } from '@angular/core';
import { PermissionService, FamilyService, PlatformService, SnackbarService } from '@app/services';
import { Subject, Subscription } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { PlanningData } from './planning/planning-data';
import { ReservationService } from '@app/services/reservation.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-reservations',
  templateUrl: './reservations.component.html',
  styleUrls: ['./reservations.component.scss']
})
export class ReservationsComponent implements OnInit, OnDestroy {

  data: PlanningData;

  @ViewChild('optionsPanelContainer', { static: true, read: ViewContainerRef }) optionsPanel: ViewContainerRef;
  @ViewChild('history') historyDialog: TemplateRef<MatDialog>;

  drawerOpened = false;
  lastPanelToggle: number;
  planningDataSub: Subscription;

  onDestroy$ = new Subject();

  constructor(
    private familyService: FamilyService,
    private permService: PermissionService,
    private reservationService: ReservationService,
    private dialog: MatDialog,
    private snackbar: SnackbarService,
    private router: Router,

    public platformService: PlatformService
  ) {
    this.data = new PlanningData();
  }

  ngOnInit() {
    if (!this.permService.hasPermission('reservation_diabolo') && !this.permService.hasPermission('reservation_mikado')) {
      this.snackbar.error('Les réservations ne sont pas activées');
      this.router.navigate(['/']);
      return;
    }

    this.planningDataSub = this.familyService.currentFamilyReadyOnce$.pipe(
      takeUntil(this.onDestroy$),
      switchMap(f => {
        this.familyService.getFamilyConsumers(f).subscribe(data => {
          this.data.consumers = data || [];
          this.data.onChangeUsager.next();
        });
        return this.reservationService.loadPlanningDataProgressive(f, this.data);
      })
    ).subscribe();
  }

  togglePlanningOptions() {
    if (!this.lastPanelToggle || (Date.now() - this.lastPanelToggle > 1000)) {
      this.drawerOpened = !this.drawerOpened;
      this.lastPanelToggle = Date.now();
    }
  }

  openHistory() {
    const ref = this.dialog.open(this.historyDialog, { width: '90vw' });
    this.platformService.adaptDialogToScreen(ref);
  }

  ngOnDestroy(): void {
    this.planningDataSub.unsubscribe();
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
