import { Component, OnInit } from '@angular/core';
import { AuthenticationService, PlatformService } from '@app/services';
import { BoardCard, BoardConfig } from '@app/models/board-card';
import { BaseConfigService } from '@app/services/config/base.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  boardConfig: BoardConfig;
  boardCards: BoardCard[];
  loading = true;
  derniereModif: any;
  localBoardHomeConf: any;

  constructor(
    private authService: AuthenticationService,
    public platformService: PlatformService,
    private baseConfigService: BaseConfigService,
  ) {
  }

  ngOnInit() {
    this.boardConfig = this.baseConfigService.getFirstConf("board-home").content;

    if (this.boardConfig) {
      this.filterCards();
      this.loading = false;
    }

  }

  filterCards() {
    const isAuth = this.authService.isAuthenticated;

    this.boardCards = this.boardConfig.cards.filter(bc => {
      return bc.enabled && (!bc.limited || (bc.limited === 'login' && isAuth) || (bc.limited === 'logout' && !isAuth));
    });
  }
}
