import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

export interface SmtpAccount {
  id: number;
  label: string;
}

@Injectable({
  providedIn: 'root'
})
export class MailService {

  constructor(
    private http: HttpClient
  ) { }

  getSmtpList() {
    return this.http.get<SmtpAccount[]>('conf/smtp-accounts');
  }

  sendForgottenPasswordEmail(to: string) {
    return this.http.post<any>('users/forgot-password', { email: to });
  }

  getEmail(fileName:string, typeConfig: string, typeEmail: string){
    return this.http.get(`mail-perso/${fileName}/${typeConfig}/${typeEmail}`);
  }

  updateEmailPerso(fileName:string, param: object){
    return this.http.post(`mail-perso/${fileName}`, param)
  }

  deleteEmailPerso(fileName:string){
    return this.http.delete(`mail-perso/${fileName}`);
  }

  getListMailPerso(){
    return this.http.get('mail-perso');
  }
}
