import { AfterViewInit, Component, ElementRef, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { radioYesNoOptions } from '@app/services/adapter/form-config-adapter.service';

@Component({
  selector: 'app-form-oui-non-type',
  templateUrl: './form-oui-non-type.component.html',
  styleUrls: ['./form-oui-non-type.component.scss']
})
export class FormOuiNonTypeComponent extends FieldType implements OnInit, AfterViewInit {

  constructor(
    private elementRef: ElementRef
  ) {
    super();
  }

  ngOnInit() {
    this.to.options = radioYesNoOptions;

    // Restore initial method, seems to work again ...
    this.field.type = 'radio';
    this.field.wrappers = ['form-field'];
    this.to.hideFieldUnderline = true;

    this.to.appearance = 'standard';
    this.to.floatLabel = 'always';
  }

  ngAfterViewInit() {
    this.moveLabelAboveInput(this.elementRef.nativeElement as HTMLElement);
  }

  moveLabelAboveInput(rootElement: Element) {
    // Manually move the "mat-label" before the "input" element, only way to get rid of "position: absolute", and respect the element height
    // Needed to display label on multiple lines (while MatFormField forces the label to be one-line only), couldn't find a better way ...
    // @NB: create a new "wrapper" based on "MatFormField" would probably be cleaner, but would require update every time Material updates
    if (rootElement) {
      const formFieldInfix = rootElement.querySelector('.mat-form-field-infix');
      const labelElement = formFieldInfix?.querySelector('.mat-form-field-label-wrapper');

      if (formFieldInfix && labelElement) {
        formFieldInfix.prepend(labelElement);
        rootElement.classList.add('label-above-input');
      }
    }
  }
}
