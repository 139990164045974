<form (ngSubmit)="openIdConnect()">

  <div *ngIf="externalAuth?.msgHeader != ''" class="msg-header" [innerHTML]="externalAuth?.msgHeader | safe">
  </div>

  <ng-container [ngSwitch]="externalAuth?.providerButtonType">

    <input *ngSwitchCase="'image-only'" type="image" (mouseover)="mouseover = true" (mouseout)="mouseover = false"
      [src]="(mouseover && externalAuth?.providerButtonHoverImage && externalAuth?.providerButtonHoverImage != '') ? externalAuth?.providerButtonHoverImage : externalAuth?.providerButtonImage"
      class="open-id-button" [value]="'S\'identifier avec '+externalAuth?.providerName">

    <button *ngSwitchCase="'logo+label'" id="btnOidcLogin" mat-raised-button color="primary" type="submit"
      class="open-id-button with-logo">
      <img class="open-id-logo" [src]="externalAuth?.providerLogo">
      <span *ngIf="externalAuth?.providerButtonLabel && externalAuth?.providerButtonLabel != ''  else defaultLabel"
        [innerHTML]="externalAuth?.providerButtonLabel |safe">
      </span>
    </button>

    <button *ngSwitchCase="'label-only'" id="btnOidcLogin" mat-raised-button color="primary" type="submit"
      class="open-id-button">
      <span *ngIf="externalAuth?.providerButtonLabel && externalAuth?.providerButtonLabel != ''  else defaultLabel"
        [innerHTML]="externalAuth?.providerButtonLabel |safe">
      </span>
    </button>

  </ng-container>

  <ng-template #defaultLabel>
    <span>S'identifier avec {{externalAuth?.providerName}}</span>
  </ng-template>

  <div *ngIf="externalAuth?.linkInfoLabel !== '' && externalAuth?.linkInfoUrl !== ''" class="">
    <a [href]="externalAuth?.linkInfoUrl" target="_blank">
      {{externalAuth?.linkInfoLabel}}
      <i class="fas fa-external-link-alt"></i>
    </a>
  </div>
</form>
