import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NewsService } from '@app/services';
import { NewsCategorie, News } from '@app/models/news';
import { Subject } from 'rxjs';
import { takeUntil, distinctUntilChanged , tap} from 'rxjs/operators';

@Component({
  selector: 'app-filter-news',
  templateUrl: './filter-news.component.html',
  styleUrls: ['./filter-news.component.scss']
})
export class FilterNewsComponent implements OnInit {

  @Input() news: News[];
  @Output() newsFilter = new EventEmitter<News[]>();
  categories: NewsCategorie[] = [];
  filterNews$ = new Subject<string[]>();
  filterNews: string[];
  newsFiltered: News[];
  destroy$ = new Subject();

  constructor(
    private newsService: NewsService,
  ) { }

  ngOnInit(): void {
    this.newsService.getVisibleCategories().pipe(tap(cat =>this.newsService.sortByCategoryOrder(cat))).subscribe(cat => {
      this.categories = cat    
})

    this.filterNews$.pipe(
      takeUntil(this.destroy$),
      distinctUntilChanged()
    ).subscribe(val => {
      if (val.length) {
        this.newsFiltered = this.getFilteredNews(this.news, val);
        this.newsFilter.emit(this.newsFiltered);
      } else {
        this.newsFiltered = this.news;
        this.newsFilter.emit(this.newsFiltered);
      }
    })
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getFilteredNews(news, catSelect) {
    let newsFiltered: News[] = [];
    news.filter(
      (aNews: News) => {
        let findCategorie = false;
        aNews.categories.forEach((cat: NewsCategorie) => {
          if (catSelect.includes(cat.idCategorie)) {
            findCategorie = true;
          }
        })
        if (findCategorie) {
          newsFiltered.push(aNews)
        }
      }
    )
    return newsFiltered;
  }

}
