<ng-container *ngIf="inDialog; else content">

  <mat-toolbar mat-dialog-title color="primary">
    <h1 class="title">Changement d'adresse email</h1>
    <button mat-icon-button class="close-dialog" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>

  <mat-dialog-content class="thin-scrollbar">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-raised-button color="primary" [disabled]="saving || newEmail.invalid" (click)="onConfirm()">
      <app-loader *ngIf="saving" size=".75" class="icon-loader"></app-loader>
      <mat-icon *ngIf="!saving">check</mat-icon>
      Valider
    </button>

    <button mat-raised-button color="warn" [disabled]="saving" (click)="onCancel()">
      <mat-icon>close</mat-icon>
      Annuler
    </button>
  </mat-dialog-actions>

</ng-container>

<ng-template #content>

  <div class="fields">
    <mat-form-field>
      <mat-label>Nouvelle adresse email</mat-label>
      <input matInput [formControl]="newEmail" type="email" required name="new-email" (keyup.enter)="onConfirm()">
      <mat-error>Vous devez saisir une adresse email valide.</mat-error>
    </mat-form-field>
  </div>

</ng-template>
