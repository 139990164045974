import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LasidoChoiceGroup, LasidoPreInscriptionConfig, LasidoPreinscription } from '@app/models/lasido';
import { FamilyService, PermissionService } from '@app/services';
import { LasidoService } from '@app/services/lasido.service';
import { Subject, forkJoin } from 'rxjs';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-lasido-preinscription',
  templateUrl: './lasido-preinscription.component.html',
  styleUrls: ['./lasido-preinscription.component.scss']
})
export class LasidoPreinscriptionComponent implements OnInit, OnDestroy {

  preinscriptions: LasidoPreinscription[];
  config: LasidoPreInscriptionConfig;
  permToCreate: boolean;
  permToEdit: boolean;

  onDestroy$ = new Subject();

  constructor(
    private lasidoPreinscriptionService: LasidoService,
    private familyService: FamilyService,
    private router: Router,
    public permService: PermissionService
  ) { }

  ngOnInit(): void {
    this.permToEdit = this.permService.hasPermission('lasido_edit') && this.familyService.currentFamily.active;
    this.permToCreate = this.permService.hasPermission('lasido_create') && this.familyService.currentFamily.active;

    this.loadData().subscribe();
  }

  loadData() {
    return this.familyService.currentFamily$.pipe(
      filter(f => !!f),
      takeUntil(this.onDestroy$),
      tap(_ => { this.preinscriptions = null; }),
      switchMap(f => {
        const loaders = [];

        loaders.push(this.lasidoPreinscriptionService.getFamilyPreinscriptions(f).pipe(tap((data) => {
          this.preinscriptions = data;
          this.preinscriptions.forEach(preinsc => {
            this.addIsCoursRetenuProperty(preinsc.choiceGroups);
          })
        })));
        loaders.push(this.lasidoPreinscriptionService.getConfigPreinscriptionForUser().pipe(tap(config => this.config = config)));

        return forkJoin(loaders);
      })
    );
  }

  addIsCoursRetenuProperty(choiceGroups: LasidoChoiceGroup[]): void {
    choiceGroups.forEach((group) => {
      group.cours.forEach((course) => {
        course.isCoursRetenu = group._TabCoursRetenu.some((coursRetenu) => coursRetenu.id === course.id);
      });
    });
  }

  onClickEdit(item: LasidoPreinscription) {
    this.router.navigate([`account/lasido-preinscriptions/${item.id}`]);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
