import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormViewerComponent, SubmitFormEvent } from '@app/components/_public/form-viewer/form-viewer.component';
import { StepType } from '@app/forms/config/form-model';
import { ContratAccueilMentaloFormData, EnfantMentalo, FamilleMentalo } from '@app/models/contrat-accueil';
import { FormType } from '@app/models/global-config';
import { FormConfigService, FormHelperService, PermissionService, SnackbarService, UserService } from '@app/services';
import { AssmatService } from '@app/services/assmat.service';
import { forkJoin, Observable } from 'rxjs';

@Component({
  selector: 'app-contrat-accueil-mentalo-edit',
  templateUrl: './contrat-accueil-mentalo-edit.component.html',
  styleUrls: ['./contrat-accueil-mentalo-edit.component.scss']
})
export class ContratAccueilMentaloEditComponent implements OnInit {

  id: number;
  idAssmat: number;
  idContratAccueil: number;
  readOnly: boolean = false;
  permToCreate: boolean;
  step: string;
  form: StepType[];
  data: any;
  // data$: Observable<any>;
  form$: Observable<any>;
  isLoading = true;
  loadingMessage = 'Chargement';
  typeForm: FormType = 'form-contrat-accueil-mentalo';
  isNewContrat: boolean;
  listEnfantsMentalo: EnfantMentalo[];
  listFamillesMentalo: FamilleMentalo[];

  formTitle: string;

  @ViewChild(FormViewerComponent) formViewer: FormViewerComponent;

  constructor(
    private formConfigService: FormConfigService,
    private helperService: FormHelperService,
    private route: ActivatedRoute,
    private assmatService: AssmatService,
    private userService: UserService,
    private location: Location,
    private permService: PermissionService,
    private snackbarService: SnackbarService
  ) { }

  ngOnInit(): void {
    this.idAssmat = this.userService.currentUser.idAssmat || null;
    this.id = +this.route.snapshot.paramMap.get('id') !== 0 ? +this.route.snapshot.paramMap.get('id') : null;
    this.step = this.route.snapshot.paramMap.get('step');
    this.isNewContrat = this.route.snapshot.routeConfig.path === "add";

    this.form$ = this.assmatService.getFormDataContratAccueilMentalo(this.idAssmat, this.id, this.step);

    this.readOnly = !this.permService.hasPermission('contrat_accueil_edit')
    this.permToCreate = this.permService.hasPermission('contrat_accueil_create');

    if (this.permToCreate && !this.step) {
      this.readOnly = false;
    }

    this.formTitle = this.readOnly ? 'Aperçu contrat d\'accueil' : 'Modification contrat d\'accueil';

    if (this.isNewContrat) {
      this.formTitle = this.route.snapshot.data.title;
    }

    forkJoin([
      this.form$,
      this.assmatService.getEnfantsMentaloByIdAssMat(this.idAssmat),
      this.assmatService.getFamillesMentaloByIdAssMat(this.idAssmat),
    ])
      .subscribe(([result, listEnfantsMentalo, listFamillesMentalo]: [any, EnfantMentalo[], FamilleMentalo[]]) => {

        if (result.config) {
          this.form = this.formConfigService.getFormView(result.config).filter(f => f.enabled);
        }

        setTimeout(() => { // doit être encadré dans un setTimeout car sinon le 'formViewer' n'est pas accessible (car il était masqué par 'isLoading')...
          this.formViewer.handleReturnProgramOnDisplay(result)
        })

        this.listEnfantsMentalo = listEnfantsMentalo;
        this.listFamillesMentalo = listFamillesMentalo;

        this.data = !result.data ? { modeCreation: true } : result.data;

        if (this.step === 'infoContrat' || this.step === null) {
          let fieldsBlocEnfant = this.formConfigService.findFieldByName(this.form, "Bloc_enfant");
          let fieldIdEnfantMentalo = this.formConfigService.findFieldByName(fieldsBlocEnfant.fieldGroup, "id");
          let fieldsBlocFamille = this.formConfigService.findFieldByName(this.form, "Bloc_famille");

          setTimeout(() => {

            if (!this.data && !fieldIdEnfantMentalo.formControl.value) {
              fieldsBlocFamille.templateOptions.disabledSelectFamily = true;
            }

            fieldIdEnfantMentalo.formControl.valueChanges.subscribe(val => {

              fieldsBlocFamille.templateOptions.disabledSelectFamily = false;
              fieldsBlocFamille.templateOptions.hideReplaceFamily = val > 0 ? true : false;

              const selectedChild = this.listEnfantsMentalo.find(child => child.id === val);
              if (selectedChild) {
                const family = this.listFamillesMentalo.find(famille => famille.id === selectedChild.idFamilleMentalo);
                fieldsBlocFamille.formControl.patchValue(family);
              }
            })
          });
        }
        this.isLoading = false;
      });
  }

  onSave(event: SubmitFormEvent) {
    const data: ContratAccueilMentaloFormData = event.model;

    data.idAssistantMaternel = this.idAssmat;
    this.formViewer.setErrorMessage('');
    this.loadingMessage = 'Enregistrement';

    if (this.readOnly) {
      return;
    }

    this.isLoading = true;
    const saveMethod = (this.id) ? this.assmatService.updateContratAccueil(data, this.idAssmat, this.id, this.step) : this.assmatService.createContratAccueil(data, this.idAssmat);

    saveMethod.subscribe((result: any) => {
      this.helperService.displayDebugTraces(result.traces);
      this.helperService.notifySuccess('Enregistrement réussi', result.messages);
      this.location.back();
    }, err => {
      this.isLoading = false;

      setTimeout(() => { // doit être encadré dans un setTimeout car sinon le 'formViewer' n'est pas accessible (car il était masqué par 'isLoading')...
        this.helperService.manageServerError(err, this.formViewer);
      })
    });
  }


}
