<div class="title">
  <mat-icon color="primary">arrow_right</mat-icon>
  <h3>{{ 'reservation.planning.options.legend_icons_title'|trans }}</h3>
</div>

<div class="legends-container">
  <div *ngFor="let status of statuses">
    <mat-icon>{{ ('reservation.state_icon.' + status)|trans }}</mat-icon>
    <span>{{ ('reservation.state_legend.' + status)|trans }}</span>
  </div>
</div>
