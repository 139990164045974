import { ColorPalette, ThemeDefinition } from '@app/services/theme.service';

export class SassTemplate {
  constructor() { }

  static ThemeVariablesCustomizerTemplate(themeDefinition: ThemeDefinition): string {
    return `
    @use 'material' as mat;
    @use 'sass:map';
    @use 'funcs';

    ` +
      SassTemplate.colorPaletteTemplate('primary', themeDefinition.primaryPalette) + '\n' +
      SassTemplate.colorPaletteTemplate('accent', themeDefinition.accentPalette) + '\n' +
      SassTemplate.colorPaletteTemplate('warn', themeDefinition.warnPalette) + '\n' +
      SassTemplate.typographyConfig(themeDefinition) + '\n' +
      SassTemplate.declareThemes(themeDefinition);
  }

  private static colorPaletteTemplate(name: string, palette: ColorPalette): string {
    return `
    $${name}-colors: (
      default: #${palette.hexNormal},
      lighter: #${palette.hexLight},
      darker: #${palette.hexDark},
      50: #${palette.shades['50']},
      100: #${palette.shades['100']},
      200: #${palette.shades['200']},
      300: #${palette.shades['300']},
      400: #${palette.shades['400']},
      500: #${palette.shades['500']},
      600: #${palette.shades['600']},
      700: #${palette.shades['700']},
      800: #${palette.shades['800']},
      900: #${palette.shades['900']},
      A100: saturate( #${palette.shades['100']} , 15% ),
      A200: saturate( #${palette.shades['200']} , 15% ),
      A400: saturate( #${palette.shades['400']} , 15% ),
      A700: saturate( #${palette.shades['700']} , 15% ),
      contrast: (
        default: #${palette.contrastNormal},
        lighter: #${palette.contrastLight},
        darker: #${palette.contrastDark},
        50: #${palette.shades.contrast['50']} ,
        100: #${palette.shades.contrast['100']} ,
        200: #${palette.shades.contrast['200']} ,
        300: #${palette.shades.contrast['300']} ,
        400: #${palette.shades.contrast['400']} ,
        500: #${palette.shades.contrast['500']} ,
        600: #${palette.shades.contrast['600']} ,
        700: #${palette.shades.contrast['700']} ,
        800: #${palette.shades.contrast['800']} ,
        900: #${palette.shades.contrast['900']} ,
        A100: #${palette.shades.contrast['100']} ,
        A200: #${palette.shades.contrast['200']} ,
        A400: #${palette.shades.contrast['400']} ,
        A700: #${palette.shades.contrast['700']} ,
      )
    );

    $${name}: mat.define-palette($${name}-colors, default, lighter, darker);
    `;
  }

  private static typographyConfig(def: ThemeDefinition) {
    // here we might set "line-height" on input to 1.1 (from 1), depending on the font family ...
    return `
  $typography : mat.define-typography-config(
    $font-family: '${def.fontFamily ? def.fontFamily + ', ' : ''}Roboto, "Helvetica Neue", sans-serif',
    $display-4: mat.define-typography-level(112px, 112px, 300),
    $display-3: mat.define-typography-level(56px, 56px, 400),
    $display-2: mat.define-typography-level(45px, 48px, 400),
    $display-1: mat.define-typography-level(34px, 40px, 400),
    $headline: mat.define-typography-level(24px, 32px, 400),
    $title: mat.define-typography-level(20px, 32px, 500),
    $subheading-2: mat.define-typography-level(16px, 28px, 400),
    $subheading-1: mat.define-typography-level(15px, 24px, 400),
    $body-2: mat.define-typography-level(14px, 24px, 500),
    $body-1: mat.define-typography-level(14px, 20px, 400),
    $caption: mat.define-typography-level(12px, 20px, 400),
    $button: mat.define-typography-level(14px, 14px, 500),
    $input: mat.define-typography-level(16px, 1, 400)
  );` + (def.buttonRadius !== undefined ? `\n$button-radius: ${def.buttonRadius}px;` : '');
  }

  private static declareThemes(def: ThemeDefinition) {
    switch (def.brightness) {
      case 'custom':
        let customTheme = `
        $custom-background: #${def.customBrightness.backgroundColor};
        $custom-text: #${def.customBrightness.textColor};
        $is-dark: ${def.customBrightness.isDark};

        $color-config: funcs.portail-custom-theme($custom-background, $custom-text, $is-dark);
        $color-config: map.merge($color-config, (primary: $primary, accent: $accent, warn: $warn));

        $theme: (color: $color-config, typography: $typography);
        $theme: map.merge($theme, $color-config);
        `;

        if (def.darkModeSwitch) {
          const contrastType = def.customBrightness.isDark ? 'light' : 'dark';
          customTheme += SassTemplate.declareTheme(contrastType + '-theme', contrastType);
        }

        return customTheme;
      case 'light-dark':
        return SassTemplate.declareTheme('theme', 'light') + '\n' +
          SassTemplate.declareTheme('dark-theme', 'dark');
      case 'light':
        return SassTemplate.declareTheme('theme', 'light');
      case 'dark':
        return SassTemplate.declareTheme('theme', 'dark');
    }
  }

  private static declareTheme(name, type) {
    return `$${name}: mat.define-${type}-theme((
      color: (primary: $primary, accent: $accent, warn: $warn),
      typography: $typography
    ));`;
  }
}
