import { Injectable } from "@angular/core";
import { ApiCrudService } from "./api-crud.service";
import { PersonalizedContent } from "@app/models/personalized-content";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";


@Injectable({ providedIn: 'root' })

export class PersonalizedContentService extends ApiCrudService<PersonalizedContent> {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  url = 'content-perso';

  constructor(
    protected http: HttpClient
  ) {
    super();
  }
  getById(id: number): Observable<any> {
    return this.http.get<PersonalizedContent[]>(`${this.url}/${id}`, this.httpOptions);
  }

  getAllPersoContent() {
    return this.http.get<PersonalizedContent[]>(this.url, this.httpOptions);
  }

  create(content: PersonalizedContent) {
    return this.http.post(`${this.url}`, content, this.httpOptions);
  }

  delete(content: PersonalizedContent | number) {
    return super.delete(content);
  }

  update(content: PersonalizedContent[]): Observable<any> {
    return super.update(content);
  }

}