<ng-container *ngIf="!inDialog">
  <ng-container *ngTemplateOutlet="newContent"></ng-container>
</ng-container>

<ng-container *ngIf="inDialog">
  <mat-toolbar fxLayout="row" color="primary" fxLayoutAlign="space-between center" mat-dialog-title cdkDrag
    cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle [cdkDragDisabled]="platformService.isMobile"
    style="cursor: move;">
    <button class="close-dialog" mat-icon-button [mat-dialog-close]>
      <mat-icon>close</mat-icon>
    </button>
    <span style="text-align: center; margin:0 10px 0 10px;">
      Visualisation de l'actualité
    </span>
    <span></span>
  </mat-toolbar>
  <div mat-dialog-content class="thin-scrollbar" [ngClass]="{'main-content-dialog': !platformService.isMobile}">
    <ng-container *ngTemplateOutlet="newContent"></ng-container>
  </div>
</ng-container>

<ng-template #newContent>
  <div class="conteneur" fxLayout="row wrap" *ngIf="news; else loader">
    <mat-card class="card" [ngStyle]="{'width': inDialog ? '90%' : ''}">
      <mat-card-header style="flex-direction: column; place-items: center;">
        <mat-card-title class="text-center" style="margin-bottom: 20px;">{{ news.title }}</mat-card-title>
        <mat-card-subtitle>{{ news.subTitle }}</mat-card-subtitle>
      </mat-card-header>
      <img class="img-news" mat-card-image [src]="news.img" alt="">
      <mat-card-content class="contenu">
        <p class="contenu-news" [innerHtml]="news.content | safe"></p>
      </mat-card-content>
      <mat-card-actions class="action-buttons">
      </mat-card-actions>
    </mat-card>
  </div>
  <ng-template #loader>
    <app-loader class="margin"></app-loader>
  </ng-template>
</ng-template>
