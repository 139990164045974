<mat-toolbar mat-dialog-title color="primary">
  <mat-icon>help</mat-icon>
  <h1 class="title">{{ title|trans }}</h1>
</mat-toolbar>
<mat-dialog-content>

  <div [innerHTML]="message | dynamic:templateData | safe"></div>

</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button [mat-dialog-close]="0" color="warn"
    [autoSubmitCountdown]="this.autoValidation==='cancel'?autoValidationConf:null">{{ cancelText|trans }}</button>
  <button mat-raised-button [mat-dialog-close]="1" color="primary"
    [autoSubmitCountdown]="this.autoValidation==='confirm'?autoValidationConf:null">{{ confirmText|trans }}</button>
  <button mat-raised-button [mat-dialog-close]="2" color="accent"
    *ngIf="!!thirdChoiceText">{{ thirdChoiceText|trans }}</button>
</mat-dialog-actions>
