import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { AuthenticationService, UserService, MenuService, SnackbarService, GlobalConfigService } from '@app/services';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { WaitingDialogComponent } from '@app/components/_elements/waiting-dialog.component';
import { combineLatest, Subject } from 'rxjs';
import { take, map, finalize, takeUntil } from 'rxjs/operators';
import { Role } from '@app/models/user';
import { OpenIdConfig, oidcMessage, oidcVariablesForMessage, } from '@app/models/authentification-config'
import { OIDC_LOGIN_CALLBACK_PATH, OIDC_LOGOUT_CALLBACK_PATH } from '@app/models/authentification-config';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { CookieConfig } from '@app/models/global-config';

/*
* Service de gestion des différents type de démarrage de l'application 
* en fonction de l'url de chargement et des paramètres reçus, notamment : 
   - lien de confirmation d'email (liens sur /go/:token)
   - connexion depuis domino 
   - retour open id connect
*/

enum SpecialStartup {
  dominoConnect,
  tokenLinkAction,
  openIdLoginCallback,
  openIdLogoutCallback
}

@Injectable({
  providedIn: 'root'
})
export class StartupService {

  private waitingDialogRef: MatDialogRef<WaitingDialogComponent>;
  private waitingComponent: WaitingDialogComponent;

  private startRoute: ActivatedRouteSnapshot;

  private started$ = new Subject()

  constructor(
    private userService: UserService,
    private authService: AuthenticationService,
    private router: Router,
    private dialog: MatDialog,
    private snackbarService: SnackbarService,
    private menuService: MenuService,
    private activatedRoute: ActivatedRoute,
    private globalConfigService: GlobalConfigService,
    private cookieService: NgcCookieConsentService,

  ) { }

  start() {
    this.startRoute = this.getRealRoute();
    let specialStartup: SpecialStartup;

    // When we connect from domino
    if (this.startRoute.url.some(fragment => fragment.path === 'dominoConnect')) specialStartup = SpecialStartup.dominoConnect
    // When user come from a link with a token (with route /go/:token)
    else if (this.startRoute.url.some(fragment => fragment.path === 'go')) specialStartup = SpecialStartup.tokenLinkAction
    // callback for openId connect / authorize request
    else if (this.startRoute.url.some(fragment => OIDC_LOGIN_CALLBACK_PATH.indexOf(fragment.path) >= 0)) specialStartup = SpecialStartup.openIdLoginCallback
    // callback for openId connect / Logout
    else if (this.startRoute.url.some(fragment => OIDC_LOGOUT_CALLBACK_PATH.indexOf(fragment.path) >= 0)) specialStartup = SpecialStartup.openIdLogoutCallback

    switch (specialStartup) {
      case SpecialStartup.dominoConnect: this.handleDominoUserRedirect(); break;
      case SpecialStartup.tokenLinkAction: this.startWithToken(); break;
      case SpecialStartup.openIdLoginCallback: this.handleOpenIdConnectAuthorizeCallback(); break;
      case SpecialStartup.openIdLogoutCallback: this.handleOpenIdConnectLogoutCallback(); break;

      // No special startup
      default: setTimeout(() => { this.finished() }); break;
    }

    return this.started$.pipe(
      // Some startup stuff triggered after all special start stuff
      finalize(() => {
        this.userService.init()
        this.showCookieConsent()
        this.authService.startExpirationChecker();
      })
    )
  }

  private finished() {
    this.menuService.setVisibility(true); // because it's now initialized at 'false' in MenuService for not display Menu during startup (some http requests are made from menu )
    if (this.waitingDialogRef) this.waitingDialogRef.close();

    this.started$.next();
    this.started$.complete();
  }

  private getRealRoute(): ActivatedRouteSnapshot {
    // As we are not in a compoment, we receive the 'root' route, so we need to parse
    //  the route tree until the last child to get the 'real' activated route (of the loaded component).
    let route = this.activatedRoute;
    while (route.firstChild) {
      route = route.firstChild
    }
    return route.snapshot
  }

  private openWaitingDialog(message: string | null = null) {
    this.waitingDialogRef = this.dialog.open(WaitingDialogComponent, { disableClose: true });
    this.waitingComponent = this.waitingDialogRef.componentInstance;
    if (message) {
      this.waitingMessage = message
    }
  }

  private set waitingMessage(value) {
    this.waitingComponent.waitingMessage = value
  }


  ////////////////// Some miscelanous stuff //////////////////

  showCookieConsent() {
    this.globalConfigService.getPart<CookieConfig>('cookie').pipe(
      take(1),
      map(config => this.globalConfigService.getCookieConsentFromConfig(config))
    ).subscribe(config => {
      this.cookieService.init(config);
    });
  }

  ////////////////// Specials startups stuff //////////////////

  // When we connect from domino
  handleDominoUserRedirect() {
    const token = this.startRoute.paramMap.get('token')
    this.openWaitingDialog('login.redirecting_from_domino')

    this.authService.afterLogout(false);

    this.userService.getTokenType(token).subscribe(response => {
      if (response.type === 'dominoUserConnect' && response.user) {
        this.authService.updateUser(response.user, true);
        this.router.navigate(response.user.role === Role.Admin ? ['/admin'] : ['/account']);
      } else if (response.type === 'messagerieUserConnect' && response.user) {
        this.authService.updateUser(response.user, true);
        this.router.navigate(['/messagerie']);
      } else {
        this.snackbarService.error(`Erreur, la requête n'a pas renvoyé l'utilisateur`);
        this.router.navigate(['/home']);
      }

      this.finished()

    });
  }

  // When user come from a link with a token (with route /go/:token)
  startWithToken() {
    const linkToken = this.startRoute.paramMap.get('token')
    if (!linkToken || linkToken.trim() === '') throw 'Token not provided';

    this.openWaitingDialog()

    this.userService.getTokenType(linkToken)
      .subscribe(response => {
        switch (response.type) {
          case 'confirmEmail':
            this.waitingMessage = 'Confirmation de l\'adresse email ...';

            this.userService.confirmEmail(linkToken).subscribe(res => {

              if (res.mustCreatePassword && res.passwordCreationToken) {
                this.router.navigate(['/reset-password',
                  {
                    token: res.passwordCreationToken,
                    title: 'Création de votre mot de passe',
                    userLogin: res.userLogin
                  }], { skipLocationChange: true });
              } else {
                if (this.authService.currentUserValue) {
                  this.router.navigate(['/account']);
                } else {
                  this.router.navigate(['/home']);
                }
              }

              this.snackbarService.info(res.message);
              this.finished();

            });
            break;

          case 'updateEmail':
            this.waitingMessage = 'Validation du changement d\'email ...';

            this.authService.sendUpdateEmailToken(linkToken).subscribe(resp => {

              if (resp.status === 'ok') {
                this.snackbarService.open({ message: 'La modification a bien été prise en compte', textButton: 'OK' });
                this.router.navigateByUrl('/home'); // remove token from URL ...
              } else {
                this.snackbarService.error('update_email.status.' + resp.status);
              }
              this.finished();

            });
            break;

          case 'reinitPassword':

            this.router.navigate(['/reset-password',
              { token: linkToken, title: 'Réinitialisation de votre mot de passe' }], { skipLocationChange: true });

            this.finished();
            break;

          case 'error':
            console.error(response.errorMessage);
            this.snackbarService.error(response.errorMessage);
            this.finished()

            break;
        }
      },
        error => {
          console.error(error);
          this.snackbarService.error(error);
          this.finished()
        });

  }


  // callback for openId connect / authorize request
  handleOpenIdConnectAuthorizeCallback() {
    this.openWaitingDialog('login.redirecting_from_oidc');

    const authCode = this.startRoute.queryParams['code']
    const state = this.startRoute.queryParams['state'];
    const authorizeCallback = this.authService.openIdConnectAuthorizeCallback(authCode, state)
    const extAuthConf = this.authService.getExternalAuthConf().pipe(takeUntil(this.started$))

    combineLatest([authorizeCallback, extAuthConf]).subscribe(([result, externalAuthConf]) => {
      if (result.userNotFound && result.userInfos) {
        let variables: oidcVariablesForMessage = { fournisseurIdentite: externalAuthConf.providerName }
        if (result.adulte) {
          variables.civilitePrenomNom = result.adulte.civilite + ' ' + result.adulte.prenom + ' ' + result.adulte.nom
        }

        this.authService.setOidcMessage({ type: 'infoUserNotFound', message: externalAuthConf.msgNoUserFound, variables } as oidcMessage)

        if (result.from === 'register') {
          this.router.navigate(['/register'])
        }

      } else if (result.canceled) {
        console.log('TOTP Canceled')

      } else if (result && result.token) { // Success

        this.authService.redirectAfterLogin(result, this.startRoute);

      }
      this.finished()

    }, error => {
      console.error(error)
      this.authService.setOidcMessage({ type: 'error', message: error.msg })
      this.finished()
    });
  }

  // callback for openId connect / Logout
  handleOpenIdConnectLogoutCallback() {
    const state = this.startRoute.queryParams['state'];

    this.openWaitingDialog('Déconnexion ...');
    this.authService.openIdConnectLogoutCallback(state).subscribe(_ => {
      this.router.navigate(['home']); // or 'login' ?? 
      this.finished()
    }, error => {
      console.error(error)
      this.snackbarService.error(error.msg)
      this.finished()
    });

  }

}