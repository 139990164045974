import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Periode } from '@app/models/periode';
import moment from 'moment';
import { DATE_FORMAT, PlanningService } from '@app/services/planning.service';
import { weekDays } from '@app/services/periode.service';
import { PlatformService } from '@app/services';
import { PlanningData } from '../planning-data';
import { ReservationPresence } from '@app/models/reservation';
import { Consumer } from '@app/models/consumer';

export interface RepeatConfig {
  // source
  usager: string;
  mode: 'week' | 'day';
  fromDate?: string;
  toDate?: string;

  // target
  startDate: string;
  endDate: string;
  weekDaysIndexed: {};
  weekDays?: number[]; // convert from indexed before return
  step?: { id: number, type: string, nbStep: number, label: string }

  presences?: ReservationPresence[];
}

interface WeekChoice {
  id: string;
  from: string;
  to: string;
};

@Component({
  selector: 'app-presence-repeat',
  templateUrl: './presence-repeat.component.html',
  styleUrls: ['./presence-repeat.component.scss']
})
export class PresenceRepeatComponent implements OnInit {

  planningData: PlanningData;
  currentDate: string;
  periode: Periode;
  weekDays = weekDays;

  config: RepeatConfig;
  stepIntervals = [
    { id: 1, type: "", nbStep: 0, label: "toutes les semaines" },
    { id: 3, type: "week", nbStep: 2, label: "1 semaine sur 2" },
    { id: 4, type: "week", nbStep: 3, label: "1 semaine sur 3" },
    { id: 5, type: "week", nbStep: 4, label: "1 semaine sur 4" },
    { id: 2, type: "month", nbStep: 2, label: "1 mois sur 2" }
  ]
  commonIntervals = ['prev-week', 'current-week', 'next-week', '', 'prev-month', 'current-month', 'next-month', '', 'current-periode'];

  usagers: Consumer[] = [];
  weeks: WeekChoice[] = [];
  selectedMode: string;
  selectedWeek: string;

  usagerPresences?: ReservationPresence[];
  presences?: ReservationPresence[];
  presencesByDate?: { date: string, presences: ReservationPresence[] }[];
  datePresencesCount: { [date: string]: number } = {};

  presetPresences = this.data.presetPresences;
  targetDates: string[];
  minTargetDate: Date;
  maxTargetDate: Date;
  availableWeekdays: number[] = [];

  singleStep: boolean;
  activeTab: number = 0;

  filterDateWithPresences = (d) => {
    return this.datePresencesCount[moment(d).format(DATE_FORMAT)];
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) private data,
    private dialogRef: MatDialogRef<PresenceRepeatComponent>,
    private planningService: PlanningService,
    public platformService: PlatformService
  ) { }

  ngOnInit(): void {
    this.planningData = this.data.planningData;
    this.periode = this.planningData.currentPeriode;
    this.currentDate = this.data.date;

    this.singleStep = !!this.presetPresences;

    // Get config from data, or build default
    this.config = this.data.config || {
      startDate: moment(this.planningData.getRealFirstEditableDate() || this.periode.startDate).format(DATE_FORMAT),
      endDate: moment(this.periode.endDate).format(DATE_FORMAT),
      weekDaysIndexed: {},
      step: { id: 1, type: "", nbStep: 0, label: "toutes les semaines" },
      mode: 'day',
      fromDate: this.currentDate,
      toDate: this.currentDate
    };

    this.minTargetDate = moment(this.planningData.getRealFirstEditableDate() || this.periode.startDate).toDate();
    this.maxTargetDate = moment(this.periode.endDate).add(1, 'day').toDate();

    this.refreshAvailableWeekdays();

    const currentDay = moment(this.currentDate).isoWeekday();

    if (this.availableWeekdays.includes(currentDay)) {
      this.config.weekDaysIndexed[currentDay] = true;
    }

    this.onUpdateTargetDates();

    // else no need to init all "source presences" config options
    if (!this.presetPresences) {
      if (this.planningData.consumers) {
        this.usagers = this.planningData.consumers.map(u => {
          let title = u.prenom + ' ' + u.nom;

          if (this.planningData.isCurrentConsumer(u.id)) {
            title = `Usager en cours (${title})`;
          }

          return { ...u, title };
        });

        const currentUsager = this.planningData.consumers.find(c => this.planningData.isCurrentConsumer(c.id));

        if (currentUsager) {
          this.config.usager = currentUsager.id;
          this.onChangeUsager();
        }
      } else if (this.planningData.currentReservation) {
        this.config.usager = this.planningData.currentReservation.idConsumer;
        this.onChangeUsager();
      }

      const week = moment(this.periode.startDate).startOf('week');

      while (week.format(DATE_FORMAT) <= this.periode.endDate) {
        const formatted = {
          id: week.format('yyyy-ww'),
          from: week.startOf('week').format(DATE_FORMAT),
          to: week.clone().endOf('week').format(DATE_FORMAT)
        } as WeekChoice;

        this.weeks.push(formatted);

        week.add(1, 'week');
      }
    }
  }

  objectComparaisonFunction(option, value): boolean {
    return option.id === value.id;
  }

  onChangeUsager() {
    this.usagerPresences = [];

    this.planningData.reservations.forEach(r => {
      if (r.idConsumer === this.config.usager && this.planningData.isCurrentPeriode(r.idPeriode, r.idPortailPeriode)) {
        this.usagerPresences.push(...this.planningService.filterPresences(r.presences));
      }
    });

    this.usagerPresences.forEach(pr => pr.contrastColor = pr.color ? this.planningService.getContrastColor(pr.color) : '');

    this.refreshPresences();

    this.datePresencesCount = {};
    this.usagerPresences.forEach(pr => {
      this.datePresencesCount[pr.date as string] = (this.datePresencesCount[pr.date as string] + 1) || 1;
    });
  }

  refreshPresences() {
    this.presences = this.usagerPresences.filter(pr => pr.date >= this.config.fromDate && pr.date <= this.config.toDate);

    if (this.config.mode === 'week') {
      const indexed: { [key: string]: { presences: ReservationPresence[], date: string } } = {};

      this.presences.forEach(pr => {
        const date = moment(pr.date).format(DATE_FORMAT);

        if (!indexed[date]) {
          indexed[date] = { date, presences: [] };
        }

        indexed[date].presences.push(pr);
      });

      this.presencesByDate = Object.values(indexed);
    }

    this.refreshAvailableWeekdays();
  }

  onChangeDateMode() {
    if (this.config.mode === 'week') {
      this.weeks.forEach(week => {
        if (this.config.fromDate >= week.from && this.config.toDate <= week.to) {
          this.selectedWeek = week.id;
          return false;
        }
      });

      this.onUpdateWeek();
    } else {
      const week = this.weeks.find(w => w.id === this.selectedWeek);
      this.config.fromDate = week.from;
      this.config.toDate = week.from; // we moved to single date mode
      this.onUpdateDay();
    }
  }

  onUpdateWeek() {
    const week = this.weeks.find(w => w.id === this.selectedWeek);
    this.config.fromDate = week.from;
    this.config.toDate = week.to;
    this.refreshPresences();
    this.selectEveryAvailableWeekday();
  }

  onUpdateDay() {
    if (typeof this.config.fromDate !== 'string') {
      this.config.fromDate = moment(this.config.fromDate).format(DATE_FORMAT);
    }

    this.config.toDate = this.config.fromDate;

    this.refreshPresences();
  }

  prefillDates(mode: string, event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }

    if (mode === 'current-periode') {
      this.config.startDate = moment(this.planningData.getRealFirstEditableDate() || this.periode.startDate).format(DATE_FORMAT);
      this.config.endDate = moment(this.periode.endDate).format(DATE_FORMAT);
      this.onUpdateTargetDates();
      return;
    }

    let startDate, endDate;

    if (mode.startsWith('current')) {
      startDate = moment(this.currentDate);
      endDate = moment(this.currentDate);
    } else {
      startDate = moment(this.config.startDate);
      endDate = moment(this.config.startDate);
    }

    if (mode === 'prev-week') {
      startDate.subtract(1, 'week');
      endDate.subtract(1, 'week');
    } else if (mode === 'next-week') {
      startDate.add(1, 'week');
      endDate.add(1, 'week');
    } else if (mode === 'prev-month') {
      startDate.subtract(1, 'month');
      endDate.subtract(1, 'month');
    } else if (mode === 'next-month') {
      startDate.add(1, 'month');
      endDate.add(1, 'month');
    }

    if (mode.endsWith('week')) {
      startDate.isoWeekday(1);
      endDate.isoWeekday(7);
    } else if (mode.endsWith('month')) {
      startDate.date(1);
      endDate.endOf('month');
    }

    this.config.startDate = startDate.format(DATE_FORMAT);
    this.config.endDate = endDate.format(DATE_FORMAT);
    this.onUpdateTargetDates();
  }

  refreshAvailableWeekdays() {
    this.availableWeekdays = this.weekDays.map((x, i) => i).filter(d => this.periode.weekdays.includes(d));

    if (this.config.mode === 'week') {
      this.availableWeekdays = this.availableWeekdays.filter(d => this.presencesByDate.some(prd => (moment(prd.date).isoWeekday() === d) && prd.presences?.length));
    }
  }

  selectEveryAvailableWeekday() {
    this.weekDays.forEach((x, i) => this.config.weekDaysIndexed[i] = this.availableWeekdays.includes(i));

    this.onUpdateTargetDates();
  }

  onUpdateTargetDates() {
    this.config.weekDays = Object.keys(this.config.weekDaysIndexed).filter(k => this.config.weekDaysIndexed[k]).map(k => +k);
    // now that we already calc the dates here, should we pass them back to Planning (instead of letting him recalculate ? not a big deal)
    this.targetDates = this.planningService.getRepeatDates(this.config).filter(rd => this.planningData.isEditableDate(rd));
  }

  validate() {
    this.config.weekDays = Object.keys(this.config.weekDaysIndexed).filter(k => this.config.weekDaysIndexed[k]).map(k => +k);
    this.config.presences = this.presences; // hehe
    this.dialogRef.close(this.config);
  }
}
