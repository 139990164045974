import moment from 'moment';
import { formatNumber } from '@angular/common';

export function computeAge(birthDate: Date) {
  const now = new Date();

  if (now.getTime() < birthDate.getTime()) {
    return { years: 0, months: 0 };
  }

  let months = now.getMonth() - birthDate.getMonth() - (now.getDate() >= birthDate.getDate() ? 0 : 1);
  let years = now.getFullYear() - birthDate.getFullYear();

  if (months < 0) {
    months = 12 + months;
    years--;
  }

  return { years, months };
}

export function parseTime(value: string) {
  const result = { hour: 0, minute: 0 };

  if (isTime(value)) {
    const parts = value.split(':');
    result.hour = +parts.shift();
    result.minute = +parts.shift();
  }

  return result;
}

export function isTime(value: string) {
  return typeof value === 'string' && value.match(/^\d{2}:\d{2}$/);
}

export function formatTime(value: { hour: number, minute: number }) {
  return `${formatNumber(value.hour, 'fr-FR', '2.0-0')}:${formatNumber(value.minute, 'fr-FR', '2.0-0')}`;
}