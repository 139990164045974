<mat-toolbar mat-dialog-title color="warn">
  <mat-icon>error</mat-icon>
  <h1 class="title">Erreur</h1>
</mat-toolbar>
<mat-dialog-content>

  <div [innerHTML]="message | safe"></div>

  <div *ngIf="!!errorMessage" class="error-message  warn-lighter-bg">
    {{errorMessage}}
  </div>

</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button color="warn" (click)="onCancel()">
    OK
  </button>
</mat-dialog-actions>
