import { Component, OnInit, Inject, Input, Optional } from '@angular/core';
import { FacturationService } from '@app/services/facturation.service';
import { SnackbarService, PlatformService } from '@app/services';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Facture, RegieConfig } from '@app/models/facturation';

@Component({
  selector: 'app-facture-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class FactureDetailComponent implements OnInit {

  @Input() facture: Facture;
  @Input() regie: RegieConfig;

  groupedFactureLines: any[];

  dialogMode = false;

  loadingPdfDownload: { progress: number, action: string } = { progress: null, action: null };
  displayPreview = false;
  pdfBlob: Blob = null;
  pdfBuffer: any;

  linesReady: boolean;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) dialogData,
    private factureService: FacturationService,
    private snackbarService: SnackbarService,
    public platformService: PlatformService,
    public dialogRef: MatDialogRef<FactureDetailComponent>,
  ) {
    if (dialogData) {
      this.facture = dialogData.facture;
      this.regie = dialogData.regie;
      this.dialogMode = true;
    }
  }

  ngOnInit() {
    if (this.facture.lines) {
      this.parseDetails(this.facture);
      this.linesReady = true;
    } else {
      this.factureService.getFactureDetails(this.facture.id).subscribe(facture => {
        this.parseDetails(facture);
        this.linesReady = true;
      });
    }
  }

  parseDetails(facture: Facture) {
    this.facture = facture;

    if (facture.lines && facture.lines.length) {
      this.groupedFactureLines = this.factureService.getIndexedLines(this.facture.lines);
    }
  }

  dowloadPDF(action) {

    if (this.pdfBlob) {
      if (action === 'download') {
        this.savePDF(this.pdfBlob);
      } else if (action === 'preview') {
        this.previewPDF(this.pdfBlob);
      }

    } else {

      this.loadingPdfDownload.progress = 0;
      this.loadingPdfDownload.action = action;

      this.factureService.getPdf(this.facture.id).subscribe(resp => {
        if (resp.type === 'dl_progress') {
          this.loadingPdfDownload.progress = resp.value as number;
        } else if (resp.type === 'response') {
          const blob: Blob = resp.value as Blob;
          if (blob.type === 'application/pdf') {
            this.pdfBlob = blob;
            if (action === 'download') {
              this.savePDF(blob);
            } else if (action === 'preview') {
              this.previewPDF(blob);
            }
          } else if (blob.type === 'application/json') {
            this.displayErrorFromBlob(blob);
          } else {
            this.snackbarService.error('Erreur lors de l\'ouverture du fichier !<br>Type de réponse inconnu.');
            console.error(resp);
          }
          this.loadingPdfDownload.progress = null;
        }
      }, err => {
        this.loadingPdfDownload.progress = null;
        this.displayErrorFromBlob(err);
      });
    }
  }

  // @see https://stackoverflow.com/questions/52154874/angular-6-downloading-file-from-rest-api
  // => keep this reference, if needs IE support ...
  savePDF(blob: Blob) {
    const blobURL = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = blobURL;
    link.download = 'Facture ' + this.facture.num;

    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

    setTimeout(() => {
      window.URL.revokeObjectURL(blobURL);
    }, 100);
  }

  displayErrorFromBlob(blob: Blob) {
    const reader = new FileReader();
    // This fires after the blob has been read/loaded.
    reader.onload = (e => { // NB : blob.text() is not yet very well supported, so ...
      const jsonResponse = reader.result as string;
      const responseObj = JSON.parse(jsonResponse);
      console.warn('Erreur lors de l\'ouverture du fichier : ', responseObj);
      if (responseObj.msgErreur) {
        this.snackbarService.error('Erreur lors de l\'ouverture du fichier : <br>' + responseObj.msgErreur);
      } else {
        this.snackbarService.error('Erreur inconnue lors de l\'ouverture du fichier !');
      }
    });
    // Start reading the blob as text.
    reader.readAsText(blob);

  }

  previewPDF(blob: Blob) {
    this.displayPreview = true;

    // Simple way, but not compatible with some current browsers, because blob.arrayBuffer() is not yet very well supported, so ...
    // blob.arrayBuffer().then(buf => this.pdfBuffer = buf);

    // An older way
    const reader = new FileReader();
    reader.onload = (e => {
      this.pdfBuffer = reader.result as ArrayBuffer;
      if (!this.platformService.isMobile) {
        this.dialogRef.addPanelClass('full-screen-dialog');
      }
    });
    reader.readAsArrayBuffer(blob);

  }

  onClickClose() {
    if (this.displayPreview) {
      this.displayPreview = false;
      if (!this.platformService.isMobile) {
        this.dialogRef.removePanelClass('full-screen-dialog');
      }
    } else {
      this.dialogRef.close();
    }
  }
}
