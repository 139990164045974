export { }; // Ensure this is treated as a module.

import { formatDate } from '@angular/common';

/*
 *  Extend the Date type to add static function
 */
declare global {
    interface DateConstructor {
      emptyDate;
        fromWebdev(msg: string): Date;
        toWebdev(date: Date, format?: 'Date' | 'DateHeure'): string;
    }
}

Date.emptyDate = '0000-00-00T00:00:00.000';

/*
 * Donne une date au format JS a partir d'une date HyperFile de WebDev
 */
Date.fromWebdev = (value: string): Date => {

    if (value.length === 8) {
        return new Date(
            parseInt(value.substring(0, 4), 10),
            parseInt(value.substring(4, 6), 10) - 1,
            parseInt(value.substring(6, 8), 10),
            0, 0);
    } else if (value.length > 8) {

        return  new Date(
            parseInt(value.substring(0, 4), 10),
            parseInt(value.substring(4, 6), 10) - 1,
            parseInt(value.substring(6, 8), 10),
            parseInt(value.substring(8, 10), 10),
            parseInt(value.substring(10, 12), 10),
            parseInt(value.substring(12, 14), 10),
            0) ; // On ignore les millisecondes ...
    } else {
        return new Date(value);
    }

};

/*
 * Donne une date au format JS a partir d'une date HyperFile de WebDev
 */

Date.toWebdev = (date: Date | null, format?: 'Date' | 'DateHeure'): string => {
    if (date == null) { return ''; }
    switch (format) {
        case 'DateHeure': return formatDate(date, 'yyyyMMddHHmmss', 'fr-FR');
        // 'Date' par défaut  :
        default: return formatDate(date, 'yyyyMMdd', 'fr-FR');
    }
};

