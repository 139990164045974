import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError, of, from } from 'rxjs';
import { catchError, retryWhen, concatMap, delay } from 'rxjs/operators';
import { AuthenticationService, SnackbarService, TypeSnackbar } from '@app/services';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';

export const retryCount = environment.production ? 10 : 5;
export const retryWaitMilliSeconds = environment.production ? 1000 : 200;

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenticationService,
    private translateService: TranslateService,
    private snackbar: SnackbarService,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      retryWhen(error =>
        error.pipe(
          concatMap((err, count) => {
            // Pour contourner le problème avec les erreurs Webdev "La connexion est refusée car le nombre maximum de connexions..."
            // => On retente 5 fois la requete (NB: c'est une erreur liée au poste de dev avec nombre de connexions limités à 10)
            const webdeverror = err.headers.get('webdeverror')
            if (count < retryCount && err.status === 503 && webdeverror && webdeverror === '14 ERR_MAX_CONNECTION') {
              console.warn('Retry ', err, 'webdeverror', webdeverror)
              return of(err);
            }
            return throwError(err);
          }),
          delay(retryWaitMilliSeconds)
        )
      ),
      catchError(err => {
        // For catching error from blob request (as GedService.getDocument())
        // if we receive error as blob, we should read the blob to get the error message 
        // then, we return original error as observable (from() convert the promise in observable)
        //  because catchError need an observable as return ...
        if (err.error instanceof Blob) {
          console.warn('error is a blob ', err)
          let clonedError = { ...err };
          return from(new Promise((resolve, reject) => {
            (err.error as Blob).text().then(errText => {
              if (err.error.type === 'application/json') { // if backend response is json, try to parse it...
                try {
                  clonedError.error = JSON.parse(errText);
                } catch (error) {
                  clonedError.error = errText
                }
              } else {
                clonedError.error = errText; // replace original blob by his text content
              }
              reject(clonedError) // must use reject because we want to propagate the error 
            });
          })) as Observable<never>;
        }
        return throwError(err) // not a blob, continue ... the next catchError will do the job ...
      }),
      catchError(err => {
        console.error('Http Error Interceptor err :', err, request);
        let error = '';

        if (err.status === 401) {
          this.handleUnauthorizedResponse();
        } else if (err.statusText === 'Unknown Error') {
          error = 'Erreur : veuillez vérifier votre connexion';
        } else if (err.error && !!err.error.fault) {
          error = err.error.fault.faultstring + '<br> Détail : ' + err.error.fault.detail;
        } else if (err.error && !!err.error.error) {
          console.log('error.error', err.error.error);
          if (err.error.error instanceof SyntaxError) {
            const newError: SyntaxError = err.error.error as SyntaxError;
            error = newError.message;
          } else {
            error = err.error || err.message || err.statusText;
          }
        } else {
          error = err.error || err.message || err.statusText;
        }

        console.error('Error :', error);

        let modeDebug = environment.modeDebug;

        // On souhaiterait ne pas afficher les erreurs avec le code 422 : elles correspondent a des erreurs de validation de formulaire
        // et on devrait toujours gérer / afficher l'erreur correctement dans le composant concerné...
        // .... mais comme webdev(22) ne veut pas gérer le code 422 (il me renvoit un 200 :-/), 
        // on renvoit donc toujours un 400, et on défini manuellement coté back une entête custom "internal-status" 
        // TODO : il faudrait faire de même pour toutes les erreurs "normales" afin de pouvoir repasser environment.modeDebug à 'false' en prod ...
        if (err.status === 400) {
          const internalSatus = err.headers.get('internal-status')
          if (parseInt(internalSatus) === 422) {
            modeDebug = false;
          }

        }


        if (modeDebug) {
          const errText = typeof error === 'string' ? error : JSON.stringify(error);

          if (typeof errText === 'string' && errText) {
            this.snackbar.open({
              message: errText.replace(/\n/g, '<br>'),
              type: TypeSnackbar.error,
              textButton: 'Ok',
              devMode: !environment.production
            });
          }
        }

        // error should not be undefined here, or Angular will throw an uncaught error
        return throwError(error || err);
      }));
  }

  handleUnauthorizedResponse() {
    if (this.authenticationService.isAuthenticated && this.authenticationService.isUserExpired()) {
      console.log('Auto logout (session expired)');

      this.authenticationService.afterLogout(true)
      this.translateService.get('login.autologout_message').subscribe(msg => this.snackbar.error(msg));

    }
  }
}
