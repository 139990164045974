import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpResponse, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SessionService, Session } from '@app/services';

@Injectable()
export class SessionInterceptor implements HttpInterceptor {
  constructor(
    private sessionService: SessionService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // Cases where session is not required (so skip to next request step)
    if (/^(http|https):/i.test(request.url)
      || /^(\/)?(assets)\//i.test(request.url) // start with '/assets/' or 'assets/'
      || /^(\/)?(theme)\//i.test(request.url) // start with'/theme/' or 'theme/'
    ) {
      return next.handle(request);
    }

    // add sid header with id session if available
    const currentSession = this.sessionService.currentSessionValue;

    if (currentSession && currentSession.id) {
      request = request.clone({
        setHeaders: {
          sid: currentSession.id
        }
      });
    }

    // test & refresh user role from response http headers if available
    return next.handle(request).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          const sid = event.headers.get('sid')
          if (sid && (!currentSession || sid !== currentSession.id)) {

            const newSession: Session = ({ id: sid } as Session)
            this.sessionService.updateSession(newSession, true);

          }
        }
      })
    );
  }

}
