import { SelectionModel } from "@angular/cdk/collections";
import { NestedTreeControl } from "@angular/cdk/tree";
import {
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Output,
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { MatTreeNestedDataSource } from "@angular/material/tree";
import { EtablissementItemNode } from "@app/models/etablissement";
import { EtablissementService } from "@app/services/etablissement.service";

const baseTreeData = [
  {
    name: "ÉTABLISSEMENTS > ACCUEILS",
    level: "root",
    etablissements: [],
  },
];

@Component({
  selector: "app-etablissement-list",
  templateUrl: "./etablissement-list.component.html",
  styleUrls: ["./etablissement-list.component.scss"],
})
export class EtablissementListComponent implements OnInit {
  treeData: EtablissementItemNode[];

  isAllExpanded = false;
  etablissements: any[];
  selectedEtablissementIds = [];
  selectedAccueilIds = []
  dataSource: MatTableDataSource<EtablissementService>;

  tabIdAcc: any[];

  selection = new SelectionModel<EtablissementItemNode>(true);

  treeControl = new NestedTreeControl<EtablissementItemNode>(
    this.etablissementservice.getTreeNodeChildren
  );
  treeSource: MatTreeNestedDataSource<EtablissementItemNode>;
  @Output() selectionChanged = new EventEmitter<EtablissementItemNode[]>();

  constructor(
    @Inject(MAT_DIALOG_DATA) private data,
    public etablissementservice: EtablissementService,
    public dialogRef: MatDialogRef<EtablissementListComponent>
  ) {
    this.treeSource = new MatTreeNestedDataSource<EtablissementItemNode>();
    this.treeData = data.etablissements;
    if (data.selectedEtablissementIds) {
      this.selectedEtablissementIds = data.selectedEtablissementIds

    }
    if (data.selectedAccueilIds) {
      this.selectedAccueilIds = data.selectedAccueilIds
    }

  }
  ngOnInit(): void {
    this.initTreeData();
  }

  initTreeData() {
    const treeData = baseTreeData as EtablissementItemNode[];
    treeData[0].etablissements = this.getSortedList(this.treeData);
    this.treeSource.data = treeData;
    this.treeControl.dataNodes = treeData;
    if (this.selectedEtablissementIds.length || this.selectedAccueilIds.length) {
      treeData[0].etablissements.forEach((etab) => {
        if (this.selectedEtablissementIds.includes(etab.idEtablissement)) {
          this.itemSelectionToggle(etab);
        } else {
          etab.accueils.forEach(acc => {
            if (this.selectedAccueilIds.includes(acc.idAccueil)) {
              this.itemSelectionToggle(acc);
            }
          })
        }
      })
    } else {
      this.itemSelectionToggle(treeData[0]);
    }
    this.treeControl.expand(this.treeSource.data[0]);

    this.onSelectionUpdate();
  }

  itemSelectionToggle(node: EtablissementItemNode): void {
    this.selection.toggle(node);

    const descendants = this.treeControl.getDescendants(node);
    this.selection.isSelected(node)
      ? this.selection.select(...descendants)
      : this.selection.deselect(...descendants);
  }
  onSelectionUpdate() {
    this.selectionChanged.emit(this.selection.selected);
  }
  descendantsAllSelected(node: EtablissementItemNode): boolean {
    const nodeSelected = this.selection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const hasNoAccueils = node.level === "etablissement" && node.accueils.length === 0;
    const isAccueil = node.level === "accueil";
    if (hasNoAccueils) return nodeSelected;
    const allSelected =
      descendants.every((child) => this.selection.isSelected(child));

    if (allSelected && !nodeSelected) {
      this.selection.select(node);
    } else if (!allSelected && nodeSelected) {
      this.selection.deselect(node);
    }
    if (isAccueil) { this.selection.select(node) }


    return allSelected;
  }

  isAccueil(_, node) {
    return node && node.level === "accueil";
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: EtablissementItemNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some((child) =>
      this.selection.isSelected(child)
    );
    return result && !this.descendantsAllSelected(node);
  }
  getSortedList(data: EtablissementItemNode[]) {
    return data.sort((a, b) => (a.name > b.name ? 1 : -1));
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Convert to lowercase
    this.treeSource.data = baseTreeData.map((root) => ({
      ...root,
      etablissements: root.etablissements.filter(
        (ets) => ets.name.toLowerCase().indexOf(filterValue) > -1
      ),
    }));
    this.treeControl.expand(this.treeSource.data[0]);

  }

  onSave() {
    this.dialogRef.close(this.selection.selected);
    this.selectionChanged.emit(this.selection.selected);
  }
}
