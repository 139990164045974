import { FormControl, ValidationErrors } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';

const pattern = /^[0-9]{5}$/;

export function ZipCodeValidator(control: FormControl): ValidationErrors {
  return !control.value || pattern.test(control.value) ? null : { zipCode: true };
}

export function ZipCodeValidatorMessage(err, field: FormlyFieldConfig) {
  return 'Code postal invalide';
}
