import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FlashService {

  storage: Storage;
  keyPrefix = 'flash.';

  constructor() {
    this.storage = sessionStorage;
  }

  addFlash(key: string, message: any) {
    // Store the object as JSON if needed
    message = typeof message === 'object' ? JSON.stringify(message) : message + '';

    this.storage.setItem(this.getFullKey(key), message);
  }

  getFlash(key: string, jsonParse = false) {
    const fullKey = this.getFullKey(key);
    const value = this.storage.getItem(fullKey);
    this.storage.removeItem(fullKey);

    return value && jsonParse ? JSON.parse(value) : value;
  }

  private getFullKey(key: string) {
    return this.keyPrefix + key;
  }

  getAll() {
    const result = [];

    for (let i = 0; i < this.storage.length; i++) {
      const key = this.storage.key(i);

      if (key.startsWith(this.keyPrefix)) {
        result.push(this.getFlash(key));
      }
    }

    return result;
  }
}
