<div #calendarWrapper class="calendar-view-wrapper" [ngStyle]="{maxWidth: calendarMaxWidth + 'px'}" [ngClass]="{
    'small-screen': isSmallScreen,
    'medium-screen': isMediumScreen,
    'readonly': editMode === 'readonly'
    }">

  <div *ngIf="showLoader || data.loadingPeriode" class="loader-overlay">
    <app-loader [size]="4">
      <p *ngIf="data.loadingPeriode">Chargement de la période sélectionnée ...</p>
      <p *ngIf="showLoader">Chargement des données pour le mois en cours ...</p>
    </app-loader>
  </div>

  <full-calendar #calendar [options]="calendarOptions"></full-calendar>
</div>

<ng-template #loader>
  <app-loader class="margin">{{ 'reservation.loader.planning'|trans }}</app-loader>
</ng-template>

<ng-template #optionsPanel>

  <div class="options-panel">

    <mat-accordion multi="true">
      <mat-expansion-panel *ngIf="currentPeriode" expanded="true">
        <mat-expansion-panel-header expandedHeight="48px" collapsedHeight="48px">
          <mat-panel-title>
            <i class="icon icodomino-periode"></i>
            {{ 'reservation.planning.options.current_selection'|trans }}
          </mat-panel-title>
        </mat-expansion-panel-header>

        <app-periode-details [periode]="currentPeriode" [consumer]="currentUsager"></app-periode-details>
      </mat-expansion-panel>

      <mat-expansion-panel expanded="{{ !currentPeriode }}" class="display-filters">
        <mat-expansion-panel-header expandedHeight="48px" collapsedHeight="48px">
          <mat-panel-title>
            <mat-icon>tune</mat-icon>
            {{ 'reservation.planning.options.display'|trans }}
          </mat-panel-title>
        </mat-expansion-panel-header>

        <mat-slide-toggle *ngIf="currentPeriode" [(ngModel)]="displayFilters.otherPeriodes"
          (change)="refreshShownEvents()">
          {{ 'reservation.planning.options.other'|trans }}
        </mat-slide-toggle>

        <mat-slide-toggle [(ngModel)]="displayFilters.showCanceled" (change)="refreshShownEvents()">
          {{ 'reservation.planning.options.show_canceled'|trans }}
        </mat-slide-toggle>

        <mat-slide-toggle [(ngModel)]="displayFilters.otherAccount" (change)="refreshShownEvents()">
          {{ 'reservation.planning.options.other_account'|trans }}
        </mat-slide-toggle>

        <div *ngIf="otherUsagers?.length" class="other-consumer">
          <h4>
            {{ ('reservation.planning.options.show_consumers' + (data.currentReservation ? '_other' : ''))|trans }}
          </h4>

          <div class="other-consumer-list">
            <ng-container *ngFor="let consumer of otherUsagers">
              <mat-checkbox *ngIf="!data.isCurrentConsumer(consumer.id)"
                [(ngModel)]="displayFilters.consumers[consumer.id]" (change)="refreshShownEvents()">
                <div>
                  <span class="consumer-initial"
                    [style.background]="consumer.color|safe:'style'">{{ consumer.initial }}</span>
                  {{ consumer.prenom }} {{ consumer.nom }}
                </div>
              </mat-checkbox>
            </ng-container>
          </div>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header expandedHeight="48px" collapsedHeight="48px">
          <mat-panel-title>
            <mat-icon>category</mat-icon>
            {{ 'reservation.planning.options.legend'|trans }}
          </mat-panel-title>
        </mat-expansion-panel-header>

        <app-planning-legend></app-planning-legend>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</ng-template>
