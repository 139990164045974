import { AbstractControl } from '@angular/forms';
import moment from 'moment';
import { Observable, Subscriber } from 'rxjs';

export function isObject(x: any) {
  return x != null && typeof x === 'object';
}

export function clone(value: any): any {
  if (!isObject(value) || value instanceof RegExp || value instanceof Observable
    || /* instanceof SafeHtmlImpl */ value.changingThisBreaksApplicationSecurity) {
    return value;
  }

  if (value instanceof AbstractControl) {
    return null;
  }

  if (value instanceof Date) {
    return new Date(value.getTime());
  }

  if (value instanceof moment) {
    return moment(value).format('YYYY-MM-DD');
  }

  if (Array.isArray(value)) {
    if (value[0] instanceof Subscriber) {
      return value;
    } else {
      return value.slice(0).map(v => clone(v));
    }
  }

  return Object.keys(value).reduce((newVal, prop) => {
    const propDescriptor = Object.getOwnPropertyDescriptor(value, prop);

    if (propDescriptor.get) {
      Object.defineProperty(newVal, prop, { ...propDescriptor, get: () => clone(value[prop]) });
    } else {
      newVal[prop] = clone(value[prop]);
    }

    return newVal;
  }, {});
}

export function accessProperty(object: object, path: string) {
  const parts = path.split('.');

  const value = object[parts.shift()];

  if (value && parts.length) {
    return accessProperty(value, parts.join('.'));
  }

  return value;
}

export function override(target, source) {
  for (const key in target) {
    if (source.hasOwnProperty(key)) {
      target[key] = source[key];
    }
  }
}

export function isNullish(obj): boolean {
  if (obj === undefined) {
    return true;
  }
  return Object.values(obj).every(value => {
    return value === null ? true : false
  });
}
