import { Directive, TemplateRef, OnInit, OnDestroy } from '@angular/core';
import { HeaderService } from '@app/services/header.service';

@Directive({
  selector: '[appTopbarActions]'
})
export class TopbarActionsDirective implements OnInit, OnDestroy {

  constructor(
    private templateRef: TemplateRef<any>,
    private headerService: HeaderService
  ) { }

  ngOnInit(): void {
    setTimeout(()=>{
      this.headerService.topbarActionsTemplate$.next(this.templateRef);
    },0)
  }

  ngOnDestroy(): void {
    this.headerService.topbarActionsTemplate$.next(null);
  }
}
