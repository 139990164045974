<mat-toolbar mat-dialog-title color="primary">
  <button mat-icon-button [mat-dialog-close] class="close-dialog">
    <mat-icon>close</mat-icon>
  </button>
  <span class="title">Détails : {{ doc.nomFichier }}</span>
</mat-toolbar>

<mat-dialog-content class="document-details thin-scrollbar">
  <div class="details-list">
    <!-- @NB: we'll need entity here to get "for child / family / etc." text, like in upload dialog -->
    <h3 style="text-align: center;">{{ piece.name }}</h3>
    <p><label>Date d'ajout :</label><span>{{ doc.dateHeureAjout|date:'dd/MM/yyyy' }}</span></p>
    <p *ngIf="!mandat"><label>Date de fin de validité :</label><span>{{ doc.dateFinValidite|date:'dd/MM/yyyy' }}</span>
    </p>
  </div>

  <div class="preview-container">
    <h4>Aperçu :</h4>
    <app-loader *ngIf="loadingPreview; else preview">Chargement de l'aperçu</app-loader>
    <ng-template #preview>
      <app-file-preview *ngIf="!error else errorMsg;" [fileName]="doc.nomFichier" [previewURL]="previewURL">
      </app-file-preview>
    </ng-template>
    <ng-template #errorMsg>
      <div class="mat-error">
        <pre [innerHTML]="error"></pre>
      </div>
    </ng-template>
  </div>
</mat-dialog-content>

<div mat-dialog-actions align="center">
  <button mat-raised-button color="primary" [mat-dialog-close]>Fermer</button>
  <button mat-raised-button color="accent" (click)="downloadDocument()">Télécharger</button>
</div>
