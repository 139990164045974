<ng-template appTopbarActions>
  <div *ngIf="!loading">
    <button mat-mini-fab matTooltip="Option/Légende" color="accent" [matMenuTriggerFor]="moreMenu">
      <mat-icon>settings</mat-icon>
    </button>
  </div>

  <mat-menu #moreMenu="matMenu">
    <div *ngIf="!loading" style="padding: 5px;">

      <h3 style="text-align: center; margin: 5px 0;">Option :</h3>

      <div>
        <mat-checkbox style="padding-left: 5px;" [(ngModel)]="showCurrentInscriptions"
          (ngModelChange)="updateSelectChild($event)">
          <span>Afficher uniquement les dossiers d'inscriptions en cours.</span>
        </mat-checkbox>
      </div>

      <h3 style="text-align: center; margin: 15px 0 5px 0;">Légende :</h3>

      <div fxLayout="row" fxLayoutAlign="start center" class="legend">
        <span style="margin:0 12px;" matBadge="1" matBadgeColor="accent"></span><span style="margin-left: 5px;">Nombre
          de
          modifications en cours</span>
      </div>

      <ng-container *ngFor="let item of legendItems">
        <div fxLayout="row" fxLayoutAlign="start center" class="legend">
          <mat-icon style="margin-right: 5px;" [color]="item.color">{{item.icon}}</mat-icon><span>{{item.title}}</span>
        </div>
      </ng-container>

      <div fxLayout="row" fxLayoutAlign="start center" class="legend"><span class="icon-presence-absence"
          [style.background-color]="(config.rubriquePresence.color|customColor)|safe:'style'"></span><span>Présences
          réelles</span></div>

      <div fxLayout="row" fxLayoutAlign="start center" class="legend"><span class="icon-presence-absence"
          [style.background-color]="(config.rubriqueAbsence.color|customColor)|safe:'style'"></span><span>Absences
          réelles</span></div>
    </div>

  </mat-menu>
</ng-template>

<ng-container *ngIf="!loading; else loader">

  <div class="select-child-container">
    <mat-form-field class="no-hint select-child">
      <mat-label>Enfant</mat-label>
      <mat-select [(ngModel)]="selectedInscription">
        <mat-select-trigger *ngIf="selectedInscription" class="icons-trigger">
          <mat-icon [color]="selectedInscription.enabledPointage ? 'primary' : 'warn'">
            {{selectedInscription.icon}}
          </mat-icon>
          <mat-icon *ngIf="selectedInscription.remplacement">autorenew</mat-icon>
          {{selectedInscription.nomReferent}}
        </mat-select-trigger>
        <mat-option *ngFor="let inscription of selectListChildInscription" [value]="inscription">
          <mat-icon style="margin-right: 1px;" [color]="inscription.enabledPointage ? 'primary' : 'warn'">
            {{inscription.icon}}</mat-icon>
          <mat-icon style="margin-right: 1px;" *ngIf="inscription.remplacement">autorenew</mat-icon>
          <ng-container *ngIf="inscription.dateFin !== '0000-00-00'">
            {{inscription.nomReferent}}
            <small>(du {{inscription.dateDebut | date:'dd/MM/yyyy'}} au
              {{inscription.dateFin | date:'dd/MM/yyyy'}})</small>
          </ng-container>
          <ng-container *ngIf="inscription.dateFin === '0000-00-00'">
            {{inscription.nomReferent}}
            <small>(à partir du {{inscription.dateDebut | date:'dd/MM/yyyy'}})</small>
          </ng-container>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <button mat-icon-button color="accent" matTooltip="Informations" [matMenuTriggerFor]="infoMenu"
      [disabled]="noChild">
      <mat-icon>info</mat-icon>
    </button>
  </div>

  <app-assmat-planning *ngIf="!noChild" [config]=config [idAssmat]=idAssmat [selectedInscription]=selectedInscription
    [preview]=false>
  </app-assmat-planning>

</ng-container>

<mat-menu #infoMenu="matMenu">
  <div *ngIf="!loading && !noChild" style="padding: 5px;">
    <h3 style="text-align: center; margin: 5px 0; padding: 10px;">Informations famille :</h3>
    <div style="padding: 5px;" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3px">
      <mat-icon>group</mat-icon><span>{{selectedInscription.civiliteFamille}}
        {{selectedInscription.nomFamille}}</span>
    </div>
    <div *ngIf="selectedInscription.telFamille" style="padding: 5px;" fxLayout="row" fxLayoutAlign="start center"
      fxLayoutGap="3px">
      <mat-icon>phone</mat-icon><span>{{selectedInscription.telFamille}}</span>
    </div>
    <ng-container *ngIf="selectedInscription.remplacement">
      <h3 style="text-align: center; margin: 5px 0; padding: 10px;">
        {{selectedInscription.datesRemplacement.length > 1 ? 'Dates' : 'Date'}} de remplacement :</h3>
      <div *ngFor="let date of selectedInscription.datesRemplacement" style="padding: 5px;">
        - {{date | date:'EEEE d MMMM y'}}
      </div>
    </ng-container>
  </div>
</mat-menu>

<ng-container *ngIf="noChild">
  <!-- TODO mettre dans translate -->
  <h3 style="text-align: center;">Aucun enfant sur la période en cours.</h3>
</ng-container>

<ng-template #loader>
  <app-loader class="margin">{{ 'reservation.loader.planning'|trans }}</app-loader>
</ng-template>
