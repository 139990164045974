import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
// import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { PersonalizedContent } from '@app/models/personalized-content';
import { PlatformService } from '@app/services';
import { HeaderService } from '@app/services/header.service';
import { PersonalizedContentService } from '@app/services/personalized-content.service';

@Component({
  selector: 'app-single-content',
  templateUrl: './single-content.component.html',
  styleUrls: ['./single-content.component.scss']
})
export class SingleContentComponent implements OnInit {

  content: PersonalizedContent;
  inDialog = false;

  constructor(
    private contentService: PersonalizedContentService,
    private route: ActivatedRoute,
    public platformService: PlatformService,
    private headerService: HeaderService,
    @Optional() @Inject(MAT_DIALOG_DATA) private dialogData
  ) { }

  ngOnInit() {
    if (this.dialogData && this.dialogData.content) {
      this.inDialog = true;
      this.content = this.dialogData.content
    } else {
      this.route.params.subscribe(params => {
        this.contentService.get(params.idContenuPerso).subscribe(content => {
          this.content = content;
          this.headerService.setCurrentPageTitle(content.titre)
        });
      });
    }
  }
}

