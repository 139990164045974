<ng-template appTopbarActions>
  <button mat-raised-button color="accent-lighter" matTooltip="{{ 'reservation.planning.options_button'|trans }}"
    (click)="togglePlanningOptions()" [hidden]="!hasPlanningOptions">
    <mat-icon>more_horiz</mat-icon>
  </button>
</ng-template>

<mat-drawer-container>
  <mat-drawer-content>

    <!-- STEPPER -->
    <mat-stepper [hidden]="!!finalReservation" [orientation]="platformService.isMobile ? 'vertical':'horizontal'" linear
      [selectedIndex]="step" (selectionChange)="step = $event.selectedIndex">
      <!-- STEP 1 : CONSUMER SELECT -->
      <mat-step *ngIf="!isEdit" [stepControl]="consumerFormGroup" label="{{ 'reservation.step.select_consumer'|trans }}"
        state="consumer" errorMessage="{{ 'reservation.step.error.select_consumer'|trans }}">
        <ng-container *ngTemplateOutlet="stepOne"></ng-container>
      </mat-step>

      <!-- STEP 2 : PERIODE TREE VIEW -->
      <mat-step *ngIf="!isEdit" [stepControl]="periodeFormGroup" label="{{ 'reservation.step.select_periode'|trans }}"
        state="periode">
        <ng-container *ngTemplateOutlet="stepTwo"></ng-container>
      </mat-step>

      <!-- STEP 3 : PLANNING VIEW -->
      <mat-step state="planning"
        [label]="(isActivityMode ? 'reservation.step.activities' : 'reservation.step.planning')|trans"
        [stepControl]="reservationForm">
        <ng-template matStepContent>
          <!-- For Lazy rendering, because full calendar doesn't initialize styles correctly when loaded while hidden -->
          <ng-container *ngTemplateOutlet="stepThree"></ng-container>
        </ng-template>

      </mat-step>

      <!-- STEP 4 : SUMMARY / CONFIRM SUBMIT -->
      <mat-step label="{{ 'reservation.step.summary'|trans }}" state="recap">
        <ng-container *ngTemplateOutlet="stepFour"></ng-container>
      </mat-step>

      <!-- Steps icons overrides. -->
      <ng-template matStepperIcon="consumer">
        <i class="icon icodomino-usager"></i>
      </ng-template>
      <ng-template matStepperIcon="periode">
        <i class="icon icodomino-etablissement"></i>
      </ng-template>
      <ng-template matStepperIcon="planning">
        <mat-icon>event_note</mat-icon>
      </ng-template>
      <ng-template matStepperIcon="recap">
        <mat-icon>list</mat-icon>
      </ng-template>

    </mat-stepper>

    <ng-container *ngIf="finalReservation">
      <ng-container *ngTemplateOutlet="finalStep"></ng-container>
    </ng-container>

  </mat-drawer-content>

  <mat-drawer position="end" [(opened)]="optionsPanelOpened">
    <ng-container #optionsPanel></ng-container>
  </mat-drawer>
</mat-drawer-container>

<!-- STEP 1 : CONSUMER SELECT -->
<ng-template #stepOne>
  <div class="step-inner-card">

    <mat-card *ngIf="inscriptionError || globalsErrors?.length" class="warn-bg inscription-errors">
      <span *ngIf="inscriptionError">
        {{ ('reservation.form_incomplete.' + inscriptionError)|trans }}
      </span>

      <!-- <ul class="errors" *ngIf="inscriptionErrorVaccins">
        <li *ngFor="let errorVaccin of inscriptionErrorVaccins">
          {{errorVaccin}}
        </li>
      </ul> -->

      <ul class="errors" *ngIf="inscriptionErrorSteps">
        <li *ngFor="let errorStep of inscriptionErrorSteps">
          <a [routerLink]="errorStep.link">{{ errorStep.label }}</a>
        </li>
      </ul>

      <ul class="errors" *ngIf="globalsErrors">
        <ng-container *ngTemplateOutlet="globalsErrorsTpl"></ng-container>
      </ul>

    </mat-card>

    <form *ngIf="data.consumers; else loader" [formGroup]="consumerFormGroup">
      <div style="margin-top: 10px;">
        <mat-form-field *ngIf="data.consumers.length; else noUsager" style="width: 100%;">
          <mat-label>{{ 'reservation.form.consumer'|trans }}</mat-label>
          <mat-select formControlName="consumer" required panelClass="consumer-select"
            (selectionChange)="onChangeUsager($event.value, $event.source)">
            <mat-option *ngFor="let consumer of data.consumers" [value]="consumer.id"
              [disabled]="!consumer.inscriptions.length">
              {{ consumer.prenom }} {{ consumer.nom }} ({{ 'enfant.age'|trans:{age: consumer.age.years} }})
            </mat-option>
          </mat-select>
        </mat-form-field>

        <ng-template #noUsager>
          {{ 'reservation.form.no_inscription'|trans }}
        </ng-template>
      </div>
    </form>

    <div align="end">
      <button mat-raised-button color="accent-lighter" (click)="stepperNext()" [disabled]="consumerFormGroup.invalid">
        {{ 'reservation.step.next'|trans }}
        <mat-icon>arrow_forward</mat-icon>
      </button>
    </div>
  </div>
</ng-template>

<!-- STEP 2 : PERIODE TREE VIEW -->
<ng-template #stepTwo>
  <div class="step-inner-card periode-tree-container">

    <form *ngIf="currentUsager" [formGroup]="periodeFormGroup">
      <mat-form-field class="no-hint" style="width: 100%;" [hidden]="currentUsager.inscriptions.length < 2">
        <mat-label>{{ 'reservation.form.inscription'|trans }}</mat-label>
        <mat-select formControlName="inscription" (selectionChange)="onChangeInscription($event.value, $event.source)">
          <mat-option *ngFor="let insc of currentUsager.inscriptions" [value]="insc.id">
            <span *ngIf="insc.type === 'diabolo'">{{'reservation.inscription.label_diabolo' | trans}}</span>
            <span *ngIf="insc.nomEtablissement"> {{ insc.nomEtablissement }}</span>
            <span *ngIf="insc.dateDebut && insc.dateFin">
              {{ 'reservation.inscription.date_range'|trans:{debut: insc.dateDebut|date:'dd/MM/yyyy', fin: insc.dateFin|date:'dd/MM/yyyy'} }}
            </span>
            <span *ngIf="insc.dateDebut && !insc.dateFin">
              {{ 'reservation.inscription.date_from'|trans:{date: insc.dateDebut|date:'dd/MM/yyyy'} }}
            </span>
            <span *ngIf="!insc.dateDebut && insc.dateFin">
              {{ 'reservation.inscription.date_until'|trans:{date: insc.dateFin|date:'dd/MM/yyyy'} }}
            </span>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>

    <ng-container *ngTemplateOutlet="messagePerso; context: {messages: messagesPersoFromPrograms.filtragePeriode}">
    </ng-container>

    <h3 style="margin-bottom: .5em;">{{ 'reservation.periode.select_title'|trans }}</h3>

    <!-- Maybe replace with a shift + click handler on TreeItem -->
    <button mat-raised-button color="accent-lighter" (click)="togglePeriodeTreeAll()"
      [disabled]="periodeTreeSource.data.length === 0"
      [matTooltip]="periodeTreeAllExpanded ? 'Tout refermer' : 'Tout ouvrir'" style="margin-bottom: .5em;">
      <mat-icon>{{ periodeTreeAllExpanded ? 'chevron_right' : 'expand_more' }}</mat-icon>
      Tous
    </button>

    <div *ngIf="!loadingTree && periodeTreeSource.data.length === 0" class="no-period-info">
      <mat-icon>mood_bad</mat-icon>
      {{'reservation.form.no_periode'|trans}}
    </div>

    <mat-tree *ngIf="!loadingTree; else periodesLoader" [dataSource]="periodeTreeSource"
      [treeControl]="periodeTreeControl">

      <mat-nested-tree-node *matTreeNodeDef="let node; when: isNotPeriode" matTreeNodePadding>
        <span fxLayout="row" class="mat-icon-rtl-mirror node-title" matTreeNodeToggle>
          <mat-icon fxFlexAlign="center" [attr.aria-label]="'toggle ' + node.name" color="primary">
            {{ periodeTreeControl.isExpanded(node) ? 'keyboard_arrow_down' : 'keyboard_arrow_right' }}
          </mat-icon>
          <i class="icon icodomino-etablissement" fxFlexAlign="center" style="margin-right: 5px;"
            *ngIf="node.level == 'etablissement'"></i>
          <i class="icon icodomino-accueil" fxFlexAlign="center" style="margin-right: 5px;"
            *ngIf="node.level == 'accueil'"></i>
          <span fxFlexAlign="center">{{ node.name }}</span>
        </span>

        <ul [class.tree-node-invisible]="!periodeTreeControl.isExpanded(node)">
          <ng-container matTreeNodeOutlet></ng-container>
        </ul>

      </mat-nested-tree-node>

      <mat-tree-node *matTreeNodeDef="let periode; when: isPeriode" fxLayout="row" matTreeNodePadding matTreeNodeToggle
        (click)="!periode.pausePaiement && onSelectPeriode(periode)">
        <div fxLayout="column" fxLayoutAlign="start start" (click)="toggleMessagePausePaiement(periode)">
          <span class="periode-node-link node-title"
            [ngClass]="{'primary': data.isCurrentPeriode(periode.id, periode.idPortail), 'pause-paiement': periode.pausePaiement }">
            <i class="icon icodomino-periode" fxFlexAlign="center" style="margin-right: 5px;"></i>
            <span fxFlexAlign="center">{{ periode.label || periode.name }}</span>
            <mat-icon *ngIf="periode.pausePaiement" style="margin-left: 5px;" color="accent">info</mat-icon>
          </span>
          <div *ngIf="periode.pausePaiement && periode.pausePaiementMessage && periode.showMessagePausePaiement"
            fxLayout="row" fxLayoutAlign="start center">
            <mat-icon style="margin-left: 20px;" color="warn">credit_card_off</mat-icon>
            <div [innerHTML]="periode.pausePaiementMessage | safe " style="font-style: italic; margin-left:5px;"></div>
          </div>
        </div>

      </mat-tree-node>
      <mat-tree-node *matTreeNodeDef="let node">
        {{ node.name || 'reservation.periode_tree.empty_node'|trans }}
      </mat-tree-node>

    </mat-tree>

    <div class="stepper-nav">
      <button mat-raised-button color="accent-lighter" (click)="stepperPrevious()">
        <mat-icon>arrow_back</mat-icon>{{ 'reservation.step.previous'|trans }}
      </button>
      <button mat-raised-button color="accent-lighter" (click)="stepperNext()" [disabled]="!currentPeriode">
        {{ 'reservation.step.next'|trans }}
        <mat-icon>arrow_forward</mat-icon>
      </button>
    </div>
  </div>
</ng-template>

<!-- STEP 3 : PLANNING VIEW -->
<ng-template #stepThree>

  <div class="planning-or-activity-wrapper">
    <mat-card *ngIf="currentPeriode?.planningMessage as message" class="primary-lighter-bg">
      <div [innerHTML]="message|safe"></div>
    </mat-card>

    <ng-container *ngTemplateOutlet="messagePerso; context: {messages: messagesPersoFromPrograms.affichagePlanning}">
    </ng-container>

    <app-reservation-activities *ngIf="isActivityMode && data.currentPeriode" [data]="data">
    </app-reservation-activities>

    <app-reservation-planning *ngIf="!isActivityMode" [data]="data" [optionsPanelContainer]="optionsPanelContainer">
    </app-reservation-planning>

    <div class="stepper-nav float">
      <button *ngIf="!isEdit" mat-raised-button color="accent-lighter" (click)="stepperPrevious()">
        <mat-icon>arrow_back</mat-icon>Retour
      </button>

      <span class="spacer"></span>

      <button mat-raised-button color="accent-lighter" (click)="stepperNext()"
        [disabled]="!currentReservation?.presences?.length">
        {{ 'reservation.step.next'|trans }}
        <mat-icon>arrow_forward</mat-icon>
      </button>
    </div>
  </div>
</ng-template>

<!-- STEP 4 : SUMMARY / CONFIRM SUBMIT -->
<ng-template #stepFour>
  <div *ngIf="data.currentReservation && data.currentPeriode" class="summary">

    <mat-card class="summary-message primary-lighter-bg">
      <mat-icon color="accent">info</mat-icon>
      {{ 'reservation.summary.title'|trans }}
    </mat-card>

    <div *ngIf="sumReservation; else loader">
      <ng-container *ngTemplateOutlet="messagePerso; context: {messages: messagesPersoFromPrograms.validationPlanning}">
      </ng-container>
      <ng-container *ngTemplateOutlet="sumErrorsCard;"></ng-container>


      <mat-card *ngIf="hasAttentes" class="accent-bg">
        <mat-card-title>Liste d'attente</mat-card-title>

        <div class="row items-center">
          <mat-icon>warning</mat-icon>

          <div>
            {{ 'reservation.summary.has_attentes'|trans }}
            <p>{{ ('reservation.summary.liste_attente_explain_' + data.currentPeriode.gestionListeAttente)|trans }}</p>
          </div>
        </div>
      </mat-card>

      <!-- *ngIf="sumReservation.presences?.length; else nothingNew" ? -->
      <app-reservation-summary [reservation]="sumReservation" [planningData]="data"
        (attenteSelectionChange)="onSummarySelectionChange($event)"></app-reservation-summary>

      <ng-template #nothingNew>
        <h4 class="nothing-new">{{ 'reservation.summary.no_update'|trans }}</h4>
      </ng-template>

      <ng-container *ngIf="showFacture">

        <hr style="margin: 2em auto">

        <mat-card class="sum-facture">
          <mat-card-header>
            <mat-card-title>
              <mat-icon style="float: left; margin-right: 0.5em;">euro</mat-icon>
              {{ 'reservation.summary.facture_estimate'|trans }}
            </mat-card-title>
          </mat-card-header>

          <mat-card-content>
            <div *ngIf="facture" class="facture-estimate">
              <div *ngIf="facture.lines.length">
                <span>
                  <app-icon type="do" icon="etablissement" [matTooltip]="'entity.etablissement'|trans"></app-icon>
                  <span>{{ currentPeriode.nameEtablissement }}</span>
                </span>
                <span>
                  <app-icon type="do" icon="accueil" [matTooltip]="'entity.accueil'|trans"></app-icon>
                  <span>{{ currentPeriode.nameAccueil }}</span>
                </span>
                <span>
                  <app-icon type="do" icon="periode" [matTooltip]="'entity.periode'|trans"></app-icon>
                  <span>{{ currentPeriode.label || currentPeriode.name }}</span>
                </span>
              </div>

              <app-facture-line-table [facture]="facture"></app-facture-line-table>
            </div>

            <!-- <app-facture-detail *ngIf="facture" [facture]="facture"></app-facture-detail> -->
          </mat-card-content>
        </mat-card>
      </ng-container>
    </div>

    <mat-card class="big-resa-alert accent-bg" *ngIf="alertBigReservation">
      <mat-icon>info</mat-icon>
      <ng-container *ngIf="saving else bigResaAlertSummary">
        <span [innerHTML]="'reservation.info.big_reservation_save_message'|trans "></span>
      </ng-container>
      <ng-template #bigResaAlertSummary>
        <span [innerHTML]="'reservation.info.big_reservation_summary_message'|trans"></span>
      </ng-template>
    </mat-card>

    <div class="stepper-nav">
      <button mat-raised-button color="accent-lighter" (click)="stepperPrevious()">
        <mat-icon>arrow_back</mat-icon>
        {{ 'reservation.step.previous'|trans }}
      </button>

      <!-- [disabled]="saving || !(sumReservation?.presences?.length) || globalsErrors?.length || sumErrorPresences?.length">  don't know why it don't work -->
      <button mat-raised-button color="accent-lighter" (click)="submit()" class="btn-submit"
        [disabled]="saving || hasError || !(presencesCount > 0)">
        {{ 'reservation.step.validate'|trans }}
        <mat-icon *ngIf="!saving">done</mat-icon>
        <app-loader *ngIf="saving" size=".75"></app-loader>
      </button>
    </div>
  </div>
</ng-template>

<!-- STEP 5 : FINISH / RECEIPT -->
<ng-template #finalStep>
  <mat-card class="final-summary ">
    <mat-card-header>
      <div mat-card-avatar>
        <mat-icon>label</mat-icon>
      </div>
      <mat-card-title>{{ 'reservation.finalSummary.title'|trans }}</mat-card-title>
    </mat-card-header>
    <mat-card-content>

      <div>

        <div class="important">
          <label>
            <mat-icon>free_cancellation</mat-icon>
            Etat de la demande :
          </label>
          <span class="value accent">
            {{ ('reservation.state_text.' + finalReservation.status)|trans }}
            <mat-icon class="accent">{{ ('reservation.state_icon.' + finalReservation.status)|trans }}</mat-icon>
          </span>
        </div>
      </div>

      <mat-card
        *ngIf="currentPeriode?.modeValidation === 'payment' && finalReservation.status === 'waiting_payment' && !!finalReservation.expirationDate"
        class="alert-validation-panier accent-lighter-bg">
        <div class="message">
          <mat-icon>announcement</mat-icon>
          <span [innerHTML]="
          ('reservation.finalSummary.alert_validation_panier'|trans:{
            expiration_date: (finalReservation.expirationDate|date:'dd/MM/yyyy' ),
            expiration_heure: (finalReservation.expirationDate|date:'hh:mm' )
          }) |safe
          ">
          </span>
        </div>
        <div *ngIf="finalReservation.hasCancel" class="nota-bene">
          <mat-icon>info</mat-icon>
          <span [innerHTML]="'reservation.finalSummary.alert_validation_panier_nota_bene_annulation'|trans"></span>
        </div>
      </mat-card>
      <div>
        <label>
          <mat-icon>face</mat-icon>
          Usager :
        </label>
        <span class="value">{{ finalReservation.referent }}</span>
      </div>

      <div>

        <label>
          <mat-icon>confirmation_number</mat-icon>
          N° de la réservation :
        </label>
        <span class="value">{{ finalReservation.id }}</span>
      </div>

      <div>

        <label>
          <mat-icon>today</mat-icon>
          Date de création :
        </label>
        <span class="value">{{ finalReservation.creationDate|date:'medium' }}</span>
      </div>
      <div>

        <label>
          <i class="icon icodomino-etablissement"></i>
          Etablissement :
        </label>
        <span class="value">{{ finalReservation.etablissement }}</span>
      </div>

      <div>

        <label>
          <i class="icon icodomino-accueil"></i>
          Accueil :
        </label>
        <span class="value">{{ finalReservation.accueil }}</span>
      </div>

      <div>

        <label>
          <i class="icon icodomino-periode"></i>
          Période :
        </label>
        <span class="value">{{ finalReservation.periode }}</span>
      </div>


      <ng-container *ngIf="hasError">
        <ng-container *ngTemplateOutlet="sumErrorsCard"></ng-container>
      </ng-container>

    </mat-card-content>

    <mat-card-actions>
      <button mat-button mat-raised-button color="primary" (click)="startNewReservation()">
        <mat-icon>add_box</mat-icon>
        {{'reservation.finalSummary.button_new' | trans}}
      </button>

      <button *ngIf="currentPeriode?.modeValidation === 'payment'" mat-button mat-raised-button color="accent"
        routerLink="/account/reservations/panier">
        <mat-icon>shopping_basket</mat-icon>
        {{'reservation.finalSummary.button_panier' | trans}}
      </button>

      <button *ngIf="!waitingRoomActive" mat-button mat-raised-button routerLink="/account/reservations">
        <mat-icon>calendar_month</mat-icon>
        {{'reservation.finalSummary.button_planning' | trans}}
      </button>

      <button mat-button mat-raised-button (click)="goHome()" [appFocusOnShow] color="accent"
        [autoSubmitCountdown]="waitingRoomActive? {delay:15, cancelAutoRedirect$, tooltip :' Redirection automatique dans {{delay}} secondes'}:null">
        <mat-icon>account_circle</mat-icon>
        {{(!waitingRoomActive ? 'reservation.finalSummary.button_home' : 'reservation.finalSummary.button_home_in_waiting_room') | trans}}
      </button>

      <!-- <button mat-button mat-raised-button (click)="testFinalSummary(false)">test</button> -->

    </mat-card-actions>


  </mat-card>

</ng-template>

<ng-template #loader>
  <app-loader></app-loader>
</ng-template>

<ng-template #periodesLoader>
  <app-loader message="{{ 'reservation.loader.periodes'|trans }}"></app-loader>
</ng-template>


<ng-template #sumErrorsCard>

  <mat-card #errorList *ngIf="sumErrorPresences?.length || globalsErrors?.length" class="warn-lighter-bg error-list">
    {{ 'reservation.summary.error_list'|trans }}
    <ul class="errors">
      <li *ngFor="let pr of sumErrorPresences">
        <span class="date">{{ pr.date|date }} : </span>
        <span class="rubrique" *ngIf="findRubrique(pr.rubrique) as rub">
          {{ rub.label || rub.name }}
        </span>
        <span class="hours"> ( de {{ pr.startTime }} à {{ pr.endTime }} ) </span>
        <div class="errorMessage">{{ pr.error.errorMessage |trans }}</div>
      </li>

      <ng-container *ngTemplateOutlet="globalsErrorsTpl"></ng-container>

    </ul>
  </mat-card>
</ng-template>

<ng-template #globalsErrorsTpl>
  <li *ngFor="let err of globalsErrors" class="errorItem">
    <div class="errorMessage" [innerHTML]=" err |safe "></div>
  </li>
</ng-template>

<ng-template #messagePerso let-messages="messages">
  <mat-card *ngIf="messages?.length" class="accent-bg">
    <div fxLayout="row" fxLayoutAlign="start center">
      <mat-icon style="margin-right: 24px;">info</mat-icon>
      <div>
        <li *ngFor="let mess of messages" class="custom-message-item" [innerHTML]=" mess |safe ">
        </li>
      </div>
    </div>
  </mat-card>
</ng-template>
