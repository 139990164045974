<div class="conteneur">
  <app-filter-news [news]="news" (newsFilter)="filterNews($event)" style="margin-left: 10px; width: 96%;">
  </app-filter-news>
</div>

<div *ngIf="news; else loader" class="conteneur">
  <ng-container *ngFor="let actu of newsFiltered || news"><!-- @NB: check this later -->
    <mat-card class="card" fxLayout="column" fxLayoutAlign="space-between center" fxFlex="31"
      fxFlex.md="47" fxFlex.sm="46" fxFlex.xs="100">
      <mat-card-header>
        <mat-card-title class="title">{{ actu.title }}</mat-card-title>
      </mat-card-header>
      <img mat-card-image [src]="actu.img" alt="">
      <mat-card-content class="contenu">
        <p>{{ actu.subTitle }}</p>
      </mat-card-content>

      <mat-card-actions class="action-buttons">
        <button mat-raised-button color="primary-lighter" routerLink="/news/{{ actu.id }}">
          {{ 'actualite.list_item_read_button'|trans }}
        </button>
      </mat-card-actions>
    </mat-card>
  </ng-container>

  <!-- <div *ngIf="!newsFiltered || !newsFiltered.length">
    <h2 style="text-align: center;">{{ 'actualite.empty_list'|trans }}</h2>
  </div> -->
</div>

<ng-template #loader>
  <app-loader class="margin" [message]="'actualite.loading_data'|trans"></app-loader>
</ng-template>
