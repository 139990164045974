<label *ngIf="label"
  [class.warn]="formControl && (formControl.dirty || formControl.touched) && formControl.errors?.required">
  {{ label }}
  <span *ngIf="required" class="mat-form-field-required-marker">*</span>
</label>

<div class="time-input-wrapper">
  <ng-container *ngIf="!platformService.isMobile">
    <input [ngModel]="hour|number:'2.0-0'" class="hours" maxlength="2" [min]="minHour" [max]="maxHour"
      (keydown)="onKeydown($event)" (keypress)="onKeypress($event)" [disabled]="disabled" (blur)="onBlur($event)">

    <span class="colon-separator">:</span>

    <input [ngModel]="minute|number:'2.0-0'" class="minutes" maxlength="2" [min]="minMinute" [max]="maxMinute"
      [disabled]="disabled" (keydown)="onKeydown($event)" (keypress)="onKeypress($event)" (blur)="onBlur($event)">
  </ng-container>

  <ng-container *ngIf="picker">
    <input [type]="!platformService.isMobile ? 'hidden' : ''" [ngModel]="value"
      [ngClass]="{'inputMobile': platformService.isMobile}" [ngxTimepicker]="timePicker"
      [disableClick]="platformService.isMobile ? false : true" [disabled]="disabled" readonly [format]="24" [min]="min"
      [max]="max"
      (ngModelChange)="writeValue($event); triggerChange()">
    <ngx-material-timepicker-toggle [for]="timePicker" [disabled]="disabled"></ngx-material-timepicker-toggle>
    <ngx-material-timepicker #timePicker [minutesGap]="minuteGap > 1 ? minuteGap : null" [hoursOnly]="minuteGap === 60"
      [cancelBtnTmpl]="cancelBtn" [confirmBtnTmpl]="confirmBtn">
    </ngx-material-timepicker>
  </ng-container>

  <ng-template #cancelBtn>
    <button mat-raised-button style="margin-right: 10px;" color="warn" type="button">Annuler</button>
  </ng-template>
  <ng-template #confirmBtn>
    <button mat-raised-button style="margin-right: 10px;" color="primary" type="button">Valider</button>
  </ng-template>
</div>
