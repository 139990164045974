import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { News, NewsCategorie } from '@app/models/news';
import { ApiCrudService } from './api-crud.service';
import { NewsAdapterService } from './adapter/news-adapter.service';
import { map, tap } from 'rxjs/operators';


@Injectable({ providedIn: 'root' })
export class NewsService extends ApiCrudService<News> {

  url = 'news';
  urlCat = 'news-categories';


  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(
    protected http: HttpClient,
    private adapterNews: NewsAdapterService,
  ) {
    super();
  }

  get(id) {
    return super.get(id).pipe(
      map(value => this.adapterNews.reverse(value))
    );
  }

  // getAll() {
  //   return super.getAll().pipe(
  //     map(values => values.map(val => this.adapterNews.reverse(val))),
  //     tap(news => this.sortByOrder(news))
  //   );
  // }

  getAllNews(typeList: 'admin' | 'public'): Observable<any> {
    return this.http.get<[]>(`news-list/${typeList}`).pipe(
      map(values => values.map(val => {
        const n = this.adapterNews.reverse(val)
        n.etablissementAccueil = JSON.parse(n.etablissementAccueil || '[]')
        n.etablissementsIds = n.etablissementAccueil.map(etb => etb.etablissementId)
        n.accueilsIds = n.etablissementAccueil.map(etb => etb.accueils).flat()
        return n
      })),
      tap(news => this.sortByOrder(news))
    );
  }

  getNewsByCategory(categories): Observable<any> {
    let cat = categories.toString();
    return this.http.get(`${this.url}/category/${cat}`, this.httpOptions).pipe(
      map((values: News[]) => values.map(val => this.adapterNews.reverse(val))),
      tap(news => this.sortByOrder(news))
    );
  }
  create(news: News): Observable<any> {
    return super.create(this.adapterNews.adapt(news));
  }

  update(news: News[]): Observable<any> {
    let newsAdapted = news.map(n => this.adapterNews.adapt(n));
    return super.update(newsAdapted);
  }

  delete(item: News | number) {
    return super.delete(item);
  }

  getNewsByCatOrAll(cat) {
    if (cat.length > 0) {
      return this.getNewsByCategory(cat)
    }
    else {
      return this.getAllNews('public');
    }
  }

  getAllCategory(): Observable<NewsCategorie[]> {
    return this.http.get<NewsCategorie[]>(this.urlCat, this.httpOptions);
  }

  getVisibleCategories() {
    return this.http.get<NewsCategorie[]>(`${this.urlCat}?onlyVisibles=1`, this.httpOptions)
  }

  saveCategories(categories: NewsCategorie[]) {
    return this.http.post(this.urlCat, { categories }, this.httpOptions);
  }

  getCategory(idCategory) {
    return this.http.get<NewsCategorie>(`${this.urlCat}/${idCategory}`, this.httpOptions)
  }

  sortByOrder(array) {
    array.sort((a: News, b: News) => {
      if (a.order < b.order) return -1;
      if (a.order > b.order) return 1;
      return 0;
    });
  }
  sortByCategoryOrder(array) {
    array.sort((a: NewsCategorie, b: NewsCategorie) => {
      if (a.ordre < b.ordre) return -1;
      if (a.ordre > b.ordre) return 1;
      return 0;
    });
  }
}
