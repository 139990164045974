<ng-container *ngIf="news; else loader">
  <div *ngIf="newsStyleType === 'news'" class="carousel-news">
    <swiper [config]="swiperConfig">
      <ng-template *ngFor="let actu of news" swiperSlide>
        <a [routerLink]="'/news/' + actu.id" class="no-underline">
          <h3>{{ actu.title }}</h3>
          <div *ngIf="actu.img">
            <img class="img-thumbnail" [src]="actu.img">
          </div>
          <p *ngIf="actu.subTitle" class="news-subtitle">{{ actu.subTitle }}</p>
        </a>
      </ng-template>

      <ng-template *ngIf="seeMoreLink" swiperSlide>
        <button mat-raised-button color="primary" [routerLink]="seeMoreLink" class="btn-more" style="margin-top: 40%;">
          Voir plus
          <mat-icon>chevron_right</mat-icon>
        </button>
      </ng-template>
    </swiper>
  </div>

  <div *ngIf="newsStyleType === 'newsList'" class="list-container">
    <div *ngFor="let actu of news">
      <a [routerLink]="'/news/' + actu.id">
        <div class="sub-container">
          <img *ngIf="actu.img" class="img-thumbnail" [src]="actu.img" alt="">

          <div class="conteneur-title-subTitle">
            <h3 class="news-title">{{ actu.title }}</h3>
            <p class="news-subTitle">{{ actu.subTitle }}</p>
          </div>
        </div>
      </a>
    </div>


    <button *ngIf="seeMoreLink" mat-stroked-button color="primary" [routerLink]="seeMoreLink" class="btn-more">
      Voir plus
      <mat-icon>chevron_right</mat-icon>
    </button>

  </div>
</ng-container>

<!-- <div class="conteneur" *ngIf="newsStyleType === 'newsList'">
  <ng-container *ngFor="let aNews of news; let i=index">
    <div *ngIf="aNews.enabled && !nbNewsEnabled">
      <ng-container *ngTemplateOutlet="newsList; context: {data: aNews}"></ng-container>
    </div>
    <div *ngIf="aNews.enabled && i < nbNewsEnabled" class="conteneur-aNews">
      <ng-container *ngTemplateOutlet="newsList; context: {data: aNews}"></ng-container>
      </div>
    </ng-container>
  </div>

  <button *ngIf="categorie.length && (newsLength > nbNewsEnabled) && nbNewsEnabled" mat-stroked-button color="primary" routerLink="/news/categories/{{categorie}}" class="btn-more">Voir plus <mat-icon>chevron_right</mat-icon> </button>
  <button *ngIf="!categorie.length && (newsLength > nbNewsEnabled) && nbNewsEnabled" mat-stroked-button color="primary" routerLink="/news/" class="btn-more">Voir plus <mat-icon>chevron_right</mat-icon> </button>

  <ng-template #newsList let-data="data">
    <a routerLink="/news/{{data.id}}">
      <div class="sub-container" fxLayout='column' fxLayoutGap="10px">
        <div fxFlexAlign='center' *ngIf="data.img" class="container-img">
        <img class="img-thumbnail" [src]="data.img" alt="">
      </div>
        <div fxFlex="90" fxFlex.sm="90" fxFlex.xs="90" fxLayout="column" class="conteneur-title-subTitle">
          <h3 class="news-title">{{data.title}}</h3>
          <p class="news-subTitle">{{data.subTitle}}</p>
        </div>
      </div>
    </a>
  </ng-template>

  <ng-template #newsCarroussel let-data="data">
    <a routerLink="/news/{{data.id}}">
      <div fxLayout="column center">
        <h3>{{data.title}}</h3>
        <div style="margin:auto;" *ngIf="data.img">
          <img class="img-thumbnail" [src]="data.img" alt="">
        </div>
        <p style="width:90%; margin:auto;" class="news-subTitle">{{data.subTitle}}</p>
      </div>
    </a>
  </ng-template> -->

<ng-template #loader>
  <app-loader style="margin: 50px auto">Chargement des actualités</app-loader>
</ng-template>
