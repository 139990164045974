import { Component, OnInit } from '@angular/core';
import { ThemeService } from '@app/services';

@Component({
  selector: 'app-theme-switch',
  templateUrl: './theme-switch.component.html',
  styleUrls: ['./theme-switch.component.scss']
})
export class ThemeSwitchComponent implements OnInit {

  isDarkMode: boolean;

  constructor(
    private themeService: ThemeService
  ) { }

  ngOnInit(): void {
    this.themeService.darkMode$.subscribe(isDarkMode => this.isDarkMode = isDarkMode);
  }

  switchDarkMode() {
    this.themeService.switchDarkMode();
  }
}
