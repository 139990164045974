<div class="conteneur" *ngIf="!isLoading && familleActive; else loader">
  <mat-card
    [ngClass]="{'form-container': !platformService.isMobile, 'form-container-mobile': platformService.isMobile}">
    <mat-card-header class="card-header" fxLayout="row" fxLayoutAlign="center center">

      <mat-card-title fxLayout="row" fxLayoutAlign="center center">
        <span *ngIf="!isEdit">{{ 'mandat_sepa.new_mandat_sepa'|trans }}</span>
        <span *ngIf="isEdit">{{ 'mandat_sepa.edit_mandat_sepa'|trans }}</span>
      </mat-card-title>
    </mat-card-header>

    <form [formGroup]="sepaMandatForm" (ngSubmit)="onSubmit()" class="form">

      <mat-form-field class="no-hint">
        <mat-label>Créancier</mat-label>
        <mat-select formControlName="idSEPACreancier" (change)="handleSelectCreancierChange($event)">
          <mat-option *ngFor="let creancier of config.tabCreanciers" [value]="creancier.idSEPACreancier">
            {{ creancier.label ? creancier.label : creancier.creancier}}
          </mat-option>
        </mat-select>
        <!-- <mat-error *ngIf="sepaMandatForm.get('creancier').invalid && sepaMandatForm.get('creancier').touched">
          <div *ngIf="sepaMandatForm.get('creancier').errors.required">Le champ est obligatoire.</div>
        </mat-error> -->
      </mat-form-field>

      <mat-form-field class="no-hint">
        <mat-label>Nom / Prenom</mat-label>
        <input matInput type="text" formControlName="nomPrenom">
        <!-- <mat-error *ngIf="sepaMandatForm.get('nomPrenom').invalid && sepaMandatForm.get('nomPrenom').touched">
          <div *ngIf="sepaMandatForm.get('nomPrenom').errors.required">Le champ est obligatoire.</div>
        </mat-error> -->
      </mat-form-field>

        <mat-form-field class="no-hint">
          <mat-label>Adresse</mat-label>
          <input matInput type="text" formControlName="adresse">
          <!-- <mat-error *ngIf="sepaMandatForm.get('adresse').invalid && sepaMandatForm.get('adresse').touched">
            <div *ngIf="sepaMandatForm.get('adresse').errors.required">Le champ est obligatoire.</div>
          </mat-error> -->
        </mat-form-field>

        <div fxLayout="row wrap">
          <mat-form-field class="no-hint" fxFlex="26" fxFlex.xs="100">
            <mat-label>Code postal</mat-label>
            <input matInput type="text" formControlName="codePostal">
            <!-- <mat-error *ngIf="sepaMandatForm.get('codePostal').invalid && sepaMandatForm.get('codePostal').touched">
              <div *ngIf="sepaMandatForm.get('codePostal').errors.required">Le champ est obligatoire.</div>
            </mat-error> -->
          </mat-form-field>
  
          <mat-form-field class="no-hint" fxFlex="74" fxFlex.xs="100">
            <mat-label>Ville</mat-label>
            <input matInput type="text" formControlName="ville">
            <!-- <mat-error *ngIf="sepaMandatForm.get('ville').invalid && sepaMandatForm.get('ville').touched">
              <div *ngIf="sepaMandatForm.get('ville').errors.required">Le champ est obligatoire.</div>
            </mat-error> -->
          </mat-form-field>
  
          <!-- <mat-form-field class="no-hint" fxFlex="37" fxFlex.xs="100">
            <mat-label>Pays</mat-label>
            <input type="text"
                 aria-label="Pays"
                 matInput
                 formControlName="pays"
                 [matAutocomplete]="auto"
                 (input)="formatPays($event)">
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
            <mat-option *ngFor="let pays of filteredlistPays | async" [value]="pays">
              {{pays}}
            </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="sepaMandatForm.get('pays').invalid && sepaMandatForm.get('pays').touched">
              <div *ngIf="sepaMandatForm.get('pays').errors.paysInvalide">Pays invalide</div>
            </mat-error>
          </mat-form-field> -->
        </div>

        <div fxLayout="row wrap">
          <mat-form-field class="no-hint" fxFlex="60" fxFlex.sm="60" fxFlex.xs="100">
            <mat-label>IBAN</mat-label>
            <input matInput type="text" formControlName="iban" (input)="formatIban($event)">
            <mat-error *ngIf="sepaMandatForm.get('iban').invalid && sepaMandatForm.get('iban').touched">
              <!-- <div *ngIf="sepaMandatForm.get('iban').errors.required">Le champ IBAN est obligatoire.</div> -->
              <div *ngIf="sepaMandatForm.get('iban').errors.invalidPays">Le pays correspondant au numéro IBAN n'est pas valide.</div>
              <div *ngIf="sepaMandatForm.get('iban').errors.invalidIbanFormat">Format de l'IBAN invalide.</div>
              <div *ngIf="sepaMandatForm.get('iban').errors.invalidIban">IBAN Invalide.</div>
            </mat-error>
          </mat-form-field>
        
        
          <mat-form-field class="no-hint" fxFlex="40" fxFlex.sm="40" fxFlex.xs="100">
            <mat-label>BIC</mat-label>
            <input matInput type="text" formControlName="bic" (focus)="searchBic()" (input)="formatBic($event)">
            <mat-spinner matSuffix *ngIf="isLoadingSearchBic" [diameter]="22" style="float: right; margin-left: 8px"></mat-spinner>
            <mat-error *ngIf="sepaMandatForm.get('bic').invalid && sepaMandatForm.get('bic').touched">
              <!-- <div *ngIf="sepaMandatForm.get('bic').errors.required">Le champ BIC est obligatoire.</div> -->
              <div *ngIf="sepaMandatForm.get('bic').errors.invalidBicFormat">Format du BIC invalide.</div>
            </mat-error>
          </mat-form-field>
      
        </div>
      

      <mat-form-field class="no-hint">
        <mat-label>Banque</mat-label>
        <input matInput type="text" formControlName="banqueMandat">
        <!-- <mat-error *ngIf="sepaMandatForm.get('banque').invalid && sepaMandatForm.get('banque').touched">
          <div *ngIf="sepaMandatForm.get('banque').errors.required">Le champ est obligatoire.</div>
        </mat-error> -->
      </mat-form-field>

      <!-- <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="30px" style="margin-block:7px;">
        <mat-label style="font-weight:500;">Paiement :</mat-label><br>
        <mat-radio-group class="no-hint" formControlName="paiement">
          <mat-radio-button value=1>Récurrent</mat-radio-button>
          <mat-radio-button value=2>Ponctuel</mat-radio-button>
        </mat-radio-group>
      </div> -->

      <!-- <div fxLayout="row wrap">
        <mat-form-field class="date no-hint" fxFlex="35" fxFlex.xs="100">
          <mat-label>Fait le</mat-label>
          <input matInput formControlName="dateSignature" [matDatepicker]="openFromDatePicker" placeholder="jj/mm/aaaa">
          <mat-datepicker-toggle matSuffix [for]="openFromDatePicker"></mat-datepicker-toggle>
          <mat-datepicker touchUi #openFromDatePicker disabled="false"></mat-datepicker> -->
          <!-- <mat-error *ngIf="sepaMandatForm.get('signatureDate').invalid && sepaMandatForm.get('signatureDate').touched">
            <div *ngIf="sepaMandatForm.get('signatureDate').errors.required">Le champ est obligatoire.</div>
          </mat-error> -->
        <!-- </mat-form-field> -->

      <mat-form-field class="no-hint" fxFlex="65" fxFlex.xs="100">
        <mat-label>Fait à</mat-label>
        <input matInput type="text" formControlName="lieuSignature">
        <!-- <mat-error *ngIf="sepaMandatForm.get('signatureLieu').invalid && sepaMandatForm.get('signatureLieu').touched">
          <div *ngIf="sepaMandatForm.get('signatureLieu').errors.required">Le champ est obligatoire.</div>
        </mat-error> -->
      </mat-form-field>
      <!-- </div> -->
      

      <mat-card-actions align="center">
      <button mat-raised-button color="primary" type="submit" [disabled]="sepaMandatForm.invalid || saving">
        Valider
        <mat-progress-spinner *ngIf="saving" mode="indeterminate" strokeWidth="2" diameter="24" color="accent-lighter"
          class="icon-loader"></mat-progress-spinner>
      </button>
    </mat-card-actions>

    </form>

  </mat-card>

</div>

<ng-template #loader>
  <app-loader class="margin"></app-loader>
</ng-template>
