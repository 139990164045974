import { RouterModule, Routes, Route } from '@angular/router';
import { NgModule } from '@angular/core';

import { AuthenticationGuard } from './guards/authentication.guard';
import { Role } from './models/user';

import { AboutComponent } from './components/_public/about/about.component';
import { HomeComponent } from './components/_public/home/home.component';
import { LoginComponent } from './components/_public/login/login.component';
import { LogoutComponent } from './components/_public/login/logout.component';
import { ForgottenPasswordComponent } from './components/_public/login/forgotten-password/forgotten-password.component';
import { UpdatePasswordComponent } from './components/_user/update-password/update-password.component';
import { NewsListComponent } from './components/_public/news';
import { PermissionGuard } from './guards/permission.guard';
import { SingleNewsComponent } from './components/_public/news/single-news/single-news.component';
import { UserEditComponent } from './components/_public/user-edit/user-edit.component';
import { PaymentBackComponent } from './components/_public/payment/back/payment-back.component';
import { NewsByCategoryComponent } from './components/_public/news/news-by-category/news-by-category.component';
import { MessagerieBoardComponent } from './components/_admin/messagerie-board/messagerie-board.component';
import { SingleContentComponent } from './components/_common/single-personalized-content/single-content.component';
import { PageNotFoundComponent } from './components/_common/page-not-found/page-not-found.component'

import { OIDC_LOGIN_CALLBACK_PATH, OIDC_LOGOUT_CALLBACK_PATH } from '@app/models/authentification-config';


const routes: Routes = [

  { path: 'home', component: HomeComponent },
  { path: '', pathMatch: 'full', component: HomeComponent },
  { path: 'go/:token', component: HomeComponent },
  {
    path: 'admin',
    loadChildren: () => import('./modules/admin.module').then(m => m.AdminModule),
    canLoad: [AuthenticationGuard],
    data: { roles: [Role.Admin], title: 'Administration' }
  },

  {
    path: 'account',
    loadChildren: () => import('./modules/user.module').then(m => m.UserModule),
    canLoad: [AuthenticationGuard],
    data: { roles: [Role.User], title: 'menu.account' }
  },

  { path: 'login', component: LoginComponent, data: { title: 'Connexion' } },
  { path: 'logout', component: LogoutComponent, data: { title: 'Déconnexion' } },
  { path: 'messagerie', component: MessagerieBoardComponent, data: { title: 'Messagerie' } },
  { path: 'forgot-password', component: ForgottenPasswordComponent },
  { path: 'reset-password', component: UpdatePasswordComponent, data: { title: 'Réinitialisation du mot de passe' } },
  // For connexion from Domino
  { path: 'dominoConnect/:token', component: LoginComponent, data: { dominoConnect: true, title: 'Redirection' } },
  // For OpenIdConnect Login 
  ...OIDC_LOGIN_CALLBACK_PATH
    .map(path => ({ path, component: LoginComponent, data: { title: 'Retour Connexion' } }) as Route),
  // For OpenIdConnect Logout
  ...OIDC_LOGOUT_CALLBACK_PATH
    .map(path => ({ path, component: LogoutComponent, data: { title: 'Retour Déconnexion' } }) as Route),

  {
    path: 'register', component: UserEditComponent, data: { title: 'Nouveau compte', perm: 'account_create' },
    canActivate: [PermissionGuard]
  },

  { path: 'news', component: NewsListComponent, data: { title: 'Actualités' } },
  { path: 'news/:id', component: SingleNewsComponent, data: { title: 'Actualité' } },
  { path: 'news/categories/:idCategories', component: NewsByCategoryComponent, data: { title: 'Actualité' } },
  { path: 'about', component: AboutComponent, data: { title: 'A propos' } },

  { path: 'paiement/retour/:type/:module', component: PaymentBackComponent },
  { path: 'paiement/retour/:type', component: PaymentBackComponent },
  //for the personalized content
  { path: 'content-page/:idContenuPerso', component: SingleContentComponent },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  // imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  imports: [RouterModule.forRoot(routes, {
    enableTracing: false // <-- debugging purposes only
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
