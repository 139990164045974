<div class="sticky-action-bar mat-elevation-z3" [style.left.px]="marginLeft" [class.loaded]="loaded">
  <div *ngIf="!platformService.isMobile">
    <button class="reply" mat-icon-button color='primary-darker' matTooltip="Retour" (click)="onBack()">
      <mat-icon>arrow_back</mat-icon>
    </button>
  </div>

  <h2 class="title">
    <mat-icon *ngIf="inAdmin">settings</mat-icon>
    <span [innerHTML]="title|trans "></span>
  </h2>

  <div class="buttons">
    <ng-content></ng-content>

    <button *ngIf="!noSave" [disabled]="saving || disabledSave" mat-raised-button color="accent"
      matTooltip="Enregistrer" (click)="save.emit()">
      <mat-icon *ngIf="!saving">save</mat-icon>
      <mat-progress-spinner *ngIf="saving" mode="indeterminate" strokeWidth="2" diameter="24" color="accent"
        class="icon-loader">
      </mat-progress-spinner>
    </button>
  </div>
</div>
