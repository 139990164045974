import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { EditionService } from '@app/services/edition.service';
import { Edition, Etat, ObjetEdition, ParamEtat, Entite } from '@app/models/edition';
import { MatDialog, MatDialogConfig} from '@angular/material/dialog';
import { SnackbarService, PlatformService } from '@app/services';


export class EditionForDownload extends Edition{
  pdfBlobs?: any = {};
  loadingPdfDownload?: any = {};
}

@Component({
  selector: 'app-user-editions',
  templateUrl: './edition-list.component.html',
  styleUrls: ['./edition-list.component.scss']
})
export class EditionListComponent implements OnInit {

  editions: EditionForDownload[];
  loading = true;

  @ViewChild('pdfPreviewDialog', { static: true }) pdfPreviewDialog: TemplateRef<any>;
  pdfPreviewBuffer: any;
  pdfPreviewLoaded = false;


  constructor(
    private editionService: EditionService,
    public platformService: PlatformService,
    public dialog: MatDialog,
    private snackbarService: SnackbarService,
  ) { }

  ngOnInit() {

    this.editionService.getCurrentFamilyAvailablesEditions()
      .subscribe(editions => {
        this.editions = editions as EditionForDownload[];
        this.loading = false;
      });
  }


  dowloadPDF(action: string, edition: EditionForDownload, entite: Entite) {

    if (edition.pdfBlobs && edition.pdfBlobs[entite.id]) {
      if (action === 'download') {
        this.savePDF(edition.pdfBlobs[entite.id].blob, edition.pdfBlobs[entite.id].filename);
      } else if (action === 'preview') {
        this.previewPDF(edition.pdfBlobs[entite.id].blob);
      }

    } else {
      edition.loadingPdfDownload = edition.loadingPdfDownload || {};
      edition.pdfBlobs = edition.pdfBlobs || {};

      edition.loadingPdfDownload[entite.id] = edition.loadingPdfDownload[entite.id] || { progress : 0,  action};

      this.editionService.getEdition(edition.idEtat, entite.id).subscribe(resp => {
        if (resp.type === 'dl_progress') {
          edition.loadingPdfDownload[entite.id].progress = resp.value as number;
        } else if (resp.type === 'response') {
          const blob: Blob = resp.value as Blob;
          const filename = resp.filename;

          if (blob.type === 'application/pdf') {
            edition.pdfBlobs[entite.id] = {blob, filename};
            if (action === 'download') {
              this.savePDF(blob, filename);
            } else if (action === 'preview') {
              this.previewPDF(blob);
            }
          } else if (blob.type === 'application/json') {
            this.displayErrorFromBlob(blob);
          } else {
            this.snackbarService.error('Erreur lors de l\'ouverture du fichier !<br>Type de réponse inconnu.');
            console.error(resp);
          }
          edition.loadingPdfDownload[entite.id] = null;
        }
      }, err => {
        edition.loadingPdfDownload[entite.id] = null;
        this.displayErrorFromBlob(err);
      });
    }
  }

  // @see https://stackoverflow.com/questions/52154874/angular-6-downloading-file-from-rest-api
  // => keep this reference, if needs IE support ...
  savePDF(blob: Blob, fileName: string) {
    const blobURL = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = blobURL;
    link.download = fileName;

    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

    setTimeout(() => {
      window.URL.revokeObjectURL(blobURL);
    }, 100);
  }

  displayErrorFromBlob(blob: Blob) {
    const reader = new FileReader();
    // This fires after the blob has been read/loaded.
    reader.onload = (e => { // NB : blob.text() is not yet very well supported, so ...
      const msgErreur = reader.result as string;
      console.warn('Erreur lors de l\'ouverture du fichier : ', msgErreur);
      this.snackbarService.error('Erreur lors de l\'ouverture du fichier : <br>' + msgErreur.replace(/\r/, '<br>'));
    });
    // Start reading the blob as text.
    reader.readAsText(blob);

  }

  previewPDF(blob: Blob) {
    this.pdfPreviewLoaded = false;
    this.pdfPreviewBuffer = null;
    const dialogRef = this.dialog.open(this.pdfPreviewDialog);
    this.platformService.adaptDialogToScreen(dialogRef);

    // Simple way, but not compatible with some current browsers, because blob.arrayBuffer() is not yet very well supported, so ...
    // blob.arrayBuffer().then(buf => this.pdfPreviewBuffer = buf);

    // An older way
    const reader = new FileReader();
    reader.onload = (e => this.pdfPreviewBuffer = reader.result as ArrayBuffer );
    reader.readAsArrayBuffer(blob);

  }

  callBackPdfLoad(event){
    this.pdfPreviewLoaded = true;
  }

}
