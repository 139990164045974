import { Component, OnInit, Inject } from '@angular/core';
import { Reservation, BaseReservationPresence, ReservationPresence } from '@app/models/reservation';
import { Consumer } from '@app/models/consumer';
import { PeriodeActivite } from '@app/models/periode';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { PlanningService } from '@app/services/planning.service';
import { HelpDialogComponent } from '@app/components/_common/help-dialog/help-dialog.component';
import { PlatformService } from '@app/services';
import { PresenceRepeatComponent, RepeatConfig } from '../presence-repeat/presence-repeat.component';
import { ActivityDetails, PlanningData } from '../planning-data';
import moment from 'moment';

export interface DetailedPresence extends BaseReservationPresence {
  reservation?: Reservation;
  consumer?: Consumer;
  activities?: ActivityDetails[];
}

export interface PlanningDetailsResult {
  action: 'new' | 'cancel' | 'cancel-multi' | 'recopy';

  presence?: DetailedPresence;
  config?: RepeatConfig;
}

interface DetailDialogData {
  date: string;
  planningData: PlanningData;
  presences: ReservationPresence[];
  selected: number;
}

@Component({
  selector: 'app-details-dialog',
  templateUrl: './details-dialog.component.html',
  styleUrls: ['./details-dialog.component.scss'],
})
export class PlanningDetailsDialogComponent implements OnInit {

  planningData: PlanningData = this.data.planningData;
  date = this.data.date;

  // @NB: Just realized this can be guessed from "data.currentReservation" (empty means readOnly)
  readOnly = !this.planningData.currentReservation;

  presences: DetailedPresence[];
  selectedPresence: DetailedPresence;

  addAction: string;

  title: any;
  animClass = '';

  editPath: string;
  canRecopy: boolean;
  disableCancel: string;
  periodeCancelLabel: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: DetailDialogData,
    private dialogRef: MatDialogRef<any>,
    private dialog: MatDialog,
    private planningService: PlanningService,
    public platformService: PlatformService
  ) { }

  ngOnInit() {
    this.presences = this.data.presences.map(pr => this.planningService.getPresenceDetails(pr, this.planningData));
    this.selectedPresence = this.presences.find(pr => (pr.id || pr.tmpId) === this.data.selected);

    if (this.readOnly) {
      this.addAction = 'reservation';
      // &&(this.planningData.currentPeriode.type === "mikado" && this.planningData.isEditableDateBySlot(this.date))
    } else if (!this.planningData.currentPeriode.disableCreation && this.planningData.isEditableDate(this.date)) {
      this.addAction = 'presence';
    }

    this.canRecopy = !this.readOnly && this.planningData.currentPeriode
      && !this.planningData.currentPeriode.disableCreation && !this.planningData.currentPeriode.disableRecopy && this.presences?.length > 0;

    this.sortPresences();
    this.refreshAttributes();
  }

  sortPresences() {
    if (this.presences) {
      this.presences.sort((pr1, pr2) => {
        // Simple hour (start - end) sort
        return pr1.startTime !== pr2.startTime ?
          (pr1.startTime > pr2.startTime ? 1 : -1) :
          (pr1.endTime !== pr2.endTime ? (pr1.endTime > pr2.endTime ? 1 : -1) : 0);
      });
    }
  }

  onClickPresence(presence: DetailedPresence) {
    this.selectedPresence = presence;

    this.animClass = 'slide-in';
    this.refreshAttributes();
  }

  returnToList() {
    this.selectedPresence = null;

    this.animClass = 'slide-out';
    this.refreshAttributes();
  }

  refreshAttributes() {
    this.editPath = null;
    this.disableCancel = null;
    this.periodeCancelLabel = null;

    if (this.selectedPresence) {
      const reservation = this.selectedPresence.reservation;

      // Show the "edit reservation" link if readOnly (not already on a reservation), if the Periode is not closed, and if not otherAccount
      // @TODO: add "periodeEditable" to Reservation (based on former check right here : enabled & date compliant)
      if (this.readOnly && !reservation.otherAccount && reservation.editable) {
        const idPeriode = reservation.idPeriode || 'c:' + reservation.idPortailPeriode;
        this.editPath = `/account/reservations/${idPeriode}-${reservation.idInscription}`;
      }

      if (this.selectedPresence.id && this.planningData.currentPeriode?.allowCancel) {
        this.disableCancel = this.getUncancelableCause(this.selectedPresence);
      }

      if (this.planningData.isCurrentPeriode(reservation.idPeriode, reservation.idPortailPeriode)) {
        this.periodeCancelLabel = this.planningData.currentPeriode.cancelLabel;
      }
    }

    this.title = this.buildTitle();
  }

  getUncancelableCause(presence: DetailedPresence) {
    if (presence.id && this.planningService.isCanceledOrDenied(presence)) {
      return 'cancel';
    }

    if (!this.planningData.isCurrentPeriode(presence.reservation.idPeriode, presence.reservation.idPortailPeriode)) {
      return 'other_periode';
    }

    if (!this.planningData.isCurrentInscription(presence.reservation.idInscription)) {
      return 'other_inscription';
    }

    if (presence.id && this.date < this.planningData.firstCancelableDate) {
      return 'too_late';
    }

    return null;
  }

  buildTitle() {
    const title: { text?: string, color?: string, size?: number } = {};

    if (this.selectedPresence) {

      if (this.selectedPresence.reservation.type === 'mikado') {
        return { text: `${this.selectedPresence.startTime} - ${this.selectedPresence.endTime}` };
      }

      // const rub = this.selectedPresence.rubrique;
      const actCount = this.selectedPresence.activities ? this.selectedPresence.activities.length : 0;
      // const act = actCount ? this.selectedPresence.activities[0] : null;

      title.text = this.selectedPresence.title;
      title.color = this.selectedPresence.color;

      if (actCount) {
        title.text += ` (+${actCount} activités)`;
      }

      // title.size = this.getLargeTextAdaptedSize(title.text, [40, 60, 80]);
    } else {
      title.text = 'Présences du ' + moment(this.data.date).format('dddd DD MMMM Y');
    }

    return title;
  }

  // Gives a font size depending on text length and tresholds given
  // i.e.: you give a 50 characters string, you get a "90%" font size
  // getLargeTextAdaptedSize(text: string, tresholds: number[], pad: number = 5) {
  //   let size = 100;

  //   for (const tresh of tresholds) {
  //     if (text.length > tresh) { size -= pad; }
  //   }

  //   return size;
  // }

  openActivityDetails(activity: PeriodeActivite) {
    const dialog = this.dialog.open(HelpDialogComponent, {
      maxWidth: 1000,
      data: {
        title: (activity.label || activity.name) + ', le ' + moment(this.selectedPresence.date).format('DD/MM/Y'),
        message: activity.description
      }
    });

    this.platformService.adaptDialogToScreen(dialog);
  }

  onClickNew() {
    this.dialogRef.close({ action: 'new' });
  }

  onClickRecopy() {
    this.openRepeatOptions().afterClosed().subscribe((result: RepeatConfig) => {
      if (result) {
        this.dialogRef.close({ action: 'recopy', config: result });
      }
    });
  }

  onClickCancel() {
    this.dialogRef.close({ action: 'cancel', presence: this.selectedPresence });
  }

  onClickCancelMulti() {
    this.openRepeatOptions(true).afterClosed().subscribe((result: RepeatConfig) => {
      if (result) {
        this.dialogRef.close({ action: 'cancel-multi', presence: this.selectedPresence, config: result });
      }
    });
  }

  openRepeatOptions(presetPresences?: boolean) {
    const repeatDialog = this.dialog.open(PresenceRepeatComponent, {
      data: {
        date: this.date,
        planningData: this.planningData,
        presetPresences
      }
    });

    this.platformService.adaptDialogToScreen(repeatDialog);

    return repeatDialog;
  }
}
