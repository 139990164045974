import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RegieConfig, PaymentType } from '@app/models/facturation';

type PaymentState = 'loading' | 'redirecting' | 'paying';

@Component({
  selector: 'app-payment-redirect-dialog',
  templateUrl: './payment-redirect-dialog.component.html',
  styleUrls: ['./payment-redirect-dialog.component.scss']
})
export class PaymentRedirectDialogComponent implements OnInit {

  regie: RegieConfig;
  state: PaymentState;
  typePaiement: PaymentType;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: { regie?: RegieConfig, typePaiement?: PaymentType, state?: PaymentState }
  ) {
    if (data) {
      this.state = data.state;
      this.regie = data.regie;
      this.typePaiement = data.typePaiement || data.regie?.typePaiement;
    }
  }

  ngOnInit(): void {
    if (!this.state) {
      this.setState(this.regie ? 'redirecting' : 'loading');
    }
  }

  setState(state: PaymentState) {
    this.state = state;
  }
}
