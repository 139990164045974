import { Component, OnInit, Input } from '@angular/core';
import { BoardCard } from '@app/models/board-card';
import { PlatformService } from '@app/services/platform.service';
import { Router } from '@angular/router';
import { AuthenticationService, SnackbarService, TypeSnackbar } from '@app/services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-board',
  templateUrl: './board.component.html',
  styleUrls: ['./board.component.scss']
})
export class BoardComponent implements OnInit {

  @Input() boardCards: BoardCard[];
  @Input() compact = false;
  @Input() previewMode = false;
  @Input() variables: object = {};

  headerStyleParts = ['borderColor', 'backgroundColor', 'borderRadius'];

  constructor(
    public platformService: PlatformService,
    private router: Router,
    private snackbarService: SnackbarService,
    private authService: AuthenticationService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
  }

  onClickCard(card: BoardCard) {
    if (card.disabledCause) {
      this.snackbarService.info(this.translateService.instant(card.disabledCause));
      return;
    }

    if (this.previewMode) {
      this.snackbarService.open({
        message: 'Lien inactif pendant la configuration',
        type: TypeSnackbar.info,
        textButton: 'Ok'
      });

      return false;
    }

    if (card.component) {
      this.router.navigate([`/account/${card.component}`]);
    }
  }
}
