import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DATE_FORMAT } from '@app/services/planning.service';
import moment from 'moment';

interface Month {
  title: string;
  fakeDays: number[]; // fake array to loop the right amount of weekdays (first line)
  firstLineWeek: Week;
  weeks: Week[];
}

interface Week {
  number?: string;
  days: DateAndDay[];
}

interface DateAndDay {
  day: string; // 'D'
  date: string; // 'YYYY-MM-DD'
}

@Component({
  selector: 'app-simple-date-calendar',
  templateUrl: './simple-date-calendar.component.html',
  styleUrls: ['./simple-date-calendar.component.scss']
})
export class SimpleDateCalendarComponent implements OnInit, OnChanges {

  @Input() start: string;
  @Input() end: string;

  @Input() highlightDates: string[] = [];
  highlightIndexed: { [key: string]: boolean };

  months: Month[] = [];

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.start || changes.end) {
      this.createCalendar();
    }

    if (changes.highlightDates) {
      this.highlightIndexed = {};
      this.highlightDates.forEach(v => this.highlightIndexed[v] = true);
    }
  }

  createCalendar() {
    let roll = moment(this.start);
    const stop = moment(this.end).format(DATE_FORMAT);

    roll.startOf('month');

    this.months = [];

    while (roll.format(DATE_FORMAT) <= stop) {
      const month = { title: roll.format('MMMM YYYY') } as Month;
      const monthFormatted = roll.format('YYYY-MM');

      const startWeekDay = roll.isoWeekday();
      month.fakeDays = new Array(startWeekDay - 1);
      month.firstLineWeek = { days: [] };

      let day = 1;

      for (let i = startWeekDay; i <= 7; i++) {
        month.firstLineWeek.days.push({ day: day + '', date: monthFormatted + '-' + (day + '').padStart(2, '0') });
        day++;
      }

      if (month.firstLineWeek) {
        month.firstLineWeek.number = moment(month.firstLineWeek.days[0].date).format('ww');
      }

      month.weeks = [];
      let weekdays: DateAndDay[] = [];
      for (let i = day; i <= roll.daysInMonth(); i++) {
        weekdays.push({ day: i + '', date: monthFormatted + '-' + (i + '').padStart(2, '0') });

        if ((i - (7 - startWeekDay + 1)) % 7 === 0) {
          month.weeks.push({ days: weekdays, number: moment(weekdays[0].date).format('ww') });
          weekdays = [];
        }
      }

      if (weekdays.length) {
        month.weeks.push({ days: weekdays, number: moment(weekdays[0].date).format('ww') });
      }

      this.months.push(month);
      roll.add('1', 'month').startOf('month');
    }
  }
}
