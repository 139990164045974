import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { SnackbarService } from '@app/services';
import { PermissionService } from '@app/services/permission.service';
import { Observable, of } from 'rxjs';
import { map, tap, filter } from 'rxjs/operators';

@Injectable()
export class PermissionGuard implements CanActivate {

  constructor(
    private permService: PermissionService,
    private snackbarServive: SnackbarService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.checkAccess(route).pipe(
      tap(access => {
        if (!access) {
          this.snackbarServive.open({ message: 'Cette fonctionnalité n\'est pas activée', type: 'error', duration: 5000 })
        }
      })
    );
  }

  checkAccess(route: ActivatedRouteSnapshot): Observable<boolean> {

    // Missing required route data argument : "perm"
    if (!(route.data && route.data.perm)) {
      return of(true);
    }

    // The permission should be already loaded but not always, so we must return an Observable ...
    return this.permService.permission$.pipe(
      map(perm => perm && perm[route.data.perm]),
      // tap(accessGranted => !accessGranted && this.router.navigate(['/unauthorized']))
    );
  }
}
