import { Component, OnInit } from '@angular/core';
import { FlashService } from '@app/services/flash.service';

interface PaymentStatus {
  datetime?: Date;
  done?: boolean;
  ref?: string;
  status?: string;
}

@Component({
  selector: 'app-payment-status',
  templateUrl: './payment-status.component.html',
  styleUrls: ['./payment-status.component.scss']
})
export class PaymentStatusComponent implements OnInit {

  paymentStatus: PaymentStatus;

  isOK: boolean;
  isTest: boolean;

  constructor(
    private flashService: FlashService
  ) { }

  ngOnInit(): void {
    this.paymentStatus = this.flashService.getFlash('payment_return_data', true);

    if (this.paymentStatus) {
      this.isOK = this.paymentStatus.status !== 'annule' && this.paymentStatus.status !== 'refuse';
      this.isTest = this.paymentStatus.ref.toLowerCase().startsWith('test');
    }
  }
}
