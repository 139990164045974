<mat-card class="conteneur">
  <mat-card-header>
    <mat-card-title>Réinitialisation de votre mot de passe </mat-card-title>
    <mat-icon mat-card-avatar class="icon">vpn_key</mat-icon>
  </mat-card-header>
  <mat-card-content>

    <!-- <mat-toolbar fxLayout="row" color="primary" fxLayoutAlign="space-between center" mat-dialog-title cdkDrag
  cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle [cdkDragDisabled]="platformService.isMobile"
  style="cursor: move;">
  <button class="close-dialog" mat-icon-button [mat-dialog-close]>
    <mat-icon>close</mat-icon>
  </button>
  <span fxFlex="85" style="text-align: center; margin:0 10px 0 10px;">
    Mot de passe oublié ?
  </span>
</mat-toolbar> -->
    <form [formGroup]="forgottenPassForm" (ngSubmit)="onSubmit()">

      <div class="content" fxLayout="column" *ngIf="response==''">

        <div class="mat-h3" style="margin-bottom: 10px;">
          Veuillez saisir votre adresse e-mail
        </div>

        <mat-form-field style="width: 100%;">
          <mat-label>Adresse email</mat-label>
          <input type="text" matInput formControlName="email" required name="email">
          <mat-error>
            Email incorrect
          </mat-error>
        </mat-form-field>
      
      </div>

      <div class="content" fxLayout="column" *ngIf="response!=''" [innerHTML]="response | safe:'html'" style="margin-bottom: 20px;"></div>


      <mat-card-actions fxLayout="column" fxLayoutAlign="space-between center" fxLayoutGap="10px">

        <button mat-raised-button type="submit" class="submit-bt" (click)="onSubmit()" color="primary" 
          *ngIf="response==''"
          [disabled]="isLoading">
          <span>Valider</span>
          <app-loader size="1" class="" *ngIf="isLoading"></app-loader> 
        </button>

        <a  href="#" routerLink="/login" *ngIf="response==''" >Retour </a>
        <button type="button"  routerLink="/login" *ngIf="response!=''" mat-raised-button color="primary" (click)="$event.preventDefault();">Retour </button>
          
      </mat-card-actions>
    </form>
  </mat-card-content>

</mat-card>