import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigTicket } from '@app/models/reservation-ticket';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

export interface PanierRegieData {
  idRegie: number;
  nomRegie: string;
  typePaiement: string;
  pausePaiement?: boolean;
  pausePaiementStart?: string;
  pausePaiementEnd?: string;
  pausePaiementMessage?: string;
  inscriptions: PanierInscriptionData[];

  total?: number; // for saisie / send panier
}

export interface PanierInscriptionData {
  idInscription: number;
  nomUsager?: string | string[];
  rubriquesFacturation: PanierRubriqueData[];
}

export interface PanierRubriqueData {
  id: number;
  designation: string;
  tarif: number;

  quantite?: number; // for saisie / send panier
}

@Injectable({
  providedIn: 'root'
})
export class TicketService {

  configUrl = 'conf/ticket';

  constructor(
    private http: HttpClient
  ) { }

  getConfigForUser() {
    return this.http.get<ConfigTicket>(this.configUrl);
  }

  getConfigForAdmin() {
    return this.http.get<ConfigTicket>('conf/admin/ticket');
  }

  saveConfig(config: any) {
    return this.http.put(this.configUrl, { config }, httpOptions);
  }

  getReleveTicket(idFamille: number) {
    return this.http.get(`familles/${idFamille}/tickets/releve`);
  }

  getPanierData(idFamille: number) {
    return this.http.get<PanierRegieData[]>(`/familles/${idFamille}/tickets/panier`);
    // return of(fakePanierData);
  }

  sendBuyRequest(family: number, regie: PanierRegieData) {
    return this.http.post(`/familles/${family}/tickets/panier`, regie);
  }
}
