import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@env/environment';

/**
 * Prefixes all requests with `environment.apiUrl`.
 */
@Injectable()
export class ApiPrefixInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!/^(http|https):/i.test(request.url) // start with 'http:' or 'https:'
      && !/^(\/)?(assets)\//i.test(request.url) // start with '/assets/' or 'assets/'
      && !/^(\/)?(theme)\//i.test(request.url) // start with'/theme/' or 'theme/'
    ) {
      if (request.url.startsWith('/') && environment.apiUrl.endsWith('/')) {
        request = request.clone({ url: environment.apiUrl + request.url.substring(1) });
      } else {
        request = request.clone({ url: environment.apiUrl + request.url });
      }
    }
    return next.handle(request);
  }
}
