import {
  transition,
  trigger,
  query,
  style,
  animate,
  group,
  animateChild
} from '@angular/animations';


export const routesAnimation = [
  trigger('routeAnimations', [
    transition('* => *', [
      query('.withMenu :enter, .withMenu :leave', style({ position: 'fixed', width: 'calc(100% - 270px)' }), { optional: true }),
      query('.withoutMenu :enter,.withoutMenu :leave', style({ position: 'fixed', width: '100%' }), { optional: true }),
      group([
        query(':leave', [
          style({ transform: 'translateX(0%)' }),
          animate('250ms ease-out', style({ transform: 'translateX(100%)' }))
        ], { optional: true }),
        query(':enter', [
          style({ transform: 'translateX(-100%)' }),
          animate('300ms ease-in-out', style({ transform: 'translateX(0%)' }))
        ], { optional: true }),
      ])
    ])
  ]),

];
