import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { MediaObserver } from '@angular/flex-layout';
import { ViewportRuler } from '@angular/cdk/scrolling';
import { MenuService } from '@app/services/menu.service';
import { Observable, merge, fromEvent, interval, BehaviorSubject } from 'rxjs';
import { debounceTime, filter, map, startWith, take, takeUntil } from 'rxjs/operators';
import { MatDialogRef } from '@angular/material/dialog';


@Injectable({ providedIn: 'root' })
export class PlatformService {

  mediaAlias$ = this.mediaObserver.asObservable().pipe(
    map(changes => changes[0].mqAlias)
  );

  constructor(
    public mediaObserver: MediaObserver,
    private menuService: MenuService,
    @Inject(PLATFORM_ID) protected platformId: object,
    @Inject(DOCUMENT) protected document: any,
    private viewportRuler: ViewportRuler,
  ) {
    // @TODO: take a look at it ?
    // this.watchForDarkMode();

    // watchForDarkMode() {
    //   window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => {
    //     this.darkMode$.next(!!e.matches);
    //   });
    // }
  }

  mainWidth(): number {
    const mainContent = this.menuService.mainContent.getElementRef().nativeElement;
    return (this.isBrowser && mainContent) ? mainContent.offsetWidth : 0;
  }

  get mainWidth$(): Observable<number> {
    return merge(
      fromEvent(window, 'resize').pipe(debounceTime(100)),
      this.menuService.sidenav.openedChange
    ).pipe(
      map(() => this.mainWidth()),
    );
  }


  mainHeight(): number {
    const mainContent = this.menuService.mainContent.getElementRef().nativeElement;
    return (this.isBrowser && mainContent) ? mainContent.offsetHeight : 0;
  }

  get mainHeight$(): Observable<number> {
    return fromEvent(window, 'resize')
      .pipe(
        startWith(() => this.mainHeight()),
        debounceTime(100),
        map(() => this.mainHeight())
      );

  }

  get viewportSize(): Readonly<{ width: number, height: number }> {
    return this.viewportRuler.getViewportSize();
  }

  get isMobile(): boolean {
    return this.mediaObserver.isActive('xs') || this.mediaObserver.isActive('sm');
  }

  get isSmall(): boolean {
    return this.mediaObserver.isActive('xs');
  }

  get isMediumSmall(): boolean {
    return this.mediaObserver.isActive('sm');
  }

  get isMedium(): boolean {
    return this.mediaObserver.isActive('md');
  }

  get isLarge(): boolean {
    return this.mediaObserver.isActive('lg');
  }

  private get isBrowser(): boolean {
    return isPlatformBrowser(this.platformId) && this.document;
  }

  adaptDialogToScreen(dialog: MatDialogRef<any>, toggleClasses = 'full-screen-dialog') {
    this.mediaObserver.asObservable().pipe(
      takeUntil(dialog.afterClosed())
    ).subscribe(changes => {
      this.isMobile ? dialog.addPanelClass(toggleClasses) : dialog.removePanelClass(toggleClasses);
    });
  }

  watchForTabClose(tabRef: Window) {
    // Watch for tab close (only way for Payfip to know when the user returns ...)
    return interval(300).pipe(
      map(_ => tabRef.closed),
      filter(closed => !!closed),
      take(1)
    );
  }
}
