<mat-drawer-container autosize>
  <mat-drawer-content style="width:100%;">

    <ng-template appTopbarActions>
      <button mat-mini-fab color="accent" (click)="toggleOptions()" matTooltip="Options d'affichage">
        <mat-icon>settings</mat-icon>
      </button>
    </ng-template>

    <div class="conteneur thin-scrollbar" *ngIf="preinscriptions; else loader">

      <mat-card *ngIf="config.infoMessageList" style="margin: 5px;" [portailCustomStyle]="config.styleCardInfoList">
        <div [innerHtml]="config.infoMessageList | safe"></div>
      </mat-card>

      <div class="preinscription-list" *ngIf="isLoading">
        <mat-card class="preinscription-card" *ngFor="let preinscription of preinscriptionsFiltered">
          <mat-card-header class="card-header" fxLayout="row" fxLayoutAlign="space-between center">
            <mat-card-title fxLayout="row" fxLayoutAlign="start center">
              <app-icon *ngIf="preinscription.idEnfant" style="margin-right: 5px;" type="do" icon="pre-inscription">
              </app-icon>
              <span>{{ preinscription.nom }} {{ preinscription.prenom }}</span>
            </mat-card-title>
            <button *ngIf="permToEdit && isEditable(preinscription)" mat-mini-fab color="primary"
              [matMenuTriggerFor]="menu">
              <mat-icon>more_vert</mat-icon>
            </button>
          </mat-card-header>

          <mat-menu #menu="matMenu" xPosition="before">
            <button mat-menu-item (click)="onClickEdit(preinscription)">
              <mat-icon color="primary">edit
              </mat-icon>
              {{ 'action.edit'|trans }}
            </button>
            <!-- <button mat-menu-item color="primary">
          <mat-icon color="warn">clear</mat-icon>
          Supprimer
        </button> -->
          </mat-menu>

          <mat-card-content>
            <div>
              <label>{{ 'cogito.annee_scolaire'|trans }} : </label>
              <span>{{ preinscription.anneeScolaire.designation }}</span>
            </div>

            <div>
              <label>{{ 'cogito.niveau_scolaire'|trans }} : </label>
              <span>{{ preinscription.niveauScolaireName }}</span>
            </div>

            <div>
              <label>{{ 'cogito.secteur_scolaire'|trans }} : </label>
              <span>{{ preinscription.secteurScolaireName }}</span>
            </div>

            <div>
              <label>{{ 'cogito.ecole'|trans }} : </label>
              <span>{{ preinscription.ecoleName }}</span>
            </div>

            <div>
              <label>{{ 'cogito.date_demande'|trans }} : </label>
              <span>{{ preinscription.dateDemande ? (preinscription.dateDemande|date) : '' }}</span>
            </div>

            <div class="status">
              <label>{{ 'cogito.status'|trans }} : </label>
              <span>
                {{ preinscription.decision }}

                <app-icon *ngIf="preinscription.decision === 'Refusée'" type="fa" icon="times-circle"
                  class="icon-decision warn"></app-icon>
                <app-icon *ngIf="preinscription.decision === 'Acceptée'" type="fa" icon="check-circle"
                  class="icon-decision primary"></app-icon>
                <!-- <app-icon *ngIf="preinscription.decision === 'Abandon'" type="fa" icon="trash" class="icon-decision">
                </app-icon> -->
                <app-icon *ngIf="preinscription.decision === 'En attente'" type="fa" icon="question-circle"
                  class="icon-decision accent"></app-icon>
              </span>
            </div>
          </mat-card-content>
        </mat-card>

      </div>

      <mat-card *ngIf="permToCreate && inscriptionsActive && isLoading" class="actions-card card-add">
        <mat-card-header class="card-header" fxLayout="row" fxLayoutAlign="center center">
          <mat-card-title fxLayout="row" fxLayoutAlign="center center">
            {{ 'cogito.new_preinscription'|trans }}
          </mat-card-title>
        </mat-card-header>
        <mat-card-content style="margin-top: 20px;" fxLayout="row" fxLayoutAlign="center center">
          <button mat-fab color="primary" matTooltip="{{ 'cogito.new_preinscription'|trans }}"
            routerLink="/account/cogito-preinscriptions/new">
            <mat-icon>add</mat-icon>
          </button>
        </mat-card-content>
      </mat-card>

      <div *ngIf="!preinscriptions.length && !permToCreate">
        <h2 style="text-align: center;">{{ 'cogito.no_preinscription'|trans }}</h2>
      </div>

    </div>

  </mat-drawer-content>

  <!-- Options d'affichage -->
  <mat-drawer id="previewDrawer" class="mat-elevation-z4 thin-scrollbar" position="end" [(opened)]="drawerOpened">
    <mat-toolbar fxLayout="row" fxLayoutAlign="space-between center" class="preview-header" color='primary'>
      <button mat-icon-button (click)="closeDrawer()">
        <mat-icon>close</mat-icon>
      </button>
      <span>Options d'affichage</span>
      <span></span>
    </mat-toolbar>

    <div fxLayout="column" style="margin: 5px; padding: 5px;">
      <mat-slide-toggle [(ngModel)]="showAccepted" (ngModelChange)="toggleShowAccepted($event)" class="slideToggle">
        <span>Afficher les pré-inscriptions acceptées
          (<app-icon type="fa" icon="check-circle" class="icon-decision primary"></app-icon>).
        </span>
      </mat-slide-toggle>
      <mat-slide-toggle [(ngModel)]="showRefused" (ngModelChange)="toggleShowRefused($event)" class="slideToggle">
        <span>Afficher les pré-inscriptions refusées (<app-icon type="fa" icon="times-circle"
            class="icon-decision warn">
          </app-icon>).</span>
      </mat-slide-toggle>
      <mat-slide-toggle [(ngModel)]="showWaiting" (ngModelChange)="toggleShowWaiting($event)" class="slideToggle">
        <span>Afficher les pré-inscriptions en attente
          (<app-icon type="fa" icon="question-circle" class="icon-decision accent"></app-icon>).
        </span>
      </mat-slide-toggle>
    </div>

  </mat-drawer>
</mat-drawer-container>

<ng-template #loader>
  <app-loader [message]="'Chargement des données...'" class="margin"></app-loader>
</ng-template>
