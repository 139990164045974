import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AssMat, AssmatAvailability } from '@app/models/ass-mat';
import { ApiCrudService } from '.';
import moment from 'moment';
import { ContratAccueilMentalo, EnfantMentalo } from '@app/models/contrat-accueil';
import { AnimationReunion, AnimationReunionPortailEnfantMentalo } from '@app/models/animationReunion';

@Injectable({
  providedIn: 'root'
})
export class AssmatService extends ApiCrudService<AssMat>{

  url = 'assmats';
  formAssmatUrl = 'assmats/#idAssmat#/form/#formPart#';
  formContratAccueilUrl = 'assmats/#idAssmat#/contratAccueil/#idContrat#/form/#formPart#';

  constructor(
    protected http: HttpClient
  ) {
    super()
  }

  getAssmatById(id: number) {
    return this.http.get(`${this.url}/${id}`)
  }

  getAssmatVariables(idAssmat: number) {
    return this.http.get(`/assmats/${idAssmat}/summary`);
  }

  getFormData(id, part?) {
    part = part ? part : '';
    const url = this.formAssmatUrl.replace('#idAssmat#', id).replace('#formPart#', part);
    return this.http.get(url, this.httpOptions);
  }

  getFormDataContratAccueilMentalo(idAssmat, idContrat, part?) {
    part = part ? part : '';
    const url = this.formContratAccueilUrl.replace('#idAssmat#', idAssmat).replace('#idContrat#', idContrat).replace('#formPart#', part);

    return this.http.get(url, this.httpOptions);
  }

  update(data: AssMat | any, id?: number, part?: string) {
    part = part ? part : '';
    const url = this.url + (id ? '/' + id : '') + '/' + part;

    return this.http.put(url, data, this.httpOptions);
  }

  updateContratAccueil(data: any, idAssmat: number, id?: number, part?: string) {
    part = part ? part : '';
    const url = this.url + '/' + idAssmat + (id ? '/' + id : '') + (part ? '/' + part : '');

    return this.http.put(url, data, this.httpOptions);
  }

  createContratAccueil(data: any, idAssmat: number) {
    const url = this.url + '/' + idAssmat + '/contratAccueilMentalo';
    return this.http.post(url, data);
  }

  getAvailability(idAssmat: number) {
    return this.http.get(`${this.url}/${idAssmat}/disponibilites`)
  }

  saveAvailability(idAssmat: number, listAvailability: AssmatAvailability[]) {
    return this.http.put(`${this.url}/${idAssmat}/disponibilites`, listAvailability, this.httpOptions);
  }

  getAgrementsSecondaireByIdAssmat(idAssmat: number) {
    return this.http.get(`${this.url}/${idAssmat}/agrementsSecondaire`)
  }

  getEnfantsMentaloByIdAssMat(idAssmat: number) {
    return this.http.get(`${this.url}/${idAssmat}/enfantsMentalo`);
  }

  getFamillesMentaloByIdAssMat(idAssmat: number) {
    return this.http.get(`${this.url}/${idAssmat}/famillesMentalo`);
  }

  getContratsAccueilByIdAssMat(idAssmat: number) {
    return this.http.get(`${this.url}/${idAssmat}/contratAccueilMentalo`);
  }

  getEtablissementsEnCoursByIdAssmat(idAssmat: number) {
    return this.http.get(`${this.url}/${idAssmat}/etablissementsMentalo`);
  }

  getTypeEtablissementsEnCoursByIdAssmat(idAssmat: number) {
    return this.http.get(`${this.url}/${idAssmat}/typeEtabsEnCours`);
  }

  getAnimationsByIdAssmat(idAssmat: number) {
    return this.http.get(`${this.url}/${idAssmat}/animations`);
  }

  getReunionsByIdAssmat(idAssmat: number) {
    return this.http.get(`${this.url}/${idAssmat}/reunions`);
  }

  inscriptionAnimationReunion(idAssmat: number, data: any) {
    return this.http.put(`${this.url}/${idAssmat}/animationsReunions/inscription`, data);
  }

  desinscriptionAnimationReunion(idAssmat: number, data: any) {
    return this.http.put(`${this.url}/${idAssmat}/animationsReunions/desinscription`, data);
  }

  desinscriptionChildAnimationReunion(idAssmat: number, idEnfantMentalo: number, data: any) {
    return this.http.put(`${this.url}/${idAssmat}/animationsReunions/${idEnfantMentalo}/desinscription`, data);
  }

  inscriptionChildAnimationReunion(idAssmat: number, data: any) {
    return this.http.post(`${this.url}/${idAssmat}/animationsReunions/enfants/inscription`, data);
  }

  //Fonctions utilisées dans les composants animation et reunion

  checkAssmatRegistered(listItem: AnimationReunion[], idAssmat: number) {
    listItem.forEach(item => {
      if (item.tabAssmats) {
        item.tabAssmats.forEach(assmat => {
          if (assmat.idParticipant === idAssmat) {
            item.assmatIsRegistered = true;
          };
        })
      }
      if (item.portailInscription && item.portailInscription.idAssmat === idAssmat) {
        item.assmatIsRegistered = true;
      }
    })
  }

  checkChildRegistered(listChild: EnfantMentalo[], listItem: AnimationReunion[]) {
    listItem.forEach(item => {
      item.listEnfantMentaloRegisteredByAssmat = [];

      if (item.portailInscription) {
        item.portailInscription._EnfantsMentalo.forEach((childRegistered: AnimationReunionPortailEnfantMentalo) => {
          listChild.forEach(child => {
            if (childRegistered.idEnfantMentalo === child.id) {
              if (!this.childExists(child.id, item.listEnfantMentaloRegisteredByAssmat)) {
                let EnfantAnimationReunion = {
                  child,
                  idPortailAnimationReunionEnfant: childRegistered.idPortailAnimationReunionEnfant,
                  typeDemande: childRegistered.typeDemande,
                  decision: childRegistered.decision,
                  motifRefus: childRegistered.motifRefus
                }
                item.listEnfantMentaloRegisteredByAssmat.push(EnfantAnimationReunion);
              }
            }
          })
        })
      }
      if (item.tabEnfantsMentalo) {
        item.tabEnfantsMentalo.forEach(childRegistered => {
          listChild.forEach(child => {
            if (childRegistered.idEnfantMentalo === child.id) {
              if (!this.childExists(child.id, item.listEnfantMentaloRegisteredByAssmat)) {
                item.listEnfantMentaloRegisteredByAssmat.push({ child });
              }
            }
          })
        })
      }
    })
  }

  getChildListWithContratEnable(contratsList: ContratAccueilMentalo[], childList: EnfantMentalo[], dateAnimationreunion: string | Date) {
    let listChildWithContratEnabled = [];
    contratsList = contratsList.filter(contrat => this.dateInPeriode(contrat.dateDebut, contrat.dateFin, dateAnimationreunion));

    contratsList.forEach(contrat => {
      childList.forEach(children => {
        if (contrat.idEnfantMentalo === children.id) {
          listChildWithContratEnabled.push(children);
        }
      })
    });
    return listChildWithContratEnabled;
  }

  // checkChilRegisteredInAnimation(listChild: EnfantMentalo[], listItem: Reunion[] | Animation[]) {
  //   listItem.forEach(item => {
  //     item.listEnfantMentaloRegisteredByAssmat = [];
  //     item.tabEnfantsMentalo.forEach(childRegistered => {
  //       listChild.forEach(childAssmat => {
  //         if (childRegistered.idParticipant === childAssmat.id || childRegistered.idEnfantMentalo === childAssmat.id) {
  //           if (!this.childExists(childAssmat.id, item.listEnfantMentaloRegisteredByAssmat)) {
  //             item.listEnfantMentaloRegisteredByAssmat.push(childAssmat);
  //           }
  //         }
  //       })
  //     })
  //   })
  // }

  // checkChildRegisteredInInscriptionAnimation(listChild: EnfantMentalo[], listItem: Animation[] | Reunion[]) {
  //   listItem.forEach(item => {
  //     item.listEnfantMentaloRegisteredByAssmat = [];
  //     item.portailAnimation._EnfantsMentalo.forEach(childRegistered => {
  //       listChild.forEach(child => {
  //         if (childRegistered.idEnfantMentalo === child.id) {
  //           if (!this.childExists(child.id, item.listEnfantMentaloRegisteredByAssmat)) {
  //             item.listEnfantMentaloRegisteredByAssmat.push(child);
  //           }
  //         }
  //       })
  //     })
  //   })
  // }

  childExists(idChild, listChild) {
    return listChild.some(function (el) {
      return el.child.id === idChild;
    });
  }

  sortByDate(listItem: AnimationReunion[]) {
    return listItem.sort((a, b) => {
      if (moment(a.date) < moment(b.date)) return -1;
      if (moment(a.date) > moment(b.date)) return 1;
      if (a.heureDebut < b.heureDebut) return -1;
      if (a.heureDebut > b.heureDebut) return 1;
      return 0;
    })
  }


  dateInPeriode(startPeriodeRef: string | Date, endPeriodeRef: string | Date, searchDate: string | Date) {

    if (startPeriodeRef && endPeriodeRef) {
      if (moment(searchDate) >= moment(startPeriodeRef) && moment(searchDate) <= moment(endPeriodeRef).add(1, "day")) {
        return true;
      }
    }

    if (startPeriodeRef && !endPeriodeRef) {
      if (moment(searchDate) >= moment(startPeriodeRef)) {
        return true;
      }
    }

    if (!startPeriodeRef && endPeriodeRef) {
      if (moment(searchDate) <= moment(endPeriodeRef).add(1, "day")) {
        return true;
      }
    }

    if (!startPeriodeRef && !endPeriodeRef) {
      return true;
    }

    return false;
  }
}

