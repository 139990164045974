import { Component, OnInit } from '@angular/core';
import { News } from '@app/models/news';
import { FamilyService, NewsService } from '@app/services';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Subject, of } from 'rxjs';

@Component({
  selector: 'app-news-by-category',
  templateUrl: './news-by-category.component.html',
  styleUrls: ['./news-by-category.component.scss']
})
export class NewsByCategoryComponent implements OnInit {

  newsList: News[];
  loading: boolean;
  destroy$ = new Subject();

  constructor(
    private newsService: NewsService,
    private familyService: FamilyService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.familyService.currentFamilyChange$.pipe(
      takeUntil(this.destroy$),
      switchMap(fam => fam ? this.familyService.getFamilyVariables(fam.id) : of({}))
    ).subscribe((vars: any) => {
      this.loading = true;
      const url = this.route.snapshot.paramMap;
      this.filterNews(url).subscribe((data) => {
        this.newsList = data.filter(news => {
          let etbAcc = []
          try {
            etbAcc = JSON.parse(news.etablissementAccueil)
          } catch (error) {
            // Non rien, c'est juste pour eviter un plantage 
            // sur le JSON.Parse si le JSON n'est pas valide ... 
            // mais comme 'etbAcc' est déjà initialisé avec un tableau vide , on ne fait rien de spécial
          }
          return !news.isPrivate || !etbAcc.length || etbAcc.filter(etb => vars.etablissementAccueil?.find(
            etab => etab.idEtablissement == etb.etablissmentId && etb.accueils.some(acc => etab.accueils.includes(acc))
          )).length
        });
        this.loading = false;
      });
    });
  }

  filterNews(url: ParamMap) {
    let cat = url.get('idCategories').split('-').toString();
    const news = this.newsService.getNewsByCategory(cat);
    return news
  }

}
