<div class="message-container">
  <div class="sender" *ngIf="message.idEtablissementExpediteur == 0; else etabName">
    <ng-container
      *ngIf="message.idPortailUtilisateurRepondant === 0 && message.idPortailUtilisateur == userId; else anotherUser">
      Vous
    </ng-container>
  </div>
  <ng-template #anotherUser>
    <div class="sender" *ngIf="message?._userInfo?.nomUser; else etabName">
      {{ message._userInfo.nomUser }} {{ message._userInfo.prenomUser }}
    </div>
  </ng-template>
  <ng-template #etabName>
    <div class="sender">
      {{ message._etablissementNom }}
    </div>
  </ng-template>


  <div class="chat-bubble-container"
    [ngClass]="{ 'unread': message.messageLu, 'message-user': message.idEtablissementExpediteur == 0, 'message-etab': message.idEtablissementExpediteur != 0 }">
    <span [innerHTML]="message.contenu | highlight: search"
      style="word-wrap: break-word; white-space: pre-line;"></span>
    <div>
      <div class="files">
        <div class="file" color="accent-lighter" (click)="openDocDetails(file)"
          *ngFor="let file of (message.showAllFiles ? message._messagePJ : message._messagePJ.slice(0, 3))">
          <app-icon *ngIf="file.type.includes('image');else pdfIcon" type='fa' size="50" icon="file-image">
          </app-icon>
          <ng-template #pdfIcon>
            <app-icon *ngIf="file.type.includes('pdf');else fileIcon" type='fa' size="50" icon="file-pdf"></app-icon>
          </ng-template>
          <ng-template #fileIcon>
            <app-icon type='fa' size="100" icon="file"></app-icon>
          </ng-template>
          <div class="file-download" (click)="$event.stopPropagation(); downloadFile(file)">
            <div class="fas fa-download"></div>
          </div>
          <div class="file-info">
            <span>{{file.nomFichier }}</span>
          </div>
        </div>
        <div class="center file" *ngIf="message._messagePJ.length > 3"
          (click)="message.showAllFiles = !message.showAllFiles">
          <p *ngIf="!message.showAllFiles">+ {{message._messagePJ.length - 3}} plus</p>
          <p *ngIf="message.showAllFiles">montrer moins</p>
        </div>
      </div>
    </div>
    <span class="chat-date">
      <span>{{ message.dateHeureEnvoi | date:'medium'}}</span>
      <!--check-mark for the user -->
      <span class="check-mark" *ngIf="message.idPortailUtilisateur == userId && message.idEtablissementExpediteur == 0"
        [ngClass]="{'unread': !message.luPerAdmin,'read': message.luPerAdmin}"
        [matTooltip]="(message.idPortailUtilisateur && message.luPerAdmin) ?  'lu'   : 'Non lu'">
        <i class="fas fa-check"></i>
      </span>
      <!--check-mark for the admin -->
      <span class="check-mark"
        *ngIf="message.idPortailUtilisateurRepondant !== 0 && message.idPortailUtilisateur != userId"
        [ngClass]="{'unread': !message.messageLu,'read': message.messageLu}"
        [matTooltip]="(message.idPortailUtilisateur && message.messageLu) ? 'lu'   : 'Non lu'">
        <i class="fas fa-check"></i>
      </span>
    </span>
    <div *ngIf="!isClosed && depth<5">
      <span class="res-btn" color="accent" (click)="respond(message)"
        [matTooltip]="(userId === -1) ? 'Cette fonctionnalité est destinée aux utilisateurs Domino' : 'Répondre'">
        <i class="fa fa-reply" aria-hidden="true"></i>
      </span>
    </div>
  </div>
</div>

<div class="responses tree">
  <div class="response" *ngFor="let response of message._responses">
    <app-chat-bubble [message]="response" [userId]="userId" [isClosed]="isClosed" [search]="search" [depth]="depth+1"
      (addMessage)="respond($event)">
    </app-chat-bubble>
  </div>
</div>

<ng-template #documentDetails let-data>
  <mat-toolbar mat-dialog-title color="primary">
    <button mat-icon-button [mat-dialog-close] class="close-dialog">
      <mat-icon>close</mat-icon>
    </button>
    <span class="title">Détails : {{ data.file.nomFichier }}</span>
  </mat-toolbar>

  <mat-dialog-content class="document-details thin-scrollbar">
    <div class="details-list">
      <h3 style="text-align: center;">{{ data.file.nomFichier }}</h3>
      <p><label>Date d'ajout :</label><span>{{ data.date |date:'dd/MM/yyyy' }}</span></p>
    </div>

    <div class="preview-container">
      <h4>Aperçu :</h4>
      <app-loader *ngIf="loadingPreview; else preview">Chargement de l'aperçu</app-loader>
      <ng-template #preview>
        <ng-container [ngTemplateOutlet]="filePreview" [ngTemplateOutletContext]="{type: data.file.type}">
        </ng-container>
      </ng-template>
    </div>
  </mat-dialog-content>

  <div mat-dialog-actions align="center">
    <button mat-raised-button color="primary" [mat-dialog-close]>Fermer</button>
    <button mat-raised-button color="accent" (click)="downloadFile(data.file)">Télécharger</button>
  </div>
</ng-template>
<ng-template #filePreview let-type="type">
  <div *ngIf="previewURL" style="text-align: center;">
    <pdf-viewer *ngIf="type.includes('/pdf')" [src]="previewURL" class="pdf-viewer" [render-text]="false"
      [original-size]="false"></pdf-viewer>
    <img *ngIf="type.includes('image/')" [src]="previewURL" class="image-viewer">
  </div>

  <div *ngIf="!previewURL" class="no-preview">
    <app-icon *ngIf="!type.includes('/msword')" type='fa' size="100" icon="file-alt">
    </app-icon>
    <app-icon *ngIf="type.includes('/msword')" type='fa' size="100" icon="file-word">
    </app-icon>

    <div style="text-align: center;font-size: large;">Visuel non disponible</div>
  </div>
</ng-template>
