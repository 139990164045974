<mat-toolbar color="primary" mat-dialog-title>
  <button class="close-dialog" mat-icon-button [mat-dialog-close]>
    <mat-icon>close</mat-icon>
  </button>
  <span class="title"> {{modeEdit ? 'Répondre' : 'Créer un nouveau message'}}</span>
  <span></span>
</mat-toolbar>
<div>
  <div class="inline-fields">

    <div class="message-to">À </div>

    <mat-form-field style=" flex: 1">
      <mat-select placeholder="Choix du destinataire" multiple [(value)]="selectedEtablissements">
        <mat-option *ngFor="let etabId of etablissementsUser[0]" [disabled]="parentId" [value]="etabId">
          {{ etablissementsMapping[etabId] }}
        </mat-option>
      </mat-select>
    </mat-form-field>


  </div>
  <div class="message-subjuct" style="margin: 30px;">
    <mat-form-field (click)="$event.stopPropagation();" style="flex: 1;">
      <mat-label>Ajouter un objet </mat-label>
      <input matInput [(ngModel)]="object" [readonly]="parentId" style="width: 100%;">
    </mat-form-field>
    <mat-divider></mat-divider>
  </div>
  <text-editor [disabled]="sending" [(ngModel)]="content" [init]="{height: 250}"></text-editor>

  <div class="files-list thin-scrollbar">
    <div class="single-file" *ngFor="let file of files; let i = index">
      <app-icon size="30" *ngIf="getIcon(file.type) as icon; else default" type="far" [icon]="'file-'+icon"
        [class.invalid]="!valid"></app-icon>
      <ng-template #default><i class="far fa-file "></i></ng-template>
      <div class="info">
        <h4 class="name">
          {{ file?.name }}
        </h4>
        <p class="size">
          {{ formatBytes(file?.size) }}
        </p>
        <mat-progress-bar *ngIf="file.progress != null" mode="determinate" [value]="file?.progress" color="accent">
        </mat-progress-bar>
      </div>
      <mat-icon color="warn" *ngIf="file.progress == null" (click)="deleteFile(i)" class="icon-action">delete</mat-icon>
    </div>
  </div>

  <div mat-dialog-actions align="center">
    <button [disabled]="sending || !content || !object || !selectedEtablissements.length" matTooltip="Envoyer"
      mat-raised-button color="primary" (click)="submit()" style="padding-left: 5px;">
      <app-icon type="do" icon="mail-send" class="margin-icon" size="35"></app-icon>
      <mat-progress-spinner *ngIf="sending" mode="indeterminate" strokeWidth="2" diameter="20"
        style="margin-left: -15px;" color="accent" class="icon-loader">
      </mat-progress-spinner>
    </button>
    <button mat-mini-fab color="accent-lighter" [disabled]="sending" matTooltip="Ajouter une pièce jointe"
      class="accent" (click)="fileInput.click()">
      <input type="file" #fileInput (change)="onFileChange($event)" multiple hidden>
      <mat-icon>attach_file</mat-icon>

    </button>


  </div>
</div>
