import { Subject } from 'rxjs';

interface SwipeEvent {
  direction: string;
  distance: number;
}

export class SwipeManager {

  xDown = null;
  yDown = null;

  onSwipe = new Subject<SwipeEvent>();

  constructor(element?: Element) {
    (element || document).addEventListener('touchstart', this.handleTouchStart.bind(this), false);
    (element || document).addEventListener('touchmove', this.handleTouchMove.bind(this), false);
  }

  handleTouchStart(event: TouchEvent) {
    const firstTouch = event.touches[0];
    this.xDown = firstTouch.clientX;
    this.yDown = firstTouch.clientY;
  }

  handleTouchMove(event: TouchEvent) {
    if (!this.xDown || !this.yDown) {
      return;
    }

    const xUp = event.touches[0].clientX;
    const yUp = event.touches[0].clientY;

    const xDiff = this.xDown - xUp;
    const yDiff = this.yDown - yUp;

    if (Math.abs(xDiff) > Math.abs(yDiff)) {/*most significant*/
      if (xDiff > 0) {
        /* left swipe */
        console.log('Left swipe ! ', xDiff);
        this.onSwipe.next({ direction: 'left', distance: Math.abs(xDiff) });
      } else {
        /* right swipe */
        console.log('Right swipe ! ', xDiff);
        this.onSwipe.next({ direction: 'right', distance: Math.abs(xDiff) });
      }
    } else {
      if (yDiff > 0) {
        /* up swipe */
        this.onSwipe.next({ direction: 'up', distance: Math.abs(yDiff) });
      } else {
        /* down swipe */
        this.onSwipe.next({ direction: 'down', distance: Math.abs(yDiff) });
      }
    }

    /* reset values */
    this.xDown = null;
    this.yDown = null;
  }

  getTouches(evt: TouchEvent) {
    return evt.touches;
  }
}
