import { Component, OnInit, Input, ViewChild, OnDestroy, AfterViewInit, ElementRef, TemplateRef, ViewContainerRef, EventEmitter, Output } from '@angular/core';
import { EventApi, CalendarOptions, EventContentArg, EventMountArg, DayCellContentArg, DatesSetArg, EventInput } from '@fullcalendar/core';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { MatDialog } from '@angular/material/dialog';
import { PlanningSelectDialogComponent } from './select-dialog/select-dialog.component';
import { PlatformService } from '@app/services/platform.service';
import { weekDays } from '@app/services/periode.service';
import { CustomStyleService } from '@app/services/custom-style.service';
import { Subject, merge, interval, Subscription } from 'rxjs';
import { tap, take, debounceTime, map, takeUntil, filter, startWith, takeWhile, delay } from 'rxjs/operators';
import { PlanningService, DATE_FORMAT } from '@app/services/planning.service';
import { SnackbarService, TypeSnackbar } from '@app/services';
import { ReservationPresence, Reservation, ReservationConfig, PresenceError } from '@app/models/reservation';
import { Router } from '@angular/router';
import { PlanningDetailsDialogComponent, PlanningDetailsResult } from './details-dialog/details-dialog.component';
import { OverlayContainer } from '@angular/cdk/overlay';

import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import frLocale from '@fullcalendar/core/locales/fr';
import tippy from 'tippy.js';
import moment from 'moment';
import { PlanningData, ReservationConsumer } from './planning-data';
import { MikadoNewPresenceComponent } from './mikado-new-presence/mikado-new-presence.component';
import { HoursData } from '@app/models/periode';
import { TinyColor } from '@ctrl/tinycolor';
import { PresenceRepeatComponent, RepeatConfig } from './presence-repeat/presence-repeat.component';
import { BaseConfigService } from '@app/services/config/base.service';
import { TranslateService } from '@ngx-translate/core';
import { SwipeManager } from '@app/utils/swipe-manager';

const defaultSettings: CalendarOptions = {
  locale: frLocale,
  plugins: [interactionPlugin, dayGridPlugin, timeGridPlugin],
  displayEventEnd: true,
  editable: false,
  weekNumberCalculation: 'ISO',
  initialView: 'dayGridMonth',
  slotMinTime: '06:00',
  slotMaxTime: '21:00',
  allDaySlot: false,
  eventDisplay: 'block',
  views: {
    dayGrid: {},
    week: {}
  }
};

interface Cascade {
  presences: ReservationPresence[];
  error: PresenceError;
}

@Component({
  selector: 'app-reservation-planning',
  templateUrl: './planning.component.html',
  styleUrls: ['./planning.component.scss']
})
export class ReservationPlanningComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() data: PlanningData;
  @Input() optionsPanelContainer: ViewContainerRef;
  @Input() editMode: 'readonly' | 'admin'; // null = normal edit

  @Output() changeDate = new EventEmitter();

  config: ReservationConfig;
  calendarOptions: CalendarOptions;

  now = moment();

  activitiesByDate: { [date: string]: number } = {}; // count of activities by date
  slotsByDate: { [date: string]: number } = {} // count for slots by date

  displayFilters = {
    consumers: {},
    otherPeriodes: true,
    showCanceled: false,
    otherAccount: false
  };

  // FullCalendar component
  @ViewChild('calendar') calendar: FullCalendarComponent;
  @ViewChild('calendarWrapper') calendarWrapper: ElementRef;

  calendarMaxWidth: number | string;

  calendarWidth: number;
  isSmallScreen: boolean;
  isVerySmallScreen: boolean;
  isMediumScreen: boolean;

  @ViewChild('optionsPanel', { read: TemplateRef }) optionsPanel: TemplateRef<any>;

  onDestroy$ = new Subject();

  renderDaySubject = new Subject();
  renderEventSubject = new Subject();

  swipeManager: SwipeManager;
  loadedChecker: Subscription;
  showLoader: boolean;
  dayRenderFixStarted: boolean;
  recurrencyGroupsDone: boolean;

  // --- Getters --- //

  get currentReservation() { return this.data.currentReservation; }
  get currentPeriode() { return this.data.currentPeriode; }

  currentUsager: ReservationConsumer;
  otherUsagers: ReservationConsumer[];

  get readOnly() { return this.editMode === 'readonly'; }

  constructor(
    private configService: BaseConfigService,
    private customStyleService: CustomStyleService,
    private platformService: PlatformService,
    private planningService: PlanningService,
    private snackbar: SnackbarService,
    private dialog: MatDialog,
    private router: Router,
    private overlayContainer: OverlayContainer,
    private elementRef: ElementRef,
    private translateService: TranslateService
  ) {
    this.calendarOptions = { ...defaultSettings };
    this.calendarOptions.customButtons = {
      recopyButton: { click: this.onClickRecopy.bind(this) }
    };
  }

  // --- Data --- //

  ngOnInit() {
    this.exposeComputedColors(this.elementRef.nativeElement as HTMLElement);

    this.data.onChangeUsager.subscribe(_ => {
      if (this.data.consumers) {
        this.currentUsager = this.currentReservation ? this.data.findConsumer(this.currentReservation.idConsumer) : null;
        this.otherUsagers = this.data.consumers.filter(c => !this.data.isCurrentConsumer(c.id));
        this.refreshDisplayFilters();
      }
    });

    this.displayFilters.otherPeriodes = !this.data.currentReservation;

    // trigger once at start
    this.data.onChangePeriode.pipe(startWith(true)).subscribe(_ => this.onChangeCurrentPeriode());

    this.data.onPresenceUpdate.pipe(
      // because when we are in edit mode, this component is now lazy loaded, 
      // so the value 'all' is emitted before this component is loaded... 
      startWith('all')
    ).subscribe(mode => {
      if (mode === 'all') {
        this.data.reservations.forEach(resa => resa.events = this.planningService.buildEventsForPresences(resa.presences));
      } else {
        this.currentReservation.events = this.planningService.buildEventsForPresences(this.currentReservation.presences);
      }

      if (this.currentPeriode && !this.recurrencyGroupsDone) {
        this.data.reservations.forEach(r => {
          if (this.data.isCurrentPeriode(r.idPeriode, r.idPortailPeriode)) {
            this.planningService.setRecurrencyGroups(r.presences, this.currentPeriode);
            this.copyPresencesGroupToEvents(r.presences, r.events);
          }
        });

        // wrong way ... but the groups should be set only for presences from the back
        this.recurrencyGroupsDone = true;
      }

      this.refreshShownEvents();
    });

    if (this.data.reservations) {
      this.refreshShownEvents();
    }

    this.loadOptions().subscribe(_ => this.calendar?.getApi()?.render());

    merge(
      this.renderDaySubject.asObservable(),
      this.renderEventSubject.asObservable()
    ).pipe(debounceTime(300)).subscribe(_ => this.refreshTooltips());

    this.refreshLoader(this.now.format(DATE_FORMAT));
  }

  ngAfterViewInit() {
    // Don't infinite loop if in "activite" mode ! (Calendar will never appear ...)
    if (!(this.currentPeriode?.modeSelection === 'activite')) {
      // Wait for Calendar to appear, then do stuff
      interval(500).pipe(map(() => this.calendar), filter(x => !!x), take(1)).subscribe(() => this.initCalendarUI());
    }
  }

  loadOptions() {
    return this.configService.get<ReservationConfig>('reservation').pipe(
      tap(config => {
        this.config = config;

        this.calendarOptions.fixedWeekCount = config.calendar.fixedWeekCount;
        this.calendarOptions.showNonCurrentDates = config.calendar.showNonCurrentDates;
        this.calendarOptions.weekends = config.calendar.weekends;

        // this looks quite wrong with aspectRatio, the cell shows "more events" button while there is remaining space ...
        const maxEvents = config.calendar.eventLimit;

        if (maxEvents) {
          this.calendarOptions.dayMaxEvents = maxEvents === -1 ? true : maxEvents;
        }

        // view render
        this.calendarOptions.datesSet = this.onDateSet.bind(this);

        // date cells customize
        this.calendarOptions.dayCellClassNames = this.getDayClassNames.bind(this);
        this.calendarOptions.dayCellContent = this.getDayContent.bind(this);


        // event customize
        this.calendarOptions.eventContent = this.getEventContent.bind(this);
        this.calendarOptions.eventClassNames = this.getEventClassNames.bind(this);
        this.calendarOptions.eventDidMount = this.onRenderEvent.bind(this);

        // event "hover" customize
        this.calendarOptions.eventMouseEnter = (e) => this.highlightGroup(e.event.groupId);
        this.calendarOptions.eventMouseLeave = () => this.highlightGroup(null);

        // click event customize
        this.calendarOptions.dateClick = this.onDateClick.bind(this);
        this.calendarOptions.eventClick = this.onEventClick.bind(this);
        this.calendarOptions.moreLinkClick = this.onMoreLinkClick.bind(this);
      })
    );
  }

  refreshPreview(simulationDate = null) {
    this.now = simulationDate ? simulationDate : moment();
    this.now.hour(new Date().getHours()).minute(new Date().getMinutes()); // reset time to real now
    this.calendarOptions.now = this.now.toDate();

    this.refreshLimiteDates();
    this.refreshCalendarUI();
  }

  refreshDisplayFilters() {
    // Generate colors for every consumer (display a small "chip" with initial on their presences)
    const usagersColors = this.customStyleService.getRandomColors(this.otherUsagers.length);

    this.otherUsagers.forEach((c, i) => {
      // wasn't setup yet
      if (!c.color || !c.initial || this.displayFilters.consumers[c.id] === undefined) {
        this.displayFilters.consumers[c.id] = this.readOnly; // In read only show every children by default
        c.color = `rgb(${usagersColors[i]})`;
        c.initial = c.prenom.trim().substr(0, 1);
      }
    });
  }

  onChangeCurrentPeriode() {
    if (this.currentPeriode) {
      this.displayFilters.otherPeriodes = this.currentPeriode?.displayAllPeriodes;
      this.refreshCurrentPeriodeStuff();
      this.resetPresences();
      this.initDayRenderFix();
    }

    this.setupCalendar();
    this.refreshCalendarUI();
  }

  refreshCurrentPeriodeStuff() {
    this.data.enabledActivities = this.currentPeriode.activites.filter(a => a.enabled);

    this.activitiesByDate = {};
    this.slotsByDate = {};

    for (const act of this.data.enabledActivities) {
      for (const ad of act.days) {
        this.activitiesByDate[ad.date] = (this.activitiesByDate[ad.date] || 0) + 1;
      }
    }

    for (const slot of this.currentPeriode.dispoPlanning) {
      this.slotsByDate[slot.date] = slot.details.length;
    }

    this.refreshLimiteDates();
  }

  refreshLimiteDates() {
    const periode = this.currentPeriode;

    // @Bug: happens to be undefined (in Mik admin), why .. ?
    const feries = (this.data.feries || []).map(f => f.date);
    this.data.firstEditableDate = this.planningService.computeFirstDate(this.now, periode.limiteSaisie, feries, this.data.currentPeriode);
    this.data.lastSaisieDate = periode.limiteSaisie?.type === 'fixed-date' ? periode.limiteSaisie.fixedDate : moment(periode.saisieFin).format(DATE_FORMAT);

    if (periode.allowCancel) {
      this.data.firstCancelableDate = this.planningService.computeFirstDate(this.now, periode.limiteAnnulation, feries, this.data.currentPeriode);
      this.data.lastCancelDate = periode.limiteAnnulation?.type === 'fixed-date' ? periode.limiteAnnulation.fixedDate : moment(periode.saisieFin).format(DATE_FORMAT);
    }
  }

  /**
   * Update calendar view options based on general config + currentPeriode or all periodes (if no currentPeriode or "showAllPeriodes")
   */
  setupCalendar() {
    if (this.currentPeriode) {
      const endDate = moment(this.currentPeriode.endDate).add(1, 'days').format(DATE_FORMAT); // we must add 1 day !?!
      this.calendarOptions.validRange = { start: this.currentPeriode.startDate, end: endDate };

      // show only open days of current periode
      if (this.config?.calendar?.hiddenDaysOfWeek) {
        this.calendarOptions.hiddenDays = weekDays
          .map((d, i) => i).filter(d => !this.currentPeriode.weekdays.includes(d % 7));
      }
    } else {
      this.data.firstEditableDate = this.now.format(DATE_FORMAT);
    }

    // adjust Calendar max-width depending on number of days, because if only 1 day is opened, we get a very wide lonely column
    this.calendarMaxWidth = Math.max(Math.min((7 - this.calendarOptions.hiddenDays?.length) * 500), 600);
  }

  onRenderEvent(data: EventMountArg) {
    data.el.setAttribute('data-group', data.event.groupId);
  }

  // Called twice at init because of "resetPresences()"
  // Seems to take longer on ReservationEdit and faster on Periode admin
  refreshShownEvents() {
    // @NB: empty because reservation not visible, because of displayFilters ...
    // Maybe add a trigger on PlanningData like refreshUsagers() ?
    this.calendarOptions.events = [].concat(...this.data.reservations.filter(r => this.isVisibleReservation(r)).map(r => {
      return (r.events || []).filter(e => this.isVisiblePresence(r.presences.find(pr => e.presence === (pr.id || pr.tmpId))));
    }));

    setTimeout(() => {
      if (this.calendar && this.calendar.getApi()) {
        this.calendar.getApi().render();
      }
    });
  }

  isVisibleReservation(resa: Reservation) {
    if (this.data.isCurrentReservation(resa.id)) {
      return true;
    }

    if (!this.data.isCurrentConsumer(resa.idConsumer) && !this.displayFilters.consumers[resa.idConsumer]) {
      return false;
    }

    if (this.currentReservation &&
      !(this.data.isCurrentPeriode(resa.idPeriode, resa.idPortailPeriode) || this.displayFilters.otherPeriodes)) {
      return false;
    }

    if (resa.otherAccount && !this.displayFilters.otherAccount) {
      return false;
    }

    return true;
  }

  isVisiblePresence(presence: ReservationPresence) {
    return presence && !presence.replacedBy &&
      (!presence.id || this.displayFilters.showCanceled || !this.planningService.isCanceledOrDenied(presence, false));
  }

  resetPresences() {
    if (this.data.currentReservation) {
      this.data.currentReservation.presences = [];
      this.data.currentReservation.events = [];
      this.refreshShownEvents();
    }
  }

  getDateVisiblePresences(date: string): ReservationPresence[] {
    const reservations = this.data.reservations.filter(r => this.isVisibleReservation(r));

    return [].concat(...reservations.map(r => r.presences)).filter(pr => pr.date === date && this.isVisiblePresence(pr));
  }

  // --- UI --- //

  exposeComputedColors(rootElement: HTMLElement) {
    const cssProperties = getComputedStyle(rootElement);
    const backgroundColor = cssProperties.getPropertyValue('--background-color');

    if (backgroundColor) {
      let tinyBC = new TinyColor(backgroundColor);

      if (tinyBC.isDark()) {
        tinyBC = tinyBC.lighten(20);
      } else {
        tinyBC = tinyBC.darken(20);
      }

      tinyBC.setAlpha(.3);

      rootElement.style.setProperty('--calendar-day-hover-color', tinyBC.toHex8String());
    }
  }

  manageOptionsPanel() {
    if (this.optionsPanelContainer) {
      this.optionsPanelContainer.clear();
      this.optionsPanelContainer.createEmbeddedView(this.optionsPanel);
    }
  }

  initCalendarUI() {
    this.manageOptionsPanel();
    this.initSwipeListener();

    this.platformService.mainWidth$.pipe(
      startWith(true),
      takeUntil(this.onDestroy$)
    ).subscribe(() => this.refreshCalendarUI());
  }

  refreshCalendarUI() {
    this.calendarWidth = this.getCalendarWidth();

    this.isSmallScreen = this.calendarWidth < 600;
    this.isVerySmallScreen = this.calendarWidth < 400;
    this.isMediumScreen = !this.isSmallScreen && this.calendarWidth < 950;

    const weekDayFormat = this.isSmallScreen ? 'short' : 'long';

    this.calendarOptions.views.dayGrid.dayHeaderFormat = { weekday: weekDayFormat };
    this.calendarOptions.views.week.dayHeaderFormat = { weekday: weekDayFormat, day: 'numeric' };

    this.refreshButtons();

    // Timeout = wait for Calendar to refresh before we update ratio (else no effect)
    setTimeout(() => {
      const height = (window.innerHeight - (this.elementRef.nativeElement as HTMLElement).getBoundingClientRect().y) - 10;
      this.calendarOptions.height = 'auto';
      // this.calendarOptions.height = Math.max(700, height);
      this.calendar?.getApi()?.updateSize();
    });
  }

  refreshButtons() {
    let buttons = '';

    if (this.data.currentPeriode && !this.currentPeriode.disableCreation && !this.currentPeriode.disableRecopy) {
      buttons += 'recopyButton';

      if (this.data.currentPeriode.enableWeekView) {
        buttons += ' dayGridMonth timeGridWeek';
      }

      setTimeout(() => {
        const recopyButton = document.querySelector('.fc-recopyButton-button');

        if (recopyButton) {
          recopyButton.classList.add('custom-button');
          if (this.isVerySmallScreen) {
            recopyButton.innerHTML = '<mat-icon class="material-icons">content_copy</mat-icon>'
          } else {
            recopyButton.innerHTML = '<mat-icon class="material-icons">content_copy</mat-icon><span class="text">Recopie</span>';
          }
        }
      });
    }

    if (this.isSmallScreen) {
      this.calendarOptions.headerToolbar = { left: 'prev next', center: 'title', right: buttons };
      this.calendarOptions.footerToolbar = { left: 'today', center: '', right: '' };
    } else {
      this.calendarOptions.headerToolbar = { left: 'prev,next today', center: 'title', right: buttons };
      this.calendarOptions.footerToolbar = false;
    }
  }

  getCalendarWidth() {
    return this.calendarWrapper ? (this.calendarWrapper.nativeElement as HTMLElement).offsetWidth : this.platformService.mainWidth();
  }

  // @NB: we use this one because the "eventDidMount" hook is not reliable, as not always called ...
  getEventContent(data: EventContentArg) {
    const event = data.event;
    const reservation = this.data.findReservation(event.extendedProps.reservation);

    const domNodes: HTMLElement[] = [];

    const presence = reservation.presences.find(pr => (pr.id || pr.tmpId) === event.extendedProps.presence);

    const contentEl = document.createElement('div');
    contentEl.classList.add('event-main-content');

    if (reservation.type === 'mikado' || presence.showTimes) {
      const timeEl = document.createElement('div');
      timeEl.classList.add('presence-time');

      timeEl.innerHTML = '<span>' + presence.startTime + ' - ' + presence.endTime + '</span>';

      if (presence.startTime2) {
        timeEl.innerHTML += '<span>' + presence.startTime2 + ' - ' + presence.endTime2 + '</span>'
      }

      contentEl.appendChild(timeEl);
    }

    if (presence.title) {
      const titleEl = document.createElement('span');
      titleEl.classList.add('event-title');
      titleEl.innerHTML = event.title;
      contentEl.appendChild(titleEl);
      contentEl.setAttribute('data-tippy-content', presence.title);
    }

    if (presence.shortTitle) {
      // Add a small version of presence title
      const codeElement = document.createElement('span');
      codeElement.classList.add('short-name');

      if (presence.askCancel) {
        codeElement.classList.add('warn');
      }

      codeElement.innerHTML = presence.shortTitle;
      contentEl.appendChild(codeElement);
    }

    domNodes.push(contentEl);

    const iconsEl = document.createElement('div');
    iconsEl.classList.add('event-icons');

    // Add icon on Event having Activities subscribed
    if (presence.activities && presence.activities.length) {
      const activityIcon = document.createElement('i');
      activityIcon.classList.add('icon', 'icodomino-activite', 'activity-icon');
      activityIcon.setAttribute('data-tippy-content', presence.activities.length + ' activités');
      iconsEl.appendChild(activityIcon);
    }

    // Add state icon (new, waiting, accepted, etc.)
    const stateIcon = document.createElement('mat-icon');
    stateIcon.classList.add('mat-icon', 'material-icons', 'status-icon');

    const status = reservation.otherAccount ? 'other_account' : presence.status;

    stateIcon.innerHTML = this.translateService.instant('reservation.state_icon.' + status);
    stateIcon.setAttribute('data-tippy-content', this.translateService.instant('reservation.state_text.' + status));
    iconsEl.appendChild(stateIcon);

    domNodes.push(iconsEl);

    if (!this.data.isCurrentConsumer(reservation.idConsumer)) {
      const consumer = this.data.findConsumer(reservation.idConsumer);
      if (consumer) {
        const childInitial = document.createElement('span');
        childInitial.classList.add('consumer-initial');
        childInitial.innerHTML = consumer.initial;
        childInitial.style.backgroundColor = consumer.color;
        childInitial.setAttribute('data-tippy-content', consumer.prenom + ' ' + consumer.nom);
        domNodes.unshift(childInitial);
      }
    }

    this.renderEventSubject.next();

    return { domNodes };
  }

  getEventClassNames(data: EventContentArg) {
    const event = data.event;

    const classNames = ['no-underline-animation'];

    const reservation = this.data.findReservation(event.extendedProps.reservation);
    const presence = this.data.findPresence(event.extendedProps.presence, reservation);

    if (presence && this.planningService.isCanceledOrDenied(presence)) {
      classNames.push('absence');
    }

    if (reservation.otherAccount) {
      classNames.push('other-account');
    }

    classNames.push(reservation?.type?.toLowerCase());

    return classNames;
  }

  highlightGroup(id: string) {
    const container = this.calendarWrapper.nativeElement as HTMLElement;
    const events = container.querySelectorAll('.fc-event');

    events.forEach(el => el.classList.toggle('highlight-recurrent', id && el.getAttribute('data-group') === id));
  }

  onDateSet(arg: DatesSetArg) {
    const startDate = moment(arg.view.currentStart).format(DATE_FORMAT);
    const endDate = moment(arg.view.currentEnd).format(DATE_FORMAT);
    this.refreshLoader(startDate, endDate);
    this.changeDate.next(arg);
  }

  getDayClassNames(data: DayCellContentArg) {
    const dateMom = moment(data.date);
    const dateStr = dateMom.format(DATE_FORMAT);

    const classes = [];

    if (!this.readOnly && !this.data.isEditableDate(dateStr)) {
      classes.push('fc-day-disabled');
    }

    if (this.currentPeriode && this.data.lastSaisieDate === dateStr && this.data.lastSaisieDate !== this.currentPeriode.endDate) {
      classes.push('last-saisie-date');
    }

    return classes;
  }

  getDayContent(data: DayCellContentArg) {
    if (data.view.type === 'timeGridWeek') {
      return { domNodes: [] };
    }

    const dateMom = moment(data.date);
    const dateStr = dateMom.format(DATE_FORMAT);

    const domNodes: HTMLElement[] = [];

    if (this.currentPeriode) {
      if (this.activitiesByDate[dateStr]) {
        const activitiesSpan = document.createElement('span');
        activitiesSpan.classList.add('activities-badge');
        activitiesSpan.innerHTML = '<i class="icon icodomino-activite"></i>' + this.activitiesByDate[dateStr];
        activitiesSpan.setAttribute('data-tippy-content', 'Activités Disponibles');
        domNodes.push(activitiesSpan);

        // Click on this badge to open SelectionDialog directly on "Activities" tab
        activitiesSpan.onclick = () => this.onClickActivityBadge(dateStr);
      }

      // if (this.currentPeriode.warnLowCapacity) {
      //   const day = this.currentPeriode.days.find(d => d.date === dateStr);

      //   if (day?.dispos.some(x => x.places < this.currentPeriode.warnLowCapacity)) {
      //     let smallRoomsLeftText = 'Peu de places restantes sur certains créneaux.';

      //     // day.dispos.forEach(di => {
      //     //   const plage = this.data.plages[di.plage];
      //     //   smallRoomsLeftText += '- ' + plage.name + ' : reste ' + di.places + '<br>';
      //     // });

      //     const disposIcon = document.createElement('span');
      //     disposIcon.classList.add('dispos-icon', 'accent');
      //     disposIcon.innerHTML = '<mat-icon class="material-icons">bolt</mat-icon>';
      //     disposIcon.setAttribute('data-tippy-content', smallRoomsLeftText);
      //     domNodes.push(disposIcon);
      //   }
      // }


      // Show Créneaux disponibles
      if (this.currentPeriode.modeSelection && this.currentPeriode.modeSelection !== "free" && this.currentPeriode.type === "mikado") {
        if (this.slotsByDate[dateStr] && !data.isPast && !data.isDisabled) {
          const slotSpan = document.createElement('span');
          slotSpan.classList.add('activities-badge');
          slotSpan.innerHTML = '<i class="icon icodomino-dispo"></i>' + this.slotsByDate[dateStr];
          const slot = this.currentPeriode.dispoPlanning.find(dispo => dispo.date === dateStr);
          const detailsHtml = slot.details.map(s => s.start + " - " + s.end).join('<br>');
          slotSpan.setAttribute('data-tippy-content', `Créneaux Disponibles : <br> ${detailsHtml}`);

          domNodes.push(slotSpan);

          slotSpan.onclick = () => this.openMikadoNewPresenceDialog(dateStr);

        }
      }
    }

    // add something for "jour férié"
    const ferie = this.data.feries?.find(f => f.date === dateStr);
    if (ferie) {
      const ferieEl = document.createElement('span');
      ferieEl.innerText = 'Férié';
      ferieEl.classList.add('spacer', 'ferie');
      ferieEl.setAttribute('data-tippy-content', ferie.detail);
      domNodes.push(ferieEl);
    } else {
      const spacer = document.createElement('span');
      spacer.classList.add('spacer');
      domNodes.push(spacer);
    }

    // simply the date..
    const dayNumber = document.createElement('span');
    dayNumber.classList.add('day-number');
    dayNumber.innerText = '' + data.date.getDate();

    if (this.currentPeriode && this.data.lastSaisieDate === dateStr && this.data.lastSaisieDate !== this.currentPeriode.endDate) {
      dayNumber.setAttribute('data-tippy-content', 'Dernier jour pour saisir vos réservations');
    }

    domNodes.push(dayNumber);

    this.renderDaySubject.next();

    return { domNodes };
  }

  initDayRenderFix() {
    if (this.readOnly || this.dayRenderFixStarted) {
      return;
    }

    merge(
      this.changeDate.asObservable(),
      this.data.onChangePeriode.asObservable()
    ).pipe(
      startWith(true),
      takeUntil(this.onDestroy$),
      delay(100)
    ).subscribe(() => {
      // search every day (not "rendered" yet) and add buttons (add / recopy)
      const container: HTMLElement = this.calendarWrapper?.nativeElement ?? this.elementRef.nativeElement;
      container.querySelectorAll('.fc-daygrid-day').forEach(day => {
        if (day.hasAttribute('data-date')) {
          this.addButtonsToDayCell(day as HTMLElement, day.getAttribute('data-date'));
        }
      });
    });
  }

  addButtonsToDayCell(element: HTMLElement, date: string) {
    const dayFrame = element.querySelector('.fc-daygrid-day-frame');

    if (!dayFrame) {
      console.warn('No dayframe content found in day cell ?');
      return;
    }

    const buttons = dayFrame.querySelector('.buttons') || document.createElement('div');
    buttons.classList.add('buttons');

    buttons.innerHTML = '';

    if ((!this.currentPeriode || !this.currentPeriode.disableCreation) && this.data.isEditableDate(date)) {
      const addButton = document.createElement('button');
      addButton.classList.add('primary-lighter-bg', 'add');
      addButton.innerHTML = '<span class="text">Ajouter</span><mat-icon class="material-icons">add</mat-icon>';

      buttons.appendChild(addButton);
    }

    if (this.currentPeriode && !this.currentPeriode.disableCreation && !this.currentPeriode.disableRecopy) {
      const recopyButton = document.createElement('button');
      recopyButton.classList.add('accent-lighter-bg', 'recopy');
      recopyButton.innerHTML = '<span class="text">Recopier</span><mat-icon class="material-icons">content_copy</mat-icon>';
      buttons.appendChild(recopyButton);
    }

    if (!buttons.parentElement) {
      dayFrame.appendChild(buttons);
    }
  }

  refreshTooltips() {
    tippy('[data-tippy-content]', {
      appendTo: this.overlayContainer.getContainerElement(),
      touch: false,
      allowHTML: true,
    });
  }

  // --- Interaction (click, open Dialogs, etc) --- //

  /**
   * On event click we open a dialog (contained in same template) with detailed data of corresponding presence
   */
  onEventClick(eventInfo: { event: EventApi, jsEvent: MouseEvent }) {
    if (this.dialog.openDialogs.length) {
      return;
    }

    // Need date, get from Event Presence
    const event = eventInfo.event;
    const eventData = event.extendedProps;

    const presence = this.data.findPresence(eventData.presence, eventData.reservation);

    this.openDetailsForDate(presence.date, this.isSmallScreen ? null : presence);
  }

  onClickActivityBadge(date: string) {
    if (this.currentPeriode.disableCreation) {
      this.snackbar.error('La création de présence est désactivée');
      return;
    }

    this.openSelectDialog(date, true);
  }

  openDetailsForDate(date: Date | string, selected?: ReservationPresence) {
    if (this.dialog.openDialogs.length) {
      return;
    }

    const dateString = moment(date).format(DATE_FORMAT);
    const datePresences = this.getDateVisiblePresences(dateString);

    const dialog = this.dialog.open(PlanningDetailsDialogComponent, {
      autoFocus: false,
      restoreFocus: false,
      data: {
        date: dateString,
        planningData: this.data,
        presences: datePresences,
        selected: selected ? selected.id || selected.tmpId : null
      }
    });

    this.platformService.adaptDialogToScreen(dialog);

    dialog.afterClosed().subscribe(result => this.afterDetailsDialog(result, dateString));
  }

  afterDetailsDialog(result: PlanningDetailsResult, date: string) {
    if (!result) {
      return;
    }

    if (this.readOnly) {
      if (result.action === 'new') {
        // We were on account reservations Planning, go to edit (create) mode
        this.router.navigate(['/account/reservations/new']);
      } else {
        // Handle nothing else if we're in readonly
        return;
      }
    } else if (result.action === 'new') {
      if (this.currentPeriode.disableCreation) {
        this.snackbar.error('La création de présence est désactivée');
        return;
      }

      if (this.currentPeriode.type === 'mikado') {
        this.openMikadoNewPresenceDialog(date);
      } else {
        this.openSelectDialog(date);
      }
    } else if (result.action === 'recopy') {
      this.recopyPresences(result.config.presences, result.config);
    } else if (result.action === 'cancel') {
      const presence = this.data.findPresence(result.presence.id || result.presence.tmpId, result.presence.reservation);

      this.cancel(presence);
    } else if (result.action === 'cancel-multi') {
      const presence = this.data.findPresence(result.presence.id || result.presence.tmpId, result.presence.reservation);
      const multiPresences = this.getRepeatPresences(presence, result.config);

      multiPresences.forEach(pr => {
        if (!pr.replacedBy) { // test if presence has not already been canceled by a cascade cancel
          this.cancel(pr)
        }
      });
    }
  }

  getRepeatPresences(basePresence: ReservationPresence, config: RepeatConfig) {
    const repeatDates = this.planningService.getRepeatDates(config);
    const otherPresences = this.data.getCurrentInscriptionPresences();

    const sameStatePresences = otherPresences.filter(pr => this.planningService.isSimilarState(pr, basePresence));

    const similarPresences = sameStatePresences.filter(pr => pr.rubrique === basePresence.rubrique ||
      (pr.activities && pr.activities.find(act => basePresence.activities && basePresence.activities.includes(act))));

    return repeatDates.map(d => similarPresences.find(pr => pr.date === d)).filter(pr => !!pr);
  }

  recopyPresences(presences: ReservationPresence[], repeatConfig: RepeatConfig) {
    const periode = this.currentPeriode;
    const activities = this.data.enabledActivities;

    const recopyDates = this.planningService.getRepeatDates(repeatConfig).filter(rd => this.data.isEditableDate(rd));

    const presencesFromSameConsumer = presences.some(pr => {
      return this.data.isCurrentConsumer(this.data.findReservation(pr.reservation)?.idConsumer);
    });

    const recopyPresences = this.planningService.copyPresences(
      presences,
      recopyDates,
      periode,
      activities,
      presencesFromSameConsumer,
      repeatConfig.mode === 'week'
    );

    const newPresences = this.planningService.createRecurrentPresencesAndClean(recopyPresences, this.data);

    const checkPresences = this.planningService.filterPresences(this.data.getCurrentConsumerPresences()).concat(newPresences);

    const ignoreRooms = this.editMode === 'admin' || !!periode.gestionListeAttente;

    const errors = [];

    for (const newPresence of newPresences) {

      newPresence.error = this.planningService.checkPresenceError(newPresence, checkPresences, periode, ignoreRooms);

      if (newPresence.error) {
        console.warn('Error type ' + newPresence.error + ' on Presence : ', newPresence);
        errors.push({
          rub: newPresence.title,
          error: newPresence.error,
          date: newPresence.date,
          startTime: newPresence.startTime,
          endTime: newPresence.endTime
        });
      } else {
        this.data.addPresence(newPresence, false);
        this.planningService.updateRooms(newPresence, this.data.currentPeriode);
      }
    }

    if (errors.length) {
      let message = this.translateService.instant('reservation.error_message.copy_some_fail') + '<br>';

      errors.forEach(e => {
        e.error = this.translateService.instant('reservation.error_type.' + e.error, e);
        message += this.translateService.instant('reservation.error_message.copy_unable_detail', e) + '<br>';
        // recurrent: !recopyDates.includes(errorPresence.date)
      });

      this.snackbar.open({ type: TypeSnackbar.alert, message, textButton: 'OK' });
    }

    this.data.onPresenceUpdate.next();
  }

  cancel(presence: ReservationPresence) {
    const cascade: Cascade = { presences: [], error: null };

    if (!this.planningService.isCanceledOrDenied(presence)) {
      this.cancelCascade(presence, cascade);
    } else {
      this.restoreCascade(presence, cascade);
    }

    // console.log('CASCADE : ', cascade);

    if (!cascade.error) {
      cascade.presences.forEach(ab => this.deleteOrCancelPresence(ab));
      this.data.onPresenceUpdate.next();
    } else {
      console.warn('Cascade aborted because error : ', cascade.error);
      // tweak to better translate "cant cancel anymore" instead of "cant tag anymore"
      const message = this.translateService.instant('reservation.error_type.' + (cascade.error === 'date' ? 'date_cancel' : cascade.error));
      this.snackbar.error('Opération impossible : ' + message);
    }
  }

  /**
   * Cancel a presence : if has dependant items, cancel them as well - simple as 1 2 3 :)
   */
  cancelCascade(presence: ReservationPresence, cascade: Cascade) {
    if (presence.id && !this.data.isCancelableDate(presence.date as string)) {
      cascade.error = 'date';
      console.warn('Cancel cascade fails on presence : ' + presence.title + ' - ' + presence.date, presence);
      return;
    }

    // First handle the presence itself, then manage all dependencies & grouped presences

    cascade.presences.push(presence);

    const otherPresences = this.planningService.filterPresences(this.data.getCurrentInscriptionPresences());
    const sameDatePresences = otherPresences.filter(pr => pr.date === presence.date);

    // dependant presences, need to be canceled too
    const deps = this.planningService.getDependantPresences(presence, sameDatePresences, this.currentPeriode.rubriqueChains);

    // grouped = recurrent + activity same group (so only for diabolo)
    const groupedPresences = this.currentPeriode.type === 'diabolo' ? this.planningService.getRecurrentPresences(otherPresences, presence, this.currentPeriode) : [];

    deps.concat(groupedPresences).forEach(pr => {
      // if not already treated, go deeper :)
      if (!this.planningService.isSamePresence(presence, pr) && !cascade.presences.some(p => this.planningService.isSamePresence(p, pr))) {
        this.cancelCascade(pr, cascade);
      }
    });
  }

  /**
   * Cancel a cancel : find a corresponding editable Presence to restore, or create a new one
   */
  restoreCascade(presence: ReservationPresence, cascade: Cascade) {
    cascade.presences.push(presence);

    const otherPresences = this.data.getCurrentInscriptionPresences();
    const sameDatePresences = otherPresences.filter(pr => pr.date === presence.date);

    // find correct dependencies (chain elements), we'll restore presences if needed
    const requiredPresences = this.planningService.getRequiredPresences(presence, sameDatePresences, this.currentPeriode.rubriqueChains, true);

    // Means there are required Rubriques that couldn't be fulfiled
    // @NB: now we could even add a mechanism here to create new presence corresponding to chained rubrique, but well ... wtf
    if (!requiredPresences) {
      cascade.error = 'chain';
      console.warn('Restore cascade fails on presence : ' + presence.title + ' - ' + presence.date, presence);
      return;
    }

    // Clear potential conflicts (since we restore a Presence, some other could be conflicting ...)
    this.planningService.getConflicts(presence, this.planningService.filterPresences(sameDatePresences))
      .forEach(pr => this.cancelCascade(pr, cascade));

    // grouped = recurrent + activity same group
    const groupedPresences = this.planningService.getRecurrentPresences(otherPresences, presence, this.currentPeriode);

    requiredPresences.concat(groupedPresences).forEach(pr => {
      if (this.planningService.isCanceledOrDenied(pr) && !cascade.presences.some(d => this.planningService.isSamePresence(d, pr))) {
        this.restoreCascade(pr, cascade);
      }
    });
  }

  deleteOrCancelPresence(presence: ReservationPresence) {
    if (presence.id) {
      this.cancelPresence(presence);
    } else {
      this.deletePresence(presence);
    }

    // restore rooms for every presence canceled / deleted ...
    this.planningService.updateRooms(presence, this.data.currentPeriode, true);
  }

  // @NB: duplicate with PlanningComponent, maybe move to PlanningData
  deletePresence(presence: ReservationPresence, triggerUpdate = false) {
    // If the presence is a cancel, reset the presence it was replacing
    if (presence.askCancel) {
      const replacedPresence = this.data.findPresence(presence.replacing || presence.idDominoPresence, presence.idDominoReservation);
      if (replacedPresence) {
        replacedPresence.replacedBy = null;
      }
    }

    this.data.removePresence(presence, triggerUpdate);
  }

  cancelPresence(presence: ReservationPresence, triggerUpdate = false) {
    // Copy all data from Presence, except ID
    const cancelPrez = this.planningService.clonePresence(presence);
    cancelPrez.askCancel = true;
    cancelPrez.status = 'canceling';
    presence.replacedBy = cancelPrez.tmpId;
    const prezReservation = this.data.findReservation(presence.reservation);
    if (prezReservation.fromDomino) {
      cancelPrez.idDominoPresence = presence.id;
      cancelPrez.idDominoReservation = presence.reservation;
    } else {
      cancelPrez.replacing = presence.id;
    }

    this.data.addPresence(cancelPrez, triggerUpdate);
  }

  onDateClick(dateData: DateClickArg) {
    this.openDetailsForDate(dateData.date);
  }

  onMoreLinkClick(data) {
    this.openDetailsForDate(data.date);

    return true;
  }

  openMikadoNewPresenceDialog(date: string) {
    const ref = this.dialog.open(MikadoNewPresenceComponent, {
      minWidth: 'min(500px, 100%)',
      data: { date, planningData: this.data }
    });

    this.platformService.adaptDialogToScreen(ref);

    ref.afterClosed().subscribe((timeData: HoursData[]) => {
      if (timeData) {
        const rubrique = this.currentPeriode.rubriques.find(r => r.id === this.currentPeriode.idRubrique);
        timeData.forEach(time => {
          const newPresence = this.planningService.createPresenceFromRubrique(rubrique, date, time, true);
          this.data.addPresence(newPresence);
        });
      }
    });
  }

  openSelectDialog(date, accessActivity = false) {
    const dialogRef = this.dialog.open(PlanningSelectDialogComponent, {
      // maxWidth: 600,
      minWidth: 'min(500px, 100%)',
      restoreFocus: false,
      autoFocus: false,
      data: { date, planningData: this.data, editMode: this.editMode, accessActivity }
    });

    this.platformService.adaptDialogToScreen(dialogRef);

    dialogRef.afterClosed().subscribe((resp: { presences: ReservationPresence[], recopy: RepeatConfig }) => {
      if (!resp?.presences) {
        return;
      }

      // in SelectDialog we trust (all checks should be done already !)
      resp.presences.forEach(pr => {
        this.data.addPresence(pr, false);
        this.planningService.updateRooms(pr, this.data.currentPeriode);
      });

      if (resp.recopy) {
        this.recopyPresences(resp.presences, resp.recopy);
      }

      // Trigger presence change even if no "new" presences, to handle activity selection changes
      // (on previously selected presences, which are not in the "newPresences" array ...)
      this.data.onPresenceUpdate.next();
    });
  }

  ngOnDestroy() {
    // this.calendar.getApi().destroy();

    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  initSwipeListener() {
    this.swipeManager = new SwipeManager(this.elementRef.nativeElement);
    this.swipeManager.onSwipe.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(swipe => {
      if (swipe.direction === 'left') {
        this.calendar.getApi().next();
      }
      if (swipe.direction === 'right') {
        this.calendar.getApi().prev();
      }
    });
  }

  refreshLoader(startDate: string, endDate?: string) {
    if (this.loadedChecker) {
      this.loadedChecker.unsubscribe();
    }

    this.loadedChecker = interval(500).pipe(
      map(_ => this.data.isLoadedDate(startDate) && (!endDate || this.data.isLoadedDate(endDate))),
      tap(x => this.showLoader = !x),
      takeWhile(x => !x)
    ).subscribe();
  }

  onClickRecopy() {
    if (!this.readOnly && this.currentPeriode && !this.currentPeriode.disableCreation && !this.currentPeriode.disableRecopy) {

      const dialog = this.dialog.open(PresenceRepeatComponent, {
        data: {
          date: moment().format(DATE_FORMAT), // or selected date, if it still exists ...
          planningData: this.data
        }
      });

      this.platformService.adaptDialogToScreen(dialog);

      dialog.afterClosed().subscribe((config: RepeatConfig) => {
        if (config) {
          this.recopyPresences(config.presences, config);
        }
      });
    }
  }

  copyPresencesGroupToEvents(presences: ReservationPresence[], events: EventInput[]) {
    presences.filter(pr => pr.group).forEach(pr => {
      const ev = events.find(e => e.id === (pr.id || pr.tmpId));

      if (ev) {
        ev.groupId = pr.group;
      }
    })
  }
}
