<div class="details-list">
  <div *ngIf="consumer">
    <i *ngIf="consumer.idEnfant > 0" class="icon icodomino-enfant" matTooltip="Enfant"></i>
    <i *ngIf="consumer.idAdulte > 0" class="icon" matTooltip="Adulte"
      [ngClass]="{'icodomino-man': consumer.sexe === 'M', 'icodomino-woman': consumer.sexe === 'F'}"></i>
    <span>{{ consumer.nom }} {{ consumer.prenom }}</span>
  </div>

  <div>
    <i class="icon icodomino-etablissement" matTooltip="Etablissement"></i>
    <span>{{ periode.nameEtablissement }}</span>
  </div>

  <div>
    <i class="icon icodomino-accueil" matTooltip="Accueil"></i>
    <span>{{ periode.nameAccueil }}</span>
  </div>

  <div>
    <i class="icon icodomino-periode" matTooltip="Période"></i>
    <span>{{ periode.label || periode.name }}</span>
  </div>

  <div>
    <mat-icon matTooltip="Dates d'ouverture">date_range</mat-icon>
    <span>
      Du {{ periode.startDate|date:'longDate' }}
      au {{ periode.endDate|date:'longDate' }}</span>
  </div>

  <div>
    <span>Jours d'ouverture&nbsp;:</span>
    <mat-chip-list>
      <ng-container *ngFor="let day of weekDays; let i = index">
        <mat-chip *ngIf="periode.weekdays.includes(i + 1)" selected="true" color="accent"
          matTooltip="{{ day|titlecase }} : Ouvert">
          {{ day|slice:0:3|titlecase }}</mat-chip>
        <mat-chip *ngIf="!periode.weekdays.includes(i + 1)" matTooltip="{{ day|titlecase }} : Fermé">
          {{ day|slice:0:3|titlecase }}</mat-chip>
      </ng-container>
    </mat-chip-list>
  </div>
</div>

<mat-card class="primary-lighter-bg saisie-limit-info">

  <div *ngIf="!!periode.saisieFin">
    <mat-icon class="icon" color="accent">info</mat-icon>
    <span>
      Vous avez jusqu'au <b>{{ periode.saisieFin|date:'dd/MM/yyyy' }}</b> pour valider vos réservations.
    </span>
  </div>

  <div *ngIf="!!periode.limiteSaisie.type">
    <mat-icon class="icon" color="accent">info</mat-icon>
    <ng-container [ngSwitch]="periode.limiteSaisie.type">
      <span *ngSwitchCase="'variable'">
        Vous devez réserver au minimum <b>{{ periode.limiteSaisie.number }}
          {{ 'reservation.units.'+periode.limiteSaisie.unit|trans }}</b>
        avant la date souhaitée.
      </span>
      <span *ngSwitchCase="'week-fixed'">
        Vous devez réserver avant le <b>{{ weekDays[periode.limiteSaisie.weekDay - 1] }}</b> de la semaine précédant la
        date souhaitée.
      </span>
      <span *ngSwitchCase="'month-fixed'">
        Vous devez réserver avant le <b>{{ periode.limiteSaisie.monthDate }}</b> du mois précédant la date souhaitée.
      </span>
      <span *ngSwitchCase="'month-param'">
        Vous devez réserver avant le <b>{{ periode.limiteSaisie.monthDayOrder }}
          {{ weekDays[periode.limiteSaisie.monthDay] }}</b> du mois précédant la date souhaitée.
      </span>
    </ng-container>
  </div>

  <div *ngIf="!!periode.limiteSaisie.time">
    <mat-icon class="icon" color="accent">access_time</mat-icon>
    Heure limite : {{ periode.limiteSaisie.time }} (à {{ periode.limiteSaisie.toleranceMinutes }} minutes près)
  </div>
</mat-card>
