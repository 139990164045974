import { animate, state, style, transition, trigger } from '@angular/animations';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, ElementRef, Inject, Input, OnInit, Renderer2, TemplateRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Messagerie } from '@app/models/message';
import { SnackbarService, UserService } from '@app/services';
import { EtablissementService } from '@app/services/etablissement.service';
import { MessagerieService } from '@app/services/messagerie.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ResponseMessageComponent } from './response-message/response-message.component';
import { ConfirmDialogComponent } from '@app/components/_common/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-messagerie-board',
  templateUrl: './messagerie-board.component.html',
  styleUrls: ['./messagerie-board.component.scss'],
  animations: [
    trigger('fadeInLeft', [
      state('void', style({ opacity: 0, transform: 'translateX(-5%)' })),
      state('*', style({ opacity: 1, transform: 'translateX(0)' })),
      transition(':enter', animate('300ms ease-out')),
      transition(':leave', animate('150ms ease-in')),
    ]),
  ]
})
export class MessagerieBoardComponent {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('chatArea', { static: false }) chatArea: ElementRef;
  @ViewChild('filterDetails', { static: true }) filterDetails: TemplateRef<any>;
  @Input() selectMode: string | boolean;
  dataSource: MatTableDataSource<Messagerie>;
  total: any
  message: Messagerie
  etablissements = [];
  allEtablissements = []
  onDestroy$ = new Subject();
  isLoading = true;
  displayedColumns = ['objetMessage', 'contenu'];
  destroyed = new Subject<void>();
  currentScreenSize: string;
  isSmallScreen = false;
  offset: number = 0;
  perPage: number = 10;
  search = "";
  isSearching = false;
  displayNameMap = new Map([
    [Breakpoints.XSmall, 'Small'],
  ]);
  authorizedEtablissement: any
  userId: number;
  selectedRow: any;
  rows: any[] = [];
  timer: any;
  isMessagesLoaded = false;
  isAnimationTriggered = false;
  shouldScrollToBottom = true;
  selectedFilterOption: string = '';
  selectedEtablissementId: any;
  authorizedEtablissements: any[] = [];
  closeDemande: boolean;
  selectedMessage: Messagerie;

  constructor(
    private messagerieService: MessagerieService,
    public dialog: MatDialog,
    private snackbarService: SnackbarService,
    private userService: UserService,
    public etablissementservice: EtablissementService,
    breakpointObserver: BreakpointObserver,
    private matDialog: MatDialog,
  ) {
    breakpointObserver
      .observe([
        Breakpoints.XSmall,
      ])
      .pipe(takeUntil(this.destroyed))
      .subscribe(result => {
        let currentScreenSize = 'Unknown';
        for (const query of Object.keys(result.breakpoints)) {
          if (result.breakpoints[query]) {
            currentScreenSize = this.displayNameMap.get(query) ?? 'Unknown';
          }
        }
        this.isSmallScreen = currentScreenSize == 'Small';
      });
  }

  ngOnInit() {
    this.etablissementservice.getAllEtablissement().subscribe(res => {
      this.allEtablissements = res;
      this.etablissements = res.filter(etablissement => etablissement.authorized === true);
      this.getMessages();
    })
  }

  processMessages(res: any) {
    this.total = res.total;
    this.isSearching == !!this.search;
    res.messages.forEach(message => {
      message.etablissements = this.allEtablissements.filter(etb => message._etablissements.includes(etb.idEtablissement));
      // message.newResponseCounts = this.countNewResponses(message);
    });
    this.isLoading = false;
    this.dataSource = new MatTableDataSource(res.messages);
    this.dataSource.sort = this.sort;
    this.isMessagesLoaded = true;
    this.userId = this.userService.currentUser.id;
    this.message = res.messages.find(msg => this.message && msg.idPortailMessage == this.message.idPortailMessage) || this.message;
  }

  getMessages() {
    this.isMessagesLoaded = false;
    this.messagerieService.getMessages(this.offset, this.perPage, this.search, this.selectedEtablissementId).subscribe(res => {
      this.processMessages(res);
    });
  }

  addMessage(msg: null) {
    const dialogRef = this.dialog.open(ResponseMessageComponent, {
      data: {
        etablissements: this.etablissements,
        message: msg,
        userInfo: this.message._userInfo,
        authorizedEtablissement: this.message._authorizedEtablissement,
        idMessageParent0: this.message ? this.message.idPortailMessage : null
      },
      disableClose: true,
      maxWidth: '700px',
      minHeight: '500px',
      width: this.isSmallScreen ? '90%' : '50%',
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.getMessages();
      }
    })
  }

  countNewResponses(msg) {
    // cast the Boolean to int
    console.log("msg", msg)
    return msg._responses.reduce((count, resp) => count + this.countNewResponses(resp),
      +(!msg.idEtablissementExpediteur && !msg.messageLu))
  }

  handlePageEvent(e: PageEvent) {
    this.perPage = e.pageSize;
    this.offset = e.pageIndex * this.perPage;
    this.isMessagesLoaded = false;
    this.getMessages();
  }

  createImageDataUrl(data: string): string {
    return data;
  }

  markMessageAsRead(idMessage: number) {
    if (this.userId !== -1) {
      this.messagerieService.markMessageAsRead(idMessage).subscribe(res => {
        this.message._countUnreadMessageAdmin = 0
        this.message.newResponseCounts = 0
      }
      );
    }
  }

  showDetails(message) {
    this.isAnimationTriggered = false;
    this.selectedMessage = message;
    setTimeout(() => {
      this.isAnimationTriggered = true;
      this.message = message;
      if (this.message._countUnreadMessageAdmin) {
        this.markMessageAsRead(message.idPortailMessage)
      }
      // const markMessageAndResponsesAsRead = (msg) => {
      //   if (!msg.idEtablissementExpediteur && !msg.messageLu) {
      //     this.markMessageAsRead(msg.idPortailMessage);
      //   }
      //   if (msg._responses && msg._responses.length > 0) {
      //     for (const response of msg._responses) {
      //       markMessageAndResponsesAsRead(response);
      //     }
      //   }
      // };
      // if (message.newResponseCounts) markMessageAndResponsesAsRead(message);
      setTimeout(() => {
        this.scrollToBottom();
      });
    }, 400);
  }

  scrollToBottom() {
    if (this.chatArea && this.chatArea.nativeElement) {
      const chatAreaEl: HTMLElement = this.chatArea.nativeElement;
      chatAreaEl.scrollTop = chatAreaEl.scrollHeight;
    }
  }

  applyFilter(filterValue: string) {
    this.search = filterValue;
    this.isSearching = true;
    this.isMessagesLoaded = false;
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.getMessages();
    }, 2000)
  }

  applyFilterMessages() {
    this.isSearching = true;
    this.isMessagesLoaded = false;
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.getMessages();
    }, 2000);
    this.matDialog.closeAll();
  }

  openFilterDetails() {
    const dialog = this.matDialog.open(this.filterDetails, {
      data: {
        etablissements: this.etablissements,
      },
      disableClose: true,
      width: this.isSmallScreen ? '90%' : '50%',
    });
  }

  closeUserDemande() {
    const ref = this.dialog.open(ConfirmDialogComponent, { data: { message: 'Voulez vous clôturer cette demande ?' } });
    ref.afterClosed().subscribe(result => {
      if (result) {
        if (this.selectedMessage && this.selectedMessage.idPortailMessage) {
          this.messagerieService.closeConversation(this.selectedMessage.idPortailMessage).subscribe(() => {
            this.selectedMessage.isClosed = true;
            this.snackbarService.info(`La demande a été clôtureé`);
          });
        }
      }
    });
  }

  markAsUnread(message) {
    this.messagerieService.markMessageAsUnread(message.idPortailMessage).subscribe(() =>
      message._countUnreadMessageAdmin = 1
    )
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  close() {
    this.isAnimationTriggered = false;
    setTimeout(() => {
      this.message = null;
      this.isAnimationTriggered = true;
    }, 300);
  }
}

