import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { AuthenticationService, SnackbarService } from '@app/services';
import { FormBuilder, Validators } from '@angular/forms';
import { User } from '@app/models/user';
import { AuthentificationConfig } from '@app/models/authentification-config';
import { TotpCodeInput } from '@app/components/_elements/totp-code-input/totp-code-input.component';
import { TotpAction } from '@app/services/authentication.service'
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: 'app-totp-verify',
  templateUrl: './totp-verify.component.html',
  styleUrls: ['./totp-verify.component.scss']
})
export class TotpVerifyComponent implements OnInit {

  user: User;
  authConfig: AuthentificationConfig;

  action: TotpAction

  totpForm = this.formBuilder.group({
    totpCode: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
  });
  @ViewChild("codeIntput") totpCodeInput: ElementRef;
  totpError: string = null;

  verifying = false;
  mustRestart = false;
  fatalErrorMsg: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data,
    private dialogRef: MatDialogRef<TotpVerifyComponent>,
    private authService: AuthenticationService,
    private formBuilder: FormBuilder,
  ) {
    this.action = this.data.action;
  }

  ngOnInit() {

    this.authService.getConf().subscribe((conf: AuthentificationConfig) => this.authConfig = conf || ({} as AuthentificationConfig));

    this.totpForm.valueChanges.subscribe(_ => {
      this.totpForm.setErrors(null)
    })

  }


  verify() {
    this.totpError = null;

    this.verifying = true;
    this.totpForm.disable()
    const codeInput = this.totpForm.get('totpCode')
    this.authService.TotpVerify(codeInput.value, this.action).pipe(
      // delay(5000) // for test
    ).subscribe(res => {
      this.totpForm.enable()

      if (res.errorMsg) {
        this.totpError = res.errorMsg
        codeInput.setErrors({ wrongCode: true })
        codeInput.markAsTouched()
        this.totpCodeInput.nativeElement.focus()

      } else if (res.totpVerified) {
        // this.snackbarService.info("Succés")
        this.dialogRef.close({ success: true, user: res })
      }
      this.verifying = false;

    }, err => {
      console.error('Erreur lors de la vérification du code TOTP', err)
      this.totpForm.enable()
      const error = err.error
      if (error.mustLogin || error.mustRestart) {
        this.fatalErrorMsg = error.errorMsg;
        this.mustRestart = true;
      } else {
        this.totpError = error.errorMsg
        codeInput.setErrors({ wrongCode: true })
        codeInput.markAsTouched()
        this.totpCodeInput.nativeElement.focus()
      }
      this.verifying = false;
    })

  }

  cancel() {
    this.verifying = false;
    this.dialogRef.close({ canceled: true })
  }


}
