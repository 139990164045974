export interface BaseAdapterInterface<T> {
  /**
   * Adapt local data model object and gives plain object with translated fields
   */
  adapt(data: T): any;

  /**
   * Reverse part of the process : gives back a model from data plain object
   */
  reverse(data: any): T;
}

export class BaseAdapterService<T> implements BaseAdapterInterface<T> {
  /**
   * For automatic field map, must be of form {localKey: remoteKey}
   */
  fields: { [key: string]: string };

  adapt(data: T): object {
    const result = {};

    for (const key in this.fields) {
      if (key in this.fields) {
        result[this.fields[key]] = data[key];
      }
    }

    return result;
  }

  reverse(data: any): T {
    const result = {};

    for (const key in this.fields) {
      if (key in this.fields) {
        result[key] = data[this.fields[key]];
      }
    }

    return result as T;
  }

  adaptCollection(collection: T[]) {
    return collection.map(item => this.adapt(item));
  }

  reverseCollection(collection: any[]) {
    return collection.map(item => this.reverse(item));
  }
}
