<!-- Cancel loader -->
<app-loader *ngIf="canceling" class="canceling-loader" size="4 " message="Annulation en cours"></app-loader>

<div class="container {{canceling?'canceling':''}}">

  <h1 mat-dialog-title class="primary">
    <mat-icon>announcement</mat-icon>
    Vous êtes actuellement en salle d'attente
  </h1>
  <mat-dialog-content class="thin-scrollbar">
    <div [innerHTML]="conf.msgInfoGlobal | safe"></div>
    <div [innerHTML]="conf.msgSalleAttente | safe"></div>

    <div class="pannel-data border">

      <p class="">
        <mat-icon matTooltip="Votre rang dans la file d'attente">format_list_numbered</mat-icon>
        <span *ngIf="data.rank>1">
          Il y a <strong>{{data.rank}}</strong> personnes devant vous.
        </span>
        <span *ngIf="data.rank<=1">
          C'est bientôt votre tour ...
        </span>

      </p>
      <p class="" *ngIf="!!waitingTime">
        <mat-icon matTooltip="Estimation basée sur le temps passé par les précédentes personnes.">timelapse</mat-icon>
        Votre temps d'attente est estimé <strong> {{waitingTime}} </strong>
      </p>


      <p class="">
        <mat-icon matTooltip="Heure de la dernière actualisation des données ci dessus.">history</mat-icon>
        Dernière actualisation : <strong>{{data.lastRefresh | date:'mediumTime'}}</strong>
      </p>
    </div>

  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-raised-button color="accent" (click)="onCancel()" [disabled]="canceling">
      <mat-icon>exit_to_app</mat-icon>
      Quitter la salle d'attente
    </button>
  </mat-dialog-actions>
</div>
