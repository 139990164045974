import { HttpClient, HttpEvent, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigEdition, Edition } from '@app/models/edition';
import { Observable } from 'rxjs';
import { FamilyService } from './family.service';
import { map } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class EditionService {

  configUrl = 'conf/edition';

  constructor(
    private http: HttpClient,
    private familyService: FamilyService
  ) { }

  getConfig(): Observable<ConfigEdition> {
    return this.http.get<ConfigEdition>(this.configUrl);
  }

  saveConfig(editions: Edition[]) {
    return this.http.put(this.configUrl, {editions}, httpOptions);
  }

  getCurrentFamilyAvailablesEditions(): Observable<Edition[]>{
    return this.http.get<Edition[]>(`familles/${this.familyService.currentFamily.id}/editions`);
  }

  getEdition(idEtat: number, idEntite: number) {
    return this.http.get(`edition/${idEtat}/${idEntite}`, {
      responseType: 'blob',
      reportProgress: true,
      observe: 'events'
    }).pipe(map(response => this.mapProgressResponse(response)));
  }

  mapProgressResponse(event: HttpEvent<any>) {
    switch (event.type) {
      case HttpEventType.DownloadProgress:
        return { type: 'dl_progress', value: Math.round(100 * event.loaded / event.total) };
      case HttpEventType.UploadProgress:
        return { type: 'up_progress', value: Math.round(100 * event.loaded / event.total) };
      case HttpEventType.Response:
        return {  type: 'response',
                  value: event.body ,
                  filename: event.headers.get('filename'),
                  ContentDisposition: event.headers.get('Content-Disposition')
                };
      default:
        return { type: 'otherType', value: event.type };
    }
  }

}
;