import { Component, EventEmitter, Input, OnChanges, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AnimationReunion, AnimationReunionAll, AnimationReunionPortailEnfantMentalo } from '@app/models/animationReunion';
import { ContratAccueilMentalo, EnfantMentalo } from '@app/models/contrat-accueil';
import { PlatformService, UserService } from '@app/services';
import { AssmatService } from '@app/services/assmat.service';
import moment from 'moment';

@Component({
  selector: 'app-list-animations-reunions',
  templateUrl: './list-animations-reunions.component.html',
  styleUrls: ['./list-animations-reunions.component.scss']
})
export class ListAnimationsReunionsComponent implements OnInit, OnChanges {

  @Input() allList: AnimationReunionAll;
  @Input() listEnfant: EnfantMentalo[];
  @Input() contrats: ContratAccueilMentalo[];
  @Input() permToAdd: boolean;
  @Input() type: 'reunion' | 'animation';

  @Output() unsubscribeEvent = new EventEmitter();
  @Output() unsubscribeChildEvent = new EventEmitter();
  @Output() registerChildEvent = new EventEmitter();

  idAssmat: number;
  list: AnimationReunion[];
  listFiltered: AnimationReunion[];
  drawerOpened = false;
  showAll: boolean = false;
  showAccepted: boolean = true;
  showRefused: boolean = true;
  showWaiting: boolean = true;
  registerChildList: number[];
  unsubscribeChildDialogRef: MatDialogRef<any>;
  unsubscribeDialogRef: MatDialogRef<any>;
  subscribeDialogRef: MatDialogRef<any>;
  decision = { accepted: "Acceptee", enAttente: "EnAttente", refused: "Refusee" }
  typeDemande = { inscription: "Inscription", desinscription: "Desinscription" }

  @ViewChild('UnsubscribeDialog', { static: true }) UnsubscribeDialog: TemplateRef<any>;
  @ViewChild('UnsubscribeChildDialog', { static: true }) UnsubscribeChildDialog: TemplateRef<any>;
  @ViewChild('RegisterChildDialog', { static: true }) RegisterChildDialog: TemplateRef<any>;

  constructor(
    private assmatService: AssmatService,
    private userService: UserService,
    private matDialog: MatDialog,
    public platformService: PlatformService,
  ) { }

  ngOnInit(): void {
    this.InitData();
  }

  InitData() {
    this.idAssmat = this.userService.currentUser.idAssmat || null;

    this.list = this.assmatService.sortByDate(this.allList.tabInscriptions);
    this.assmatService.checkAssmatRegistered(this.list, this.idAssmat);

    this.list.forEach(anim => {
      anim.active = moment(anim.date) > moment(new Date()) ? true : false;
      anim.isBeforeLimiteDateInscription = moment(anim.dateEcheanceInscription) >= moment(new Date().toDateString());
    });

    if (!this.showAll) {
      this.listFiltered = this.list.filter(anim => anim.active);
    } else {
      this.listFiltered = this.list;
    }

    this.assmatService.checkChildRegistered(this.listEnfant, this.list);
  }

  ngOnChanges(): void {
    this.InitData();
  }

  getAssmatStatut(animationReunion: AnimationReunion): string {
    if (animationReunion.portailInscription) {
      return animationReunion.portailInscription.decision;
    } else return "Acceptee";
  }

  openUnsubscribeDialog(animationReunion: AnimationReunion) {
    this.unsubscribeDialogRef = this.matDialog.open(this.UnsubscribeDialog, {
      data: { animationReunion },
      maxWidth: '700px',
      minWidth: 'min(500px, 100%)',
      panelClass: this.platformService.isMobile ? 'full-screen-dialog' : '',
    });

    this.platformService.adaptDialogToScreen(this.unsubscribeDialogRef);
  }

  openUnsubscribeChildDialog(animationReunion: AnimationReunion, child) {
    this.unsubscribeChildDialogRef = this.matDialog.open(this.UnsubscribeChildDialog, {
      data: { animationReunion, child },
      maxWidth: '700px',
      minWidth: 'min(500px, 100%)',
      panelClass: this.platformService.isMobile ? 'full-screen-dialog' : '',
    });

    this.platformService.adaptDialogToScreen(this.unsubscribeChildDialogRef);
  }

  openRegisterChildDialog(animationReunion: AnimationReunion) {
    this.registerChildList = [];
    let listChildWithContratEnabled = this.assmatService.getChildListWithContratEnable(this.contrats, this.listEnfant, animationReunion.date);
    let listChildNotRegistered = this.createChildListNotRegistered(animationReunion, listChildWithContratEnabled);

    this.subscribeDialogRef = this.matDialog.open(this.RegisterChildDialog, {
      data: { animationReunion, listChildNotRegistered },
      maxWidth: '700px',
      minWidth: 'min(500px, 100%)',
      panelClass: this.platformService.isMobile ? 'full-screen-dialog' : ''
    });
    this.platformService.adaptDialogToScreen(this.subscribeDialogRef);
  }

  unsubscribe(animationReunion: AnimationReunion) {
    this.unsubscribeEvent.emit(animationReunion);
    this.unsubscribeDialogRef.close();
  }

  unsubscribeChild(animationReunion: AnimationReunion, child: any) {
    this.unsubscribeChildEvent.emit({ animationReunion, child });
    this.unsubscribeChildDialogRef.close();
  }

  registerChild(animationReunion: AnimationReunion) {
    this.registerChildEvent.emit({ animationReunion, registerChildList: this.registerChildList })
    this.subscribeDialogRef.close();
  }


  createChildListNotRegistered(animationReunion: AnimationReunion, listChild: EnfantMentalo[]) {
    let listChildNotRegistered = [];
    let listIdChildRegistered = animationReunion.listEnfantMentaloRegisteredByAssmat.map(childRegistered => childRegistered.child.id);

    listChild.forEach(child => {
      if (!listIdChildRegistered.includes(child.id)) {
        listChildNotRegistered.push(child);
      }
    });

    return listChildNotRegistered;
  }

  unsubcribeChildExist(childList: AnimationReunionPortailEnfantMentalo[]): boolean {
    if (childList && childList.length) {
      childList.forEach(child => {
        if (child.typeDemande === this.typeDemande.desinscription) {
          return true;
        }
      });
    }
    return false;
  }

  childWithDecisionExist(decision: string, childList: AnimationReunionPortailEnfantMentalo[]): boolean {
    if (childList && childList.length) {
      childList.forEach(child => {
        if (child.decision === decision) {
          return true;
        }
      });
    }
    return false;
  }

  closeDrawer() {
    this.drawerOpened = false;
  }

  toggleOptions() {
    this.drawerOpened = !this.drawerOpened;
  }

  toggleShowAll(event) {
    if (event === false) {
      this.listFiltered = this.listFiltered.filter(animReu => animReu.active);
    } else {
      let listHistory = this.list.filter(animReu => !animReu.active);
      this.listFiltered.push(...listHistory);
    }
    this.assmatService.sortByDate(this.listFiltered);
  }

  toggleShowAccepted(event) {
    this.filterByDecision(this.decision.accepted, event)
  }

  toggleShowRefused(event) {
    this.filterByDecision(this.decision.refused, event)
  }

  toggleShowWaiting(event) {
    this.filterByDecision(this.decision.enAttente, event)
  }

  filterByDecision(decision: string, event: boolean) {
    if (event === false) {
      this.listFiltered = this.listFiltered.filter(animReu => {
        if ((!animReu.portailInscription && animReu.active) ||
          (animReu.portailInscription?.typeDemande === this.typeDemande.desinscription && animReu.portailInscription.decision === this.decision.refused)) {
          return decision !== this.decision.accepted;
        }
        return animReu.portailInscription?.decision !== decision;
      })

    } else {
      let listAnimReu = this.list.filter(animReu => {
        if ((!animReu.portailInscription && animReu.active) ||
          (animReu.portailInscription?.typeDemande === this.typeDemande.desinscription && animReu.portailInscription.decision === this.decision.refused)) {
          return decision === this.decision.accepted;
        }
        return animReu.portailInscription?.decision === decision;
      });
      this.listFiltered.push(...listAnimReu);
    }
    this.assmatService.sortByDate(this.listFiltered);
  }
}
