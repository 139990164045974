<div class="container">

  <div class="top-actions">

    <span class="filter-field">
      <mat-form-field class="no-hint small-outline" style="width: auto;">
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filtre">
      </mat-form-field>
    </span>

    <span class="flex-1"></span>

    <ng-content select=".top-actions"></ng-content>

  </div>

  <table mat-table [dataSource]="dataSource" matSort matSortActive="creationDate" matSortDirection="desc"
    style="width: 100%;">

    <ng-container matColumnDef="numDossier">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="id">{{ 'reservation.table.num_dossier'|trans }}</th>
      <td mat-cell *matCellDef="let resa">
        <span>{{ resa.id }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="creationDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="creationDate">
        {{ 'reservation.table.date_creation'|trans }}
      </th>
      <td mat-cell *matCellDef="let resa">
        <p>{{ resa.creationDate|date:'shortDate' }} à {{ resa.creationDate|date:'shortTime' }}</p>
      </td>
    </ng-container>

    <ng-container matColumnDef="nomReferent">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="referent">{{ 'reservation.table.referent'|trans }}</th>
      <td mat-cell *matCellDef="let reservation">
        <b>{{ reservation.referent }}</b>
      </td>
    </ng-container>

    <ng-container matColumnDef="etablissement">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'reservation.table.periode_info'|trans }}</th>
      <td mat-cell *matCellDef="let resa">
        <div class="eap-list">
          <span>{{ resa.etablissement }}</span>
          <span>{{ resa.accueil }}</span>
          <span>{{ resa.periode }}</span>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="dates">
      <th mat-header-cell *matHeaderCellDef style="width: 155px;">{{ 'reservation.table.dates'|trans }}</th>
      <td mat-cell *matCellDef="let resa">
        <p *ngIf="resa.startDate">
          Du {{ resa.startDate|date:'longDate' }}<br>
          au {{ resa.endDate|date:'longDate' }}
        </p>
      </td>
    </ng-container>

    <ng-container matColumnDef="etat">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="state">{{ 'reservation.table.etat'|trans }}</th>
      <td mat-cell *matCellDef="let resa">
        <mat-icon [matTooltip]="('reservation.state_text.' + resa.status)|trans">
          {{ ('reservation.state_icon.' + resa.status)|trans }}
        </mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef>{{ 'reservation.table.details'|trans }}</th>
      <td mat-cell *matCellDef="let resa">
        <button mat-icon-button [matTooltip]="'reservation.table.details_tooltip'|trans" (click)="openDetail(resa)">
          <mat-icon>more_vert</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[5, 10, 20]" [pageSize]="5"></mat-paginator>
</div>

<ng-template #reservationDetail let-data>
  <mat-toolbar mat-dialog-title color="primary" class="title-toolbar">
    <button class="close-dialog" mat-icon-button mat-dialog-close matTooltip="Fermer">
      <mat-icon>close</mat-icon>
    </button>
    <span class="title">{{ 'reservation.detail.title'|trans }}</span>
  </mat-toolbar>

  <mat-dialog-content class="thin-scrollbar" style="min-width: 35vw;">
    <app-user-reservation-details [reservation]="data.reservation"></app-user-reservation-details>
  </mat-dialog-content>

  <mat-dialog-actions align="center">
    <button *ngIf="!data.reservation.otherAccount" mat-raised-button color="accent-lighter" mat-dialog-close
      routerLink="/account/reservations/{{ data.reservation.idPeriode }}-{{ data.reservation.idInscription }}">
      {{ 'reservation.detail.button_edit'|trans }}
    </button>
  </mat-dialog-actions>
</ng-template>
