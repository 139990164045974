<div class="reservation-info">
  <section>
    <div>
      <label>Référent :</label>
      <span>{{ reservation.referent }}</span>
    </div>

    <div>
      <label>N° de la réservation :</label>
      <span>{{ reservation.id }}</span>
    </div>

    <div>
      <label>Etat de la réservation :</label>
      <span>{{ ('reservation.state_text.' + reservation.status)|trans }}</span>
      <mat-icon style="margin-left: 5px">{{ ('reservation.state_icon.' + reservation.status)|trans }}</mat-icon>
    </div>

    <div>
      <label>Date de création :</label>
      <span>{{ reservation.creationDate|date:'medium' }}</span>
    </div>
  </section>

  <hr>

  <!-- @TODO: see if could reuse small component from UserEditReservation ("selected periode summary") -->

  <section>
    <div>
      <i class="icon icodomino-etablissement"></i>
      <label>Etablissement :</label>
      <span>{{ reservation.etablissement }}</span>
    </div>

    <div>
      <i class="icon icodomino-accueil"></i>
      <label>Accueil :</label>
      <span>{{ reservation.accueil }}</span>
    </div>

    <div>
      <i class="icon icodomino-periode"></i>
      <label>Période :</label>
      <span>{{ reservation.periode }}</span>
    </div>
  </section>
</div>

<mat-divider style="margin-top: 13px;"></mat-divider>

<table mat-table [dataSource]="dataSource" matSort style="width: 100%;">
  <ng-container matColumnDef="title">
    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 33%;">Votre demande</th>
    <td mat-cell *matCellDef="let presence">{{ presence.title }}</td>
  </ng-container>

  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef style="width: 33%;" mat-sort-header>Date</th>
    <td mat-cell *matCellDef="let presence" class="date">
      <div>
        <span>
          <i class="icon icodomino-presence"></i>
          Le <b>{{ presence.date|date:'mediumDate' }}</b>
        </span>
        <span *ngIf="presence.fromTime || presence.toTime">
          <i class="icon icodomino-horloge"></i>
          de {{ presence.startTime || '-' }} à {{ presence.endTime || '-' }}
        </span>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="state">
    <th mat-header-cell *matHeaderCellDef mat-sort-header style="width: 33%;">Etat</th>
    <td mat-cell *matCellDef="let presence">
      {{ ('reservation.state_text.' + presence.status)|trans }}
      <mat-icon>{{ ('reservation.state_icon.' + presence.status)|trans }}</mat-icon>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns:displayedColumns;"></tr>
</table>

<mat-paginator [pageSizeOptions]="[5, 10, 20]" [pageSize]="5" showFirstLastButtons></mat-paginator>
