import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, OnDestroy } from '@angular/core';
import { MenuService, PlatformService, HeaderService } from '@app/services';
import { merge, Subject, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-sticky-action-bar',
  templateUrl: './sticky-action-bar.component.html',
  styleUrls: ['./sticky-action-bar.component.scss']
})
export class StickyActionBarComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() title: string;
  @Input() inAdmin: boolean;

  @Input() saving: boolean;
  @Input() disabledSave: boolean;

  // I think this option should be renamed and opposite : "saveBtn" only when needed (in admin mostly) + now we have more "noSave" case ...
  @Input() noSave: boolean;
  @Input() back: any;

  @Output() save = new EventEmitter();

  marginLeft: number;

  loaded = false;

  onDestroy$ = new Subject();

  constructor(
    public menuService: MenuService,
    public platformService: PlatformService,
    public route: ActivatedRoute,
    private headerService: HeaderService,
  ) { }

  ngOnInit() {
    merge(
      this.menuService.sidenav.openedStart,
      this.menuService.sidenav.closedStart
    ).pipe(
      takeUntil(this.onDestroy$),
    ).subscribe(x => this.setMargin());



    // this.title = this.getOrBuildTitle(this.route.snapshot.data)
    // if (this.title) {
    //   this.setPageTitle(this.title)
    // } else {
    //   this.headerService.pageTitle$.subscribe(title => {
    //     this.title = title;
    //   })
    // }

    if (this.title) {
      this.headerService.setCurrentPageTitle(this.title, this.inAdmin)
    } else {
      combineLatest([
        this.headerService.pageTitle$,
        this.headerService.inAdmin$
      ]).subscribe(([pageTitle, inAdmin]) => {
        this.title = pageTitle;
        this.inAdmin = inAdmin
      })
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.loaded = true;
      this.setMargin();
    }, 1000);
  }

  // getOrBuildTitle(currentRouteData): string {
  //   console.log('getOrBuildTitle', currentRouteData)
  //   let title = this.title;
  //   console.log('title', title)
  //   const adminRouteFrag = this.route.snapshot.pathFromRoot.findIndex(rs => rs.routeConfig?.path === 'admin');
  //   this.isAdminRoute = adminRouteFrag >= 0;
  //   console.log('this.isAdminRoute', this.isAdminRoute)
  //   if (!title) {
  //     if (!this.isAdminRoute) {
  //       title = currentRouteData?.title || '';
  //     } else {
  //       title = 'admin_board.' + this.route.snapshot.pathFromRoot
  //         .slice(adminRouteFrag + 1) // uniquement la partie après '/admin/
  //         .map(rs => rs.routeConfig).filter(rc => !!rc) // on prend que les 'routeConfig'
  //         .filter(rc => rc.path !== '') // seulement s'il y a un 'path' 
  //         .filter(rc => !!rc.data && !!rc.data.title)
  //         .map(rc => !!rc.children ? rc.data.title + '.links' : rc.data.title)
  //         .join(".");
  //     }
  //   }

  //   console.log('getOrBuildTitle => ', title)
  //   return title
  // }

  // setPageTitle(title) {
  //   this.headerService.setCurrentPageTitle(title);
  //   if (this.isAdminRoute) {
  //     // Add a gear character in Page Title (Tab title of the navigator)
  //     let pageTitle = this.titleService.getTitle()
  //     console.log('pageTitle', pageTitle)
  //     this.titleService.setTitle('⚙ ' + pageTitle)
  //   }
  // }

  setMargin() {
    this.marginLeft = this.menuService.sidenav.opened && this.menuService.sidenav.mode !== 'over' ? 270 : 0;
  }

  onBack() {
    if (!this.back) {
      history.back();
    } else if (this.back instanceof EventEmitter) {
      this.back.emit();
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
