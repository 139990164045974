<mat-toolbar class="mat-elevation-z6 navbar" color="primary"
  [style.backgroundColor]="config.title?.style?.backgroundColor">

  <ng-container *ngIf="platformService.isMobile; else desktopHeader">

    <button *ngIf="!hideBackButton" mat-icon-button matTooltip="Retour" (click)="onBack()">
      <mat-icon>arrow_back</mat-icon>
    </button>
    <!-- <div class="title"> -->
    <span class="title-page">{{ headerService.pageTitle$|async|trans }}</span>

    <ng-container *ngTemplateOutlet="extras"></ng-container>
    <!-- </div> -->
    <div class="navbar-toggle">
      <button type="button" class="navbar-toggler" [ngClass]="{'toggled': menuService.sidenav.openedChange|async}"
        (click)="menuService.sidenav.toggle()">
        <span class="navbar-toggler-bar bar1"></span>
        <span class="navbar-toggler-bar bar2"></span>
        <span class="navbar-toggler-bar bar3"></span>
      </button>
    </div>
  </ng-container>

  <ng-template #desktopHeader>
    <button class="menu-button" mat-icon-button (click)="menuService.sidenav.toggle()">
      <mat-icon>menu</mat-icon>
    </button>

    <div *ngIf="config" class="title">
      <img *ngIf="config.logo?.enabled" [src]="config.logo?.image" routerLink="/" alt="logo"
        [ngStyle]="{'height': config.logo?.height + 'px', 'width': config.logo?.width + 'px'}">
      <span *ngIf="config.title?.enabled" class="title-portail" [portailCustomStyle]="config.title?.style"
        [exclude]="['backgroundColor']">
        {{ config.title?.text }}
      </span>
    </div>

    <ng-container *ngTemplateOutlet="extras"></ng-container>
  </ng-template>


  <ng-template #extras>
    <div [class]="platformService.isMobile ? 'extras mini' : 'extras' ">
      <div *ngIf="waitingRoomNotifierDisplay">
        <app-waiting-room-notifier></app-waiting-room-notifier>
      </div>


      <div *ngIf="reservationsToPay" class="panier-button-container " [ngClass]="panierButtonAnimationClass">
        <button mat-fab color="warn" [matTooltip]="'panier.main_header_button'|trans"
          routerLink="/account/reservations/panier">
          <mat-icon [matBadge]="reservationsToPay" matBadgeColor="accent" matBadgeOverlap="false">shopping_basket
          </mat-icon>
        </button>
      </div>
    </div>

  </ng-template>


  <app-app-update-band></app-app-update-band>

</mat-toolbar>
