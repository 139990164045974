<ng-template appTopbarActions>
  <button mat-mini-fab color="accent-lighter" [matMenuTriggerFor]="moreMenu">
    <mat-icon>filter_list</mat-icon>
  </button>

  <mat-menu #moreMenu="matMenu">
    <button mat-menu-item (click)="onToggleInvalideFile()">
      <span *ngIf="!showInvalideFile">
        <mat-icon color="accent">visibility</mat-icon>
        {{'ged.button_expired_show' | trans}}
      </span>
      <span *ngIf="showInvalideFile">
        <mat-icon color="warn">visibility_off</mat-icon>
        {{'ged.button_expired_hide' |trans}}
      </span>
    </button>
  </mat-menu>
</ng-template>

<div *ngIf="config && loaded; else loader">

  <div class="container" [class.small-screen]="platformService.isMobile">

    <mat-card class="info-card" *ngIf="config.info" [portailCustomStyle]="config.styleCardInfo">
      <div [innerHtml]="config.info | safe"></div>
    </mat-card>

    <mat-accordion multi="false">

      <ng-container *ngFor="let type of knownTypes">
        <mat-expansion-panel
          *ngIf="(dataByType[type]?.entities?.length || dataByType[type]?.entitiesByTypeStructure?.show) && dataByType[type] as typeData"
          [expanded]="type === firstType">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <i *ngIf="type !== 'preinscription' && 'assistant maternel'" class="icon icodomino-{{ type }}"></i>
              <i *ngIf="type === 'preinscription'" class="icon icodomino-pre-inscription"></i>
              <i *ngIf="type === 'assistant maternel'" class="icon icodomino-assmat"></i>
              <span class="title">{{ ('ged.type.' + type)|trans|titlecase }}</span>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <ng-template matExpansionPanelContent>

            <mat-accordion multi="false">
              <ng-container *ngIf="type === 'inscription' || type === 'preinscription'">
                <ng-container *ngFor="let typeStructure of knownTypesStructures">
                  <mat-expansion-panel
                    *ngIf="typeData.entitiesByTypeStructure[typeStructure]?.entities?.length && typeData.entitiesByTypeStructure[typeStructure]?.pieces?.length && typeData.entitiesByTypeStructure[typeStructure] as typeStructureData">
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        <span class="title">{{typeStructureData.label}}</span>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <ng-template matExpansionPanelContent>
                      <mat-card *ngFor="let entite of typeStructureData.entities" class="pieces-card">
                        <mat-card-header *ngIf="entite.name !== ''" class="card-header">
                          <mat-card-title>
                            <div fxLayout="row" fxLayoutAlign="center center">
                              <mat-icon>arrow_right</mat-icon>
                              <span>{{ entite.name }} <br>
                                <span *ngIf="entite.details"
                                  style="font-size:small; font-style:italic;margin-right: 2px;">{{entite.details}}</span>
                                <span *ngIf="entite.anneeScolaire"
                                  style="font-size:small; font-style:italic;margin-right: 2px;">({{entite.anneeScolaire}})</span>
                                <span *ngIf="entite.dateDemande"
                                  style="font-size:small; font-style:italic;">({{entite.dateDemande | date:"shortDate"}})</span>
                                <span *ngIf="entite.typeStructure === 'Diabolo' || entite.typeStructure === 'Mikado'"
                                  style="font-size:small; font-style:italic;">
                                  <ng-container *ngIf="entite.dateDebut && entite.dateDebut != '0000-00-00'">
                                    <span *ngIf="entite.dateFin && entite.dateFin != '0000-00-00'; else fromDate">
                                      (du {{ entite.dateDebut|date:'shortDate' }} au
                                      {{ entite.dateFin|date:'shortDate' }})
                                    </span>
                                    <ng-template #fromDate>
                                      <span>(à partir du {{ entite.dateDebut|date:'shortDate' }})</span>
                                    </ng-template>
                                  </ng-container>
                                </span>
                              </span>
                            </div>

                            <mat-icon *ngIf="entite.error" color="warn" class="error" [matTooltip]="entite.error"
                              matTooltipClass="tooltip-pre-line tooltip-large">
                              warning
                            </mat-icon>
                          </mat-card-title>
                        </mat-card-header>

                        <mat-card-content>
                          <ng-container [ngTemplateOutlet]="panelContentCard" [ngTemplateOutletContext]="{
                        entite: entite,
                        pieces: typeData.pieces,
                        documents: typeData.documents
                      }"></ng-container>
                        </mat-card-content>
                      </mat-card>

                    </ng-template>

                  </mat-expansion-panel>
                </ng-container>


              </ng-container>
            </mat-accordion>

            <ng-container *ngIf="type !== 'inscription' || type !== 'preinscription'">
              <mat-card *ngFor="let entite of typeData.entities" class="pieces-card">
                <mat-card-header *ngIf="entite.name !== ''" class="card-header">
                  <mat-card-title>
                    <div fxLayout="row" fxLayoutAlign="center center">
                      <mat-icon>arrow_right</mat-icon>
                      <span>{{ entite.name }} <br>
                        <span *ngIf="entite.details"
                          style="font-size:small; font-style:italic;margin-right: 2px;">{{entite.details}}</span>
                        <span *ngIf="entite.anneeScolaire"
                          style="font-size:small; font-style:italic;margin-right: 2px;">({{entite.anneeScolaire}})</span>
                        <span *ngIf="entite.dateDemande"
                          style="font-size:small; font-style:italic;">({{entite.dateDemande | date:"shortDate"}})</span>
                      </span>
                    </div>
                    <mat-icon *ngIf="entite.error" color="warn" class="error" [matTooltip]="entite.error"
                      matTooltipClass="tooltip-pre-line tooltip-large">
                      warning
                    </mat-icon>
                  </mat-card-title>
                </mat-card-header>

                <mat-card-content>
                  <ng-container [ngTemplateOutlet]="panelContentCard" [ngTemplateOutletContext]="{
                    entite: entite,
                    pieces: typeData.pieces,
                    documents: typeData.documents
                  }"></ng-container>
                </mat-card-content>
              </mat-card>
            </ng-container>
          </ng-template>
        </mat-expansion-panel>
      </ng-container>
    </mat-accordion>
  </div>
</div>

<ng-template #panelContentCard let-pieces="pieces" let-entite="entite" let-documents="documents">
  <ng-container *ngFor="let piece of pieces">
    <div *ngIf="entite.typeStructure === piece.typeStructure" class="piece-container">
      <div class="piece-content">
        <div fxLayout="row wrap" fxLayoutAlign="start center">
          <b>{{ piece.name }}</b>
          <span *ngIf="piece.pieceJointeObligatoire" class="doc-obligatoire">
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-icon *ngIf="!getDocumentsFor(documents, piece, entite, true)?.length" color="warn">error_outline
              </mat-icon>
              <span>(Document obligatoire)</span>
            </div>
          </span>
        </div>

        <div class="piece-description">
          <div [innerHtml]="piece.description | safe"></div>
        </div>

        <div *ngIf="getDocumentsFor(documents, piece, entite) as filteredDocs" class="documents-list">
          <ng-container [ngTemplateOutlet]="docsList"
            [ngTemplateOutletContext]="{docs: filteredDocs, entite: entite, piece: piece, valid: true}"></ng-container>

          <ng-container *ngIf="showInvalideFile" [ngTemplateOutlet]="docsList"
            [ngTemplateOutletContext]="{docs: filteredDocs, entite: entite, piece: piece, valid: false}"></ng-container>
        </div>
      </div>

      <div class="piece-buttons">
        <button *ngIf="piece?.accueils.length" mat-mini-fab matTooltip="Informations" color="accent"
          (click)="openPieceDetailsDialog(piece)">
          <app-icon type="fa" icon="info"></app-icon>
        </button>

        <button mat-mini-fab color="primary" *ngIf="permService.hasPermission('document_add')"
          matTooltip="Ajouter un fichier" (click)="inputFile.click()">
          <input #inputFile type="file" hidden (change)="onFileSelect($event.target.files, entite, piece)">
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </div>
  </ng-container>


</ng-template>

<ng-template #docsList let-docs="docs" let-entite="entite" let-piece="piece" let-valid="valid">
  <!-- @NB: this makes many container and loop item for nothing .. maybe filter before ? (getFilteredDocuments()) -->
  <ng-container *ngFor="let doc of docs">
    <ng-container *ngIf="doc.valid === valid && doc.idEntite === entite.id && doc.idPieceAFournir === piece.id">
      <div class="file" [matMenuTriggerFor]="documentMenu" [matMenuTriggerData]="{doc: doc, valid: valid}">

        <div *ngIf="documentDownload[doc.idDocumentElectronique] as progress; else icon" class="download-progress">
          <mat-progress-spinner [value]="progress" diameter="32">
          </mat-progress-spinner>
          <span>{{ progress }}%</span>
        </div>

        <ng-template #icon>
          <i *ngIf="getFileIcon(doc) as icon; else default" class="far fa-file-{{ icon }}" [class.invalid]="!valid"></i>
          <ng-template #default><i class="far fa-file"></i></ng-template>
        </ng-template>

        <div class="name-file">
          {{ doc.nomFichier }}
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-template>

<mat-menu #documentMenu>
  <ng-template matMenuContent let-doc="doc" let-valid="valid">
    <button mat-menu-item (click)="openDocDetails(doc)">
      <mat-icon color="primary">visibility</mat-icon>
      <span>Détails</span>
    </button>
    <button mat-menu-item (click)="downloadDocument(doc)">
      <mat-icon color="accent">get_app</mat-icon>
      <span>Télécharger</span>
    </button>
    <button mat-menu-item (click)="deleteDocument(doc)" [disabled]="doc.idDocumentElectronique > 0">
      <mat-icon color="warn">delete</mat-icon>
      <span>Supprimer</span>
    </button>
    <div *ngIf="!valid" style="font-size: 11px; text-align: center; margin-top: 10px;"><em>Fichier expiré</em></div>
  </ng-template>
</mat-menu>

<ng-template #pieceDetailsDialog let-data>
  <mat-toolbar mat-dialog-title color="primary">
    <button mat-icon-button [mat-dialog-close] class="close-dialog">
      <mat-icon>close</mat-icon>
    </button>
    <span class="title">{{ data.piece.name }} : Informations</span>
  </mat-toolbar>

  <div mat-dialog-content class="thin-scrollbar">

    <ng-container *ngIf="data.mandatoryAccueils.length" [ngTemplateOutlet]="accueilsList"
      [ngTemplateOutletContext]="{accueils: data.mandatoryAccueils, mandatory: true}"></ng-container>

    <ng-container *ngIf="data.optionalAccueils.length" [ngTemplateOutlet]="accueilsList"
      [ngTemplateOutletContext]="{accueils: data.optionalAccueils, mandatory: false}"></ng-container>

  </div>
</ng-template>

<ng-template #accueilsList let-accueils="accueils" let-forced="mandatory">
  <div>
    <h4>
      <app-icon *ngIf="forced" type="fa" icon="exclamation-triangle" class="icon-exclamation"></app-icon>
      Ce document est {{ forced ? 'obligatoire' : 'demandé' }} dans
      {{ accueils.length > 1 ? 'les Établissements / Accueils suivants :' : 'l\'Établissement / Accueil suivant :' }}
    </h4>

    <div *ngFor="let accueil of accueils" class="accueil-list-item">
      <app-icon type="do" icon="accueil" class="icon"></app-icon>
      <span class="name">
        {{ (accueil.nameEtablissement + ' ' + accueil.name).trim() }}
      </span>
    </div>
  </div>
</ng-template>

<ng-template #loader>
  <app-loader [message]="'Chargement des données...'" class="margin"></app-loader>
</ng-template>
