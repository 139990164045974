<mat-drawer-container>
  <mat-drawer-content>
    <div id="reunion-conteneur" class="thin-scrollbar" *ngIf="listReunionsFiltered; else loader">
      <div fxLayout="row wrap" fxLayoutAlign="center center">

        <mat-form-field class="no-hint" style="margin:0 5px 0 5px;" fxFlex="50" fxFlex.sm="100" fxFlex.xs="100">
          <mat-label>Rechercher</mat-label>
          <input matInput [(ngModel)]="filters.search" (keyup)="changeFilter($event)">
        </mat-form-field>

        <!-- <mat-form-field class="no-hint" style="min-width: 350px; flex: 1; font-size: small;">
        <mat-label>Etablissements</mat-label>
        <mat-select [(ngModel)]="filters.regie" (ngModelChange)="changeFilter($event)">
          <mat-option style="font-style: italic;">Tous</mat-option>
          <mat-option *ngFor="let reunion of listReunions" [value]="reunion.etablissement.id">
            {{ reunion.etablissement.designation }}
          </mat-option>
        </mat-select>
      </mat-form-field> -->

        <mat-form-field class="no-hint" style="margin:0 5px 0 5px; max-width: 160px;">
          <mat-label>Du</mat-label>
          <input matInput [(ngModel)]="filters.fromDate" [matDatepicker]="fromDatePicker"
            (dateChange)="changeFilter($event)">
          <mat-datepicker-toggle matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #fromDatePicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="no-hint" style="margin:0 5px 0 5px; max-width: 160px;">
          <mat-label>Au</mat-label>
          <input matInput [(ngModel)]="filters.toDate" [matDatepicker]="toDatePicker"
            (dateChange)="changeFilter($event)">
          <mat-datepicker-toggle matSuffix [for]="toDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #toDatePicker></mat-datepicker>
        </mat-form-field>
      </div>

      <div *ngIf="!listReunionsFiltered.length" style="width: 100%; text-align: center;">
        <h3>{{'animation_reunion.animation.no_reunions_available'|trans}}</h3>
      </div>

      <div style="max-width:929px; margin: 10px auto auto auto;">
        <mat-accordion class="headers-align">
          <ng-container *ngFor="let reunion of listReunionsFiltered">
            <mat-expansion-panel [ngStyle]="{'opacity': reunion.saving ? 0.7 : 1}">
              <mat-expansion-panel-header>
                <mat-panel-title style="font-weight:bold;" fxLayout="row" fxLayoutAlign="start center">
                  <span>{{reunion.titre}}</span>
                </mat-panel-title>
                <mat-panel-description style="min-height: 40px;">
                  <span> Le {{reunion.date | date: 'longDate'}} de {{reunion.heureDebut}} à {{reunion.heureFin}}</span>
                  <span matTooltip="Places restantes">
                    <app-icon type="fa" icon="ticket-alt" [matBadge]="reunion.nbPlacesRestantes" matBadgeSize="small"
                      style="margin-right: 10px;" matBadgeColor="accent" class="primary">
                    </app-icon>
                  </span>
                </mat-panel-description>
              </mat-expansion-panel-header>

              <div *ngIf="reunion.description" class="description-html"><span
                  [innerHTML]="reunion.description | safe"></span>
              </div>
              <div>
                <div style="margin-top: 10px;">
                  <span
                    style="text-decoration: underline; margin-bottom: 5px;"><b>{{'animation_reunion.etablissement_organisateur' | trans}}
                      :</b></span>
                  <div style="margin-left:10px;">
                    <span *ngIf="reunion.etablissement.designation">{{reunion.etablissement.designation}}<br></span>
                    <span *ngIf="reunion.etablissement.rue1">{{ reunion.etablissement.rue1 }}<br></span>
                    <span *ngIf="reunion.etablissement.rue2">{{ reunion.etablissement.rue2 }}<br></span>
                    <span>{{ reunion.etablissement.codePostal }}
                      {{ reunion.etablissement.ville }}</span>
                  </div>
                </div>
                <div style="margin-top: 10px;">
                  <span style="text-decoration: underline; margin-bottom: 5px;">
                    <b>Lieu de la reunion : </b></span>
                  <div style="margin-left:10px;">
                    <span *ngIf="reunion.lieu">{{reunion.lieu}}<br></span>
                    <span *ngIf="reunion.rue1">{{ reunion.rue1 }}<br></span>
                    <span *ngIf="reunion.rue2">{{ reunion.rue2 }}<br></span>
                    <span>{{ reunion.codePostal }} {{ reunion.ville }}</span>
                  </div>
                </div>
              </div>
              <mat-action-row fxLayout="row" fxLayoutAlign="space-between center">
                <div>
                  <p><b>Places restantes : </b>{{ reunion.nbPlacesRestantes }}</p>
                  <p><b>Date limite d'inscription :</b> {{ reunion.dateEcheanceInscription | date: 'longDate' }}</p>
                </div>
                <button *ngIf="!reunion.assmatIsRegistered" mat-raised-button color="primary"
                  (click)="openRegisterChildDialog(reunion)" [disabled]="reunion.saving">
                  S'inscrire
                </button>
              </mat-action-row>
            </mat-expansion-panel>
          </ng-container>

        </mat-accordion>
      </div>

    </div>

  </mat-drawer-content>

  <!-- Options d'affichage -->
  <mat-drawer id="previewDrawer" class="mat-elevation-z4 thin-scrollbar" position="end" [(opened)]="drawerOpened">
    <mat-toolbar fxLayout="row" fxLayoutAlign="space-between center" class="preview-header" color='primary'>
      <button mat-icon-button (click)="closeDrawer()">
        <mat-icon>close</mat-icon>
      </button>
      <span>Options d'affichage</span>
      <span></span>
    </mat-toolbar>

    <div fxLayout="column" style="margin: 5px; padding: 5px;">

    </div>

  </mat-drawer>
</mat-drawer-container>

<!-- Inscription + ajout enfants dialog -->
<ng-template #RegisterChildDialog let-data>
  <mat-toolbar mat-dialog-title color="primary">
    <button mat-icon-button [mat-dialog-close] class="close-dialog" style="margin-right: 10px;">
      <mat-icon>close</mat-icon>
    </button>
    <span class="title-confirmation-dialog">
      Inscription
    </span>
  </mat-toolbar>
  <div>
    <app-loader-overlay *ngIf="saving"></app-loader-overlay>
    <div mat-dialog-content class="thin-scrollbar">
      <h3 style="text-align: center;">
        {{ 'animation_reunion.reunion.add'|trans:{titre : data.reunion.titre} }}
      </h3>
      <p *ngIf="data.listChild.length">{{'animation_reunion.add_child' | trans}}</p>
      <div class="conteneur-list-child">
        <mat-selection-list [(ngModel)]="registerChildList" style="margin-bottom: 10px;" [disabled]="saving">
          <mat-list-option *ngFor="let child of data.listChild" [value]="child.id" [disabled]="child.id <= 0">
            {{child.nom}} {{child.prenom}} <div fxLayout="row" fxLayoutAlign="start center" *ngIf="child.id <= 0"
              style="font-style:italic; font-size:small">
              <mat-icon color="warn" style="margin-right:5px;">warning</mat-icon>
              <span>{{'animation_reunion.contrat_accueil_en_attente' | trans }}</span>
            </div>
          </mat-list-option>
        </mat-selection-list>
      </div>
    </div>
  </div>

  <div mat-dialog-actions align="center">
    <button mat-raised-button color="warn" (click)="cancel()" [disabled]="saving">Annuler</button>
    <button mat-raised-button color="primary" (click)="clickToConfirm(data.reunion)"
      [disabled]="saving">confirmer</button>
  </div>
</ng-template>

<ng-template #loader>
  <app-loader [message]="'Chargement des données...'" class="margin"></app-loader>
</ng-template>
