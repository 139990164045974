import { Injectable } from '@angular/core';
import { Child } from '@app/models/child';
import { HttpClient, HttpEvent, HttpEventType } from '@angular/common/http';
import { map, tap, delay } from 'rxjs/operators';
import { ChildAdapter } from './adapter/child-adapter.service';
import { ApiCrudService } from './api-crud.service';
import { FamilyService } from './family.service';
import { of, Observable } from 'rxjs';
import { Family } from '@app/models/family';

@Injectable({
  providedIn: 'root'
})
export class ChildService extends ApiCrudService<Child> {

  url = 'enfants';
  formUrl = 'enfants/#idEnfant#/form/#formPart#';

  cachedChildren = null;

  constructor(
    protected http: HttpClient,
    private adapter: ChildAdapter,
    private familyService: FamilyService
  ) {
    super();
  }

  getAll() {
    // Actually forbidden ? (or admin only ..)
    return super.getAll().pipe(
      map((data: []) => data.map(item => this.adapter.adapt(item)))
    );
  }

  getCurrentFamilyChildren(allowFromCache: boolean = false): Observable<Child[]> {
    if (this.familyService.currentFamily) {
      return this.getFamilyChildren(this.familyService.currentFamily, allowFromCache);
    }
    return of(null);
  }

  getFamilyChildren(family: Family | number, allowFromCache: boolean = false): Observable<Child[]> {
    const id = typeof family === 'object' ? family.id : family;

    if (this.cachedChildren && this.cachedChildren[id] && allowFromCache) {
      return of(this.cachedChildren[id])
    }

    return this.http.get<Child[]>(`familles/${id}/enfants`).pipe(
      tap(children => {
        this.cachedChildren = this.cachedChildren || {};
        this.cachedChildren[id] = children;
      }),
      map((data: []) => data.map(item => this.adapter.adapt(item)))
    );
  }

  getFamilyChild(idFamily: number, idChild: number, allowFromCache: boolean = false): Observable<Child> {
    return this.getFamilyChildren(idFamily, allowFromCache).pipe(
      map(children => children.find(c => c.id === idChild))
    );
  }

  create(data: Child | any) {
    this.cachedChildren = null;
    return this.http.post(this.familyService.getCurrentFamilyDependantUrl(this.url), data, this.httpOptions);
  }

  update(childData: Child | any, id?: number, part?: string) {
    this.cachedChildren = null;
    part = part ? part : '';
    const url = this.url + (id ? '/' + id : '') + '/' + part;
    let data = { formData: childData, idFamille: this.familyService.currentFamily.id }
    return this.http.put(url, data, this.httpOptions);
  }

  getFormData(id, part) {
    part = part ? part : '';

    const url = this.formUrl.replace('#idEnfant#', id).replace('#formPart#', part);
    return this.http.get(`${url}?idFamilleEnCours=${this.familyService.currentFamily.id}`);
  }


  sendPhoto(file: File, idEnfant: number) {

    const formData = new FormData();

    formData.set('idEntite', idEnfant + '');

    if (file) {
      formData.set('file', file);
      formData.set('type', file.type);
    }

    return this.http.post(`enfants/${idEnfant}/photo`, formData, {
      reportProgress: true,
      observe: 'events',
    }).pipe(map(event => this.mapProgressResponse(event)));
  }

  getPhoto(idChild: number) {
    return this.http.get(`enfants/${idChild}/photo`, {
      responseType: 'blob',
      reportProgress: true,
      observe: 'events'
    }).pipe(map(response => this.mapProgressResponse(response)));
  }

  mapProgressResponse(event: HttpEvent<any>) {
    switch (event.type) {
      case HttpEventType.DownloadProgress:
        return { type: 'dl_progress', value: Math.round(100 * event.loaded / event.total) };
      case HttpEventType.UploadProgress:
        return { type: 'up_progress', value: Math.round(100 * event.loaded / event.total) };
      case HttpEventType.Response:
        return { type: 'response', value: event.body };
      default:
        return { type: 'otherType', value: event.type };
    }
  }

  deletePhoto(idChild: number) {
    return this.http.delete(`enfants/${idChild}/photo`);
  }

  getAllChildPhotoByIdFamille(family: Family | number) {
    const id = typeof family === 'object' ? family.id : family;
    return this.http.get(`/familles/${id}/photosEnfants`);
  }
}
