import { Component, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { FieldArrayType, FormlyFormBuilder } from '@ngx-formly/core';

@Component({
  selector: 'app-form-order-list',
  templateUrl: './form-order-list.component.html',
  styleUrls: ['./form-order-list.component.scss']
})
export class FormOrderListComponent extends FieldArrayType implements OnInit {

  limit: number;

  constructor(builder: FormlyFormBuilder) {
    super(builder);
  }

  ngOnInit() {
    this.limit = this.to.limit;
  }

  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.model, event.previousIndex, event.currentIndex);

    // Not sure at all /!\ Should be tested with other cases (and adapted)
    moveItemInArray(this.field.fieldGroup, event.previousIndex, event.currentIndex);
  }

}
