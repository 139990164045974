import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-new-password-type',
  template: `
  <app-new-password
   [requiredForce]="to.requiredForce"
   (valueChange)="onValueChange($event)"
   (statusChange)="onStatusChange($event)"
  ></app-new-password>`
})
export class NewPasswordTypeComponent extends FieldType {
  onValueChange(value: string) {
    this.model[this.key as string] = value;
    this.form.get(this.key as string).setValue(value);
  }

  onStatusChange(status: string) {
    this.form.setErrors(status === 'VALID' ? null : { invalid: true });
  }
}
