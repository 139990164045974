import { Component, EventEmitter, Input, OnInit, OnDestroy, Output } from '@angular/core';
import { AuthenticationService, SnackbarService } from '@app/services';
import { OpenIdConfig } from '@app/models/authentification-config';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';



@Component({
  selector: 'app-open-id-form',
  templateUrl: './open-id-connect-form.component.html',
  styleUrls: ['./open-id-connect-form.component.scss']
})
export class OpenIdFormComponent implements OnInit, OnDestroy {

  externalAuth: OpenIdConfig;

  @Input() from: 'login' | 'register';

  @Output() redirecting = new EventEmitter();
  @Output() redirectingMessage = new EventEmitter();

  destroy$ = new Subject();

  constructor(
    private authService: AuthenticationService,
    private snackbarService: SnackbarService
  ) {
  }

  ngOnInit(): void {
    this.authService.getExternalAuthConf().pipe(takeUntil(this.destroy$))
      .subscribe((conf: OpenIdConfig) => this.externalAuth = conf);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  openIdConnect() {
    this.redirecting.emit(true);
    this.redirectingMessage.emit('redirecting_to_oidc');

    this.authService.openIdConnectInitLogin(this.from).subscribe(result => {
      if (result.redirectTo) {
        window.location.href = result.redirectTo;
      } else {
        this.redirecting.emit(false);
        this.snackbarService.error(`Erreur, la requête n'a pas renvoyé l'url de redirection`);
        console.error(`Erreur, la requête n'a pas renvoyé l'url de redirection : `, result)
      }

    })
  }

}
