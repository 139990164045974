import { Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet'
import { AuthenticationService, UserService } from '@app/services';
import { AuthentificationConfig, DoubleAuthConfig } from '@app/models/authentification-config';
import { Router } from '@angular/router';

@Component({
  selector: 'app-totp-restrict-info',
  templateUrl: './totp-restrict-info.component.html',
  styleUrls: ['./totp-restrict-info.component.scss']
})
export class TotpRestrictInfoComponent implements OnInit {

  conf: DoubleAuthConfig;
  message: string

  constructor(
    private _bottomSheetRef: MatBottomSheetRef<TotpRestrictInfoComponent>,
    private authService: AuthenticationService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.authService.getConf().subscribe((conf: AuthentificationConfig) => {
      this.conf = conf.doubleAuth
      this.message = this.conf.msgRestrictedArea
    })
  }

  go() {
    this.router.navigate(['/account/totp-manage'])
    this._bottomSheetRef.dismiss();
  }

  cancel() {
    this._bottomSheetRef.dismiss();
  }

}
