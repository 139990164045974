<!-- <app-loader *ngIf="isLoading">
</app-loader> -->
<!-- <p *ngIf="!isLoading && !isSearching && !total" class="empty-message">{{'messagerie.no_message'|trans }}</p> -->
<ng-template appTopbarActions>
  <button mat-mini-fab color="accent-lighter" [matTooltip]="'messagerie.refresh_messages'|trans" (click)="getMessages()"
    [hidden]="!total">
    <i class="fas fa-redo-alt"></i>
  </button>
  <button mat-mini-fab color="accent-lighter" [disabled]="isLoading" [matTooltip]="'messagerie.add_new_message'|trans"
    (click)="addMessage()" class="add_btn">
    <mat-icon>add</mat-icon>
  </button>
</ng-template>
<div class="container">
  <div class="chat-list mat-elevation-z5" [ngStyle]="{ 'width': (isSmallScreen ? 100 : 40) + '%' }"
    [hidden]="isSmallScreen && message">
    <div class="inbox">
      <i class="far fa-envelope-open envlop-style"></i>
      <span style="font-size: 20px; font-weight: bold;">{{ 'messagerie.message_inbox'|trans }}</span>
    </div>
    <div class="search-input">
      <mat-form-field>
        <input matInput placeholder="Rechercher" (keyup)="applyFilter($event.target.value)">
      </mat-form-field>

    </div>

    <div class="message-card thin-scrollbar">
      <app-loader *ngIf="!isMessagesLoaded"></app-loader>
      <div *ngIf="!total && !isLoading && !isSearching ">
        <p class="empty-message">{{'messagerie.no_message'|trans }}</p>
        <button mat-mini-fab color="accent-lighter" [matTooltip]="'messagerie.add_new_message'|trans"
          (click)="addMessage()" class="add_btn">
          <mat-icon>add</mat-icon>
        </button>
      </div>

      <table mat-table [dataSource]="dataSource" matSort style="width:100%; " [hidden]="!isMessagesLoaded">
        <ng-container matColumnDef="contenu">
          <td class="hover-card" mat-cell *matCellDef="let row"
            [ngClass]="{'focused': message && row.idPortailMessage == message.idPortailMessage}">
            <div [matBadge]="row.newResponseCounts" matBadgeOverlap="false" style="top: 35px;"
              class="demo-section red-badge" *ngIf="row.newResponseCounts"></div>
            <h3 class="message-object" [class.bold-row]="row.newResponseCounts"
              [innerHtml]="row.objetMessage | highlight: search"></h3>
            <div style="margin-bottom: 10px;">
              <span class="etab-send" [class.bold-row]="row.newResponseCounts"
                *ngFor="let etab of  row.etablissements.slice(0,3);let last = last ">{{etab.name}}{{last ? "" : ", "}}
              </span>
            </div>
            <div class="message-contenu" [innerHtml]="(row.contenu | highlight: search) | safe "></div>
            <span class="date-message">{{row.dateHeureEnvoi | date:'short' }}</span>
          </td>
        </ng-container>
        <tr mat-row *matRowDef="let row; columns: ['contenu']" (click)="showDetails(row)" class="preview-detail-row">
        </tr>
      </table>
    </div>
    <mat-paginator (page)="handlePageEvent($event)" [length]="total" [pageSizeOptions]="[10, 20, 50, 100]">
    </mat-paginator>
  </div>
  <div class="chat-details mat-elevation-z5" [ngStyle]="{ 'width': (isSmallScreen ? 100 : 60) + '%' }"
    [hidden]="isSmallScreen && !message">
    <div *ngIf="message" [@fadeInLeft]="isAnimationTriggered ? '*' : 'void'" class="header">
      <div class="messages-header">
        <span (click)="close()" style="cursor: pointer;" matTooltip="Retour">
          <i class="fa fa-arrow-left" aria-hidden="true"></i>
        </span>
        <div style="display: flex; align-items: flex-start;">
          <div class="object-title" style="flex: 1; margin-top: 15px;margin-bottom: 25px;">
            <h2 style="display: inline;">{{ 'messagerie.message_object'|trans }} :
            </h2>
            <span style="font-size: 16px;display: inline;">{{message.objetMessage}}</span>
          </div>
          <div class="infos-msg" *ngIf="!message.isClosed">
            <form>
              <button class="respond-btn" type="button" mat-raised-button color="accent-lighter"
                (click)="addMessage(message)" [matTooltip]="'messagerie.respond_to_first_message'|trans">
                Répondre
              </button>
            </form>
          </div>
        </div>
        <div class="etab-container">
          <div class="etab-to">
            <span>À :</span>
          </div>
          <div class="etablissements">
            <div class="etablissement"
              *ngFor="let etab of (message.showAllEtabs ? message.etablissements : message.etablissements.slice(0, 4))">
              <span>{{etab.name}}</span>
            </div>

            <div class="etablissement" *ngIf="message._etablissements.length > 4"
              (click)="message.showAllEtabs = !message.showAllEtabs">
              <span *ngIf="!message.showAllEtabs">afficher {{message._etablissements.length - 4}} de plus</span>
              <span *ngIf="message.showAllEtabs">afficher moins</span>
            </div>
          </div>
        </div>
        <mat-divider></mat-divider>

      </div>
      <div class="chat-area thin-scrollbar" #chatArea>
        <app-chat-bubble class="chat" [message]="message" [search]="search" [isClosed]="message.isClosed"
          [userId]="userId" (addMessage)="addMessage($event)">
        </app-chat-bubble>

      </div>
      <div class="close-demande" *ngIf="message && message.isClosed">
        <p style="text-align: center;font-size: medium;">{{ 'messagerie.demande_is_closed_user'|trans }}</p>
      </div>
    </div>
    <div *ngIf="!message" class="select_to_read" [hidden]="!total">
      <p>{{ 'messagerie.select_to_read'|trans }}</p>
    </div>
  </div>
</div>
