import { Injectable } from '@angular/core';
import { SnackbarService } from './';
import { FormConfigService } from './';

@Injectable({ providedIn: 'root' })
export class FormHelperService {


  constructor(
    private formConfigService: FormConfigService,
    private snackbarService: SnackbarService,
  ) { }

  notifySuccess(defaultMessage: string, customMessages: string[] = null) {

    let snackOptions: any = {message : (defaultMessage && defaultMessage != '') ? defaultMessage : ''}

    if (customMessages && customMessages.length>0 ){
      snackOptions.textButton = 'Ok' ;
      snackOptions.message += (snackOptions.message!=='') ? '<hr>' : '';
      customMessages.forEach(msg => snackOptions.message+= `<li>${msg}</li>`);
    }

    if (snackOptions.message!== ''){
      this.snackbarService.open(snackOptions)
    }
  }

  displayDebugTraces(traces: string[]) {
    if (traces) {
      traces.forEach(t => console.log(t));
    }
  }

  manageServerError(err, formViewer) : string{
        let errorMessage = '';
    if (typeof (err) === 'object' && err.errors) {
        errorMessage = '';
        this.displayDebugTraces(err.traces);
        if (Array.isArray(err.errors)){
          err.errors.forEach(e => errorMessage += `<li>${e}</li>`);
        }else{
          for (const fieldName in err.errors) {
            if (err.errors.hasOwnProperty(fieldName)) {
              const field = this.formConfigService.findFieldByName(formViewer.formFields, fieldName);
              if (field){
                field.formControl.setErrors({ apiError: err.errors[fieldName] });
                field.formControl.markAsTouched();
                errorMessage += `<li>${(field.templateOptions && field.templateOptions.label) ?
                  field.templateOptions.label : fieldName} : ${err.errors[fieldName]}</li>`;
              }else{
                errorMessage += `<li>${err.errors[fieldName]}</li>`;
              }
            }
          }
        }
    } else if (typeof (err) === 'string') {
      errorMessage = err;
    }

    formViewer.setErrorMessage(errorMessage)        

    return errorMessage;
  }

}
