import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmDialogComponent } from '@app/components/_common/confirm-dialog/confirm-dialog.component';
import { ConfigDiabolo, DiaboloInsc, Periode } from '@app/models/diabolo';
import { FamilyService, HeaderService, PermissionService, SnackbarService } from '@app/services';
import { DiaboloService } from '@app/services/diabolo.service';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';

export interface UsagerForFilter {
  id: number;
  nom: string;
  prenom: string;
}
@Component({
  selector: 'app-diabolo-inscriptions',
  templateUrl: './diabolo-inscriptions.component.html',
  styleUrls: ['./diabolo-inscriptions.component.scss']
})
export class DiaboloInscriptionsComponent implements OnInit {
  inscriptions: DiaboloInsc[];
  inscriptionsFiltered: DiaboloInsc[];
  permToCreate: boolean;
  permToEdit: boolean;
  createInscriptionsActive: boolean;
  isLoaded: boolean;
  config: ConfigDiabolo;
  drawerOpened = false;
  listUsagers: UsagerForFilter[];
  filterUsagers: UsagerForFilter[] = [];
  showDisabledPeriods: boolean = false;
  showExpiredInscriptions: boolean = false;
  idFamille: number;

  onDestroy$ = new Subject();

  constructor(
    private permService: PermissionService,
    private familyService: FamilyService,
    private diaboloService: DiaboloService,
    private router: Router,
    private snackbarService: SnackbarService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private headerService: HeaderService
  ) { }

  ngOnInit(): void {
    this.permToEdit = this.permService.hasPermission('diabolo_inscriptions_edit') && this.familyService.currentFamily.active;
    this.permToCreate = this.permService.hasPermission('diabolo_inscriptions_create') && this.familyService.currentFamily.active;
    this.idFamille = this.familyService.currentFamily.id;
    this.loadData().subscribe(_ => {
      this.createInscriptionsActive = this.diaboloService.checkPermInscription(this.config);
      this.listUsagers = this.setListUsager(this.inscriptions);
      this.inscriptionsFiltered = [...this.inscriptions];
      this.isLoaded = true;
    })
  }

  loadData() {
    return this.familyService.currentFamily$.pipe(
      filter(f => !!f),
      takeUntil(this.onDestroy$),
      tap(_ => { this.inscriptions = null; }),
      switchMap(family => this.diaboloService.getFamilyInscriptions(family).pipe(tap((data: any) => {
        this.config = data.config;
        this.filterTabPeriode(data.inscriptions);
        this.inscriptions = data.inscriptions.reverse();
      }))
      )
    );
  }

  setListUsager(inscriptions: DiaboloInsc[]): UsagerForFilter[] {
    let listUsagersInscriptions = inscriptions.map(insc => {
      return { id: insc.usager.id, nom: insc.nom, prenom: insc.prenom }
    })

    return listUsagersInscriptions.filter((value, index, self) =>
      index === self.findIndex((child) => (
        child.id === value.id && child.nom === value.nom && child.prenom === value.prenom
      ))
    )
  }

  onClickEdit(item: any) {
    this.router.navigate([`account/diabolo-inscriptions/${item.idInscriptionDiabolo}`]);
  }

  onClickDelete(idInscription: number) {

    const ref = this.dialog.open(ConfirmDialogComponent, { data: { message: this.translate.instant('diabolo.confirm_delete_message') } });
    ref.afterClosed().subscribe(result => {
      if (result) {
        this.isLoaded = false;
        this.diaboloService.eraseInscription(this.idFamille, idInscription).subscribe(_ => {
          this.diaboloService.getFamilyInscriptions(this.idFamille).subscribe((data: any) => {
            this.filterTabPeriode(data.inscriptions);
            this.inscriptions = data.inscriptions.reverse();
            this.createInscriptionsActive = this.diaboloService.checkPermInscription(this.config);
            this.setListUsager(this.inscriptions);
            this.inscriptionsFiltered = [...this.inscriptions];
            this.isLoaded = true;
          }, err => {
            this.snackbarService.error('Une erreur s\'est produite lors de la récupération des inscriptions.')
            console.error(err)
          })

          this.snackbarService.info(this.translate.instant('diabolo.snackbar_delete_message'));
        }, err => {
          this.snackbarService.error('Une erreur s\'est produite lors de la suppression.')
          console.error(err)
        });
      }
    });

  }

  filterTabPeriode(inscriptions: DiaboloInsc[]) {
    inscriptions.forEach((insc: DiaboloInsc) => {
      insc._periodes = insc._periodes.filter((periode: Periode) => this.config.periodeIds.includes(periode.idPeriode))
    })
  }

  closeDrawer() {
    this.drawerOpened = false;
  }

  toggleOptions() {
    this.drawerOpened = !this.drawerOpened;
  }

  changeSelectUser($event) {
    this.applyFilter();
  }

  applyFilter() {

    if (this.filterUsagers.length) {
      this.inscriptionsFiltered = this.inscriptions.filter((insc: DiaboloInsc) => {

        return this.filterUsagers.some((usager: UsagerForFilter) => {
          return usager.nom === insc.nom && usager.prenom === insc.prenom && usager.id === insc.usager.id;
        });

      })
    } else {
      this.inscriptionsFiltered = this.inscriptions;
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
