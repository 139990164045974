import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExtendedFormlyFieldConfig, ExtendedFormlyFormOptions } from '@app/forms/config/form-model';
import { FormConfigService } from '@app/services';
import { clone } from '@app/utils/object';
import { of } from 'rxjs';


@Component({
  selector: 'app-formly-data-table-dialog',
  templateUrl: './datatable.dialog.component.html',
  styles: [ /*css*/``],
})
export class DataTableTypeDialogComponent implements OnInit {
  form: UntypedFormGroup;
  model: {};
  fields: ExtendedFormlyFieldConfig[];
  options: ExtendedFormlyFormOptions[];
  // Suspend submit while form init, to avoid too early validation, and error "Expression has changed after it was checked"
  suspendSubmit = true;

  constructor(
    public dialogRef: MatDialogRef<DataTableTypeDialogComponent>,
    private formConfigService: FormConfigService,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.form = new UntypedFormGroup({});
    this.model = clone(this.data.field.model);
    this.fields = clone(this.data.field.fieldGroup);
    
    for (const field of this.fields) {
      if(field.fieldGroup){
        const fieldsCloned = field.fieldGroup;
        this.formConfigService.parseAllFields(fieldsCloned, (field) => {
          if (field.format) {
            setTimeout(() => this.formConfigService.applyFormatter(field));
          }
        })
      }
    }

    if (this.data.options.formState.disabled || this.data.readOnly) {
      setTimeout(() => this.form.disable());
    } else {
      setTimeout(() => {
        this.suspendSubmit = false;
        if (this.data.field.formControl.invalid) { this.markAllTouched(this.form as UntypedFormGroup); }
      });
    }
  }

  onEnter(event: KeyboardEvent) {
    const target = event.target as HTMLElement;
    if (target.localName === 'textarea') {
      return;
    }
    if (this.data.options.formState.disabled) {
      return;
    }
    this.validate();
  }

  // Mark all fields as "touched" to notice the user of fields with error, even ones he didn't touch (mostly required)
  validate() {
    if (!this.form.valid) {
      this.markAllTouched(this.form as UntypedFormGroup);
    }
  }

  markAllTouched(formGroup: UntypedFormGroup | UntypedFormArray) {

    const controls = formGroup instanceof UntypedFormArray ?
      formGroup.controls : Object.keys(formGroup.controls).map(k => formGroup.controls[k]);

    controls.forEach(control => {
      control.markAsTouched();

      if ((control instanceof UntypedFormGroup || control instanceof UntypedFormArray) && control.controls) {
        this.markAllTouched(control);
      }
    });
  }

  onSubmit() {
    if (this.form.valid) {
      this.dialogRef.close(this.model);
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }

}
