<div #formlyValidationMessage *ngIf="showError" class="mat-error">
  <formly-validation-message [field]="field">
  </formly-validation-message>
</div>

<div class="data-row row display-flex-row" *ngFor="let item of field.fieldGroup; let i = index;">
  <div class="data-container ">

    <div *ngIf="item.title" [innerHTML]="item.title|safe:'html'"></div>

    <span *ngFor="let colDef of to.columns" [ngClass]="colDef.className">
      {{ getColValue(item,colDef.key) }}
    </span>
    <div *ngIf="item.formControl.invalid" class="item-alert">
      <mat-icon>warning</mat-icon>
      {{to.invalidItemLabel }}
    </div>

  </div>

  <div class="buttons-container">

    <ng-container *ngIf="!(options.formState.disabled || field.readOnly)">
      <button mat-icon-button type="button" [color]="(item.formControl.invalid)?'accent' : 'primary'"
        (click)="openEditDialog(item,i)" matTooltip="Voir / modifier">
        <mat-icon>edit</mat-icon>
      </button>
      <button mat-icon-button type="button" color="primary" (click)="onRemoveClick(i)" matTooltip="Supprimer">
        <mat-icon>delete</mat-icon>
      </button>
    </ng-container>
    <ng-container *ngIf="options.formState.disabled || field.readOnly">
      <button mat-icon-button type="button" color="primary" (click)="openEditDialog(item,i)" matTooltip="Voir">
        <mat-icon>pageview</mat-icon>
      </button>
    </ng-container>
  </div>
</div>

<div style="margin:10px 0;" *ngIf="!options.formState.disabled && !field.readOnly">
  <button type="button" style="display:block; margin:auto;" [disabled]="to.max && model.length >= to.max"
    mat-raised-button color="primary" (click)="onAddClick()">
    <mat-icon>add</mat-icon>
    {{ 'Ajouter '+to.itemLabel }}
  </button>
</div>

<ng-template #suggestionDialog>
  <div class="suggest-dialog">
    <h1 mat-dialog-title>Sélectionner ou ajouter {{ to.itemLabel }}</h1>
    <div mat-dialog-content>
      <div *ngIf="showError" class="mat-error">
        <formly-validation-message [field]="field">
        </formly-validation-message>
      </div>

      <div class="data-row row display-flex-row" *ngFor="let item of suggestedItemsFiltered ; let i = index;">
        <mat-checkbox [(ngModel)]="item.checked" class="">
          <span *ngFor="let colDef of to.columns" [ngClass]="colDef.className">
            {{item[colDef.key]}}
          </span>
        </mat-checkbox>
      </div>

      <button mat-button color="primary" mat-dialog-close (click)="openEditDialog(null, -1 )" class="add-new-button">
        <mat-icon>add_circle</mat-icon>
        {{ 'Ajouter '+ to.itemLabel }}
      </button>
    </div>
    <div mat-dialog-actions>
      <button class="close-dialog" mat-raised-button color="primary" mat-dialog-close>
        <mat-icon>close</mat-icon> Annuler
      </button>
      <button mat-raised-button color="accent" mat-dialog-close (click)="validateSelection()"
        [disabled]="!selectionIsValid()">
        <mat-icon>check</mat-icon>
        Valider la sélection
      </button>
    </div>

  </div>

</ng-template>
