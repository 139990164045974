import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Messagerie } from '@app/models/message';
import { ApiCrudService } from './api-crud.service';
import { AuthenticationService } from './authentication.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

interface MessagerieResponse {
  messages: Messagerie[];
  total: number
}

@Injectable({ providedIn: 'root' })
export class MessagerieService extends ApiCrudService<Messagerie> {
  url = 'messages';
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(
    protected http: HttpClient,
    private authService: AuthenticationService,
  ) {
    super();
  }

  get currentUser() {
    return this.authService.currentUserValue;
  }

  getMessages(offset, perPage, search, idEtablissement) {
    return this.http.get<MessagerieResponse>(`${this.url}?offset=${offset}&perPage=${perPage}&search=${search}&idEtablissement=${idEtablissement}`, this.httpOptions)
  }

  createMessage(data: FormData) {
    return this.http.post(`message`, data);
  }

  getFile(idMessagePJ) {
    return this.http.get(`${this.url}/files/${idMessagePJ}`, {
      responseType: 'blob',
      reportProgress: true,
      observe: 'events'
    }).pipe(map(response => this.mapProgressResponse(response)));
  }

  mapProgressResponse(event: HttpEvent<any>) {
    switch (event.type) {
      case HttpEventType.DownloadProgress:
        return { type: 'dl_progress', value: Math.round(100 * event.loaded / event.total) };
      case HttpEventType.UploadProgress:
        return { type: 'up_progress', value: Math.round(100 * event.loaded / event.total) };
      case HttpEventType.Response:
        return { type: 'response', value: event.body };
      default:
        return { type: 'otherType', value: event.type };
    }
  }

  markMessageAsRead(idMessage: number): Observable<void> {
    const url = `message/markAsRead/${idMessage}`;
    return this.http.put<void>(url, this.httpOptions);
  }

  closeConversation(messageId: number): Observable<void> {
    const url = `message/close/${messageId}`;
    return this.http.put<void>(url, this.httpOptions);
  }

  getUnreadMessages() {
    return this.http.get<number>(`${this.url}/unreadMessages`, this.httpOptions)
  }

  markMessageAsUnread(idMessage: number): Observable<void> {
    const url = `message/markAsUnread/${idMessage}`;
    return this.http.put<void>(url, this.httpOptions);
  }

}