<table mat-table [dataSource]="dataSource" matSort matSortActive="date" matSortDirection="desc">

  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="date" style="width: 20%;">
      {{ 'facture.data.date'|trans }}
    </th>
    <td mat-cell *matCellDef="let facture">{{ facture.date|date:'shortDate' }}</td>
    <td mat-footer-cell *matFooterCellDef>{{ 'facture.data.total'|trans }}</td>
  </ng-container>

  <ng-container matColumnDef="details">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="num" style="width: 20%;">
      {{ 'facture.data.num'|trans }}
    </th>
    <td mat-cell *matCellDef="let facture">{{ facture.num }}</td>
    <td mat-footer-cell *matFooterCellDef>{{ totalNumber }}</td>
  </ng-container>

  <ng-container matColumnDef="montantFacture">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="montant" style="width: 20%;">
      {{ 'facture.data.montant'|trans }}
    </th>
    <td mat-cell *matCellDef="let facture" class="montant-facture">{{ facture.montant | currency:'EUR' }}</td>
    <td mat-footer-cell *matFooterCellDef>
      <span *ngIf="config.afficheTotaux">{{ totalMontant|currency:'EUR' }}</span>
    </td>
  </ng-container>

  <ng-container matColumnDef="etat">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="status" style="width: 5%;">
      <span>{{ 'facture.data.etat'|trans }}</span>
    </th>
    <td mat-cell *matCellDef="let facture">
      <span class="row items-center content-center">
        <mat-icon *ngIf="facture.status === 'Paye'" class="success-color" [matTooltip]="'facture.status.Paye'|trans">
          check
        </mat-icon>
        <mat-icon *ngIf="facture.status === 'EnAttente'" color="primary"
          [matTooltip]="'facture.status.EnAttente'|trans">
          hourglass_empty
        </mat-icon>
        <mat-icon *ngIf="facture.status === 'Contentieux'" color="warn"
          [matTooltip]="config.libelleContentieux || ('facture.status.Contentieux'|trans)">
          warning
        </mat-icon>
      </span>
    </td>
    <td mat-footer-cell *matFooterCellDef>
    </td>
  </ng-container>

  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef style="width: 10%;">{{ 'facture.table.actions'|trans }}</th>
    <td mat-cell *matCellDef="let facture">
      <button mat-icon-button [matTooltip]="'facture.action.details_tooltip'|trans" (click)="openDetail(facture)">
        <mat-icon color="primary">more_vert</mat-icon>
      </button>
    </td>
    <td mat-footer-cell *matFooterCellDef>
      <span *ngIf="config.afficheTotaux && config.afficheEtatFacture">
        {{ ('facture.table.solde_' + (totalReste < 0 ? 'faveur' : 'du'))|trans }}
        &nbsp;: {{ (totalReste < 0 ? totalReste * -1 : totalReste)|currency:'EUR' }}
      </span>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  <tr mat-footer-row *matFooterRowDef="displayedColumns" [style.display]="config.afficheTotaux ? '' : 'none'"></tr>
</table>

<mat-paginator [pageSizeOptions]="[10, 20, 50, 100, 200]" showFirstLastButtons></mat-paginator>
