import { Component, OnInit, Input, ElementRef, OnDestroy, HostBinding } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'app-loader-overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.scss']
})
export class LoaderOverlayComponent implements OnInit {

  @HostBinding('class.overlay-container') true;

  @Input() loaderSize = 2; // The most common case is a quite big loader
  @Input() loaderMessage: string;
  @Input() loaderColor: ThemePalette = 'accent';

  containerInitialStyle = {} as any;

  constructor(
    private el: ElementRef
  ) { }

  ngOnInit() {
    const parent = this.el.nativeElement.parentNode;

    this.containerInitialStyle.position = parent.style.position;
    parent.style.position = 'relative';

    this.containerInitialStyle.display = parent.style.display;
    parent.style.display = 'block';

    this.containerInitialStyle.minHeight = parent.style.minHeight;
    // Because spinner takes approx this to display, and if no height, no background
    parent.style.minHeight = '70px';
  }

  // ngOnDestroy(): void {
  //   this.el.nativeElement.parentNode.style.position = this.containerInitialStyle.position;
  //   this.el.nativeElement.parentNode.style.display = this.containerInitialStyle.display;
  //   this.el.nativeElement.parentNode.style.minHeight = this.containerInitialStyle.minHeight;
  // }

}
