import { Injectable } from '@angular/core';
import { MatSidenav, MatSidenavContent } from '@angular/material/sidenav';
import { NavItem } from '@app/models/nav-item';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiCrudService } from './api-crud.service';
import { map, tap } from 'rxjs/operators';
import { BaseConfigService, loadConf } from './config/base.service';

@Injectable({ providedIn: 'root' })
export class MenuService extends ApiCrudService<NavItem> {

  url = 'conf/menu';

  sidenav: MatSidenav;
  mainContent: MatSidenavContent;
  menuItems$ = new BehaviorSubject<NavItem[]>(null);
  menuVisible$ = new BehaviorSubject<boolean>(false);

  constructor(
    protected http: HttpClient,
    private baseConfigService: BaseConfigService
  ) {
    super();
  }

  getAll() {
    return super.getAll().pipe(
      map((res: any) => 'items' in res ? res.items : res)
    );
  }

  initMenuItems() {
    if (!this.menuItems$.value) {
      this.menuItems$.next(this.baseConfigService.getFirstConf("menu-items").content.items);
    }
  }

  updateMenuItems(items: NavItem[]): Observable<any> {
    return this.update({ items }).pipe(
      tap(_ => this.menuItems$.next(JSON.parse(JSON.stringify(items))))
    );
  }

  getChangelog() {
    return this.http.get('/assets/CHANGELOG.md?ngsw-bypass=true', {
      responseType: 'text',
      headers: new HttpHeaders({ 'Content-Type': 'text/plain' })
    });
  }

  setVisibility(value: boolean) {
    setTimeout(() => {
      this.menuVisible$.next(value);
    });
  }
}
