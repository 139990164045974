import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

export const HTTP_OPTIONS_JSON = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

export abstract class ApiCrudService<T extends any> {

  url: string;

  protected http: HttpClient;

  httpOptions = HTTP_OPTIONS_JSON;

  // Basic CRUD methods

  get(id?: number | any): Observable<T> {
    return this.http.get<T>(this.url + (id ? '/' + id : ''));
  }

  create(data: T | any) {
    return this.http.post(this.url, data, this.httpOptions);
  }

  update(data: T | any, id?: string | number) {
    const url = this.url + (id ? '/' + id : '');

    return this.http.put(url, data, this.httpOptions);
  }

  delete(item) {
    const id = item && typeof item === 'object' ? item.id : item;

    return this.http.delete(`${this.url}/${id}`);
  }

  // Helpers

  getAll(): Observable<T[]> {
    return this.http.get<T[]>(this.url);
  }

  // @TODO: updateOrCreate() ?
}
